import httpClient from "@/libs/http-client";

//아이템 리스트
export function apiItemList(data) {
  const url = "/item/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//아이템 구매
export function apiItemPurchase(data) {
  const url = "/item/purchase";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//아이템 선물
export function apiItemGift(data) {
  const url = "/item/gift";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

import httpClient from "@/libs/http-client";
import { setAuthToken, getAuthToken } from "@/libs/auth-helper";

//채팅방 리스트
export function apiRoomList(data) {
  const url = "/chat/room/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//채팅방 생성
export function apiRoomOpen(data) {
  const url = "/chat/open";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//채팅방 개설권 리스트
export function apiChatItemList() {
  const url = "/chat/item/list";
  return httpClient.get(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//채팅방 입장 확인
export function apiRoomImmigration(data) {
  const url = "/chat/immigration";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 채팅방 상세 정보
export function apiRoomInfo(data) {
  const url = "/chat/room/info";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 채팅방 방 컨트롤
// 채팅방 방정보 수정
export function apiRoomInfoUpdate(data) {
  const url = "/chat/room/info/magic";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 채팅방 방 삭제
export function apiRoomDestroy(data) {
  const url = "/chat/room/destroy";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//채팅방 관리
//채팅방 참가자 리스트
export function apiRoomFanList(data) {
  const url = "/chat/room/fan/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 채팅방 강퇴 리스트
export function apiRoomVanishList(data) {
  const url = "/chat/room/fan/vanish/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//채팅방 참가자 강퇴
export function apiRoomFanVan(data) {
  const url = "/chat/room/fan/vanish";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//채팅방 참가자 권한 셋팅
export function apiRoomFanSetting(data) {
  const url = "/chat/room/fan/setting";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 채팅방 추천 하기
export function apiRoomLike(data) {
  const url = "/chat/room/ddabong";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//본인 개설 채팅방 여부
export function apiRoomMine() {
  const url = "/chat/own";
  return httpClient.get(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 채팅방 방장픽 리스트
export function apiMasterPickList(data) {
  const url = "/chat/room/pick/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 채팅방에 보석 기부
export function apiJewelDonation(data) {
  const url = "/chat/room/domination";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//채팅방 상태변경
// frozen, clean
export function apiChatRoomSetting(method) {
  const url = `/chat/room/setting/${method}`;
  return httpClient.post(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 1:1채팅 신청
export function apiChatPersonal(data) {
  const url = "/chat/personal";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 1:1채팅방 호출
export function apiChatPersonalBell(data) {
  const url = "/chat/personal/bell";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 1:1채팅방 조회 , 유저 접속 조회
export function apiChatPersonalCheck(data) {
  const url = "/chat/personal/check";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 1:1채팅방 삭제
export function apiChatPersonalDel(data) {
  const url = "/chat/personal/del";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 1:1채팅방 방정보
export function apiChatPersonalInfo(data) {
  const url = "/chat/personal/info";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 1:1채팅대화리스트
export function apiChatPersonalList() {
  const url = "/chat/personal/list";
  return httpClient.get(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

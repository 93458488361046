import board from "./board";
import user from "./user";
import cscenter from "./cscenter";
import chat from "./chat";
import family from "./family";
import market from "./market";
import game from "./game";
import popup from "./popup";
import policy from "./policy";

const member = [board, cscenter, user, market, family, game, popup, policy];

const routes = [
    {
        path: "/",
        name: "main",
        component: () => import("@/views/pc"),
        meta: {
            layout: "default",
            userOnly: false,
        },
    },
    {
        path: "/member",
        name: "member",
        component: () => import("@/views/pc/member"),
        meta: {
            layout: "default",
            userOnly: false,
        },
        children: member,
    },
    {
        path: "/family_rank",
        name: "family_rank",
        component: () => import("@/views/pc/member/rank/familyRank"),
        meta: {
            layout: "default",
            userOnly: false,
        },
    },
    {
        path: "/chat_rank",
        name: "chat_rank",
        component: () => import("@/views/pc/member/rank/chatRank"),
        redirect: {
            name: "chat_rank_list",
        },
        meta: {
            layout: "default",
            userOnly: false,
        },
        children: [
            {
                path: "/chat_rank/list",
                name: "chat_rank_list",
                component: () => import("@/views/pc/member/rank/chatRank/list"),
                meta: {
                    layout: "default",
                    userOnly: false,
                },
            },
        ],
    },

    {
        path: "/pickster_rank",
        name: "pickster_rank",
        component: () => import("@/views/pc/member/rank/picksterRank/index"),
        redirect: {
            name: "pickster_rank_list",
        },
        meta: {
            layout: "default",
            userOnly: false,
        },

        children: [
            {
                path: "/pickster_rank/list",
                name: "pickster_rank_list",
                component: () => import("@/views/pc/member/rank/picksterRank/list"),
                props: true,
                meta: {
                    layout: "default",
                    userOnly: false,
                },
            },
        ],
    },
    {
        path: "/social/:type",
        name: "social_auth",
        component: () => import("@/views/SocialAuth.vue"),
        meta: {
            userOnly: false,
        },
    },
    {
        path: "/gift",
        name: "gift_user",
        component: () => import("@/views/pc/popup/gift"),
        meta: {
            layout: "popup",
            userOnly: true,
        },
    },
    {
        path: "/subscription",
        name: "subscription_user",
        component: () => import("@/views/pc/popup/subscribe"),
        meta: {
            layout: "popup",
            userOnly: true,
        },
    },
    {
        path: "/bail",
        name: "bailList",
        component: () => import("@/views/pc/popup/bail"),
        meta: {
            layout: "popup",
            userOnly: true,
        },
    },
    {
        path: "/talk",
        name: "personal_talk",
        redirect: { name: "personal_talk_main" },
        component: () => import("@/views/pc/popup/talk"),
        meta: {
            layout: "popup",
            userOnly: true,
        },
        children: [
            {
                path: "/talk/main",
                name: "personal_talk_main",
                component: () => import("@/views/pc/popup/talk/main"),
                meta: {
                    layout: "popup",
                    userOnly: true,
                },
            },
            {
                path: "/talk/room",
                name: "personal_talk_room",
                component: () => import("@/views/pc/popup/talk/room"),
                meta: {
                    layout: "popup",
                    userOnly: true,
                },
            },
        ],
    },
    chat,
];

export default routes;
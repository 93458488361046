export default {
  path: "/chat/room/:roomIdx",
  name: "chatRoom",
  component: () => import("@/views/pc/chat/room/index.vue"),
  meta: {
    layout: "popup",
    userOnly: true,
  },
  props: true,
};

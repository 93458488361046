<template>
  <div
    class="profile_img"
    :style="
      this.img
        ? {
            backgroundColor: 'transparent',
            backgroundImage: `url(https://${this.$store.state.domainURL}/api/${this.img})`,
          }
        : ''
    "
  ></div>
</template>

<script>
export default {
  name: "Profile_image",
  props: ["img"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.profile_img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #acacac;
  background-image: url("../assets/img/anonymous.png");
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}
</style>

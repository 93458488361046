<template>
  <section id="side_right">
    <div class="pick_content_wrap custom_scroll">
      <div class="side_content">
        <ul class="pick_title_wrap">
          <li :class="{ active: gameType.indexOf('dhpower') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('dhpowerball'), setSubGameType('')]"
            >
              <span class="fw_bold">동행파워볼</span>
            </button>
          </li>

          <li :class="{ active: gameType.indexOf('dhladder') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('dhladder'), setSubGameType('')]"
            >
              <span class="fw_bold">동행사다리</span>
            </button>
          </li>
        </ul>
        <ul class="pick_title_wrap">
          <li :class="{ active: gameType.indexOf('eospowerball') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('eospowerball'), setSubGameType('5')]"
            >
              <span class="fw_bold">EOS파워볼</span>
            </button>
          </li>
          <li :class="{ active: gameType.indexOf('pbgpower') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('pbgpowerball'), setSubGameType('')]"
            >
              <span class="fw_bold">PBG파워볼</span>
            </button>
          </li>
        </ul>
        <ul class="pick_title_wrap">
          <li :class="{ active: gameType.indexOf('coinpowerball') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('coinpowerball'), setSubGameType('5')]"
            >
              <span class="fw_bold">코인파워볼</span>
            </button>
          </li>
          <li :class="{ active: gameType.indexOf('coinladder') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('coinladder'), setSubGameType('5')]"
            >
              <span class="fw_bold">코인사다리</span>
            </button>
          </li>
        </ul>
        <ul class="pick_title_wrap">
          <li :class="{ active: gameType.indexOf('metapower') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('metapowerball'), setSubGameType('5')]"
            >
              <span class="fw_bold">메타파워볼</span>
            </button>
          </li>
          <li :class="{ active: gameType.indexOf('metaladder') > -1 }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="[setGameType('metaladder'), setSubGameType('5')]"
            >
              <span class="fw_bold">메타사다리</span>
            </button>
          </li>
        </ul>

        <ul class="pick_title_wrap" v-if="isSubGameType">
          <li
            class="sub_title"
            :class="{ active: SubGameType === '5' }"
            @click="setSubGameType('5')"
          >
            <span class="fw_bold">5분</span>
          </li>
          <li
            class="sub_title"
            :class="{ active: SubGameType === '3' }"
            @click="setSubGameType('3')"
          >
            <span class="fw_bold">3분</span>
          </li>
        </ul>
        <section class="gamePicker">
          <article id="powerBallPicker">
            <div class="myEx">
              <div class="hold_ex">
                <span>보유경험치</span>
                <img src="@/assets/img/experience.png" />
                <span>{{ addCommas(userInfo.exp) }}</span>
              </div>
              <div class="re_count">
                <img src="@/assets/img/refresh.png" @click="expRefill" />
                <p>
                  [잔여횟수
                  <span>
                    {{ userInfo.expRefillCnt }}
                  </span>
                  회]
                </p>
              </div>
            </div>
            <powerball
              v-if="gameType.indexOf('powerball') > -1"
              ref="gameType"
              :bet="bet"
              :gameType="`${gameType}${SubGameType}`"
              :pickActive="pickActive"
              :loggedIn="loggedIn"
              @checkActive="checkActive"
              @betAmtReset="betAmtReset"
              @checkBetActive="checkBetActive"
            />
            <ladder
              v-else
              ref="gameType"
              :bet="bet"
              :gameType="`${gameType}${SubGameType}`"
              :pickActive="pickActive"
              :loggedIn="loggedIn"
              @checkActive="checkActive"
              @betAmtReset="betAmtReset"
              @checkBetActive="checkBetActive"
            />

            <div class="padding_bottom">
              <div class="border_bottom"></div>
            </div>
            <div class="bet_wrap">
              <div class="bet_cart">
                <div class="bet_content">
                  <span>경험치</span>
                  <img src="@/assets/img/experience.png" />
                  <input type="number" v-model="bet" ref="betAmt" />
                  <button id="btn_reset" type="button" @click="betAmtReset">
                    리셋
                  </button>
                </div>
              </div>
            </div>
            <div class="bet_btn">
              <div class="bet_pick">
                <button
                  type="button"
                  @click="changeBetType('min')"
                  :class="{ active: betAmtType === 'min' }"
                >
                  최소
                </button>
                <button
                  type="button"
                  @click="changeBetType('minDouble')"
                  :class="{ active: betAmtType === 'minDouble' }"
                >
                  따당(최소X2)
                </button>
                <button
                  type="button"
                  @click="changeBetType('quater')"
                  :class="{ active: betAmtType === 'quater' }"
                >
                  쿼터(25%)
                </button>
              </div>
              <div class="bet_pick">
                <button
                  type="button"
                  @click="changeBetType('half')"
                  :class="{ active: betAmtType === 'half' }"
                >
                  하프(50%)
                </button>
                <button
                  type="button"
                  @click="changeBetType('max')"
                  :class="{ active: betAmtType === 'max' }"
                >
                  최대
                </button>
                <button
                  type="button"
                  @click="changeBetType('manual')"
                  :class="{ active: betAmtType === 'manual' }"
                >
                  직접입력
                </button>
              </div>
            </div>
            <div class="pick_choice">
              <button type="button" @click="this.$emit('gameToggle', false)">
                닫기
              </button>
              <button
                type="button"
                :class="{ disable: !pickActive || !betActive }"
                @click="
                  !pickActive || !betActive
                    ? ''
                    : this.$refs.galoggedInmeType.doBetting()
                "
              >
                게임픽 등록하기
              </button>
            </div>
          </article>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import powerball from "./powerball.vue";
import ladder from "./ladder.vue";
import { apiExpRefill } from "@/api/game";
import Select from "@/components/ui/select.vue";
export default {
  name: "NavGamePick",
  components: {
    Select,
    powerball,
    ladder,
  },
  emits: ["gameToggle", "modalOpen"],
  props: ["loggedIn"],
  data() {
    return {
      gameType: "dhpowerball",
      isSubGameType: false,
      SubGameType: "",
      pickActive: false,
      bet: 0,
      betAmtType: "manual",
      betActive: false,
    };
  },
  methods: {
    setGameType(type) {
      this.gameType = type;
    },
    setSubGameType(sub) {
      if (!sub) {
        this.isSubGameType = false;
      } else {
        this.isSubGameType = true;
      }
      this.SubGameType = sub;
    },
    checkActive(active) {
      this.pickActive = active;
    },
    checkBetActive(active) {
      this.betActive = active;
    },
    // 경험치 리필
    async expRefill() {
      if (!this.loggedIn) {
        return alert("로그인 후 이용해주세요.");
      }
      const res = await apiExpRefill();
      if (res) {
        alert("리필완료");
        await this.refreshUserInfo();
      }
    },
    // 베팅 금액 리셋
    betAmtReset() {
      this.bet = 0;
      this.betAmtType = "";
    },
    changeBetType(type) {
      if (!this.userInfo.exp) {
        return alert("보유 경험치가 없습니다!");
      }
      if (this.userInfo.exp < 10) {
        return alert("경험치가 최소 10이상이여야 합니다.");
      }
      this.betAmtType = type;
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    gameType: {
      handler(type) {
        this.betAmtReset();
        this.$refs.gameType.pickModelReset();
      },
    },
    betAmtType: {
      handler(type) {
        const betMin = 10;
        this.$refs.betAmt.setAttribute("disabled", true);
        switch (type) {
          case "min":
            this.bet = betMin;
            break;
          case "minDouble":
            this.bet = betMin * 2;
            break;
          case "quater":
            this.bet = Math.floor(this.userInfo.exp * 0.25);
            break;
          case "half":
            this.bet = Math.floor(this.userInfo.exp * 0.5);
            break;
          case "max":
            this.bet = this.userInfo.exp;
            break;
          case "manual":
            this.bet = 0;
            this.$refs.betAmt.removeAttribute("disabled");
            break;
        }
      },
    },
  },
  async created() {},
};
</script>

<style scoped>
#side_right {
  width: 100%;
  /* width: 313px; */
  /* min-width: 313px; */
  position: relative;
}

.pick_content_wrap {
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
}
.pick_title_wrap {
  display: flex;
  width: 100%;
}
.pick_title_wrap > li {
  flex: 0 2 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 45px;
  /* border-bottom: 2px solid #f5f5f5; */
  border: 1px solid rgb(224, 224, 224);
}

.pick_title_wrap > li.active {
  background: #e0e0e0;
}

.pick_title_wrap > li.active span,
.pick_title_wrap > li.disable span {
  color: rgb(0, 0, 0);
}

.pick_title_wrap > li button {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.myEx {
  display: flex;
  height: 40px;
  background-color: #929292;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.myEx .hold_ex {
  display: flex;
  padding: 9px;
  align-items: center;
}
.myEx .hold_ex > span {
  font-size: 0.8em;
  padding-right: 11px;
  font-weight: 500;
  color: #fff;
}
.myEx .hold_ex > img {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}
.myEx .re_count {
  display: flex;
  float: right;
  align-items: center;
}
.myEx .re_count > img {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}
.myEx .re_count > p {
  font-size: 0.8em;
  font-weight: 500;
  color: #fff;
}
.myEx .re_count > span {
  color: chocolate;
}

.padding_bottom {
  padding: 10px;
}
.border_bottom {
  border-bottom: 2px solid #dadada;
}
.bet_wrap {
  padding: 5px;
}
.bet_wrap .bet_cart {
  padding: 5px;
  border-radius: 14px;
  background: #9cb9df;
  color: #fff;
}
.bet_cart .bet_content {
  display: flex;
  position: relative;
  font-size: 0.9em;
  align-items: center;
  justify-content: space-around;
  padding: 0 30px;
}

.bet_content span {
  padding: 5px;
  min-width: 65px;
  box-sizing: border-box;
}
.bet_content img {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}
.bet_content input {
  height: 23px;
  text-align: right;
  padding-right: 5px;
  font-weight: 900;
  box-sizing: border-box;
  font-size: 14px;
  width: 148px;
  margin-right: 10px;
  background-color: #fff;
}
.bet_content input:disabled {
  background: #e1e1e1;
  color: #000;
}
#btn_reset:hover {
  background: #6a67b7;
}
#btn_reset {
  padding: 5px;
  background: #006e26;
  border-radius: 4px;
  color: #fff;
  font-size: 0.8em;
  cursor: pointer;
}
.bet_btn {
  flex-direction: column;
  margin-bottom: 5px;
}

.bet_btn .bet_pick {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  font-size: 0.7em;
  color: #fff;
  /* margin-top: 5px; */
  padding: 5px 5px 0 5px;
  gap: 20px;
}

.bet_btn .bet_pick button {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  cursor: pointer;
  background: linear-gradient(to top, #ddd, #fff);
  text-align: center;
  color: #000;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.bet_pick .active {
  box-shadow: inset -3px -3px 7px #ffffff73,
    inset 3px 3px 5px rgba(94, 104, 121, 0.288) !important;
  background: #9cb9df !important;
  color: #fff !important;
}
.pick_choice {
  display: flex;
  margin-top: 30px;
}
.pick_choice button {
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pick_choice button:nth-child(1) {
  background-color: #dbdbdb;
}
.pick_choice button:nth-child(2) {
  background-color: #ffbf00;
}
.pick_choice button.disable {
  background: gray;
  cursor: not-allowed;
}

.gamePicker {
  position: relative;
  height: 100%;
}

#powerBallPicker {
  height: 100%;
}
</style>

<template>
  <div class="pickr-wrapper">
    <div class="pickr-wrap">
      <div class="calendar_picker">
        <flat-pickr
          class="dateSelector"
          :value="startDate"
          @on-change="setStartDate"
          :config="startConfig"
        />
        <img src="@/assets/img/calendar.png" />
      </div>
      <template v-if="useEnd">
        <span>~</span>
        <div class="calendar_picker">
          <flat-pickr
            class="dateSelector"
            :value="endDate"
            @on-change="setEndDate"
            :config="endConfig"
          />
          <img src="@/assets/img/calendar.png" />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "DatePicker",
  components: {
    flatPickr,
  },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    configs: {
      type: Object,
      default: {
        end: true,
        enableTime: false,
        dateFormat: "Y-m-d",
      },
    },
  },
  data() {
    return {
      datepickr: "",
      date: {},
      startConfig: {
        time_24hr: true,
        enableTime: false,
        dateFormat: "Y-m-d",
        disableMobile: true,
        locale: Korean,
        defaultDate: this.startDate,
        maxDate: "today",
      },
      endConfig: {
        time_24hr: true,
        enableTime: false,
        dateFormat: "Y-m-d",
        locale: Korean,
        disableMobile: true,
        defaultDate: this.endDate,
        maxDate: "today",
      },
    };
  },
  computed: {
    useEnd() {
      return this.configs.end;
    },
  },
  methods: {
    maxDate() {
      const today = new Date();
      today.setHours(24, 0, 0);
      return today;
    },

    setStartDate(date) {
      this.$emit("setStartDate", date[0]);
    },
    setEndDate(date) {
      this.$emit("setEndDate", date[0]);
    },
  },
};
</script>

<style scoped>
.pickr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickr-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickr-wrap > span {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #b9b9b9;
}
@media (max-width: 800px) {
  .pickr-wrap > span {
    padding: 1vw;
  }
}
.date-range-selector-container {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.range-selector-wrap {
  display: flex;
}
.range-selector-wrap > input {
  display: none;
}
.range-selector-wrap > input + label {
  box-sizing: border-box;
  background: #6ab0f3;
  padding: 6px 6px;
  font-weight: 700;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-right: 4px;
  font-size: 12px;
}
.range-selector-wrap > input:checked + label {
  background: #620000;
}
.range-selector-wrap:last-child > input + label {
  margin-right: 0;
}

.calendar_picker {
  border: 1px solid #d9d9d9;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.calendar_picker img {
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}
.dateSelector {
  width: 100%;
  padding: 10px 10px;
  text-align: center;
  padding-right: 25px;
  box-sizing: border-box;
  font-size: 0.8em;
  font-weight: 700;
  color: #000;
  position: relative;
  z-index: 1;
  background: transparent;
}
</style>

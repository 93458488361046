export default {
  path: "/game",
  name: "game",
  redirect: { name: "EOSpowerball5" },
  component: () => import("@/views/pc/game"),
  meta: {
    layout: "default",
    userOnly: false,
  },
  children: [
    {
      path: "/game/eospowerball5",
      name: "EOSpowerball5",
      redirect: { name: "EOSpowerball5Live" },
      component: () => import("@/views/pc/game/EOSpowerball_5"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/EOSpowerball5/live",
          name: "EOSpowerball5Live",
          component: () =>
            import("@/views/pc/game/EOSpowerball_5/EOSpowerball5Live"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/EOSpowerball5/daily",
          name: "EOSpowerball5Daily",
          component: () => import("@/views/pc/game/EOSpowerball_5/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/EOSpowerball5/round",
          name: "EOSpowerball5Round",
          component: () => import("@/views/pc/game/EOSpowerball_5/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/EOSpowerball5/pattern",
          name: "EOSpowerball5Pattern",
          component: () => import("@/views/pc/game/EOSpowerball_5/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/eospowerball3",
      name: "EOSpowerball3",
      redirect: { name: "EOSpowerball3Live" },
      component: () => import("@/views/pc/game/EOSpowerball_3"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/EOSpowerball3/live",
          name: "EOSpowerball3Live",
          component: () =>
            import("@/views/pc/game/EOSpowerball_3/EOSpowerball3Live"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/EOSpowerball3/daily",
          name: "EOSpowerball3Daily",
          component: () => import("@/views/pc/game/EOSpowerball_3/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/EOSpowerball3/round",
          name: "EOSpowerball3Round",
          component: () => import("@/views/pc/game/EOSpowerball_3/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/EOSpowerball3/pattern",
          name: "EOSpowerball3Pattern",
          component: () => import("@/views/pc/game/EOSpowerball_3/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/coinpowerball5",
      name: "coinpowerball5",
      redirect: { name: "coinpowerball5Live" },
      component: () => import("@/views/pc/game/coinpowerball_5"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/coinpowerball5/live",
          name: "coinpowerball5Live",
          component: () =>
            import("@/views/pc/game/coinpowerball_5/coinpowerball5Live"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinpowerball5/daily",
          name: "coinpowerball5Daily",
          component: () => import("@/views/pc/game/coinpowerball_5/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinpowerball5/round",
          name: "coinpowerball5Round",
          component: () => import("@/views/pc/game/coinpowerball_5/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinpowerball5/pattern",
          name: "coinpowerball5Pattern",
          component: () => import("@/views/pc/game/coinpowerball_5/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/coinpowerball3",
      name: "coinpowerball3",
      redirect: { name: "coinpowerball3Live" },
      component: () => import("@/views/pc/game/coinpowerball_3"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/coinpowerball3/live",
          name: "coinpowerball3Live",
          component: () =>
            import("@/views/pc/game/coinpowerball_3/coinpowerball3Live"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinpowerball3/daily",
          name: "coinpowerball3Daily",
          component: () => import("@/views/pc/game/coinpowerball_3/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinpowerball3/round",
          name: "coinpowerball3Round",
          component: () => import("@/views/pc/game/coinpowerball_3/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinpowerball3/pattern",
          name: "coinpowerball3Pattern",
          component: () => import("@/views/pc/game/coinpowerball_3/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/coinladder5",
      name: "coinladder5",
      redirect: { name: "coinladderLive5" },
      component: () => import("@/views/pc/game/coinladder_5"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/coinladder5/live",
          name: "coinladderLive5",
          component: () =>
            import("@/views/pc/game/coinladder_5/coinladderLive"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinladder5/daily",
          name: "coinladderDaily5",
          component: () => import("@/views/pc/game/coinladder_5/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinladder5/round",
          name: "coinladderRound5",
          component: () => import("@/views/pc/game/coinladder_5/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinladder5/pattern",
          name: "coinladderPattern5",
          component: () => import("@/views/pc/game/coinladder_5/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/coinladder3",
      name: "coinladder3",
      redirect: { name: "coinladderLive3" },
      component: () => import("@/views/pc/game/coinladder_3"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/coinladder3/live",
          name: "coinladderLive3",
          component: () =>
            import("@/views/pc/game/coinladder_3/coinladderLive"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinladder3/daily",
          name: "coinladderDaily3",
          component: () => import("@/views/pc/game/coinladder_3/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinladder3/round",
          name: "coinladderRound3",
          component: () => import("@/views/pc/game/coinladder_3/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/coinladder3/pattern",
          name: "coinladderPattern3",
          component: () => import("@/views/pc/game/coinladder_3/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/metapowerball5",
      name: "metapowerball5",
      redirect: { name: "metapowerball5Live" },
      component: () => import("@/views/pc/game/metapowerball_5"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/metapowerball5/live",
          name: "metapowerball5Live",
          component: () =>
            import("@/views/pc/game/metapowerball_5/metapowerball5Live"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metapowerball5/daily",
          name: "metapowerball5Daily",
          component: () => import("@/views/pc/game/metapowerball_5/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metapowerball5/round",
          name: "metapowerball5Round",
          component: () => import("@/views/pc/game/metapowerball_5/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metapowerball5/pattern",
          name: "metapowerball5Pattern",
          component: () => import("@/views/pc/game/metapowerball_5/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/metapowerball3",
      name: "metapowerball3",
      redirect: { name: "metapowerball3Live" },
      component: () => import("@/views/pc/game/metapowerball_3"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/metapowerball3/live",
          name: "metapowerball3Live",
          component: () =>
            import("@/views/pc/game/metapowerball_3/metapowerball3Live"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metapowerball3/daily",
          name: "metapowerball3Daily",
          component: () => import("@/views/pc/game/metapowerball_3/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metapowerball3/round",
          name: "metapowerball3Round",
          component: () => import("@/views/pc/game/metapowerball_3/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metapowerball3/pattern",
          name: "metapowerball3Pattern",
          component: () => import("@/views/pc/game/metapowerball_5/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/metaladder5",
      name: "metaladder5",
      redirect: { name: "metaladderLive5" },
      component: () => import("@/views/pc/game/metaladder_5"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/metaladder5/live",
          name: "metaladderLive5",
          component: () =>
            import("@/views/pc/game/metaladder_5/metaladderLive"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metaladder5/daily",
          name: "metaladderDaily5",
          component: () => import("@/views/pc/game/metaladder_5/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metaladder5/round",
          name: "metaladderRound5",
          component: () => import("@/views/pc/game/metaladder_5/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metaladder5/pattern",
          name: "metaladderPattern5",
          component: () => import("@/views/pc/game/metaladder_5/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/metaladder3",
      name: "metaladder3",
      redirect: { name: "metaladderLive3" },
      component: () => import("@/views/pc/game/metaladder_3"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/metaladder3/live",
          name: "metaladderLive3",
          component: () =>
            import("@/views/pc/game/metaladder_3/metaladderLive"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metaladder3/daily",
          name: "metaladderDaily3",
          component: () => import("@/views/pc/game/metaladder_3/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metaladder3/round",
          name: "metaladderRound3",
          component: () => import("@/views/pc/game/metaladder_3/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/metaladder3/pattern",
          name: "metaladderPattern3",
          component: () => import("@/views/pc/game/metaladder_3/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/dhpowerball",
      name: "dhpowerball",
      redirect: { name: "dhpowerballLive" },
      component: () => import("@/views/pc/game/dhpowerball"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/dhpowerball/live",
          name: "dhpowerballLive",
          component: () =>
            import("@/views/pc/game/dhpowerball/dhpowerballLive"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/dhpowerball/daily",
          name: "dhpowerballDaily",
          component: () => import("@/views/pc/game/dhpowerball/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/dhpowerball/round",
          name: "dhpowerballRound",
          component: () => import("@/views/pc/game/dhpowerball/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/dhpowerball/pattern",
          name: "dhpowerballPattern",
          component: () => import("@/views/pc/game/dhpowerball/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/dhladder",
      name: "dhladder",
      redirect: { name: "dhladderLive" },
      component: () => import("@/views/pc/game/dhladder"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/dhladder/live",
          name: "dhladderLive",
          component: () => import("@/views/pc/game/dhladder/dhladderLive"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/dhladder/daily",
          name: "dhladderDaily",
          component: () => import("@/views/pc/game/dhladder/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/dhladder/round",
          name: "dhladderRound",
          component: () => import("@/views/pc/game/dhladder/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/dhladder/pattern",
          name: "dhladderPattern",
          component: () => import("@/views/pc/game/dhladder/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/game/pbgpowerball",
      name: "pbgpowerball",
      redirect: { name: "pbgpowerballLive" },
      component: () => import("@/views/pc/game/pbgpowerball"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/game/pbgpowerball/live",
          name: "pbgpowerballLive",
          component: () =>
            import("@/views/pc/game/pbgpowerball/pbgpowerballLive"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/pbgpowerball/daily",
          name: "pbgpowerballDaily",
          component: () => import("@/views/pc/game/pbgpowerball/daily"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/pbgpowerball/round",
          name: "pbgpowerballRound",
          component: () => import("@/views/pc/game/pbgpowerball/round"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/game/pbgpowerball/pattern",
          name: "pbgpowerballPattern",
          component: () => import("@/views/pc/game/pbgpowerball/pattern"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },
  ],
};

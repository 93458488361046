<template>
  <article>
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="modalClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="logo_symbol">
          <img src="@/assets/img/ballpick_logo_1.png" />
        </div>
        <section class="signUp_body">
          <div class="sign_form">
            <fieldset class="input_fs">
              <legend>채팅방 비밀번호</legend>
              <input
                type="password"
                maxlength="20"
                v-model="model.roomPw"
                placeholder="채팅방 비밀번호"
              />
            </fieldset>
          </div>
          <div class="signUp">
            <button class="signUp_btn" type="button" @click="roomEnter">
              입장하기
            </button>
          </div>
        </section>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  name: "roomPassword",
  emits: ["modalClose"],
  data() {
    return {
      model: {
        roomIdx: 0,
        roomPw: "",
      },
    };
  },
  methods: {
    modalClose() {
      this.$emit("modalClose", false);
      this.$store.dispatch("settingsetCheckRoomInfo", {});
    },
    async roomEnter() {
      const roomInfo = this.$store.state.checkRoomInfo;
      this.model.roomIdx = roomInfo.roomIdx || roomInfo.celebRoomIdx;
      let check;
      this.$store
        .dispatch("roomImmigration", this.model)
        .then((res) => {
          check = res;
          if (check) {
            this.$router.push({
              name: "chatRoom",
              query: { gameType: roomInfo.gameType || roomInfo.roomType },
              params: { roomIdx: roomInfo.roomIdx },
            });
          }
        })
        .catch(() => {
          this.modalClose();
        })
        .finally(() => {
          this.modalClose();
        });
    },
  },
  watch: {},
  beforeRouteEnter(to, from, next) {
    const user = getUserData();
    if (!!user) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
article {
  width: 90%;
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signUp_wrap .signUp_contents {
  width: 100%;
  /* height: 1115px; */
  background: #ffffff;
  box-shadow: 0px 0px 10px #ebe7e7;

  position: relative;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  margin-top: 2em;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}
.signUp_body {
  padding: 0 5vw;
  padding-bottom: 20px;
}
.sign_form {
  padding: 20px 0 0;
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
}

.input_fs {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  /* height: 56px; */
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input,
.input_fs > span {
  font-size: 1em;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
  font-weight: 700;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.signUp_btn {
  height: 48px;
  width: 90%;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}
</style>

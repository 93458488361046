<template>
  <div class="modalWrap">
    <template v-for="(item, index) in pickData" :key="item.idx">
      <button class="modal_close" @click="modalClose(index)">
        <i class="fas fa-times"></i>
      </button>
      <div class="signUp_wrap" v-if="item.roomCode" @click="roomEnter(item)">
        <div class="signUp_contents bgc_1">
          <span
            >{{ $t(`games.${item.gameType}`) }}
            방채팅픽
            {{ item.conWin }} 연승</span
          >
          <span>{{ item.nickName }}님 {{ item.round }}회차 방채팅픽 등록</span>
        </div>
      </div>
      <div class="signUp_wrap" v-else @click="goMyPage(item.userIdx)">
        <div class="signUp_contents bgc_2">
          <span
            >{{ $t(`games.${item.gameType}`) }}
            공개픽
            {{ item.conWin }} 연승</span
          >
          <span>{{ item.nickName }}님 {{ item.round }}회차 공개픽 등록</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ["pickData"],
  data() {
    return {};
  },
  methods: {
    // 알림 모달 닫기
    modalClose(index) {
      this.$store.commit("setPickDataSplice", index);
    },
    // 채팅방 입장
    async roomEnter(item) {
      // console.log(item);
      if (!this.loggedIn) {
        return alert("로그인 후 이용해주세요");
      }
      let check;
      // item.isSecretRoom 1 비밀방
      // item.isSecretRoom 2 공개방
      // 본인 방이면 확인 없이 바로 입장
      if (this.myRoom.roomIdx == item.roomIdx) {
        return window.open(
          `/chat/room/${item.roomIdx}?gameType=${item.gameType}`,
          "chat",
          "width=1400,height=800,location=no"
        );
      }
      // 비밀방일때, 패스워드 모달 오픈
      if (item.isSecretRoom == 1) {
        this.$store.dispatch("settingsetCheckRoomInfo", item);
        this.modalOpen("roomPassword");
        return;
      }

      // 일반 방일때 권한 확인
      await this.$store.dispatch("roomImmigration", item).then((res) => {
        check = res;
      });
      if (check) {
        window.open(
          `/chat/room/${item.roomIdx}?gameType=${item.gameType}`,
          "chat",
          "width=1400,height=800,location=no"
        );
      }
    },
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    myRoom() {
      return this.$store.getters.myRoom;
    },
  },
};
</script>

<style scoped>
.modalWrap {
  position: fixed;
  /* overflow-y: scroll; */
  bottom: 0;
  left: 0;
  /* width: 100%; */
  /* height: 100%; */
  z-index: 10;
  margin: 20px;
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.signUp_wrap:hover {
  cursor: pointer;
}
.signUp_wrap .signUp_contents {
  align-items: center;
  justify-content: center;
  height: 70px;
  color: #fff;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 10px;
  width: 300px;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}
.text_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  /* width: 100%; */
}
.text_box h1 {
  font-size: 2.3rem;
  font-weight: 700;
  /* margin-left: 10px; */
}

.text_box p {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 400;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

.profile_img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #acacac;
  background-image: url("../../assets/img//anonymous.png");
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}

.btn_wrap > button {
  padding: 10px 15px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  margin: 0 10px;
  cursor: pointer;
}
.btn_wrap > button:hover {
  font-weight: 800;
}
.btn_wrap > button:nth-child(2) {
  background-color: #ddd;
}

.bgc_1 {
  background-color: #ffb100;
}

.bgc_2 {
  background-color: #3da0f4;
}
.modal_close {
  position: absolute;
  right: 13px;
  z-index: 1;
  color: #fff;
  font-size: 0.7rem;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 15px;
}
</style>

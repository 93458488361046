<template>
  <router-view />
</template>

<script>
export default {
  name: "PopupLayout",
  components: {},
  data() {},
  watch: {},
  created() {
    document.querySelector("body").classList.add("min_w");
  },
};
</script>

<style scoped>
main {
  min-width: 1400px;
  max-width: 1400px;
  width: 100%;
}
</style>

<template>
  <div class="pickr-wrapper">
    <div class="pickr-wrap">
      <button type="button" @click="prevMonth">
        <i class="fas fa-angle-left"></i>
      </button>
      <flat-pickr
        class="dateSelector monthlyPick"
        :config="config"
        @on-close="setMonthly"
      />
      <button type="button" @click="nextMonth">
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/flatpickr.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

export default {
  name: "DatePicker",
  components: {
    flatPickr,
  },
  props: {
    searchMonth: {
      type: Date,
      default: new Date(),
    },
    ref: {
      type: String,
      default: "monthlyPickr",
    },
  },
  data() {
    return {
      datepickr: "",
      date: {},
      config: {
        locale: Korean,
        defaultDate: new Date(),
        disableMobile: true,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "Y-m",
            ariaDateFormat: "Y-m",
          }),
        ],
      },
    };
  },
  methods: {
    nextMonth() {
      this.$emit("nextMonth");
    },
    prevMonth() {
      this.$emit("prevMonth");
    },
    setMonthly(searchMonth) {
      this.$emit("setMonth", searchMonth);
    },
  },
  created() {},
};
</script>

<style scoped>
.pickr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-right:20px; */
}
.pickr-wrap {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
}
.pickr-wrap > span {
  font-weight: 800;
  font-size: 19px;
  margin: 0 5px;
}
/* month selector */
.monthlyPick {
  padding: 11px;
  display: flex;
  align-items: center;
  background: transparent;
  font-weight: 900;
  width: 93px;
  box-sizing: border-box;
}
/* .monthlyPick button{
  cursor: pointer;
} */
.monthlyPick img {
  width: 12px;
}
.pickr-wrap > button {
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}
.month_display {
  min-width: 93px;
}
</style>

<template>
  <footer>
    <section class="container">
      <div class="logo_wrap">
        <img src="@/assets/img/ballpick_logo_2.png" />
      </div>
      <div>
        <ul class="fx link_wrap">
          <li>
            <router-link :to="{ name: 'policy_service' }">
              <span>이용약관</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'policy_privacy' }">
              <span>개인정보 취급방침</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'policy_refusalmail' }">
              <span>이메일주소수집거부</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'policy_youthpolicy' }">
              <span>청소년보호정책</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'policy_point' }">
              <span>캐시 및 환불 정책</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'cscenter_noticeList' }">
              <span>고객센터</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'policy_prisonpolicy' }">
              <span>수감규정안내</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="noti_wraps">
        <div class="noti_wrap">
          <p>주소 : 서울특별시 행복구 행운로 1, 1층</p>
          <p>대표 : 김볼픽</p>
          <!-- <p>사업자 등록번호 : 555-86-02496</p> -->
          <!-- <p>통신판매등록번호 : 제 2023-서울마포-3291 호</p> -->
          <p>개인정보관리책임자 : 김관리</p>
          <p>대표전화 : 02-123-4567</p>
          <p>이메일 : help.ballpick@email.test</p>
        </div>
      </div>
      <div class="noti_wrap">
        <p>
          볼픽의 모든 컨텐츠 저작권은 볼픽에 있으며, 무단도용시 법적 불이익을
          받습니다.
        </p>
        <p>
          볼픽은 불법적인 정보제공하거나 불법적인 사이트를 알선하지 않습니다.
          볼픽은 베팅사이트가 아니며 게임정보제공 사이트입니다.
        </p>
        <p>Copyright © 볼픽. All rights reserved</p>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {},
};
</script>

<style scoped>
footer {
  font-size: 13px;
  min-width: 1400px;
  width: 100%;
  box-sizing: border-box;
}
.container {
  margin: 0 auto;
  max-width: 1400px;
  padding: 40px;
  box-sizing: border-box;
}
.container > div {
  margin-bottom: 20px;
}
.container > div:last-child {
  margin-bottom: 0;
}
a:hover {
  color: #000;
}

.noti_wraps {
  display: flex;
  justify-content: space-between;
}
.noti_wraps > div {
  width: 50%;
}
.noti_wraps > div:nth-child(2) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.noti_wrap {
  color: #5f5f5f;
}
.link_wrap {
  color: #727272;
  gap: 20px;
}
footer {
  border-top: 2px solid #cecece;
}
.logo_wrap > img {
  height: 80px;
}
</style>

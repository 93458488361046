<template>
  <div class="chat_content_wrap">
    <div ref="chatRoom" id="chat_room" class="custom_scroll">
      <div class="chat_room_wrap">
        <div class="chat_room" ref="chatList">
          <template v-for="(item, idx) in chatList" :key="idx">
            <div class="user_content" :class="{ active: item.isItemTrue }">
              <button @click="toggleItemFalse(idx)">
                <i class="fas fa-times"></i>
              </button>
              <ul>
                <li class="user_info">
                  <lvImg :level="item.level_cd" />
                  <span>{{ item.nickname }}</span>
                </li>
                <!-- 유저 정보 모달 (다른 회원)-->
                <template v-if="item.lgn_id != userInfo.userIdx">
                  <li
                    @click="[goMyPage(item.lgn_id), (item.isItemTrue = false)]"
                  >
                    유저홈
                  </li>
                  <li
                    @click="[openMsg(item.nickname), (item.isItemTrue = false)]"
                  >
                    쪽지
                  </li>
                  <li @click="[openTalk(item), (item.isItemTrue = false)]">
                    1:1대화
                  </li>
                  <li @click="[giftJewel(item), (item.isItemTrue = false)]">
                    보석 선물
                  </li>
                  <li @click="[userBlocking(item), (item.isItemTrue = false)]">
                    차단 등록
                  </li>
                  <li
                    @click="[subscribeFriend(item), (item.isItemTrue = false)]"
                  >
                    구독 친구 등록
                  </li>
                </template>
                <!-- 유저 정보 모달 (나의 정보) -->
                <template v-else>
                  <li
                    @click="[goMyPage(item.lgn_id), (item.isItemTrue = false)]"
                  >
                    나의 유저홈 방문
                  </li>
                </template>
              </ul>
            </div>
            <!-- 어드민 메세지 -->
            <div class="chat_item admin_msg" v-if="item.type === 'admin'">
              <div class="chat_user_wrap">
                <lvImg :level="item.level_cd" />
                <span>
                  {{ item.nickname }}
                </span>
              </div>

              <div class="msg_box">
                <span class="chat_text">{{ item.chatMsg }}</span>
              </div>
            </div>
            <!-- 일반 회원 메세지 -->
            <div class="chat_item" v-if="item.type === 'user'">
              <div class="chat_user_wrap">
                <lvImg :level="item.level_cd" />
                <button
                  type="button"
                  class="btn_link uInfo"
                  @click="loggedIn ? CheckUser(idx, item.lgn_id, $event) : ''"
                >
                  {{ item.nickname }}
                </button>
              </div>
              <template v-if="item.code == 'C01'">
                <div class="msg_box">
                  <span
                    class="chat_text"
                    :style="{
                      color: this.fontItemCheck(item.font_color.expire)
                        ? $t(`fontColor.${item.font_color.item_cd}`)
                        : '#000',
                      fontWeight: this.fontItemCheck(item.font_color.expire)
                        ? 700
                        : 500,
                    }"
                    >{{ item.chatMsg }}</span
                  >
                </div>
              </template>
              <template v-else>
                <div class="msg_box">
                  <span class="chat_text">{{ item.message }}</span>
                </div>
              </template>
            </div>
            <!-- 시스템 정보 -->
            <template v-if="item.type === 'system'">
              <div
                class="chat_item system_alarm_msg fx-col-end_col"
                v-if="item.code === 'C02'"
              >
                <div class="item_wrap">
                  <div class="item_text">
                    <p>{{ item.chatMsg }}</p>
                  </div>
                </div>
              </div>
              <div
                class="chat_item system_alarm_msg fx-col-end_col"
                v-if="item.code === 'C02-2' && item.userIdx == userInfo.userIdx"
              >
                <div class="item_wrap">
                  <div class="item_text">
                    <p>{{ item.chatMsg }}</p>
                  </div>
                </div>
              </div>
              <!-- 보석 선물 메세지 -->
              <div
                class="chat_item system_msg jewel fx-col-end_col"
                v-if="item.code === 'C11'"
              >
                <div class="chat_user_wrap">
                  <lvImg :level="item.level_cd" />
                  <button
                    type="button"
                    class="btn_link uInfo"
                    @click="loggedIn ? CheckUser(idx, item.lgn_id, $event) : ''"
                  >
                    {{ item.nickname }}
                  </button>
                </div>
                <div class="item_wrap">
                  <div class="img_wrap">
                    <img src="@/assets/img/jewels_ex.png" />
                  </div>
                  <div class="item_text">
                    <p>{{ item.chatMsg }}</p>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <!-- 메세지 전송 창 -->
    <div class="chat_input">
      <template v-if="loggedIn">
        <input
          type="text"
          v-model="chatInput"
          @keyup.enter="chatSend"
          placeholder="메세지를 입력해주세요.(최대 40자)"
          maxlength="40"
        />
        <button type="button" class="btn_chat" @click="chatSend">
          <img src="@/assets/img/arrow_up_icon.svg" />
        </button>
      </template>
      <template v-else>
        <div id="chat_notice">
          <span class="txt_p_c">로그인 후 이용해주세요</span>
        </div>
      </template>
    </div>
  </div>
  <talk-app v-if="modalActive" :userData="userData" @modalClose="modalClose" />
</template>

<script>
import io from "socket.io-client";
import { SOCKET_INFO } from "@/libs/constants";
import { apiChatPersonalCheck } from "@/api/chat";
import { apiUserActive, apiUserBlocking, apiSubscribeAdd } from "@/api/user";
import talkApp from "@/components/Modal_mobile/talkApp.vue";
export default {
  name: "NavChatRoom",
  props: ["loggedIn", "userInfo"],
  emits: ["setChatCnt"],
  components: { talkApp },
  data() {
    return {
      modalActive: false,
      chatInput: "",
      socket: null,
      chatList: [],
      _socketConnect: false,
      tgtY: 0,
      userData: {
        userIdx: 0,
      },
    };
  },
  methods: {
    // 모달창 닫기
    modalClose(bool) {
      this.modalActive = bool;
    },
    // 탈퇴 유저 확인 후 유저 팝업 오픈
    async CheckUser(index, userIdx, e) {
      this.tgtY = e.pageY - 600;
      for (let i = 0; i < this.chatList.length; i++) {
        this.chatList[i].isItemTrue = false;
      }
      const model = {
        userIdx: userIdx,
      };
      try {
        const res = await apiUserActive(model);
        if (res.msg == "SUCCESS") {
          this.toggleItemTrue(index);
        } else {
        }
      } catch (e) {
        //  // console.error(e);
      }
    },

    // 유저 팝업
    toggleItemTrue(index) {
      // 특정 아이템의 isItemTrue 값을 토글합니다.
      this.chatList[index].isItemTrue = !this.chatList[index].isItemTrue;
      for (let i = 0; i < this.chatList.length; i++) {
        if (index != i) {
          this.chatList[i].isItemTrue = false;
        }
      }
    },
    // 유저 팝업 클로즈
    toggleItemFalse(index) {
      // 특정 아이템의 isItemTrue 값을 토글합니다.
      this.chatList[index].isItemTrue = false;
    },
    // 쪽지 보내기
    openMsg(nickname) {
      if (!this.loggedIn) {
        alert("로그인 후 이용해주세요!");
        return;
      }
      this.$router.push({
        name: "msgWrite",
        params: { type: "msgWrite" },
        query: { nickname: nickname },
      });
    },
    // 1:1대화 신청
    async openTalk(user) {
      if (!this.loggedIn) {
        alert("로그인 후 이용해주세요!");
        return;
      }
      this.userData.userIdx = user.lgn_id;
      const model = {
        toUserIdx: user.lgn_id,
      };
      try {
        const res = await apiChatPersonalCheck(model);
        if (res) {
          const data = res.data;
          // 방 존재 => 바로 방으로 연결
          if (data) {
            this.$router.push({
              name: "talk_room",
              query: {
                userIdx: user.lgn_id,
                roomcode: data.roomCode,
                roomidx: data.roomIdx,
              },
            });
          }
          // 방 없음 = > 신청 페이지로 연결
          else {
            this.modalActive = true;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 보석 선물
    giftJewel(item) {
      if (!this.loggedIn) {
        alert("로그인 후 이용해주세요!");
        return;
      }
      this.$store.dispatch("joinGiftUserInfo", item);
      this.modalOpen("giftJewel");
    },
    // 친구 구독
    async subscribeFriend(item) {
      if (!this.loggedIn) {
        alert("로그인 후 이용해주세요!");
        return;
      }
      const _confirm = confirm("해당 회원을 구독하시겠습니까?");
      if (!_confirm) {
        return;
      }
      const req = {
        celebIdx: item.lgn_id,
      };
      try {
        const res = await apiSubscribeAdd(req);
        if (res) {
          await this.refreshUserInfo();
          alert("구독 완료!");
        }
      } catch (err) {}
    },
    // 회원차단
    async userBlocking(item) {
      const req = {
        choiceIdx: item.lgn_id,
      };
      const _confirm = confirm("해당 회원을 차단하시겠습니까?");
      if (!_confirm) {
        return;
      }
      try {
        const res = await apiUserBlocking(req);
        if (res) {
          alert("차단 완료");
          this.refreshUserInfo();
        }
      } catch (e) {
        //  // console.error(e);
      }
    },
    scrollBottom(el) {
      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    },
    // 소켓 연결
    socketConnect() {
      if (this._socketConnet) {
        return;
      }
      this.socket = io(SOCKET_INFO);
      this.socket.on("connect", (res) => {
        // console.log("socket connected");
        this.chatList = [];
        this._socketConnect = true;
        if (this.loggedIn) {
          this.ioAuth();
        } else {
          this.join();
        }
      });
      this.socket.on("disconnect", (res) => {
        if (this.socket.disconnected) {
          // console.log("socket disconnected");
          // this.socket = null;
          // this.socketConnect();
        }
      });
      this.socket.on("reconnect", (res) => {
        // console.log('reconnect success')
      });

      this.socket.on("chat", async (msg) => {
        if (msg.hasOwnProperty("m")) {
          const type = msg.m;
          switch (type) {
            case "room_join":
              this.getCurrentTalk();
              break;
            case "io_auth":
              this.join();
              break;
            case "room_msg":
              if (this.chatList.length > 0) {
                return;
              }
              if (msg.msgs.length !== 0) {
                const chatList = msg.msgs;
                chatList.forEach((item) => {
                  this.chatForm(item);
                });
              }
              break;
            case "msg_new":
              if (!msg.items[0].msg_type) {
                this.chatForm(msg.items[0]);
              }
              break;
            case "newConn":
              const cnt = msg.items[0].userConnCnt;
              this.$emit("setChatCnt", cnt);
              break;
          }
        } else if (msg.hasOwnProperty("msg")) {
          const delivery = {
            lgn_id: -1,
            m: msg.msg,
            v: msg.val,
          };
          // console.log(delivery);
          this.chatForm(delivery);
        }
      });
      this.socket.on("err", () => {
        console.error("chat err");
      });
    },
    chatForm(msg) {
      let parseData = {};
      if (msg.lgn_id === -1 || msg.m) {
        if (typeof msg.message === "string") {
          parseData = JSON.parse(msg.message);
        } else {
          parseData = msg;
        }
        msg.code = parseData.m;
        if (typeof parseData.v === "string") {
          msg.message = JSON.parse(parseData.v);
        } else {
          try {
            msg.message = parseData.v;
          } catch (err) {
            // console.log(err);
          }
        }
        const _msg = this.systemMessageConvertor(msg);
        if (_msg) {
          _msg.type = "system";
          this.chatList.push(_msg);
        }
      } else {
        try {
          parseData = JSON.parse(msg.message);
        } catch (err) {
          parseData = msg.message;
        }
        msg.code = parseData.m;
        if (msg.code) {
          msg.message = parseData.v;
          const _msg = this.systemMessageConvertor(msg);
          this.chatList.push(_msg);
        } else {
          this.chatList.push(msg);
        }
      }
    },
    // 코드별 메세지 변환
    systemMessageConvertor(item) {
      const code = item.code;
      const data = item.message;
      switch (code) {
        // 운영자 채팅
        case "ADMIN": {
          const model = {
            code: code,
            type: "admin",
            chatMsg: item.message.content,
            level_cd: "00",
            nickname: "운영자",
          };
          return model;
        }
        // 일반 유저 채팅
        case "C01": {
          // console.log(item);
          const model = {
            code: code,
            type: "user",
            chatMsg: data.content,
            nickname: item.nickname,
            level_cd: item.level_cd,
            lgn_id: item.lgn_id || item.mb_no,
            regdate: item.regdate,
            font_color: {
              item_cd: data.font_color.item_cd,
              expire: data.font_color.expire,
            },
            isItemTrue: false,
          };
          return model;
        }
        // 채팅방 관리 메세지
        case "C02": {
          const model = {
            code: code,
            type: "system",
            chatMsg: data.content,
            userIdx: data.userIdx,
            nickname: "system",
          };
          return model;
        }
        case "C02-1": {
          const model = {
            code: code,
            type: "system",
            chatMsg: data.content,
            userIdx: data.userIdx,
            nickname: "system",
          };
          return model;
        }
        case "C02-2": {
          const model = {
            code: code,
            type: "system",
            chatMsg: data.content,
            userIdx: data.userIdx,
            nickname: "system",
          };
          return model;
        }
        // 보석 선물
        case "C11": {
          const model = {
            code: code,
            type: "system",
            level_cd: data.fanUserInfo.levelCd,
            lgn_id: data.fanUserInfo.userIdx,
            nickname: data.fanUserInfo.nickName,
            chatMsg: data.chatMsg,
            regdate: item.regdate,
          };
          return model;
        }
        default: {
          return null;
        }
      }
    },
    reconnect() {
      this.socket.reconnect();
    },
    disconnect() {
      // console.log(this.socket);
      // this._socketConnect = false;
      // this.socket.disconnect();
    },
    // 채팅방 연결
    join() {
      const joinModel = { mode: "room_join", room_no: "5b725452d0c4a" };
      this.socket.emit("chat", joinModel);
    },
    // 회원정보 연결
    ioAuth() {
      const userId = this.userInfo.lgnId;
      const authModel = { mode: "io_auth", mb_id: userId };
      this.socket.emit("chat", authModel);
    },
    // 채팅 보내기
    chatSend() {
      // console.log(this.loginUser)
      const userId = this.loginUser.lgnId;
      const text = this.chatInput;
      if (!text) {
        return;
      }
      const chatModel = { mode: "msg_new", msg: text, mb_id: userId };
      try {
        this.socket.emit("chat", chatModel);
        this.chatInput = "";
      } catch (error) {
        // console.log(error);
      }
    },
    // 로그인 소켓 전송
    loginSend() {
      const loginModel = { mode: "user_login_cnt" };
      try {
        this.socket.emit("chat", loginModel);
      } catch (error) {
        // console.log(error);
      }
    },
    // 유저 수 카운팅 소켓 전송
    userNewSend() {
      const userNewModel = { mode: "user_new_cnt" };
      try {
        this.socket.emit("chat", userNewModel);
      } catch (error) {
        // console.log(error);
      }
    },
    // 채팅 불러오기
    getCurrentTalk() {
      // console.log("getroom");
      this.socket.emit("chat", { mode: "room_msg" });
    },
    // 폰트 아이템 여부 체크
    fontItemCheck(expire) {
      if (expire) {
        const today = new Date();
        const expireDate = new Date(expire);
        return today < expireDate;
      } else return false;
    },
  },
  watch: {
    "chatList.length": {
      handler() {
        this.$nextTick(() => {
          setTimeout(() => {
            this.scrollBottom(this.$refs.chatRoom);
          }, 500);
        });
      },
      deep: true,
    },
    loggedIn: {
      handler(value) {
        const socket = this.socket;
        if (value && socket) {
          this.loginSend();
          this.ioAuth();
        }
      },
    },
    socket: {
      handler(value) {
        if (value && this.loggedIn) {
          this.loginSend();
          this.ioAuth();
        }
      },
    },
    signUpCount: {
      handler(count) {
        this.userNewSend();
      },
    },
  },
  computed: {
    signUpCount() {
      return this.$store.getters.signUpCount;
    },
  },
  async created() {
    this.socketConnect();
  },
};
</script>

<style scoped>
/* cmc chat */
.chat_content_wrap {
  position: relative;
  height: calc(100% - 50px);
}

#chat_room {
  height: calc(100% - 52px);
  overflow-y: scroll;
  font-size: 0.75em;
}
.chat_room_wrap {
  width: 100%;
}

.btn_chat {
  background-color: #808080;
  opacity: 0.4;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}
.chat_input {
  border-top: 2px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
}

.chat_input input {
  width: calc(100% - 40px);
  font-size: 14px;
  margin-right: 10px;
}
.chat_item {
  font-size: 0.8rem;
  padding: 3px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.chat_user_wrap {
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: fit-content;
}

.lv_img {
  width: 40px;
}

.chat_item .uInfo {
  font-weight: 700;
  white-space: nowrap;
}

.chat_item .msg_box {
  max-width: 80%;
  width: fit-content;
  margin-left: 8px;
  line-height: 27px;
  word-break: break-all;
  position: relative;
  display: inline;
}

.chat_item.admin_msg {
  background-color: #e6264c24;
  margin: 5px 0;
}
.chat_item.admin_msg > .chat_user_wrap {
  cursor: unset;
}
.chat_item.admin_msg > .chat_user_wrap > span {
  font-weight: 700;
  font-size: 0.8rem;
}
.chat_item .msg_box > span {
  vertical-align: middle;
}

.chat_item.system_alarm_msg {
  color: #5e5e5e;
  border: 3px dotted #5e5e5e;
  margin: 5px;
  background-color: #dddddd;
  font-weight: 700;
  border-radius: 10px;
}
.chat_item.system_alarm_msg .item_wrap {
  padding: 5px;
}

.chat_item.system_msg .item_wrap {
  border: 3px double #b9b9b9;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #e6264c;
}

.chat_item.jewel {
  width: 100%;
}
.chat_item.jewel .chat_user_wrap {
  margin-bottom: 5px;
}
.chat_item.jewel .item_wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-top: 0;
  justify-content: center;
}
.chat_item.jewel .item_text {
  font-weight: 500;
  text-align: center;
  color: #fff;
  word-break: keep-all;
}
.chat_item.jewel .img_wrap {
  width: 40px;
  margin-right: 10px;
}
.chat_item.jewel .img_wrap img {
  width: 100%;
  display: block;
  filter: drop-shadow(0px 0px 14px #abafe39e);
}

.user_content {
  display: none;
}
.user_content.active {
  display: block;
  position: absolute;
  left: 80px;
  top: 20%;
  z-index: 3;
}
.user_content button {
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
}

.user_content ul {
  background-color: #686868;
  color: #fff;
  width: 180px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.user_content li {
  padding: 8px;
  font-size: 0.8rem;
}
.user_content li:not(:first-child):hover {
  background-color: #e4e4e4;
  color: #000;
  cursor: pointer;
}

.user_content li:not(:last-child) {
  border-bottom: 1px dotted #e4e4e4;
}
.user_content .user_info {
  display: flex;
  align-items: center;
  font-weight: 600;
}
</style>

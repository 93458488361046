import {
  getDateStr,
  addCommas,
  deleteCommas,
  convertDateStr,
  getType,
  convertTypeCd,
  getTypeCode,
  delMilliSec,
  textToCode,
  convertDate,
  oninputPhone,
} from "@/libs/utils";
import { POPUP_INFO } from "@/libs/constants";

//components
import FixedItem from "@/components/mainFixItem";
import UiSelect from "@/components/ui/select";
import lodash from "lodash";
import { apiUserInfo } from "@/api/user";
import { getUserData, setAuthData } from "@/libs/auth-helper";

export default {
  components: {
    FixedItem,
    UiSelect,
  },
  data() {
    return {
      loginUser: this.$store.state.userInfo,
    };
  },
  methods: {
    convertDate(value) {
      return convertDate(value);
    },
    // 변경된 유저 정보가 있으면, 아래 함수 사용
    async refreshUserInfo() {
      // const storeUserInfo = this.$store.state.userInfo;
      const cookieUserData = getUserData();
      // console.log(cookieUserData)
      if (cookieUserData) {
        const refreshInfo = await apiUserInfo();
        if (refreshInfo.msg === "SUCCESS") {
          let newData = Object.assign(cookieUserData, refreshInfo.data);
          let userIdx = this.$store.state.userInfo.userIdx;
          this.$store.dispatch("setUserInfo", newData);
          this.$store.dispatch("chatOwn");
        }
      } else {
        this.$store.dispatch("setUserInfo", null);
      }
    },
    delMilliSec(dateStr) {
      return delMilliSec(dateStr);
    },
    modalOpen(modalName) {
      this.$emit("modalOpen", modalName);
    },
    convertDateStr(str) {
      return convertDateStr(str);
    },
    convertTypeCd(category, typeCd) {
      return convertTypeCd(category, typeCd);
    },
    textToCode(category, typeCd) {
      return textToCode(category, typeCd);
    },
    getType(category, typeCd) {
      return getType(category, typeCd);
    },
    getTypeCode(category, typeCd) {
      return getTypeCode(category, typeCd);
    },
    goMyPage(userIdx) {
      let _userIdx = userIdx;
      let myIdx = this.$store.getters.userInfo.userIdx;
      if (_userIdx == myIdx) {
        this.$router.push({ name: "user_main", query: { userIdx: myIdx } });
      } else {
        this.$router.push({ name: "user_main", query: { userIdx: _userIdx } });
      }
    },
    goUserMyPage(userIdx) {
      let _userIdx = userIdx;
      if (_userIdx) {
        _userIdx = this.$store.getters.userInfo.userIdx;
      }
      this.$router.push({ name: "user_main", query: { userIdx: _userIdx } });
    },
    pushPage(name, data) {
      let model = {
        name: name,
      };
      if (data) {
        model = Object.assign(model, data);
      }
      this.$router.push(model);
    },
    clone(value) {
      return lodash.cloneDeep(value);
    },
    getDateStr(value, defaultStr) {
      return getDateStr(value, defaultStr);
    },
    addCommas(value) {
      return addCommas(value);
    },
    deleteCommas(value) {
      return deleteCommas(value);
    },
    oninputPhone(value) {
      return oninputPhone(value);
    },
    popupOpen(path, name) {
      const width = POPUP_INFO[name].width;
      const height = POPUP_INFO[name].height;
      window.open(path, name, `width=${width},height=${height},top=0,left=0`);
    },
  },
};

<template>
  <main-header @modalOpen="modalOpen" @modalClose="modalClose" />
  <main>
    <!-- 한줄메세지 -->
    <article id="arow_msg">
      <template v-if="Msg">
        <div class="scroll-text" v-if="currentPage === 'main'">
          <div class="track">
            <template v-for="item in arowMagList" :key="item.idx">
              <p class="content">{{ item.messege }}</p>
            </template>
          </div>
        </div>
      </template>
    </article>
    <!-- 팝업 모달창 -->
    <article
      class="modal_popup_wrap"
      :class="this.popup.length == 1 ? 'modal_pop_center' : ''"
      v-if="currentPage === 'main'"
    >
      <popup-modal :popup="popup" />
    </article>
    <section id="item_container">
      <main-nav @modalOpen="modalOpen" :B002="advert.B002" />
      <section class="main_content_wrap">
        <router-view @modalOpen="modalOpen" :B001="advert.B001" />
      </section>

      <!-- right chatList -->
      <article id="best_chat_container">
        <div class="rank" @click="pushPage('pickster_rank')">
          <p>픽스터 랭킹</p>
        </div>
        <div class="rank bg_color_yellow" @click="pushPage('family_rank')">
          <p>가족방 랭킹</p>
        </div>
        <div class="best_chat_wrap">
          <div class="head btn_link" @click="pushPage('chat_rank')">
            <p>채팅방</p>
          </div>
          <div class="item_wrap" v-if="roomList.length !== 0">
            <template v-for="(item, idx) in roomList" :key="item.roomIdx">
              <div
                class="item"
                :class="{ first: idx === 0 }"
                @click="chatRoomEnter(item)"
              >
                <profileImg :img="item.userProfile" />
                <div class="info_box">
                  <div>
                    <span>{{ item.roomTit }}</span>
                    <span
                      >승률 {{ Math.floor(item.winRate) }}%
                      {{ item.totalRecord }}전 {{ item.mainTotWin }}승
                      {{ item.mainTotLose }}패</span
                    >
                  </div>
                  <span>
                    <span class="now"
                      >{{ item.roomUserCnt }}명/{{
                        item.roomMaxUserCnt
                      }}명</span
                    >
                  </span>
                </div>
              </div>
            </template>
          </div>
          <div
            class="rank bg_color_dark"
            @click="this.$store.commit('alarmSet')"
          >
            <p>알림음 {{ this.soundActive ? "ON" : "OFF" }}</p>
          </div>
        </div>
      </article>
    </section>
  </main>
  <main-footer />
  <Modal :modalName="modalName" @modalClose="modalClose" v-if="modalActive" />
</template>

<script>
import MainHeader from "./Header";
import MainFooter from "./Footer";
import MainNav from "./Nav/index";
import popupModal from "@/components/popupModal.vue";
import { getPopupDate, setPopupDate } from "@/libs/auth-helper";

import { apiAdvert, apiArowList } from "@/api/main";
export default {
  name: "DefaultLayout",
  components: {
    MainHeader,
    MainFooter,
    MainNav,
    popupModal,
  },
  data() {
    return {
      modalName: "",
      modalActive: false,
      advertList: [],
      advert: {
        B001: [],
        B002: [],
      },
      popup: [],
      arowMagList: [],
      Msg: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    roomList() {
      let list = this.clone(this.$store.getters.roomList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      }
      return list;
    },
    myRoom() {
      return this.$store.getters.myRoom;
    },
    soundActive() {
      return this.$store.getters.soundActive;
    },
  },
  watch: {
    $route: {
      async handler(value) {
        this.currentPage = value.name;
        this.$store.dispatch("setCurrentPage", value.name);
        if (this.loggedIn) {
          this.$store.dispatch("getNewAlarm");
        }
      },
      immediate: true,
    },
    modalActive(active) {
      const body = document.querySelector("body");
      if (active) {
        body.style.overflowY = "hidden";
      } else {
        body.removeAttribute("style");
      }
    },
  },
  methods: {
    async chatRoomEnter(item) {
      if (!this.loggedIn) {
        return alert("로그인 후 이용해주세요");
      }
      // console.log(this.myRoom);
      // console.log(item);
      let check;
      // item.isSecretRoom 1 비밀방
      // item.isSecretRoom 2 공개방
      // 본인 방이면 확인 없이 바로 입장
      if (this.myRoom.roomIdx == item.roomIdx) {
        return window.open(
          `/chat/room/${item.roomIdx}?gameType=${item.gameType}`,
          "chat",
          "width=1400,height=800,location=no"
        );
      }
      // 비밀방일때, 패스워드 모달 오픈
      if (item.isSecretRoom == 1) {
        this.modalOpen("roomPassword");
        this.$store.dispatch("settingsetCheckRoomInfo", item);
        return;
      }

      // 일반 방일때 권한 확인
      await this.$store.dispatch("roomImmigration", item).then((res) => {
        check = res;
      });
      if (check) {
        window.open(
          `/chat/room/${item.roomIdx}?gameType=${item.gameType}`,
          "chat",
          "width=1400,height=800,location=no"
        );
      }
    },
    // 알람 개수
    setAlarmInterval() {
      if (this.alarmInterval) {
        // console.log("alarm interval reset");
        clearInterval(this.alarmInterval);
      }
      this.alarmInterval = setInterval(() => {
        this.$store.dispatch("getNewAlarm");
      }, 60000);
    },
    modalOpen(modalName, item) {
      this.modalName = modalName;
      this.modalActive = true;
    },
    modalClose() {
      this.modalActive = false;
    },
    // 배너 팝업 이미지 주소 불러오기
    async getAdvert() {
      const res = await apiAdvert();
      // console.log(res.data);
      if (res.data) {
        this.advertList = res.data;
        for (let i = 0; i < this.advertList.length; i++) {
          if (this.advertList[i].typeCd == "B001") {
            this.advert.B001 = this.advertList[i];
          } else if (this.advertList[i].typeCd == "B002") {
            this.advert.B002 = this.advertList[i];
          } else {
            if (getPopupDate(this.advertList[i].idx) === "Y") {
            } else {
              this.popup.push(this.advertList[i]);
              // console.log(this.popup);
            }
          }
        }
      }
    },
    // 한줄메세지 리스트 불러오기
    async getArowMsg() {
      const res = await apiArowList();
      // console.log(res);
      if (res) {
        this.arowMagList = res.list;
        let arr = [];
        for (const item of res.list) {
          arr.push(item.messege);
        }
        this.Msg = arr.join("   ");
      }
    },
  },
  async created() {
    this.currentPage = this.$route.name;
    this.$store.dispatch("setCurrentPage", this.$route.name);
    if (this.loggedIn) {
      this.$store.dispatch("getNewAlarm");
      this.setAlarmInterval();
    }
    this.getAdvert();
    this.getArowMsg();
  },
};
</script>

<style scoped>
main {
  min-width: 1280px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 65px;
}
#item_container {
  display: flex;
  gap: 20px;
}

#best_chat_container {
  position: sticky;
  top: 20px;
  min-width: 60px;
  height: fit-content;
  /* border-radius: 10px; */
  box-shadow: 0px 0px 10px #ebe7e7;
  z-index: 1;
}
#best_chat_container .head {
  width: 60px;
  height: 60px;
  padding: 10px;
  background: #94c93e;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}
#best_chat_container .head:hover {
  font-weight: 700;
}

#best_chat_container .head p {
  font-size: 0.8rem;
  word-break: keep-all;
}

#best_chat_container .item_wrap {
  background: #fff;
}
#best_chat_container .item {
  padding: 8px;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  flex-direction: row-reverse;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
}

#best_chat_container .item:hover > .info_box {
  display: flex;
}
#best_chat_container .item:hover {
  border: 1px solid #e6264c;
  padding: 0;
}
#best_chat_container .item img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
#best_chat_container .head > span {
  display: block;
  height: 2px;
  width: 100%;
  background: #fff;
}
#best_chat_container .head > span:first-child {
  width: 100%;
}
#best_chat_container .head > span:nth-child(2) {
  width: 75%;
}
#best_chat_container .head > span:nth-child(3) {
  width: 50%;
}
.info_box {
  display: none;
  position: absolute;
  right: 59px;
  top: -1px;
  background: #e6264c;
  color: #fff;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  gap: 30px;
  width: 300px;
  font-size: 0.9em;
}

.info_box > div {
  display: flex;
  flex-direction: column;
  max-width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info_box span {
  white-space: nowrap;
  font-weight: 700;
}
.info_box > div > span:last-child {
  font-size: 0.7rem;
  font-weight: 500;
}

/* 팝업 */
.modal_popup_wrap {
  z-index: 1000;
  position: relative;
  height: 0;
  margin: 0 auto;
}

.modal_pop_center {
  display: flex;
  justify-content: center;
}

.rank {
  width: 60px;
  height: 60px;
  padding: 10px;
  background-color: #938e7e;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
}
.rank:hover {
  font-weight: 700;
}
.bg_color_yellow {
  background-color: #e6264c !important;
}
.bg_color_dark {
  background-color: #404040 !important;
}
.profile_img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0% !important;
}
#arow_msg {
  padding: 20px 0;
}
/* 움직이는 텍스트 */
@keyframes scroll-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.scroll-text {
  width: 100%;
  overflow: hidden;
  display: flex;
}
.track {
  position: relative;
  width: 100%;
  animation: scroll-animation 30s linear infinite;
  display: flex;
  gap: 35px;
  padding: 0 10px;
}
.track p {
  font-weight: 500;
  color: #423e31;
  white-space: nowrap;
}
</style>

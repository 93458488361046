<template>
  <article id="user_notice_wrap">
    <ul class="notice_title_wrap">
      <li :class="{ active: tab_menu === 'nickname_search' }">
        <button
          type="button"
          class="fx dir_col center_all"
          @click="tab_menu = 'nickname_search'"
        >
          <span class="fw_bold">대화목록</span>
        </button>
      </li>
      <li :class="{ active: tab_menu === 'freind_list' }">
        <button
          type="button"
          class="fx dir_col center_all"
          @click="tab_menu = 'freind_list'"
        >
          <span class="fw_bold">친구리스트</span>
        </button>
      </li>
    </ul>
    <div class="user_notice custom_scroll" v-if="tab_menu == 'freind_list'">
      <div class="notice_title">
        <span>내가 구독하는 친구</span>
      </div>
      <div class="notice_contents">
        <template v-if="celebList.length !== 0">
          <template v-for="(item, idx) in celebList" :key="item">
            <div
              class="user_info_wrap"
              :class="{ active: item.isItemTrue }"
              @click="CheckUser('celeb', idx, item.celebIdx, $event)"
            >
              <div class="profile_box">
                <profileImg :img="item.profileImgSrc" />
              </div>
              <div class="fx-center-all">
                <div>
                  <lvImg :level="item.levelCd" />
                </div>
                <div>
                  <p>
                    {{ item.nickName }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="user_content"
              :class="{ active: item.isItemTrue }"
              :style="{
                top: B002.idx ? tgtY - 300 + 'px' : tgtY + 'px',
              }"
            >
              <button @click="toggleItemFalse('celeb', idx, $event)">
                <i class="fas fa-times"></i>
              </button>
              <ul>
                <li class="user_info">
                  <lvImg :level="item.levelCd" />
                  <span>{{ item.nickName }}</span>
                </li>
                <li
                  @click="
                    [
                      goMyPage(item.celebIdx),
                      toggleItemFalse('celeb', idx, $event),
                    ]
                  "
                >
                  유저홈
                </li>
                <li
                  @click="
                    [openMsg(item), toggleItemFalse('celeb', idx, $event)]
                  "
                >
                  쪽지
                </li>
                <li
                  @click="
                    [
                      openTalk(item, 'celeb'),
                      ,
                      toggleItemFalse('celeb', idx, $event),
                    ]
                  "
                >
                  1:1대화
                </li>
                <li
                  @click="
                    [
                      subscribeCancel(item.celebIdx),
                      toggleItemFalse('celeb', idx, $event),
                    ]
                  "
                >
                  친구삭제
                </li>
              </ul>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="notice_empty news">구독하는 친구가 없습니다.</div>
        </template>
      </div>

      <div class="notice_title">
        <span>나를 구독하는 친구</span>
      </div>
      <div class="notice_contents">
        <template v-if="fanList.length > 0">
          <template v-for="(item, idx) in fanList" :key="item">
            <div
              class="user_info_wrap"
              :class="{ active: item.isItemTrue }"
              @click="CheckUser('fan', idx, item.fanIdx, $event)"
            >
              <div class="profile_box">
                <profileImg :img="item.profileImgSrc" />
              </div>
              <div class="fx-center-all">
                <div>
                  <lvImg :level="item.levelCd" />
                </div>
                <div>
                  <p>
                    {{ item.nickName }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="user_content"
              :class="{ active: item.isItemTrue }"
              :style="{
                top: B002.idx ? tgtY - 300 + 'px' : tgtY + 'px',
              }"
            >
              <button @click="toggleItemFalse('fan', idx, $event)">
                <i class="fas fa-times"></i>
              </button>
              <ul>
                <li class="user_info">
                  <lvImg :level="item.levelCd" />
                  <span>{{ item.nickName }}</span>
                </li>
                <li
                  @click="
                    [goMyPage(item.fanIdx), toggleItemFalse('fan', idx, $event)]
                  "
                >
                  유저홈
                </li>
                <li
                  @click="[openMsg(item), toggleItemFalse('fan', idx, $event)]"
                >
                  쪽지
                </li>
                <li
                  @click="
                    [openTalk(item, 'fan'), toggleItemFalse('fan', idx, $event)]
                  "
                >
                  1:1대화
                </li>
              </ul>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="notice_empty news">나를 구독하는 친구가 없습니다.</div>
        </template>
      </div>

      <div class="notice_title">
        <span>차단 리스트</span>
      </div>
      <div class="notice_contents">
        <template v-if="blockList.length !== 0">
          <template v-for="(item, idx) in blockList" :key="item">
            <div
              class="user_info_wrap"
              :class="{ active: item.isItemTrue }"
              @click="CheckUser('block', idx, item.choiceIdx, $event)"
            >
              <div class="profile_box">
                <profileImg :img="item.profileImgSrc" />
              </div>
              <div class="fx-center-all">
                <div>
                  <lvImg :level="item.levelCd" />
                </div>
                <div>
                  <p>
                    {{ item.nickname }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="user_content"
              :class="{ active: item.isItemTrue }"
              :style="{
                top: B002.idx ? tgtY - 300 + 'px' : tgtY + 'px',
              }"
            >
              <button @click="toggleItemFalse('block', idx, $event)">
                <i class="fas fa-times"></i>
              </button>
              <ul>
                <li class="user_info">
                  <lvImg :level="item.levelCd" />
                  <span>{{ item.nickname }}</span>
                </li>
                <li
                  @click="
                    [
                      goMyPage(item.choiceIdx),
                      toggleItemFalse('block', idx, $event),
                    ]
                  "
                >
                  유저홈
                </li>
                <li
                  @click="
                    [openMsg(item), toggleItemFalse('block', idx, $event)]
                  "
                >
                  쪽지
                </li>
                <li
                  @click="
                    [
                      userUnBlocking(item.choiceIdx),
                      toggleItemFalse('block', idx, $event),
                    ]
                  "
                >
                  차단리스트삭제
                </li>
              </ul>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="notice_empty news">내가 차단하는 회원이 없습니다.</div>
        </template>
      </div>
    </div>
    <div
      class="user_talk_list customm_scroll"
      v-if="tab_menu == 'nickname_search'"
    >
      <div class="notice_title">
        <span>대화리스트</span>
      </div>
      <div class="notice_contents">
        <template v-if="personalChatList.length !== 0">
          <template v-for="item in personalChatList" :key="item">
            <div class="user_info_wrap">
              <div @click="openTalk(item)">
                <div class="profile_box">
                  <profileImg :img="item.profileImgSrc" />
                </div>
                <div class="fx-center-all">
                  <div>
                    <lvImg :level="item.userLevelCd" />
                  </div>
                  <div>
                    <p>
                      {{ item.userNm }}
                    </p>
                  </div>
                </div>
              </div>
              <button
                class="close_btn"
                type="button"
                @click="chatDelete(item.roomIdx)"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="notice_empty news">대화중인 대화방이 없습니다.</div>
        </template>
      </div>
    </div>
  </article>
</template>

<script>
import {
  apiFanList,
  apiCelebList,
  apiSubscribeCancel,
  apiUserUnBlocking,
  apiUserBlockingList,
  apiUserActive,
} from "@/api/user";
import alarmCounter from "@/components/alarmCounter";
import {
  apiChatPersonalList,
  apiChatPersonalCheck,
  apiChatPersonalDel,
} from "@/api/chat";
export default {
  name: "NotiWindow",
  props: ["B002"],
  components: {
    alarmCounter,
  },
  data() {
    return {
      tab_menu: "nickname_search",
      tgtY: 0,
      list: [],
      fanList: [],
      celebList: [],
      blockList: [],
      personalChatList: [],
    };
  },
  methods: {
    // 탈퇴 유저 확인 후 유저 팝업 오픈
    async CheckUser(type, index, userIdx, e) {
      this.tgtY = e.pageY - e.offsetY - 200;
      for (let i = 0; i < this.celebList.length; i++) {
        this.celebList[i].isItemTrue = false;
      }
      for (let i = 0; i < this.fanList.length; i++) {
        this.fanList[i].isItemTrue = false;
      }
      const model = {
        userIdx: userIdx,
      };
      try {
        const res = await apiUserActive(model);
        if (res.msg == "SUCCESS") {
          this.toggleItemTrue(type, index);
        } else {
        }
      } catch (e) {
        //  // console.error(e);
      }
    },
    // 유저 팝업 오픈
    toggleItemTrue(type, index) {
      if (type == "celeb") {
        // 특정 아이템의 isItemTrue 값을 토글합니다.
        this.celebList[index].isItemTrue = !this.celebList[index].isItemTrue;
        for (let i = 0; i < this.celebList.length; i++) {
          if (index != i) {
            this.celebList[i].isItemTrue = false;
          }
        }
      } else if (type == "fan") {
        // 특정 아이템의 isItemTrue 값을 토글합니다.
        this.fanList[index].isItemTrue = !this.fanList[index].isItemTrue;
        for (let i = 0; i < this.fanList.length; i++) {
          if (index != i) {
            this.fanList[i].isItemTrue = false;
          }
        }
      } else {
        this.blockList[index].isItemTrue = !this.blockList[index].isItemTrue;
        for (let i = 0; i < this.blockList.length; i++) {
          if (index != i) {
            this.blockList[i].isItemTrue = false;
          }
        }
      }
    },
    // 유저 팝업 클로즈
    toggleItemFalse(type, index) {
      // 특정 아이템의 isItemTrue 값을 토글합니다.
      if (type == "celeb") {
        // 특정 아이템의 isItemTrue 값을 토글합니다.
        this.celebList[index].isItemTrue = false;
      } else if (type == "fan") {
        // 특정 아이템의 isItemTrue 값을 토글합니다.
        this.fanList[index].isItemTrue = false;
      } else {
        this.blockList[index].isItemTrue = false;
      }
    },
    // 날 구독중인 회원 리스트 가져오기
    async getfanList() {
      const req = { page: 1, rowCnt: 1000 };
      const res = await apiFanList(req);
      if (res) {
        this.fanList = res.list;
        for (const key in this.fanList) {
          this.fanList[key].isItemTrue = false;
        }
      }
    },
    // 내가 구독중인 회원 리스트 가져오기
    async getceleList() {
      const req = { page: 1, rowCnt: 1000 };
      const res = await apiCelebList(req);
      if (res) {
        this.celebList = res.list;
        for (const key in this.celebList) {
          this.celebList[key].isItemTrue = false;
        }
      }
    },
    // 내가 차단한 회원 리스트 가져오기
    async getblockList() {
      const req = { page: 1, rowCnt: 1000 };
      const res = await apiUserBlockingList(req);
      if (res) {
        this.blockList = res.list;
        for (const key in this.blockList) {
          this.blockList[key].isItemTrue = false;
        }
      }
    },
    // 쪽지 보내기
    openMsg(item) {
      const routerLink = this.$router.resolve({
        name: "msgWrite",
        params: { type: "msgWrite" },
        query: { nickname: item.nickname || item.nickName },
      });
      this.popupOpen(routerLink.href, "msgPopup");
    },
    // 1:1채팅 대화리스트
    async getPersonalList() {
      const res = await apiChatPersonalList();
      if (res) {
        this.personalChatList = res.list;
      }
    },
    // 1:1대화 신청
    async openTalk(user) {
      const model = {
        toUserIdx: user.userIdx,
      };
      try {
        const res = await apiChatPersonalCheck(model);
        if (res) {
          const data = res.data;
          // 방 존재 => 바로 방으로 연결
          if (data) {
            const routerLink = this.$router.resolve({
              name: "personal_talk_room",
              query: {
                userIdx: user.userIdx,
                roomcode: data.roomCode,
                roomidx: data.roomIdx,
              },
            });
            window.open(
              routerLink.href,
              `${data.roomCode}`,
              "width=540px,height=600px,top=0,left=0,scrollbars=yes,resizable=yes"
            );
          }
          // 방 없음 = > 신청 페이지로 연결
          else {
            const routerLink = this.$router.resolve({
              name: "personal_talk_main",
              query: { userIdx: user.lgn_id || user.userInfo.lgn_id },
            });
            window.open(
              routerLink.href,
              "_blank",
              "width=540px,height=250px,top=0,left=0,scrollbars=yes,resizable=yes"
            );
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 1:1채팅 영구 삭제
    async chatDelete(roomIdx) {
      const model = {
        roomIdx: roomIdx,
      };
      const _confirm = confirm("해당 대화방을 영구적으로 삭제하시겠습니까?");
      if (!_confirm) {
        return;
      }
      const res = await apiChatPersonalDel(model);
      if (res) {
        alert("대화방 삭제 완료");
        await this.getPersonalList();
      }
    },
    // 차단 해제
    async userUnBlocking(idx) {
      const req = {
        choiceIdx: idx,
      };
      const trigger = confirm("차단을 해제하시겠습니까?");
      if (!trigger) {
        return;
      }
      try {
        const res = await apiUserUnBlocking(req);
        if (res) {
          alert("차단 해제 완료");
          await this.getblockList();
        }
      } catch (e) {
        // console.error(e);
      }
    },
    // 구독 해제
    async subscribeCancel(idx) {
      const model = {
        celebIdx: idx,
      };
      const trigger = confirm("구독을 취소하시겠습니까?");
      if (!trigger) {
        return;
      }
      try {
        const res = await apiSubscribeCancel(model);
        if (res) {
          alert("구독 취소가 완료되었습니다.");
          await this.getceleList();
        }
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    alarm() {
      return this.$store.getters.alarm;
    },
  },
  watch: {
    tab_menu: {
      async handler(menu) {
        switch (menu) {
          case "freind_list":
            await this.getceleList();
            await this.getfanList();
            await this.getblockList();
            break;
          case "nickname_search":
            await this.getPersonalList();
            break;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
#user_notice_wrap {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #ddd;
}
.notice_title_wrap {
  display: flex;
  width: 100%;
}

.notice_title_wrap > li {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 45px;
  border-bottom: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
}
.notice_title_wrap > li:last-child {
  border-right: 0;
}
.notice_title_wrap > li.active {
  /* background: #e74646;
    border-color: #e74646; */
  border-bottom: 3px solid #ffce32 !important;
}
.notice_title_wrap > li button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.user_notice {
  height: 400px;
  overflow-y: scroll;
}
.user_talk_list {
  overflow-y: scroll;
  height: 400px;
}
.notice_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #dadada;
  background-color: #ffce3252;
}
.notice_title span {
  font-size: 1em;
  font-weight: 700;
  padding-left: 10px;
}
.notice_title button {
  border: 1px solid rgb(206, 206, 206);
  padding: 5px;
  font-size: 0.8em;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
  color: rgb(49, 49, 49);
}
.notice_title button:hover {
  background: #e6264c;
  color: #fff;
  border-color: #e6264c;
}
.notice_contents div.news {
  border-bottom: 1px solid #dadada;
  padding: 10px;
  color: #a9a7a7;
  font-size: 0.9em;
  align-items: center;
  display: flex;
}
.notice_contents div.news a {
  color: #a9a7a7;
}
.notice_contents div.news a:hover {
  color: #000;
}

.notice_empty {
  justify-content: center;
}

.alarm_count {
  top: 50%;
  right: 8px;
}

.user_talk_list .user_info_wrap {
  justify-content: space-between;
}
.user_info_wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  /* width: 100%; */
  border-bottom: 1px dotted #ddd;
  padding: 10px;
  font-size: 0.8rem;
}

.user_info_wrap:hover {
  background-color: #fbfbfb;
  cursor: pointer;
}

.user_talk_list .user_info_wrap > div:first-child {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.profile_img {
  width: 50px !important;
  height: 50px !important;
}

#personal_list .close_btn {
  position: relative;
  z-index: 2;
}

.user_content.active {
  left: 150px;
  top: 10%;
}
.lv_img {
  height: 30px;
}
</style>

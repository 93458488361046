import err_code from "@/locales/err_kr";

export const apiErrorHandler = (err) => {
  if (process.env.NODE_ENV === "devlopment") {
    console.error(err.url);
    console.error(err.message);
    console.error(err.code);
  }
  if (err.code) {
    return alert(err_code[err.code]);
  } else if (err_code[err.code]) {
    return alert(err_code[err.code]);
  } else {
    return err.code;
  }
};

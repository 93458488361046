export default {
  path: "/board",
  name: "board",
  redirect: {
    name: "boardList",
    params: { type: "free" },
    query: { sub: "all", page: 1 },
  },
  component: () => import("@/views/pc/member/board"),
  meta: {
    layout: "default",
    userOnly: false,
  },
  children: [
    {
      path: "/board/list/:type",
      name: "boardList",
      component: () => import("@/views/pc/member/board/list"),
      props: true,
      meta: {
        layout: "default",
        userOnly: false,
      },
    },
    {
      path: "/board/write/:type",
      name: "boardWrite",
      component: () => import("@/views/pc/member/board/write"),
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
    {
      path: "/board/edit/:boardIdx",
      name: "boardEdit",
      component: () => import("@/views/pc/member/board/write"),
      props: true,
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
  ],
};

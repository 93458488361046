export default {
  path: "/user/msg",
  name: "userMessage",
  redirect: { name: "msgList" },
  component: () => import("@/views/mobile/member/msg"),
  meta: {
    layout: "mobile",
    userOnly: true,
  },
  children: [
    {
      path: "/user/msg/list/:type",
      name: "msgList",
      component: () => import("@/views/mobile/member/msg/list"),
      meta: {
        layout: "mobile",
        userOnly: true,
      },
    },
    {
      path: "/user/msg/write/:type",
      name: "msgWrite",
      component: () => import("@/views/mobile/member/msg/write"),
      meta: {
        layout: "mobile",
        userOnly: true,
      },
    },
    {
      path: "/user/msg/detail/:type/:idx",
      name: "msgDetail",
      component: () => import("@/views/mobile/member/msg/detail"),
      meta: {
        layout: "mobile",
        userOnly: true,
      },
    },
  ],
};

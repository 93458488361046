<template>
  <footer>
    <div class="footer_wrap">
      <div class="footer_list">
        <div class="footer_icon">
          <div class="user_info_item">
            <ul class="ui_list_section_line cashBox">
              <li>
                <div class="fx center_all cash_item blue">
                  <span class="cash_icon">C</span>
                  <span class="cash_value">{{ addCommas(userInfo.cash) }}</span>
                </div>
              </li>
              <li>
                <div class="fx center_all cash_item red">
                  <span class="cash_icon">P</span>
                  <span class="cash_value">{{
                    addCommas(userInfo.point)
                  }}</span>
                </div>
              </li>
              <li>
                <div class="fx center_all cash_item green">
                  <span class="cash_icon">E</span>
                  <span class="cash_value">{{ addCommas(userInfo.exp) }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="user_info">
          <span class="border_side"></span>
          <router-link
            :to="{ name: 'msgList', params: { type: 'receiveMsg' } }"
          >
            <img src="@/assets/img/chat2-icon.png" />
          </router-link>
          <button type="button" @click="alarmToggle">
            <img src="@/assets/img/bell-icon.png" />
          </button>
          <router-link
            :to="{ name: 'user', query: { userIdx: userInfo.userIdx } }"
          >
            <profileImg
              style="width: 20px; height: 20px"
              class="profile_img"
              :img="userInfo.profileImgSrc"
            />
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    alarmToggle() {
      this.$emit("alarmToggle");
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
};
</script>

<style scoped>
footer {
  height: var(--footer);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background-color: #c5c5c5;
  display: flex;
  justify-content: center;
  z-index: 9998;
}

.footer_wrap {
  width: 100%;
  height: 100%;
}
.footer_wrap .footer_list {
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.footer_icon {
  width: 40%;
}
.footer_list img {
  width: 16px;
  margin-right: 20px;
  height: 16px;
  border-radius: 100%;
}

.user_info_item {
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  margin-left: 10px;
}
ul[class*="ui_list"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul[class*="ui_list"] > li {
  display: flex;
  align-items: center;
}

.border_side {
  content: "";
  display: inline-block;
  width: 1px;
  height: 22px;
  background: #9d9d9d;
  margin: 0px 15px 0px 0px;
}

ul[class*="ui_list"] > li:last-child:after {
  content: "";
  display: none;
}

.cashBox * {
  font-size: 0.7em;
  font-weight: 700;
  margin-right: 5px;
}

.cashBox .cash_icon {
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
}

.cash_item.blue .cash_value {
  color: #1abcff;
}

.cash_item.blue .cash_icon {
  background: #1abcff;
}

.cash_item.red .cash_value {
  color: #fd434f;
}

.cash_item.red .cash_icon {
  background: #fd434f;
}

.cash_item.green .cash_value {
  color: #ffb100;
}

.cash_item.green .cash_icon {
  background: #ffb100;
}
.user_info {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
}
</style>

export default {
  path: "/cscenter",
  name: "cscenter",
  redirect: { name: "cscenter_noticeList" },
  component: () => import("@/views/mobile/member/cs_center"),
  meta: {
    layout: "mobile",
    userOnly: false,
  },
  children: [
    {
      path: "/cscenter/notice",
      name: "cscenter_noticeList",
      redirect: { name: "cscenter_notice" },
      component: () => import("@/views/mobile/member/cs_center/notice/index"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
      children: [
        {
          path: "/cscenter/notice/list",
          name: "cscenter_notice",
          component: () =>
            import("@/views/mobile/member/cs_center/notice/noticeList"),
          meta: {
            layout: "mobile",
            userOnly: false,
          },
        },
        {
          path: "/cscenter/notice/detail",
          name: "cscenter_noticeDetail",
          component: () =>
            import("@/views/mobile/member/cs_center/notice/noticeDetail"),
          meta: {
            layout: "mobile",
            userOnly: false,
          },
        },
      ],
    },
    {
      path: "/cscenter/qna/list",
      name: "cscenter_qnaList",
      component: () => import("@/views/mobile/member/cs_center/qna"),
      meta: {
        layout: "mobile",
        userOnly: true,
      },
    },
    {
      path: "/cscenter/qna/write",
      name: "cscenter_qnaWrite",
      component: () => import("@/views/mobile/member/cs_center/write"),
      meta: {
        layout: "mobile",
        userOnly: true,
      },
    },
    {
      path: "/cscenter/qna/detail/:idx",
      name: "cscenter_qnaDetail",
      component: () => import("@/views/mobile/member/cs_center/qnaDetail"),
      meta: {
        layout: "mobile",
        userOnly: true,
      },
      props: true,
    },
    {
      path: "/cscenter/faq/list",
      name: "cscenter_faqList",
      component: () => import("@/views/mobile/member/cs_center/faq"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/cscenter/prison/list",
      name: "cscenter_prisonList",
      component: () => import("@/views/mobile/member/cs_center/prison"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/cscenter/attendance",
      name: "cscenter_attendanceList",
      component: () => import("@/views/mobile/member/cs_center/attendance"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
  ],
};

<!-- 메인 배너 광고 -->
<template>
  <!-- ad_1 -->
  <article id="ads_top">
    <img
      :src="`${this.$store.state.adminURL}/api/advert/cmsimg/${B001.imgSrc}`"
    />
  </article>
</template>

<script>
export default {
  name: "Adtop",
  props: ["B001"],
};
</script>

<style scoped>
#ads_top > img {
  width: 100%;
  object-fit: cover;
}
#ads_top {
  width: 90%;
  height: 110px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;
}
</style>

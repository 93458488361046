<!-- 랜덤아이템 모달 -->
<template>
  <article class="modalWrap custom_scroll">
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="modalClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <article>
          <div class="sticker_container rotating-text-wrapper">
            <div class="img_box">
              <img
                :src="require(`@/assets/img/item/${this.itemList.itemCd}.png`)"
              />
            </div>
            <p>{{ this.itemList.result }}</p>
          </div>
        </article>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  name: "randomItem",
  props: ["itemList"],
  emits: ["closeModal"],
  data() {
    return {};
  },
  methods: {
    modalClose() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.modalWrap {
  position: fixed;
  z-index: 9999;
  background: #28282810;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;

  position: relative;
  padding: 48px;
  box-sizing: border-box;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

.sticker_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
/* .sticker_container .img_box {
  width: 50px;
} */

.sticker_container .img_box img {
  width: 150px;
  animation: shake 0.5s infinite;
}

.sticker_container p {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 5px 20px;
  border-bottom: 3px solid #ff3838;
}

@keyframes shake {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  20% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}
</style>

import { createWebHistory, createRouter } from "vue-router";
import { isMobile } from "@/libs/utils";
import routesPC from "./pc";
import routesMobile from "./mobile";
import routesGame from "./game";
let routes = [routesGame]
if(isMobile()){
    routes.push(...routesMobile)
} else {
    routes.push(...routesPC)
}
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, left: 0 };
    },
});
export default router;
<template>
  <div class="signUp_wrap">
    <div class="signUp_contents">
      <div class="close">
        <button class="close_btn" @click="modalClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="sign_form">
        <fieldset class="input_fs">
          <legend>가족방명</legend>
          <input
            type="text"
            v-model="model.nm"
            placeholder="가족방명을 입력하세요"
          />
        </fieldset>
        <span v-if="errorCheck" class="error-box_active"
          >5글자 이하로 입력해주세요.</span
        >
      </div>
      <div class="signUp">
        <button class="signUp_btn" type="button" @click="create">
          가족방 생성하기
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { apiFamilyCreate } from "@/api/family";
import { getUserData } from "@/libs/auth-helper";

export default {
  name: "familyCreate",
  emits: ["modalClose"],
  data() {
    return {
      model: {
        nm: "",
      },
      errorCheck: false,
    };
  },
  methods: {
    modalClose() {
      this.$emit("modalClose", false);
    },
    // 가족방 생성
    async create() {
      const req = this.model;
      if (this.errorCheck || !this.model.nm) {
        return alert("가족방명을 확인해주세요!");
      }
      try {
        const res = await apiFamilyCreate(req);
        if (res) {
          await this.refreshUserInfo();
          this.emitter.emit("familyRoomCreateResult");
          const trigger = confirm(
            "가족방이 생성되었습니다. 생성한 가족방으로 이동하시겠습니까?"
          );
          this.modalClose();
          if (trigger) {
            this.$router.push({
              name: "family",
              query: { familyIdx: this.userInfo.familyIdx },
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    model: {
      handler(val) {
        const length = val.nm.length;
        if (length > 5) {
          this.errorCheck = true;
        } else {
          this.errorCheck = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  beforeRouteEnter(to, from, next) {
    const user = getUserData();
    if (!!user) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9em;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;

  position: relative;
  padding: 30px 48px;
  box-sizing: border-box;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 80px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.input_fs {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input {
  /* margin: .4rem; */
  /* border: none; */
  /* padding: 20px; */
  font-size: 1.4em;
  margin-left: 17px;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}
.error-box_active {
  position: relative;
  font-size: 0.75rem;
  color: red;
}
</style>

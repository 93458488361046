export default {
    path: "/live",
    name: "gameViewer",
    meta: {
        layout: "frame"
    },
    redirect: "gameViewer",
    children:[
        {
            path: ":gameName",
            name: "gameViewer",
            component: () => import("@/views/pc/gameFrame"),
            props: true
        },
        {
            path: ":device/:gameName",
            name: "gameViewerWithDevice",
            component: () => import("@/views/pc/gameFrame"),
            props: true
        }
    ]
}
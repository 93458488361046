<template>
  <router-view />
</template>

<script>
export default {
  name: "FrameLayout",
  components: {},
  data() {},
  watch: {},
  created() {
    document.querySelector("body").classList.add("frame");
  },
};
</script>

<style scoped></style>

<template>
  <div class="racing">
    <span>
      {{ $t(`games.${gameType}`) }}
    </span>
    <template v-if="dhBlock">
      <span>{{ nextGameInfo.round }}회차</span>
      <span id="diffTime">
        <span>{{ timer.m }}분</span>
        <span>{{ timer.s }}초</span>
      </span>
      후 마감
    </template>
    <template v-else> <span> 준비중</span> </template>
  </div>
  <template v-if="dhBlock">
    <div class="expectation">
      <div class="expected_pick">
        <div class="expected_item">
          <div :style="{ width: `${1.1 * pickRatio.pwOe1}px` }">
            {{ pickRatio.pwOe1 }}%<span class="ex_odd">홀</span>
          </div>
          <div class="text">파워볼홀짝</div>
          <div :style="{ width: `${1.1 * pickRatio.pwOe2}px` }">
            <span class="ex_even">짝</span>{{ pickRatio.pwOe2 }}%
          </div>
        </div>
      </div>
      <div class="expected_pick">
        <div class="expected_item">
          <div :style="{ width: `${1.1 * pickRatio.pwUnover1}px` }">
            {{ pickRatio.pwUnover1 }}%<span class="ex_odd">언</span>
          </div>
          <div class="text">파워볼언오버</div>
          <div :style="{ width: `${1.1 * pickRatio.pwUnover2}px` }">
            <span class="ex_even">오</span>{{ pickRatio.pwUnover2 }}%
          </div>
        </div>
      </div>
      <div class="expected_pick">
        <div class="expected_item">
          <div :style="{ width: `${1.1 * pickRatio.oe1}px` }">
            {{ pickRatio.oe1 }}%<span class="ex_odd">홀</span>
          </div>
          <div class="text">일반볼홀짝</div>
          <div :style="{ width: `${1.1 * pickRatio.oe2}px` }">
            <span class="ex_even">짝</span>{{ pickRatio.oe2 }}%
          </div>
        </div>
      </div>
      <div class="expected_pick">
        <div class="expected_item">
          <div :style="{ width: `${1.1 * pickRatio.unover1}px` }">
            {{ pickRatio.unover1 }}%<span class="ex_odd">언</span>
          </div>
          <div class="text">일반볼언오버</div>
          <div :style="{ width: `${1.1 * pickRatio.unover2}px` }">
            <span class="ex_even">오</span>{{ pickRatio.unover2 }}%
          </div>
        </div>
      </div>
      <div class="section_pick">
        <div><span class="ex_large">대</span>{{ pickRatio.size3 }}%</div>
        <div><span class="ex_mid">중</span>{{ pickRatio.size2 }}%</div>
        <div><span class="ex_small">소</span>{{ pickRatio.size1 }}%</div>
      </div>
    </div>
    <div class="pick_container" v-if="!pickTimeOver && !myPick">
      <div class="gameType_wrap">
        <div class="type_pick">메인픽 타입을 먼저 선택해주세요.</div>
        <div class="game_type">
          <button
            type="button"
            @click="setPick('mainPick', 1)"
            :class="{ active: pickModel.mainPick === 1 }"
          >
            파워볼
          </button>
          <button
            type="button"
            @click="setPick('mainPick', 2)"
            :class="{ active: pickModel.mainPick === 2 }"
          >
            일반볼
          </button>
        </div>
      </div>
      <div class="gameType_wrap">
        <div class="type_pick" :class="{ active: pickModel.mainPick === 1 }">
          파워볼
        </div>
        <div class="main_pick odd_even">
          <button
            type="button"
            @click="setPick('pwOe', 1)"
            :class="{ active: pickModel.pwOe === 1 }"
          >
            홀
          </button>
          <button
            type="button"
            @click="setPick('pwOe', 2)"
            :class="{ active: pickModel.pwOe === 2 }"
          >
            짝
          </button>
        </div>
        <div class="main_pick unOver">
          <button
            type="button"
            @click="setPick('pwUnover', 1)"
            :class="{ active: pickModel.pwUnover === 1 }"
          >
            언더
          </button>
          <button
            type="button"
            @click="setPick('pwUnover', 2)"
            :class="{ active: pickModel.pwUnover === 2 }"
          >
            오버
          </button>
        </div>
      </div>
      <div class="gameType_wrap">
        <div class="type_pick" :class="{ active: pickModel.mainPick === 2 }">
          일반볼
        </div>
        <div class="main_pick odd_even">
          <button
            type="button"
            @click="setPick('oe', 1)"
            :class="{ active: pickModel.oe === 1 }"
          >
            홀
          </button>
          <button
            type="button"
            @click="setPick('oe', 2)"
            :class="{ active: pickModel.oe === 2 }"
          >
            짝
          </button>
        </div>
        <div class="main_pick unOver">
          <button
            type="button"
            @click="setPick('unover', 1)"
            :class="{ active: pickModel.unover === 1 }"
          >
            언더
          </button>
          <button
            type="button"
            @click="setPick('unover', 2)"
            :class="{ active: pickModel.unover === 2 }"
          >
            오버
          </button>
        </div>
        <div class="main_pick large_small">
          <button
            type="button"
            @click="setPick('size', 3)"
            :class="{ active: pickModel.size === 3 }"
          >
            대
          </button>
          <button
            type="button"
            @click="setPick('size', 2)"
            :class="{ active: pickModel.size === 2 }"
          >
            중
          </button>
          <button
            type="button"
            @click="setPick('size', 1)"
            :class="{ active: pickModel.size === 1 }"
          >
            소
          </button>
        </div>
      </div>
    </div>
    <div class="pickBlocker" v-else>
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="pick_notice" v-if="myPick">
        <span>픽 등록 완료</span>
      </div>
      <div class="pick_time_over" v-else>
        <p>픽 등록 시간이 초과되었습니다.</p>
        <p>다음 게임을 이용해주세요.</p>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="pickBlocker">
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="pick_time_over">
        <p>게임픽 가능시간 : 06 ~ 24시</p>
      </div>
    </div>
  </template>
</template>

<script>
import { apiGamesPick } from "@/api/game";

export default {
  name: "dhpowerball",
  props: ["bet", "pickActive", "gameType", "loggedIn"],
  emits: ["checkActive", "betAmtReset", "checkBetActive"],
  data() {
    return {
      betActive: false,
      pickModel: {
        mainPick: 0,
        oe: 0,
        pwOe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
        bet: 0,
      },
      timer: {
        m: 0,
        s: 0,
      },
    };
  },
  methods: {
    // 픽 선택
    setPick(type, value) {
      if (this.pickModel[type] == value) {
        this.pickModel[type] = 0;
        return;
      }
      if (type.indexOf("pw") > -1) {
        this.pickModel.pwOe = 0;
        this.pickModel.pwUnover = 0;
      } else {
        this.pickModel.size = 0;
        this.pickModel.unover = 0;
        this.pickModel.oe = 0;
      }
      this.pickModel[type] = value;
    },
    // 픽 모델 리셋
    pickModelReset() {
      for (const key in this.pickModel) {
        this.pickModel[key] = 0;
      }
    },
    // 배팅하기
    async doBetting() {
      // console.log("베팅하기");
      if (!this.loggedIn) {
        return alert("로그인 후 이용해주세요.");
      }
      const req = this.pickModel;
      req.bet = this.bet;

      if (req.bet === 0) {
        return;
      }
      const res = await apiGamesPick(this.gameType, req);
      if (res) {
        alert("픽 등록이 완료되었습니다.");
        switch (this.gameType) {
          case "dhpowerball":
            this.$store.dispatch(
              "updatemyDhpowerballPickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "coinpowerball5":
            this.$store.dispatch(
              "updatemyCoinpowerball5PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "coinpowerball3":
            this.$store.dispatch(
              "updatemyCoinpowerball3PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "eospowerball5":
            this.$store.dispatch(
              "updatemyEOSpowerball5PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "eospowerball3":
            this.$store.dispatch(
              "updatemyEOSpowerball3PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "metapowerball5":
            this.$store.dispatch(
              "updatemyMetapowerball5PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "metapowerball3":
            this.$store.dispatch(
              "updatemyMetapowerball3PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "pbgpowerball":
            this.$store.dispatch(
              "updatemyPbgpowerballPickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
        }
        await this.refreshUserInfo();
        for (const key in this.pickModel) {
          this.pickModel[key] = 0;
        }
        this.$emit("betAmtReset");
        this.$emit("checkActive", false);
        this.$emit("checkBetActive", false);
      }
    },
  },
  computed: {
    dhBlock() {
      switch (this.gameType) {
        case "dhpowerball":
          return this.$store.getters.dhBlock;
        default:
          return true;
      }
    },
    pickTimeOver() {
      switch (this.gameType) {
        case "dhpowerball":
          return this.$store.state.dhpowerballTimeOver;
        case "coinpowerball5":
          return this.$store.state.coinpowerball5TimeOver;
        case "coinpowerball3":
          return this.$store.state.coinpowerball3TimeOver;
        case "eospowerball5":
          return this.$store.state.EOSpowerball5TimeOver;
        case "eospowerball3":
          return this.$store.state.EOSpowerball3TimeOver;
        case "metapowerball5":
          return this.$store.state.metapowerball5TimeOver;
        case "metapowerball3":
          return this.$store.state.metapowerball3TimeOver;
        case "pbgpowerball":
          return this.$store.state.pbgpowerballTimeOver;
      }
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    pickRatio() {
      switch (this.gameType) {
        case "dhpowerball":
          return this.$store.getters.gamePickRatio.dhpowerball;
        case "coinpowerball5":
          return this.$store.getters.gamePickRatio.coinpowerball5;
        case "coinpowerball3":
          return this.$store.getters.gamePickRatio.coinpowerball3;
        case "eospowerball5":
          return this.$store.getters.gamePickRatio.eospowerball5;
        case "eospowerball3":
          return this.$store.getters.gamePickRatio.eospowerball3;
        case "metapowerball5":
          return this.$store.getters.gamePickRatio.metapowerball5;
        case "metapowerball3":
          return this.$store.getters.gamePickRatio.metapowerball3;
        case "pbgpowerball":
          return this.$store.getters.gamePickRatio.pbgpowerball;
      }
    },
    myPick() {
      switch (this.gameType) {
        case "dhpowerball":
          return this.$store.state.myDhpowerballPick;
        case "coinpowerball5":
          return this.$store.state.myCoinpowerball5Pick;
        case "coinpowerball3":
          return this.$store.state.myCoinpowerball3Pick;
        case "eospowerball5":
          return this.$store.state.myEOSpowerball5Pick;
        case "eospowerball3":
          return this.$store.state.myEOSpowerball3Pick;
        case "metapowerball5":
          return this.$store.state.myMetapowerball5Pick;
        case "metapowerball3":
          return this.$store.state.myMetapowerball3Pick;
        case "pbgpowerball":
          return this.$store.state.myPbgpowerballPick;
      }
    },
    nextGameInfo() {
      switch (this.gameType) {
        case "dhpowerball":
          return this.$store.state.nextDhpowerballInfo;
        case "coinpowerball5":
          return this.$store.state.nextCoinladder5Info;
        case "coinpowerball3":
          return this.$store.state.nextCoinladder3Info;
        case "eospowerball5":
          return this.$store.state.nextEOSpowerball5Info;
        case "eospowerball3":
          return this.$store.state.nextEOSpowerball3Info;
        case "metapowerball5":
          return this.$store.state.nextMetapowerball5Info;
        case "metapowerball3":
          return this.$store.state.nextMetapowerball3Info;
        case "pbgpowerball":
          return this.$store.state.nextPbgpowerballInfo;
      }
    },
    diffTime() {
      switch (this.gameType) {
        case "dhpowerball":
          return this.$store.getters.dhpowerballdiffTime;
        case "coinpowerball5":
          return this.$store.getters.coinpowerball5diffTime;
        case "coinpowerball3":
          return this.$store.getters.coinpowerball3diffTime;
        case "eospowerball5":
          return this.$store.getters.EOSpowerball5diffTime;
        case "eospowerball3":
          return this.$store.getters.EOSpowerball3diffTime;
        case "metapowerball5":
          return this.$store.getters.metapowerball5diffTime;
        case "metapowerball3":
          return this.$store.getters.metapowerball3diffTime;
        case "pbgpowerball":
          return this.$store.getters.pbgpowerballdiffTime;
      }
    },
  },
  watch: {
    bet: {
      handler(bet) {
        this.pickModel.bet = bet;
        if (bet > 0) {
          this.betActive = true;
        } else {
          this.betActive = false;
        }
      },
    },
    myPick: {
      handler(value) {
        if (value) {
          this.$emit("betAmtReset");
          this.$emit("checkActive", false);
          this.$emit("checkBetActive", false);
        }
      },
    },
    diffTime(diff) {
      if (diff > -1) {
        this.timer.m = Math.floor(diff / 60);
        this.timer.s = diff % 60;
      }
      if (diff < 10) {
        this.$emit("checkActive", false);
        this.$emit("checkBetActive", false);
      } else {
        if (!this.pickActive) {
          this.$emit("checkActive", true);
        }
      }
    },
    pickModel: {
      handler(model) {
        const mainPick = model.mainPick;
        const main = mainPick ? true : false;
        let normal = false;
        let power = false;
        let powerPickCnt = 0;
        let normalPickCnt = 0;
        if (!main) {
          this.$emit("checkBetActive", false);
          return;
        }
        for (const key in model) {
          if (key.indexOf("pw") == 0) {
            if (model[key]) {
              powerPickCnt++;
            }
          } else {
            if (key.indexOf("main") != 0 && key.indexOf("bet") != 0) {
              if (model[key]) {
                normalPickCnt++;
              }
            }
          }
        }
        if (powerPickCnt > 0) {
          power = true;
        } else {
          power = false;
        }
        if (normalPickCnt > 0) {
          normal = true;
        } else {
          normal = false;
        }
        if (main && power && normal && this.betActive) {
          this.$emit("checkBetActive", true);
        } else {
          this.$emit("checkBetActive", false);
        }
      },
      deep: true,
    },
  },
  async created() {},
};
</script>

<style scoped>
.racing {
  height: 40px;
  background-color: #94c93e;
  color: #fff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expectation {
  flex-direction: column;
  margin-bottom: 5px;
}
.expected_pick {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.7em;
  color: #fff;
  margin-top: 5px;
  gap: 5px;
  padding: 0 5px;
  box-sizing: border-box;
}
.expected_pick {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 0.7em;
  color: #fff;
  margin-bottom: 5px;
}
.section_pick {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.8em;
  color: #fff;
  margin-top: 5px;
  gap: 5px;
  box-sizing: border-box;
  padding: 0 5px;
}
.section_pick div {
  padding: 5px;
  background-color: #c7c7c7;
  border-radius: 10px;

  flex: 1 0 auto;
  display: flex;
  justify-content: space-evenly;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.section_pick div:nth-child(1) {
  background: #e74c3c;
}
.section_pick div:nth-child(2) {
  background: #279aff;
}
.section_pick div:nth-child(3) {
  background: #ffb100;
}
.section_pick .ex_large {
  float: left;
}
.section_pick .ex_mid {
  float: left;
}
.section_pick .ex_small {
  float: left;
}
.type_pick {
  font-size: 0.8em;
  font-weight: 500;
  padding-bottom: 5px;
}
.type_pick.active::after {
  content: "[메인픽]";
  display: inline;
  margin-left: 4px;
}
.game_type {
  position: relative;
  display: flex;
  font-size: 0.8em;
  font-weight: 700;
  gap: 10px;
  padding-bottom: 5px;
}
.game_type button {
  flex: 1 1 50%;
  padding: 8px 0;
  color: #aba8a8;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(to top, #ddd, #fff);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.game_type button.active {
  box-shadow: inset -3px -3px 7px #ffffff73,
    inset 3px 3px 5px rgba(94, 104, 121, 0.288);
}
.gameType_wrap {
  padding: 5px;
}

.odd_even button:first-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #279aff;
  box-shadow: rgb(50 70 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 42 64 / 35%) 0px -2px 6px 0px inset;
}
.odd_even button:last-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #e74c3c;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.unOver button:first-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #279aff;
  box-shadow: rgb(50 70 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 42 64 / 35%) 0px -2px 6px 0px inset;
}
.unOver button:last-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #e74c3c;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.main_pick {
  position: relative;
  display: flex;
  font-size: 0.8em;
  font-weight: 700;
  gap: 10px;
  padding-bottom: 5px;
}
.main_pick > button.active {
  box-shadow: inset -3px -3px 7px #ffffff3d, inset 3px 3px 7px rgb(0 0 0 / 40%) !important;
}
.large_small button:nth-child(1) {
  flex: 1 1 33%;
  padding: 6px 0;
  color: #fff;
  border-radius: 14px;
  box-sizing: border-box;
  cursor: pointer;
  background: #e74c3c;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.large_small button:nth-child(2) {
  flex: 1 1 33%;
  padding: 6px 0;
  color: #fff;
  border-radius: 14px;
  box-sizing: border-box;
  cursor: pointer;
  background: #279aff;
  box-shadow: rgb(50 70 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 42 64 / 35%) 0px -2px 6px 0px inset;
}
.large_small button:nth-child(3) {
  flex: 1 1 33%;
  padding: 6px 0;
  color: #fff;
  border-radius: 14px;
  box-sizing: border-box;
  cursor: pointer;
  background: #ffb100;
  box-shadow: rgb(93 86 50 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(64 48 10 / 35%) 0px -2px 6px 0px inset;
}
.racing > span {
  margin-left: 4px;
}
.pickBlocker {
  margin: 20px 0;
}
.pickBlocker .logo_symbol {
  margin: 20px 0;
}
.pickBlocker img {
  width: 100px;
}
.pickBlocker .pick_notice {
  font-weight: 700;
  font-size: 1.2em;
  color: #e6264c;
  text-align: center;
}
.pickBlocker .pick_time_over {
  text-align: center;
  font-weight: 500;
  font-size: 1em;
  color: #919195;
}
.logo_symbol img {
  width: 174px;
  /* height: 68px; */
  display: block;
  margin: 0 auto;
}

.expected_item {
  width: fit-content;
  position: relative;
  display: flex;
}
.expected_item > div:not(.text) {
  transition: width 0.5s;
  position: absolute;
  max-width: 110px;
  min-width: 65px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.expected_item div:nth-child(1) {
  text-align: right;
  padding: 5px;
  background-color: #279aff;
  border-radius: 10px;
  left: -5px;
  transform: translateX(-100%);
  box-shadow: rgb(50 70 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 42 64 / 35%) 0px -2px 6px 0px inset;
}
.expected_item div:nth-child(3) {
  padding: 5px;
  background-color: #e74c3c;
  border-radius: 10px;
  right: -5px;
  transform: translateX(100%);
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.expected_item div:nth-child(2) {
  width: 67px;
  padding: 5px;
  background: linear-gradient(to top, #ddd, #fff);
  border-radius: 10px;
  text-align: center;
  color: #000;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
</style>

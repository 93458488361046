<template>
  <main-header
    @navToggle="navToggle"
    :navActive="navActive"
    @modalOpen="modalOpen"
    @modalClose="modalClose"
  />
  <main ref="main">
    <!-- 한줄메세지 -->
    <template v-if="arowMagList.length > 0">
      <article
        id="arow_msg"
        v-if="currentPage !== 'chat_rank_list' && currentPage !== 'chatRoom'"
      >
        <div class="scroll-text" v-if="currentPage === 'main'">
          <div class="track" v-for="item in arowMagList" :key="item.idx">
            <p>{{ item.messege }}</p>
          </div>
        </div>
      </article>
    </template>
    <template v-else>
      <article
        :class="$route.name === 'chat_rank_list' ? '' : 'mb_20'"
      ></article>
    </template>
    <popup-modal
      v-if="currentPage === 'main'"
      @getAdvert="getAdvert"
      :popup="popup"
    />
    <section id="item_container">
      <router-view @modalOpen="modalOpen" :B001="advert.B001" />
    </section>
  </main>
  <div id="right_controller">
    <ul class="footer_menu">
      <li @click="chatActive = !chatActive">
        <i class="fas fa-gamepad"></i>
        <span>실시간채팅</span>
      </li>
      <!-- <li @click="pushPage(msgList)">
        <i class="fas fa-gamepad"></i>
        <span>쪽지함</span>
      </li> -->
      <li @click="alarmToggle(true)">
        <i class="fas fa-rocket"></i>
        <span>알람</span>
      </li>
    </ul>

    <button type="button" id="chat_toggle" @click="wheelOn()">
      <img src="@/assets/img/ballpick_logo_3.png" />
    </button>

    <div class="sr_wheel" :class="{ active: wheelActive }">
      <div class="sr_tool_wrap">
        <div class="sr_circle"></div>
        <ul class="sr_tool_list">
          <li class="sr_tool_item">
            <button @click="linkMove('pickster_rank')">
              <i class="fas fa-chart-bar"></i>
              <span>픽스터랭킹</span>
            </button>
          </li>
          <li class="sr_tool_item">
            <button @click="linkMove('family_rank')">
              <i class="fas fa-chart-bar"></i>
              <span>가족방랭킹</span>
            </button>
          </li>
          <li class="sr_tool_item">
            <button @click="linkMove('chat')">
              <i class="fas fa-comments"></i>
              <span>채팅방</span>
            </button>
          </li>
          <li class="sr_tool_item">
            <button @click="gameToggle(true)">
              <i class="fas fa-gamepad"></i>
              <span>게임픽</span>
            </button>
          </li>

          <li class="sr_tool_item">
            <button @click="linkMove('talk')">
              <i class="fas fa-comment-dots"></i>
              <span>1:1대화</span>
            </button>
          </li>
          <li class="sr_tool_item">
            <button @click="linkMove('msgList')">
              <i class="fas fa-envelope"></i>
              <span>쪽지</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <main-nav
    :B002="advert.B002"
    :navActive="navActive"
    :loggedIn="loggedIn"
    @navToggle="navToggle"
    @modalOpen="modalOpen"
  />
  <main-chat
    :class="{ active: chatActive }"
    :chatActive="chatActive"
    :loggedIn="loggedIn"
    @chatToggle="chatToggle"
    @modalOpen="modalOpen"
  />
  <main-alarm v-if="alarmActive" @alarmToggle="alarmToggle" />
  <game-pick
    :class="{ active: gameActive }"
    :gameActive="gameActive"
    :loggedIn="loggedIn"
    @gameToggle="gameToggle"
    @modalOpen="modalOpen"
  />
  <Modal :modalName="modalName" @modalClose="modalClose" v-if="modalActive" />
</template>

<script>
import MainHeader from "./Header";
import MainFooter from "./Footer";
import MainNav from "./Nav";
import MainChat from "./Chat";
import MainAlarm from "./notiWindow";
import gamePick from "./game";
import AdTop from "@/components/ad/ADTop_mob";
import { getPopupDate } from "@/libs/auth-helper";
import popupModal from "@/components/popupModal_mobile.vue";

import { apiAdvert, apiArowList } from "@/api/main";
import { apiRoomList } from "@/api/chat";
export default {
  name: "MobileLayout",
  components: {
    MainNav,
    MainFooter,
    MainHeader,
    MainChat,
    MainAlarm,
    AdTop,
    gamePick,
    popupModal,
  },
  data() {
    return {
      navActive: false,
      chatActive: false,
      alarmActive: false,
      modalActive: false,
      gameActive: false,
      subBtnActive: {
        game: false,
        rank: false,
      },
      controllerActive: false,
      modalName: "",
      roomList: [],
      advertList: [],
      advert: {
        B001: [],
        B002: [],
      },
      popup: [],
      wheelActive: false,
      arowMagList: [],
    };
  },
  methods: {
    wheelOn() {
      this.wheelActive = !this.wheelActive;
    },
    linkMove(name) {
      if (name == "talk") {
        if (!this.loggedIn) {
          return alert("로그인 후 이용해주세요.");
        }
      } else if (name == "msgList") {
        if (!this.loggedIn) {
          return alert("로그인 후 이용해주세요.");
        } else {
          this.wheelActive = false;
          this.$router.push({
            name: "msgList",
            params: { type: "receiveMsg" },
          });
          return;
        }
      }
      this.wheelActive = false;
      this.$router.push({ name: name });
    },

    alarmToggle(active) {
      if (!this.loggedIn) {
        alert("로그인 후 이용해주세요.");
        return;
      }
      if (active === false) {
        this.alarmActive = active;
      } else {
        this.alarmActive = !this.alarmActive;
      }
    },

    setAlarmInterval() {
      if (this.alarmInterval) {
        // console.log("alarm interval reset");
        clearInterval(this.alarmInterval);
      }
      this.alarmInterval = setInterval(() => {
        this.$store.dispatch("getNewAlarm");
      }, 60000);
    },
    // 채팅방 리스트
    async getRoomList() {
      const model = {
        page: 1,
        rowCnt: 10,
        gameType: "EOS5",
      };
      const res = await apiRoomList(model);
      // console.log(res);
      if (res) {
        this.roomList = res.list;
        for (const item of this.roomList) {
          const totalRecord = item.mainTotLose + item.mainTotWin;
          item.totalRecord = totalRecord;
          if (totalRecord <= 0) {
            item.winRate = 0;
          } else {
            item.winRate = (item.mainTotWin / totalRecord).toFixed(2);
          }
          //conWin=1, conLose=0
          item.wlStatusType = !item.mainConLose ? 1 : 0;
          item.wlStatus = !item.mainConLose
            ? item.mainConWin
            : item.mainConLose;
        }
        this.$store.commit("setRoomList", this.roomList);
      }
    },
    subBtnToggle(type) {
      for (const key in this.subBtnActive) {
        if (type !== key) {
          this.subBtnActive[key] = false;
        } else {
          this.subBtnActive[type] = !this.subBtnActive[type];
          // this.$nextTick(()=>{
          //   // console.log(this.$refs[`sub_btn_${key}`].classList.add('a'));
          // })
        }
      }
    },
    navToggle(bool) {
      this.navActive = bool;
    },
    chatToggle(bool) {
      this.chatActive = bool;
    },
    gameToggle(bool) {
      if (bool === false) {
        this.gameActive = bool;
      } else {
        this.gameActive = !this.gameActive;
      }
      this.wheelActive = false;
    },
    modalOpen(modalName) {
      this.modalName = modalName;
      this.modalActive = true;
    },
    modalClose() {
      this.modalActive = false;
    },
    // 배너 팝업 이미지 주소 불러오기
    async getAdvert() {
      const res = await apiAdvert();
      if (res.data) {
        this.advertList = res.data;
        for (let i = 0; i < this.advertList.length; i++) {
          if (this.advertList[i].typeCd == "B001") {
            this.advert.B001 = this.advertList[i];
          } else if (this.advertList[i].typeCd == "B002") {
            this.advert.B002 = this.advertList[i];
          } else {
            if (getPopupDate(this.advertList[i].idx) === "Y") {
            } else {
              this.popup.push(this.advertList[i]);
            }
          }
        }
      }
    },
    // 한줄메세지 리스트 불러오기
    async getArowMsg() {
      const res = await apiArowList();
      // console.log(res);
      if (res) {
        this.arowMagList = res.list;
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  watch: {
    $route: {
      async handler(value) {
        this.currentPage = value.name;
        this.chatToggle(false);
        this.gameToggle(false);
        this.$store.dispatch("setCurrentPage", value.name);

        if (this.loggedIn) {
          this.$store.dispatch("getNewAlarm");
        }
        this.$nextTick(() => {
          if (this.$route.name === "chatRoom") {
          } else {
            this.$refs.main.classList.remove("chatMain");
          }
        });

        // this.getRoomList();
      },
      immediate: true,
    },
    controllerActive(active) {
      if (!active) {
        for (const key in this.subBtnActive) {
          this.subBtnActive[key] = false;
        }
      }
    },
    chatActive: {
      handler(active) {
        if (active) {
          this.navActive = false;
        }
      },
    },
    gameActive: {
      handler(active) {
        if (active) {
          this.navActive = false;
        }
      },
    },
    navActive: {
      handler(active) {
        if (active) {
          this.chatActive = false;
          this.gameActive = false;
        }
      },
    },
  },
  created() {
    this.currentPage = this.$route.name;
    this.getAdvert();
    this.getArowMsg();
  },
};
</script>

<style scoped>
main {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 50px;
}
main.chatMain {
  padding: 0;
  min-height: unset;
  height: calc(100vh - 100px);
}
main.chatMain #item_container {
  height: 100%;
}

#side_right {
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 1;
  height: calc(100% - 100px);
  margin: var(--header) 0 var(--footer);
}
#side_right.active {
  transform: translateX(0%);
}
#right_controller {
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  bottom: 0px;
  z-index: 10;
  background-color: #423e31;
}
#right_controller .footer_menu {
  width: 100%;
  display: flex;
  background-color: #423e31;
  z-index: 3;
}
#right_controller .footer_menu li {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
#right_controller .footer_menu li span {
  font-size: 0.7em;
}
#chat_toggle {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #bcbcbc;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 43%;
  bottom: 20px;
  z-index: 4;
}
#chat_toggle > img {
  width: 70%;
  margin-top: 3px;
}

#best_chat_container {
  position: fixed;
  top: 50px;
  right: 0;
  min-width: 60px;
  height: fit-content;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: 0.2s all;
}
#best_chat_container.active {
  max-height: 200px;
  overflow: unset;
}
#best_chat_container .head {
  width: 60px;
  height: 60px;
  padding: 20px;
  background: #94c93e;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
#best_chat_container .head p {
  font-size: 0.8em;
}

#best_chat_container .item_wrap {
  background: #fff;
}
#best_chat_container .item {
  padding: 8px;
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row-reverse;
  box-sizing: border-box;
  /* margin:2px; */
  /* overflow: hidden; */
}
/* #best_chat_container .item.first:after{
    content: '';
    position: absolute;
    box-sizing: border-box;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 50% 50%,50% 50%;
    background-position: 0 0,100% 0,100% 100%,0 100%;
    background-image: linear-gradient(#e6264c,#e6264c),linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0)),linear-gradient(#e6264c,#e6264c),linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
    animation: rotate 1.5s linear infinite;
} */
/* @keyframes rotate {
    100% {
        transform: rotate(1turn)
    }
} */
#best_chat_container .item > .info_box {
  display: flex;
}
#best_chat_container .item:hover {
  border: 1px solid #e6264c;
  /* margin:0; */
}
#best_chat_container .item img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
#best_chat_container .head > span {
  display: block;
  height: 2px;
  width: 100%;
  background: #fff;
}
#best_chat_container .head > span:first-child {
  width: 100%;
}
#best_chat_container .head > span:nth-child(2) {
  width: 75%;
}
#best_chat_container .head > span:nth-child(3) {
  width: 50%;
}
.info_box {
  display: none;
  position: absolute;
  right: 59px;
  top: -1px;
  background: #8ea5c1;
  color: #fff;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  gap: 30px;
  width: 70vw;
  font-size: 0.9em;
  border-bottom: 1px solid #fff;
}
.info_box > div,
.info_box > span {
  display: block;
  height: fit-content;
}
.info_box > div {
  max-width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info_box span {
  white-space: nowrap;
}

/* 휠 관련 */
.sr_wheel {
  display: none;
  position: absolute;
  z-index: 1;
  /* width: 335px; */
  height: 335px;
  background-color: #fff;
  border-radius: 50%;
  width: 100%;
  top: -167px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.sr_wheel.active {
  display: block;
  animation: scale 0.2s ease;
}

.sr_tool_wrap {
  height: 100%;
  position: relative;
}
.sr_circle {
  width: 50%;
  height: 50%;
  background-color: #e3e3e3;
  position: absolute;
  top: 25%;
  left: 25%;
  border-radius: 100%;
}
.sr_tool_list {
  height: 100%;
}
.sr_tool_list > li:nth-child(1) {
  left: 7%;
  bottom: 51%;
}
.sr_tool_list > li:nth-child(2) {
  left: 17%;
  bottom: 69%;
}
.sr_tool_list > li:nth-child(3) {
  left: 34%;
  bottom: 78%;
}
.sr_tool_list > li:nth-child(4) {
  right: 34%;
  bottom: 78%;
}
.sr_tool_list > li:nth-child(5) {
  right: 17%;
  bottom: 69%;
}
.sr_tool_list > li:nth-child(6) {
  right: 7%;
  bottom: 51%;
}
.sr_tool_item {
  position: absolute;

  width: 50px;
  height: 60px;

  /* background-color: #8ea5c1; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.sr_tool_item > button {
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: center;
}

.sr_tool_item > button > i {
  font-size: 1.5rem;
  padding: 5px;
  border-radius: 5px;
  background-color: #e62645;
  color: #fff;
}
.sr_tool_item > button > span {
  font-size: 0.8rem;
  font-weight: 700;
  white-space: nowrap;
}

.sr_tool_list::before {
  margin-left: 43px;
  -webkit-transform: translate(-50%, -50%) rotate(-112deg);
  transform: translate(-50%, -50%) rotate(-112deg);
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

#arow_msg {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}
/* 움직이는 텍스트 */
@keyframes scroll-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.scroll-text {
  width: 100%;
  overflow: hidden;
  display: flex;
}
.track {
  position: relative;
  width: 100%;
  animation: scroll-animation 30s linear infinite;
  display: flex;
  gap: 35px;
  padding: 0 10px;
}
.track p {
  font-weight: 500;
  color: #423e31;
  white-space: nowrap;
}
</style>

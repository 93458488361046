import httpClient from "@/libs/http-client";
import { getAuthToken } from "@/libs/auth-helper";

//로그인
export function apiSignIn(data) {
  const url = "/signin";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//소셜로그인
export function apiSocialLogin() {
  const url = "/signin/token";
  return httpClient.post(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//유저정보
export function apiUserInfo(data) {
  const url = "/user/info";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//회원가입
export function apiSignUp(data) {
  const url = "/signup";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//소셜회원가입
export function apiSocialSignUp(data) {
  const url = "/signup/social";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//아이디 중복검사
export function apiDuplicateId(data) {
  const url = "/account/test/id";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//닉네임 중복검사
export function apiDuplicateNick(data) {
  const url = "/account/test/nickname";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//아이디 찾기
export function apiFindId(data) {
  const url = "/account/id";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//비밀번호 재설정
export function apiRewritePw(data) {
  const url = "/account/pw";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 회원 탈퇴
export function apiUserDel(data) {
  const url = "/account/user/del";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 유저 탈퇴 확인
export function apiUserActive(data) {
  const url = "/account/user/active";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//회원가입
export function apiSignOut(data) {
  const url = "/signout";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//마이페이지 기본정보
export function apiUserPageInfo(data) {
  const url = "/user/page";
  const token = getAuthToken();
  return httpClient
    .get(url, { headers: { VICTOKEN: token }, params: data })
    .then((response) => {
      if (response) {
        return response.data;
      } else {
        return response;
      }
    });
}

//프로필 이미지 업로더
export function apiProfileUpload(data) {
  const url = "/acc/profile/image/upload";
  const headers = { "Content-Type": "multipart/form-data" };
  return httpClient.post(url, data, headers).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//배너 이미지 업로더
export function apiBannerUpload(data) {
  const url = "/acc/banner/image/upload";
  const headers = { "Content-Type": "multipart/form-data" };
  return httpClient.post(url, data, headers).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//환전 이미지 업로더
export function apiexchangeImageUpload(data) {
  const url = "/exchange/image/upload";
  const headers = { "Content-Type": "multipart/form-data" };
  return httpClient.post(url, data, headers).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//프로필 이미지 url 변경
export function apiSetProfileSrc(data) {
  const url = "/mypage/info/profileImg";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//배너 이미지 url 변경
export function apiSetBannerSrc(data) {
  const url = "/mypage/info/bannerImg";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 유저 정보 변경
export function apiSetUserInfo(type, data) {
  const url = `/mypage/info/${type}`;
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//구독
export function apiSubscribeAdd(data) {
  const url = "/subscribe/plus";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//구독취소
export function apiSubscribeCancel(data) {
  const url = "/subscribe/minus";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//구독 리스트
export function apiCelebList(data) {
  const url = "/subscribe/celeb/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 내 구독자 리스트
export function apiFanList(data) {
  const url = "/subscribe/fan/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 로그인 기록
export function apiLoginLog(data) {
  const url = "/mypage/login/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 닉네임 기록
export function apiNicknameLog(data) {
  const url = "/mypage/nickname/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 아이템 리스트
export function apiInventory(data) {
  const url = "/item/private/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 캐시 리스트
export function apiCashLog(data) {
  const url = "/mypage/cash/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 포인트 리스트
export function apiPointLog(data) {
  const url = "/mypage/point/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 경험치 리스트
export function apiExpLog(data) {
  const url = "/mypage/exp/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//등급구매
export function apiLevelUp() {
  const url = "/mypage/exp/level/up";
  return httpClient.post(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//등급구매
export function apiLevelDown() {
  const url = "/mypage/exp/level/down";
  return httpClient.post(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 보낸쪽지목록
export function apiMsgSendList(data) {
  const url = "/zzokzi/from/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 받은쪽지목록
export function apiMsgReceiveList(data) {
  const url = "/zzokzi/to/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 미확인 쪽지 개수
export function apiMsgNewCnt(data) {
  const url = "/zzokzi/news";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//쪽지 상세
export function apiMsgDetail(data) {
  const url = "/zzokzi/item";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//쪽지 전송
export function apiMsgSend(data) {
  const url = "/zzokzi/plus";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//쪽지 전체삭제
export function apiMsgAllDelete(data) {
  const url = "/zzokzi/empty";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//쪽지 전체 읽음
export function apiMsgAllRead() {
  const url = "/zzokzi/clear";
  return httpClient.post(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//닉네임 검색
export function apiSearchUser(data) {
  const url = "/account/search";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//알림 카운트
export function apiAlarm() {
  const url = "/alarm/now";
  return httpClient.get(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//알림 리스트
export function apiAlarmList(data) {
  const url = "/alarm/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//알림 전체삭제
export function apiAlarmDeleteAll(data) {
  const url = "/alarm/empty";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//아이템 내역
export function apiItemLog(data) {
  const url = "/item/private/bill";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//아이템 사용하기
export function apiItemConsumption(data) {
  const url = "/item/consumption";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 내 환전 내역 조희
export function apiExchangeJewelList(data) {
  const url = "/jewel/exchange/info";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 환전하기
export function apiExchangeJewelCnt(data) {
  const url = "/jewel/exchange";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 환전 취소 신청
export function apiExchangeJewelCancel(data) {
  const url = "/jewel/exchange/cancel";
  return httpClient.put(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 보유중인 보석 조회
export function apiMyJewlLog(data) {
  const url = "/mypage/jewel/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 선물받은 보석 조회
export function apiMyJewgiftlLog(data) {
  const url = "/mypage/jewelgift/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 보석 선물
export function apiJewelDonation(data) {
  const url = "/user/domination";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 유저 차단
export function apiUserBlocking(data) {
  const url = "/account/user/blocking";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 유저 차단 해제
export function apiUserUnBlocking(data) {
  const url = "/account/user/unBlocking";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 유저 차단 리스트
export function apiUserBlockingList(data) {
  const url = "/account/user/blockingList";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

export default {
  path: "/chat",
  name: "chat",
  redirect: { name: "chat_rank_list" },
  component: () => import("@/views/mobile/member/chat"),
  meta: {
    layout: "mobile",
    userOnly: false,
  },
  children: [
    {
      path: "/chat_rank/list",
      name: "chat_rank_list",
      component: () => import("@/views/mobile/member/chat/list"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/chat/room/:roomIdx",
      name: "chatRoom",
      component: () => import("@/views/mobile/member/chat/room/index.vue"),
      meta: {
        layout: "mobile",
        userOnly: true,
      },
      props: true,
    },
  ],
};

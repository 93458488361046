import httpClient from "@/libs/http-client";

// 연승 리스트
export function apiHisConwin(gameType, data) {
  const url = `/pick/hist/${gameType}/conwin`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// ! 게임 별 히스토리
export function apiHisPick(gameType, data) {
  const url = `/pick/hist/${gameType}/pick`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 승률전적
export function apiWinRate(gameType, data) {
  const url = `/pick/hist/${gameType}/winper`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// ! 승률전적 - 게임타입별로
export function apiWinHist(gameType, data) {
  const url = `/pick/hist/${gameType}/win/hist`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// !  참여중인 픽 - 공통
export function apiGamePick(name, data) {
  const url = `/home/${name}/now`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

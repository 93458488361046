import { createApp } from "vue";
// 사이트 이름
import { DOCUMENT_TITLE_PREFIX } from "@/libs/constants";
// 로그인 관련 정보
import { getAuthToken, getUserData, removeAuthData } from "@/libs/auth-helper";
// 라우터 정보
import _router from "./router";

// 공통 함수 모아놓은곳
import mixin from "./plugins/common";
// (글로벌 변수 보관함)
import connectPlugin from "./plugins/connectPlugin";
import store from "./store";
import App from "./App.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import i18n from "./i18n";
// 모바일 환경 확인하는 함수 불러와서 확인
import { isMobile } from "@/libs/utils";
// 마우스버튼을 길게 눌러 스크롤을 가능하게 하는 기능
import { dragscrollNext } from "vue-dragscroll";
// 전역으로 사용할 컴포넌트 import
import DatePickr_month from "@/components/ui/DateSelector_monthly";
import DatePickr from "@/components/ui/DateSelector";
import lvImg from "@/components/levelImage";
import profileImg from "@/components/profile_img";
import ModalContainer from "@/components/Modal/index";
import ModalContainer_mobile from "@/components/Modal_mobile/index";
// 멀리 떨어져 있는 컴포넌트로 데이터 전송이 필요할때 쉽게 전송이 가능한 라이브러리
import mitt from "mitt";
import LoadScript from "vue-plugin-load-script";

const emmiter = mitt();

import "@/assets/css/fontAwsome.css";

// 모바일, pc에 따른 css 적용
const styles = () => {
  if (isMobile()) {
    import("@/style/reset_mobile.css");
  } else {
    import("@/style/reset_pc.css");
  }
};
styles();

const router = _router;
router.beforeEach((to, from, next) => {
  const title = DOCUMENT_TITLE_PREFIX;
  document.title = title;
  const token = getAuthToken();
  const userInfo = getUserData();
  const { userOnly } = to.meta;
  if (userOnly) {
    if (to.name.indexOf("chat") > -1) {
      return next();
    }
    if (!token || token.indexOf("TKE00") > -1 || !userInfo) {
      alert("로그인이 필요한 서비스 입니다.");
      return next({ name: "main" });
    }
  }
  next();
});

// 접속 환경에 따라 모달이 달라짐
const app = createApp(App);
if (isMobile()) {
  app.component("Modal", ModalContainer_mobile);
} else {
  app.component("Modal", ModalContainer);
}

app
  .mixin(mixin)
  .use(CKEditor)
  .use(store)
  .use(i18n)
  .use(router)
  .use(connectPlugin)
  .use(LoadScript)
  .directive("dragscroll", dragscrollNext)
  .component("lvImg", lvImg)
  .component("profileImg", profileImg)
  .component("DatePickr", DatePickr)
  .component("MonthPickr", DatePickr_month);
app.config.globalProperties.emitter = emmiter;
app.config.unwrapInjectedRef = true;
app.mount("body");
window.app = app;

import { format, subDays } from "date-fns";
import { TYPE_MODEL, TYPE_CODE, CONVERT_TYPE_CD } from "./constants";
import MobileDetect from "mobile-detect";
// 모바일 디바이스 확인
export function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.mobile();
}

// 날짜로 변환
export const convertDateStr = (value) => new Date(value);

// 숫자확인 후 콤마 추가
export function onlyNumber(e) {
  const val = e.target.value;
  e.target.value = val.replace(/[^0-9]/g, "");
  return addCommas(e.target.value);
}

// 날짜 데이터 T 지우기
export function convertDate(value) {
  const dateStr = delMilliSec(value);
  return new Date(dateStr.replace(/ /g, "T"));
}

// 날짜 변환
export function getDateStr(value, defaultStr) {
  // console.log(defaultStr);
  if (!defaultStr) {
    defaultStr = "yyyy-MM-dd HH:mm:ss";
  }
  return format(value, defaultStr);
}

// 날짜 타입  화인
const isValidDate = function (value) {
  return value instanceof Date && !isNaN(value);
};

// 초 시간 지우기
export function delMilliSec(dateStr) {
  if (!isValidDate(new Date(dateStr))) {
    return dateStr;
  }
  const _dateStr =
    dateStr.length < 19 || !dateStr.length ? dateStr : dateStr.substring(0, 19);
  return _dateStr;
}

//숫자 콤마 추가
export function addCommas(value) {
  if (!isNaN(Number(value))) {
    return Number(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
// 숫자 콤마 제거
export function deleteCommas(value) {
  const split = value.split(",");
  let returnVal = "";
  for (const item of split) {
    returnVal += item;
  }
  if (isNaN(returnVal)) {
    return null;
  } else {
    return Number(returnVal);
  }
}

// 핸드폰 번호
export function oninputPhone(target) {
  const num = target
    .replace(/[^0-9]/g, "")
    .replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, "$1-$2-$3");
  return num;
}

// 타입 변환
export const textToCode = (menu, type) => {
  return TYPE_MODEL[menu][type];
};

// 타입 변환
export const getType = (menu, type) => {
  return TYPE_MODEL[menu][type];
};

// 타입 변환
export const getTypeCode = (menu, type) => {
  return TYPE_CODE[menu][type];
};

// 타입 변환
export const convertTypeCd = (menu, typeCd) => {
  return CONVERT_TYPE_CD[menu][typeCd];
};
// 컬러 변환
export const convertPickColor = (pick) => {
  switch (pick) {
    case 0:
      return "gray";
    case 1:
    case 3:
    case 6:
      return "blue";
    case 2:
    case 4:
    case 7:
      return "red";
    case 5:
      return "green";
  }
};

<template>
  <div class="racing">
    <span>
      {{ $t(`games.${gameType}`) }}
    </span>
    <template v-if="dhBlock">
      <span>{{ nextGameInfo.round }}회차</span>
      <span id="diffTime">
        <span>{{ timer.m }}분</span>
        <span>{{ timer.s }}초</span>
      </span>
      후 마감
    </template>
    <template v-else> <span> 준비중</span> </template>
  </div>
  <template v-if="dhBlock">
    <div class="expectation">
      <div class="expected_pick">
        <div class="expected_item">
          <div :style="{ width: `${1.1 * pickRatio.lr1}px` }">
            {{ pickRatio.lr1 }}%<span class="ex_odd">좌</span>
          </div>
          <div class="text">일반볼좌우</div>
          <div :style="{ width: `${1.1 * pickRatio.lr2}px` }">
            <span class="ex_even">우</span>{{ pickRatio.lr2 }}%
          </div>
        </div>
      </div>
      <div class="expected_pick">
        <div class="expected_item">
          <div :style="{ width: `${1.1 * pickRatio.line1}px` }">
            {{ pickRatio.line1 }}%<span class="ex_odd">3</span>
          </div>
          <div class="text">일반볼34</div>
          <div :style="{ width: `${1.1 * pickRatio.line2}px` }">
            <span class="ex_even">4</span>{{ pickRatio.line2 }}%
          </div>
        </div>
      </div>
      <div class="expected_pick">
        <div class="expected_item">
          <div :style="{ width: `${1.1 * pickRatio.oe1}px` }">
            {{ pickRatio.oe1 }}%<span class="ex_odd">홀</span>
          </div>
          <div class="text">일반볼홀짝</div>
          <div :style="{ width: `${1.1 * pickRatio.oe2}px` }">
            <span class="ex_even">짝</span>{{ pickRatio.oe2 }}%
          </div>
        </div>
      </div>
      <div class="section_pick">
        <div>
          <div class="ball ladder_l3e red_ball">짝</div>
          <div class="bar">
            <span>
              <span></span>
              <span>{{ pickRatio.sub1 }}%</span>
            </span>
            <div
              class="bar-fill red"
              :style="{ width: pickRatio.sub1 + '%' }"
            ></div>
          </div>
        </div>
        <div>
          <div class="ball blue_ball ball ladder_l4o">홀</div>
          <div class="bar">
            <span>
              <span></span>
              <span>{{ pickRatio.sub3 }}%</span>
            </span>
            <div
              class="bar-fill blue"
              :style="{ width: pickRatio.sub3 + '%' }"
            ></div>
          </div>
        </div>
      </div>
      <div class="section_pick">
        <div>
          <div class="deepblue_ball ball ladder_r3o">홀</div>
          <div class="bar">
            <span>
              <span></span>
              <span>{{ pickRatio.sub2 }}%</span>
            </span>
            <div
              class="bar-fill deepblue"
              :style="{ width: pickRatio.sub2 + '%' }"
            ></div>
          </div>
        </div>
        <div>
          <div class="deepred_ball ball ladder_r4e">짝</div>
          <div class="bar">
            <span>
              <span></span>
              <span>{{ pickRatio.sub4 }}%</span>
            </span>
            <div
              class="bar-fill deepred"
              :style="{ width: pickRatio.sub4 + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pick_container" v-if="!pickTimeOver && !myPick">
      <div class="gameType_wrap">
        <div class="type_pick" :class="{ active: pickModel.mainPick === 1 }">
          메인픽
        </div>
        <div class="main_pick odd_even">
          <button
            type="button"
            @click="setPick('lr', 1)"
            :class="{ active: pickModel.lr === 1 }"
          >
            좌
          </button>
          <button
            type="button"
            @click="setPick('lr', 2)"
            :class="{ active: pickModel.lr === 2 }"
          >
            우
          </button>
        </div>
        <div class="main_pick unOver">
          <button
            type="button"
            @click="setPick('line', 3)"
            :class="{ active: pickModel.line === 3 }"
          >
            3줄
          </button>
          <button
            type="button"
            @click="setPick('line', 4)"
            :class="{ active: pickModel.line === 4 }"
          >
            4줄
          </button>
        </div>
        <div class="main_pick unOver">
          <button
            type="button"
            @click="setPick('oe', 1)"
            :class="{ active: pickModel.oe === 1 }"
          >
            홀
          </button>
          <button
            type="button"
            @click="setPick('oe', 2)"
            :class="{ active: pickModel.oe === 2 }"
          >
            짝
          </button>
        </div>
      </div>
      <div class="gameType_wrap" v-if="mainpick">
        <div class="type_pick" :class="{ active: pickModel.mainPick === 2 }">
          서브픽
        </div>
        <div class="subpick_wrap">
          <button
            v-if="sub1Active"
            class="ball"
            type="button"
            @click="setSubPick(1)"
            :class="pickModel.sub === 1 ? 'ladder_l3e red_ball' : 'white_ball'"
          >
            짝
          </button>
          <button
            v-if="sub2Active"
            class="ball"
            type="button"
            :class="
              pickModel.sub === 2 ? 'ladder_r3o deepblue_ball' : 'white_ball'
            "
            @click="setSubPick(2)"
          >
            홀
          </button>
          <button
            v-if="sub3Active"
            class="ball"
            type="button"
            @click="setSubPick(3)"
            :class="pickModel.sub === 3 ? 'ladder_l4o blue_ball' : 'white_ball'"
          >
            홀
          </button>
          <button
            v-if="sub4Active"
            class="ball"
            type="button"
            :class="
              pickModel.sub === 4 ? 'ladder_r4e deepred_ball' : 'white_ball'
            "
            @click="setSubPick(4)"
          >
            짝
          </button>
        </div>
      </div>
    </div>
    <div class="pickBlocker" v-else>
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="pick_notice" v-if="myPick">
        <span>픽 등록 완료</span>
      </div>
      <div class="pick_time_over" v-else>
        <p>픽 등록 시간이 초과되었습니다.</p>
        <p>다음 게임을 이용해주세요.</p>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="pickBlocker">
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="pick_time_over">
        <p>게임픽 가능시간 : 06 ~ 24시</p>
      </div>
    </div>
  </template>
</template>

<script>
import { apiGamesPick } from "@/api/game";
export default {
  name: "dhladder",
  props: ["bet", "pickActive", "gameType"],
  emits: ["checkActive", "betAmtReset", "checkBetActive"],
  data() {
    return {
      betActive: false,
      mainpick: false,
      pickModel: {
        sub: 0,
        lr: 0,
        oe: 0,
        line: 0,
        bet: 0,
      },
      timer: {
        m: 0,
        s: 0,
      },
      sub1Active: false,
      sub2Active: false,
      sub3Active: false,
      sub4Active: false,
    };
  },
  methods: {
    // 픽 선택
    setPick(type, value) {
      if (this.pickModel[type] == value) {
        this.pickModel[type] = 0;
      } else {
        this.pickModel[type] = value;
      }
      for (const key in this.pickModel) {
        if (key !== type && key !== "sub" && key !== "bet") {
          this.pickModel[key] = 0;
          this.pickModel.sub = 0;
        }
      }
    },
    setSubPick(value) {
      if (this.pickModel.sub == value) {
        this.pickModel.sub = 0;
      } else {
        this.pickModel.sub = value;
      }
    },
    // 픽 모델 리셋
    pickModelReset() {
      for (const key in this.pickModel) {
        this.pickModel[key] = 0;
      }
    },
    // 배팅하기
    async doBetting() {
      // console.log("베팅하기");
      const req = this.pickModel;
      req.bet = this.bet;

      if (req.bet === 0) {
        return;
      }
      const res = await apiGamesPick(this.gameType, req);
      if (res) {
        alert("픽 등록이 완료되었습니다.");
        await this.refreshUserInfo();
        switch (this.gameType) {
          case "dhladder":
            this.$store.dispatch(
              "updatemyDhladderPickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "coinladder5":
            this.$store.dispatch(
              "updatemyCoinladder5PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "coinladder3":
            this.$store.dispatch(
              "updatemyCoinladder3PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "metaladder5":
            this.$store.dispatch(
              "updatemyMetaladder5PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
          case "metaladder3":
            this.$store.dispatch(
              "updatemyMetaladder3PickInfo",
              this.$store.state.userInfo.userIdx
            );
            break;
        }

        for (const key in this.pickModel) {
          this.pickModel[key] = 0;
        }
        this.$emit("betAmtReset");
        this.$emit("checkActive", false);
        this.$emit("checkBetActive", false);
      }
    },
  },
  computed: {
    dhBlock() {
      switch (this.gameType) {
        case "dhladder":
          return this.$store.getters.dhBlock;
        default:
          return true;
      }
    },
    pickTimeOver() {
      switch (this.gameType) {
        case "dhladder":
          return this.$store.state.dhladderTimeOver;
        case "coinladder5":
          return this.$store.state.coinladder5TimeOver;
        case "coinladder3":
          return this.$store.state.coinladder3TimeOver;
        case "metaladder5":
          return this.$store.state.metaladder5TimeOver;
        case "metaladder3":
          return this.$store.state.metaladder3TimeOver;
      }
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    pickRatio() {
      switch (this.gameType) {
        case "dhladder":
          return this.$store.getters.gamePickRatio.dhladder;
        case "coinladder5":
          return this.$store.getters.gamePickRatio.coinladder5;
        case "coinladder3":
          return this.$store.getters.gamePickRatio.coinladder3;
        case "metaladder5":
          return this.$store.getters.gamePickRatio.metaladder5;
        case "metaladder3":
          return this.$store.getters.gamePickRatio.metaladder3;
      }
    },
    myPick() {
      switch (this.gameType) {
        case "dhladder":
          return this.$store.state.myDhladderPick;
        case "coinladder5":
          return this.$store.state.myCoinladder5Pick;
        case "coinladder3":
          return this.$store.state.myCoinladder3Pick;
        case "metaladder5":
          return this.$store.state.myMetaladder5Pick;
        case "metaladder3":
          return this.$store.state.myMetaladder3Pick;
      }
    },
    nextGameInfo() {
      switch (this.gameType) {
        case "dhladder":
          return this.$store.state.nextDhladderInfo;
        case "coinladder5":
          return this.$store.state.nextCoinladder5Info;
        case "coinladder3":
          return this.$store.state.nextCoinladder3Info;
        case "metaladder5":
          return this.$store.state.nextMetaladder5Info;
        case "metaladder3":
          return this.$store.state.nextMetaladder3Info;
      }
    },
    diffTime() {
      switch (this.gameType) {
        case "dhladder":
          return this.$store.getters.dhladderdiffTime;
        case "coinladder5":
          return this.$store.getters.coinladder5diffTime;
        case "coinladder3":
          return this.$store.getters.coinladder3diffTime;
        case "metaladder5":
          return this.$store.getters.metaladder5diffTime;
        case "metaladder3":
          return this.$store.getters.metaladder3diffTime;
      }
    },
  },
  watch: {
    bet: {
      handler(bet) {
        this.pickModel.bet = bet;
        if (bet > 0) {
          this.betActive = true;
        } else {
          this.betActive = false;
        }
      },
    },
    myPick: {
      handler(value) {
        if (value) {
          this.$emit("betAmtReset");
          this.$emit("checkActive", false);
          this.$emit("checkBetActive", false);
        }
      },
    },
    diffTime(diff) {
      if (diff > -1) {
        this.timer.m = Math.floor(diff / 60);
        this.timer.s = diff % 60;
      }
      if (diff < 10) {
        this.$emit("checkActive", false);
        this.$emit("checkBetActive", false);
      } else {
        if (!this.pickActive) {
          this.$emit("checkActive", true);
        }
      }
    },
    pickModel: {
      handler(model) {
        let mainpickCnt = 0;
        for (const key in model) {
          if (key !== "sub" && key !== "bet") {
            if (model[key]) {
              mainpickCnt++;
              if (key == "lr") {
                if (model[key] == 1) {
                  this.sub1Active = true;
                  this.sub3Active = true;
                  this.sub2Active = false;
                  this.sub4Active = false;
                } else {
                  this.sub1Active = false;
                  this.sub3Active = false;
                  this.sub2Active = true;
                  this.sub4Active = true;
                }
              } else if (key == "line") {
                if (model[key] == 1) {
                  this.sub1Active = true;
                  this.sub2Active = true;
                  this.sub3Active = false;
                  this.sub4Active = false;
                } else {
                  this.sub1Active = false;
                  this.sub2Active = false;
                  this.sub3Active = true;
                  this.sub4Active = true;
                }
              } else if (key === "oe") {
                if (model[key] == 1) {
                  this.sub2Active = true;
                  this.sub3Active = true;
                  this.sub1Active = false;
                  this.sub4Active = false;
                } else {
                  this.sub2Active = false;
                  this.sub3Active = false;
                  this.sub1Active = true;
                  this.sub4Active = true;
                }
              }
            }
          }
        }
        this.mainpick = mainpickCnt > 0 ? true : false;
        if (!this.mainpick) {
          model.sub = 0;
        }
        if (this.mainpick && model.sub && model.bet) {
          this.$emit("checkBetActive", true);
        } else {
          this.$emit("checkBetActive", false);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.racing {
  height: 40px;
  background-color: #94c93e;
  color: #fff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expectation {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.expected_pick {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 0.7em;
  color: #fff;
  margin-bottom: 5px;
}
.expected_item {
  width: fit-content;
  position: relative;
  display: flex;
}
.expected_item > div:not(.text) {
  transition: width 0.5s;
  position: absolute;
  max-width: 110px;
  min-width: 65px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.expected_item div:nth-child(1) {
  text-align: right;
  padding: 5px;
  background-color: #279aff;
  border-radius: 10px;
  left: -5px;
  transform: translateX(-100%);
  box-shadow: rgb(50 70 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 42 64 / 35%) 0px -2px 6px 0px inset;
}
.expected_item div:nth-child(3) {
  padding: 5px;
  background-color: #e74c3c;
  border-radius: 10px;
  right: -5px;
  transform: translateX(100%);
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.expected_item div:nth-child(2) {
  width: 67px;
  padding: 5px;
  background: linear-gradient(to top, #ddd, #fff);
  border-radius: 10px;
  text-align: center;
  color: #000;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.section_pick {
  display: flex;
  width: 100%;
  font-size: 0.8em;
  color: #fff;
  margin-top: 5px;
  gap: 5px;
}
.section_pick > div {
  display: flex;
  gap: 5px;
}
.section_pick .bar {
  overflow: hidden;
  position: relative;
  width: 112px;
  height: 15px;
  text-align: right;
  padding: 5px;
  background: rgba(90, 90, 90, 0.2);
  border-radius: 10px;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}

.section_pick span {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.section_pick .bar-fill {
  transition: width 1s;
  position: absolute;
  width: 0;
  height: 25px;
  background: #e74c3c;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
  top: 0;
  left: 0;
}
.section_pick .bar-fill.red {
  background: #e74c3c;
}
.section_pick .bar-fill.blue {
  background: #3498db;
}
.section_pick .bar-fill.deepblue {
  background: #0c75bc;
}
.section_pick .bar-fill.deepred {
  background: #c82c1c;
}
.section_pick .ball {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.type_pick {
  font-size: 0.8em;
  font-weight: 500;
  padding-bottom: 5px;
}
.type_pick.active::after {
  content: "[메인픽]";
  display: inline;
  margin-left: 4px;
}

.gameType_wrap {
  padding: 5px;
}
.gameType_wrap .subpick_wrap {
  display: flex;
  justify-content: center;
}

.gameType_wrap .subpick_wrap button:hover {
  cursor: pointer;
}

.odd_even button:first-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #279aff;
  box-shadow: rgb(50 70 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 42 64 / 35%) 0px -2px 6px 0px inset;
}
.odd_even button:last-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #e74c3c;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.unOver button:first-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #279aff;
  box-shadow: rgb(50 70 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 42 64 / 35%) 0px -2px 6px 0px inset;
}
.unOver button:last-child {
  flex: 1 1 50%;
  padding: 6px 0;
  color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: #e74c3c;
  box-shadow: rgb(93 50 50 / 25%) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgb(64 10 10 / 35%) 0px -2px 6px 0px inset;
}
.main_pick {
  position: relative;
  display: flex;
  font-size: 0.8em;
  font-weight: 700;
  gap: 10px;
  padding-bottom: 5px;
}
.main_pick > button.active {
  box-shadow: inset -3px -3px 7px #ffffff3d, inset 3px 3px 7px rgb(0 0 0 / 40%) !important;
}

.racing > span {
  margin-left: 4px;
}

.pickBlocker {
  border-top: 1px solid gray;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* background: gray; */
}
.pickBlocker .logo_symbol {
  margin: 20px 0;
}
.pickBlocker img {
  width: 100px;
}
.pickBlocker .pick_notice {
  font-weight: 700;
  font-size: 1.2em;
  color: #e6264c;
}
.pickBlocker .pick_time_over {
  text-align: center;
  font-weight: 500;
  font-size: 1em;
  color: #919195;
}
</style>

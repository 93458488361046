<template>
  <article class="modalWrap custom_scroll" v-if="modalName">
    <component
      :message="message"
      :is="this.modalName"
      @modalClose="modalClose"
    ></component>
  </article>
</template>

<script>
import SignUp from "./signUp";
import FamilyCreate from "./familyCreate";
import RoomCreate from "./chatRoomCreate";
import FamilyDonation from "./FamilyDonation";
import errorModal from "./errorModal";
import itemModal from "./itemModal";
import password from "./password";
import giftItem from "./giftItem";
import roomPassword from "./roomPassword.vue";
import talkAlarm from "./talkAlarm.vue";
import giftJewelRoom from "./giftJewelRoom.vue";
import subscribe from "./subscribe.vue";
export default {
  name: "ModalContainer",
  emits: ["modalClose"],
  data() {
    return {
      modalComponent: "",
    };
  },
  props: ["isActive", "modalName", "message"],
  components: {
    signUp: SignUp,
    familyCreate: FamilyCreate,
    roomCreate: RoomCreate,
    familyDonation: FamilyDonation,
    errorModal: errorModal,
    itemModal: itemModal,
    password: password,
    giftItem: giftItem,
    roomPassword,
    talkAlarm,
    giftJewelRoom,
    subscribe,
  },
  methods: {
    modalClose(value) {
      this.$emit("modalClose", value);
    },
  },

  created() {},
};
</script>

<style scoped>
.modalWrap {
  position: fixed;
  z-index: 9999;
  background: #28282838;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
}
</style>

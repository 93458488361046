export default {
  path: "/family",
  name: "family",
  redirect: { name: "family_main" },
  component: () => import("@/views/pc/member/family"),
  beforeEnter(to, from, next) {
    const require = to.query.familyIdx;
    if (!require) {
      alert("존재하지 않는 가족방입니다.");
      return next(from.fullPath);
    } else {
      return next();
    }
  },
  meta: {
    layout: "default",
    userOnly: false,
  },
  children: [
    {
      path: "/family/main",
      name: "family_main",
      component: () => import("@/views/pc/member/family/main"),
      meta: {
        layout: "default",
        userOnly: false,
      },
    },
    {
      path: "/family/members",
      name: "family_members",
      component: () => import("@/views/pc/member/family/members"),
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
    {
      path: "/family/board",
      name: "family_board",
      redirect: { name: "family_board_list" },
      component: () => import("@/views/pc/member/family/board"),
      props: true,
      meta: {
        layout: "default",
        userOnly: false,
      },
      beforeEnter(to, from, next) {
        const require = to.query.familyIdx;
        if (!require) {
          return next(from.fullPath);
        } else {
          return next();
        }
      },
      children: [
        {
          path: "/family/board/list",
          name: "family_board_list",
          component: () => import("@/views/pc/member/family/board/list"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/family/board/detail",
          name: "family_board_detail",
          component: () => import("@/views/pc/member/family/board/detail"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
        {
          path: "/family/board/write",
          name: "family_board_write",
          component: () => import("@/views/pc/member/family/board/write"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/family/board/edit/:familyBoardIdx",
          name: "family_board_edit",
          component: () => import("@/views/pc/member/family/board/write"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        // {
        //   path: "/family/manage/point",
        //   name: "family_manage_point",
        //   component: () => import("@/views/pc/member/family/manage/point"),
        //   meta: {
        //     layout: "default",
        //     userOnly: true,
        //   },
        // },
        // {
        //   path: "/family/manage/benefit",
        //   name: "family_manage_benefit",
        //   component: () => import("@/views/pc/member/family/manage/benefit"),
        //   meta: {
        //     layout: "default",
        //     userOnly: true,
        //   },
        // },
      ],
    },
    {
      path: "/family/manage",
      name: "family_manage",
      redirect: { name: "family_manage_setting" },
      component: () => import("@/views/pc/member/family/manage"),
      meta: {
        layout: "default",
        userOnly: true,
      },
      children: [
        {
          path: "/family/manage/setting",
          name: "family_manage_setting",
          component: () => import("@/views/pc/member/family/manage/setting"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/family/manage/members",
          name: "family_manage_members",
          component: () => import("@/views/pc/member/family/manage/members"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/family/manage/allow",
          name: "family_manage_allow",
          component: () => import("@/views/pc/member/family/manage/allow"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/family/manage/regist",
          name: "family_manage_regist",
          component: () => import("@/views/pc/member/family/manage/regist"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/family/manage/point",
          name: "family_manage_point",
          component: () => import("@/views/pc/member/family/manage/point"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/family/manage/benefit",
          name: "family_manage_benefit",
          component: () => import("@/views/pc/member/family/manage/benefit"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
      ],
    },
  ],
};

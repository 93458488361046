<template>
  <section id="side_right">
    <!-- chat -->
    <article id="user_chat_wrap" class="pb_5">
      <ul class="chat_title_wrap">
        <li>
          <div class="title_text">
            <span class="fw_bold"
              >실시간채팅
              <span class="fa-xs">
                <i class="fas fa-user-circle"></i>
                {{ addCommas(chatCnt) }}</span
              ></span
            >
          </div>
          <button type="button" class="close_btn" @click="chatClose">
            <i class="fas fa-times"></i>
          </button>
        </li>
      </ul>
      <chat-room
        :loggedIn="loggedIn"
        :userInfo="userInfo"
        @setChatCnt="setChatCnt"
        @modalOpen="modalOpen"
      />
    </article>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import ChatRoom from "./chat_nav";

export default {
  name: "Chat",
  components: {
    ChatRoom,
    Swiper,
    SwiperSlide,
  },
  emits: ["chatToggle", "modalOpen"],
  props: ["loggedIn"],
  data() {
    return {
      chatCnt: 0,
    };
  },
  methods: {
    // 채팅방 참여자 수
    setChatCnt(cnt) {
      this.chatCnt = cnt;
    },
    // 채팅 닫기
    chatClose() {
      this.$emit("chatToggle", false);
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>

<style scoped>
/* cmc chat */
#user_chat_wrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat_title_wrap {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
}

.chat_title_wrap > li {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 45px;
  position: relative;
}

.chat_title_wrap > li.active {
  background-color: #e6264c;
}

.chat_title_wrap > li.disable {
  background-color: #e6264c;
}

.chat_title_wrap > li.active span,
.chat_title_wrap > li.disable span {
  color: #fff;
}

#side_right {
  width: 100%;
  position: relative;
}

.title_text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
</style>

export default {
  install: (app, options) => {
    const callAction = () => {
      // console.log('call')
    };
    app.config.globalProperties.$_callAction = callAction;
    const callbackAction = (value) => {
      // console.log('this is parent')
    };
    app.config.globalProperties.$_callbackAction = callbackAction;
  },
};

<template>
  <template v-if="options">
    <div class="select_container">
      <select :ref="id" :id="id" @change="onSelected">
        <option v-for="item in opts" :key="item.value" :value="item.value">
          {{ item.text }}
        </option>
      </select>
      <i class="fas fa-angle-down"></i>
    </div>
  </template>
  <div v-else>select err</div>
</template>

<script>
export default {
  name: "select",
  props: ["options", "id"],
  data() {
    return {
      opts: [],
      selected: {
        value: "",
        text: "",
      },
    };
  },
  methods: {
    onSelected(evt) {
      const target = evt.target;
      const value = target.value;
      this.opts.forEach((item) => {
        if (item.value === value) {
          this.selected.value = value;
          this.selected.text = item.text;
        }
      });
      this.$emit("onSelected", value);
    },
    openSelect() {
      const event = new MouseEvent("mousedown");
      this.$refs[this.id].dispatchEvent(event);
    },
  },
  created() {
    this.opts = this.options;
    this.selected = this.clone(this.options[0]);
  },
};
</script>

<style scoped>
.select_container {
  background: #fff;
  position: relative;
  overflow: hidden;
  height: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
}
select {
  background: #fff;
  display: flex;
  align-items: center;
  height: 25px;
  padding-right: 25px;
  padding-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 700;
  font-size: 12px;
  position: relative;
}
.select_container i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}
</style>

export default {
  path: "/cscenter",
  name: "cscenter",
  redirect: { name: "cscenter_noticeList" },
  component: () => import("@/views/pc/member/cs_center"),
  meta: {
    layout: "default",
    userOnly: false,
  },
  children: [
    {
      path: "/cscenter/notice",
      name: "cscenter_noticeList",
      redirect: { name: "cscenter_notice" },
      component: () => import("@/views/pc/member/cs_center/notice/index"),
      meta: {
        layout: "default",
        userOnly: false,
      },
      children: [
        {
          path: "/cscenter/notice/list",
          name: "cscenter_notice",
          component: () =>
            import("@/views/pc/member/cs_center/notice/noticeList"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },

        {
          path: "/cscenter/notice/detail",
          name: "cscenter_noticeDetail",
          component: () =>
            import("@/views/pc/member/cs_center/notice/noticeDetail"),
          meta: {
            layout: "default",
            userOnly: false,
          },
        },
      ],
    },

    {
      path: "/cscenter/qna/list",
      name: "cscenter_qnaList",
      component: () => import("@/views/pc/member/cs_center/qna"),
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
    {
      path: "/cscenter/qna/write",
      name: "cscenter_qnaWrite",
      component: () => import("@/views/pc/member/cs_center/write"),
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
    {
      path: "/cscenter/qna/detail/:idx",
      name: "cscenter_qnaDetail",
      component: () => import("@/views/pc/member/cs_center/qnaDetail"),
      meta: {
        layout: "default",
        userOnly: true,
      },
      props: true,
    },
    {
      path: "/cscenter/faq/list",
      name: "cscenter_faqList",
      component: () => import("@/views/pc/member/cs_center/faq"),
      meta: {
        layout: "default",
        userOnly: false,
      },
    },
    {
      path: "/cscenter/prison/list",
      name: "cscenter_prisonList",
      component: () => import("@/views/pc/member/cs_center/prison"),
      meta: {
        layout: "default",
        userOnly: false,
      },
    },
    {
      path: "/cscenter/attendance",
      name: "cscenter_attendanceList",
      component: () => import("@/views/pc/member/cs_center/attendance"),
      meta: {
        layout: "default",
        userOnly: false,
      },
    },

    // {
    //   path: "/cscenter/detail",
    //   name: "cscenterDetail",
    //   component: () => import("@/views/pc/member/cs_center/detail"),
    //   meta: {
    //     layout: "default",
    //     userOnly: false
    //   }
    // },
  ],
};

<template>
  <div class="signUp_wrap">
    <div class="signUp_contents">
      <div class="close">
        <button class="close_btn" @click="modalClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="sign_form">
        <fieldset class="input_fs_pw">
          <legend>기존 비밀번호</legend>
          <input
            type="password"
            ref="beforePw"
            maxlength="20"
            v-model="model.beforePw"
            placeholder="기존에 사용하던 비밀번호"
          />
        </fieldset>
      </div>
      <div class="sign_form">
        <fieldset class="input_fs_pw">
          <legend>재설정 비밀번호</legend>
          <input
            type="password"
            ref="lgnPw"
            maxlength="20"
            v-model="model.lgnPw"
            placeholder="숫자,문자,특수문자 각 1개 이상 조합, 8자 이상 20자이하"
          />
          <span v-if="this.errorCheck.lgnPw" class="error-box_active"
            >숫자,문자,특수문자 각 1개 이상 조합, 8자 이상 20자 이하만
            가능합니다.</span
          >
        </fieldset>
      </div>
      <div class="sign_form">
        <fieldset class="input_fs_pw">
          <legend>재설정 비밀번호 확인</legend>
          <input
            type="password"
            ref="lgnPwChk"
            maxlength="20"
            v-model="lgnPwChk"
            placeholder="비밀번호를 다시 입력해주세요"
          />
        </fieldset>
      </div>
      <div class="signUp">
        <button class="signUp_btn" type="button" @click="password">
          변경하기
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { apiRewritePw } from "@/api/user";
import { SIGNUP_REGEXP } from "@/libs/constants";
import { removeAuthData } from "@/libs/auth-helper";

export default {
  name: "password",
  emits: ["modalClose"],
  data() {
    return {
      model: {
        beforePw: "",
        lgnPw: "",
      },
      lgnPwChk: "",
      userCheck: false,
      target: {
        celebIdx: 0,
      },
      errorCheck: {
        lgnPw: false,
        lgnPwChk: false,
      },
    };
  },
  methods: {
    modalClose() {
      this.$emit("modalClose", false);
    },
    userRegExp(type) {
      const regExp = SIGNUP_REGEXP[type];
      const target = this.model[type];
      return regExp.test(target);
    },
    // 중복체크
    async dupleCheck(type) {
      let res = "";
      const model = {};
      model[type] = this.model[type];
      if (!model[type]) {
        alert(this.$t(`text.${type}`) + "를 입력해주세요.");
        return;
      }
      const regExpTest = this.userRegExp(type);
      if (!regExpTest) {
        // alert(this.$t(`text.${type}`) + "를 다시 입력해주세요.");
        this.errorCheck[type] = true;
        this.$refs[type].focus();
        return;
      } else {
        this.errorCheck[type] = false;
      }
      if (res) {
        alert("사용 가능한 " + this.$t(`text.${type}`) + "입니다.");
        this.dupleChecked[type] = true;
      }
    },
    // 비밀번호 변경
    async password() {
      const req = this.model;
      if (!this.model.lgnPw) {
        alert("비밀번호를 입력해주세요!");
        this.$refs.lgnPw.focus();
        return;
      }
      if (this.model.lgnPw) {
        const regExpTest = this.userRegExp("lgnPw");
        if (!regExpTest) {
          this.errorCheck.lgnPw = true;
          this.$refs.lgnPw.focus();
          return;
        } else {
          this.errorCheck.lgnPw = false;
        }
      }

      if (this.model.beforePw == this.model.lgnPw) {
        alert("기존의 비밀번호와 재설정 비밀번호가 동일합니다!");
        return;
      }

      if (this.model.lgnPw !== this.lgnPwChk) {
        alert("재설정 비밀번호가 다릅니다 다시 확인해주세요.");
        this.$refs.lgnPwChk.focus();
        return;
      }
      req.reLgnPw = this.lgnPwChk;
      try {
        const _confirm = confirm("비밀번호를 재설정하시겠습니까?");
        if (!_confirm) {
          return;
        } else {
          const res = await apiRewritePw(req);
          if (res) {
            this.modalClose();
            this.$router.go();
            removeAuthData();
            this.$store.dispatch("setUserInfo", null);
            alert("비밀번호 재설정 성공! 다시 로그인 해주세요!");
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9em;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;
  position: relative;
  padding: 30px 48px;
  box-sizing: border-box;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 60px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.input_fs {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input {
  /* margin: .4rem; */
  /* border: none; */
  /* padding: 20px; */
  font-size: 1.4em;
  margin-left: 17px;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.overlap_check {
  display: flex;
  margin-top: 10px;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.signUp_btn {
  height: 56px;
  width: 470px;

  /* border: 1px solid #e0e0eb; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1em;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
  font-weight: 700;
  background-color: #ffce32;
  box-shadow: 0px 0px 10px #cecece;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

.input_fs_pw {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs_pw > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs_pw > input {
  padding: 0 20px;
  font-size: 1.3em;
  width: 100%;
  box-sizing: border-box;
}

.input_fs_pw > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}
.error-box_active {
  position: relative;
  top: 18px;
  font-size: 0.75rem;
  color: red;
}
</style>

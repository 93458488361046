export default {
  "err_msg": {
    "SI002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 로그인정보가 없습니다. 아이디나 패스워드를 확인해주세요."])},
    "PBP002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경험치가 부족합니다."])},
    "FMI001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 가족방이 있습니다."])},
    "FMI002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족 등록 실패"])},
    "FMI003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복된 가족방 이름입니다."])},
    "FMJ001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 가종방이 있습니다."])},
    "FMJ002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 가족방 가입 승인 대기중 입니다."])},
    "FMJ003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 가입 신청 실패"])},
    "item": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필 변경권이 없습니다."])},
      "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 변경권이 없습니다."])},
      "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 변경권이 없습니다."])},
      "hanmadi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한마디 변경권이 없습니다."])}
    }
  },
  "nav": {
    "alarm": {
      "C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공통"])},
      "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자"])}
    },
    "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족"])},
    "subscriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "text": {
    "lgnId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "nickName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
    "hanmadi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한마디"])},
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배너 이미지"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필 이미지"])},
    "family": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 이름"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 공지"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 설명"])},
      "congrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입 축하 메세지"])},
      "familyImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 대표 이미지"])},
      "familyBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 배너"])},
      "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 가입조건"])},
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 가입유형"])},
      "joinStatus": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 탈퇴"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거절"])}
      ],
      "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족방 해체"])}
    },
    "msg": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목을"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용을"])}
    },
    "chat": {
      "inOut": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFF"])}
      ],
      "openClose": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개"])}
      ],
      "autoCon": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이템,포인트"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용안함"])}
      ],
      "result": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패"])}
      ]
    },
    "wl": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연패중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연승중"])}
    ],
    "joinType": {
      "NM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디"])},
      "G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글 로그인 정보"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네이버 로그인 정보"])},
      "K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오 로그인 정보"])}
    }
  },
  "alert": {
    "roomsTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방제목을 입력해주세요."])},
    "walletIdx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 아이템을 선택해주세요."])},
    "secretRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 설정해주세요."])}
  },
  "pick": {
    "powerball": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "eospowerball5": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "coinpowerball5": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "coinpowerball3": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "metapowerball5": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "metapowerball3": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "dhpowerball": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "pbgpowerball": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언더"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오버"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])}
    ],
    "coinladder5": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좌"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])}
    ],
    "coinladder3": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좌"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])}
    ],
    "metaladder5": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좌"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])}
    ],
    "metaladder3": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좌"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])}
    ],
    "dhladder": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASS"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좌"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짝"])}
    ]
  },
  "board": {
    "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자유"])},
    "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유머"])},
    "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포토"])},
    "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석"])}
  },
  "notice": {
    "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지"])},
    "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
    "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트"])}
  },
  "qna": {
    "answer": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변대기"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변완료"])}
    ]
  },
  "subscribe": {
    "celeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독중인 "])},
    "fan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자"])}
  },
  "level": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제한없음"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신입"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하수"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평민"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중수"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고수"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영웅"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지존"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초인"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신"])}
  ],
  "item": {
    "en": {
      "ACCNKC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
      "ACCPIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Image"])},
      "ACCTMC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Message"])},
      "GMHST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Reset"])},
      "RNMSG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random Message"])},
      "CHATOPN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Room"])},
      "HITALK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Message"])},
      "RNPNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random Point"])},
      "ACCBNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Banner"])},
      "JWL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jewel"])},
      "RNBX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random Box"])},
      "ADVERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Advert"])},
      "CHATLCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font Color"])},
      "CHATSLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Shield"])},
      "UPHST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Initialization"])},
      "CPHST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Record Initialization"])}
    },
    "gift": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물불가"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물가능"])}
    ]
  },
  "family": {
    "joinType": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인제"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동가입"])}
    ],
    "params": {
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["familyImageSrc"])},
      "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["familyBannerSrc"])},
      "congrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["familyCongrat"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["familyDescription"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["familyName"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["familyNotice"])},
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["familyJoin"])},
      "familyJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joinType"])}
    }
  },
  "games": {
    "eospowerball5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EOS파워볼 5분"])},
    "EOS5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EOS파워볼 5분"])},
    "eospowerball3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EOS파워볼 3분"])},
    "EOS3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EOS파워볼 3분"])},
    "coinpowerball5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인파워볼 5분"])},
    "CB5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인파워볼 5분"])},
    "coinpowerball3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인파워볼 3분"])},
    "CB3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인파워볼 3분"])},
    "metapowerball5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타파워볼 5분"])},
    "MB5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타파워볼 5분"])},
    "metapowerball3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타파워볼 3분"])},
    "MB3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타파워볼 3분"])},
    "coinladder5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인사다리 5분"])},
    "CL5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인사다리 5분"])},
    "coinladder3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인사다리 3분"])},
    "CL3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인사다리 3분"])},
    "metaladder5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타사다리 5분"])},
    "ML5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타사다리 5분"])},
    "metaladder3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타사다리 3분"])},
    "ML3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메타사다리 3분"])},
    "dhpowerball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동행파워볼"])},
    "DH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동행파워볼"])},
    "dhladder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동행사다리"])},
    "DL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동행사다리"])},
    "pbgpowerball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBG파워볼"])},
    "PBG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBG파워볼"])}
  },
  "fontColor": {
    "ICD0029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#F8DE22"])},
    "ICD0030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#002dff"])},
    "ICD0031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#009205"])},
    "ICD0032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#E84C3C"])}
  }
}
<template>
  <transition name="layout">
    <template v-if="layoutName">
      <component :is="layoutName" v-if="layoutName.indexOf('empty') === -1" />
      <router-view v-else />
    </template>
  </transition>
</template>

<script>
import DefaultLayout from "@/layouts/pc/DefaultLayout.vue";
import PopupLayout from "@/layouts/pc/PopupLayout.vue";
import FrameLayout from "@/layouts/pc/FrameLayout.vue";
import MobileLayout from "@/layouts/mobile/MobileLayout";
export default {
  name: "App",
  components: {
    "default-layout": DefaultLayout,
    "popup-layout": PopupLayout,
    "mobile-layout": MobileLayout,
    "frame-layout": FrameLayout,
  },
  data() {
    return {
      EOSpower5diffInterval: false,
      EOSpower3diffInterval: false,
      coin5diffInterval: false,
      coin3diffInterval: false,
      meta5diffInterval: false,
      meta3diffInterval: false,
      coinladder5diffInterval: false,
      coinladder3diffInterval: false,
      metaladder5diffInterval: false,
      metaladder3iffInterval: false,
      dhpowerballdiffInterval: false,
      dhladderdiffInterval: false,
      pbgpowerballdiffInterval: false,
    };
  },
  methods: {
    // 동행 블락 시간 계산
    calc_dhpowerballBlock() {
      setInterval(() => {
        this.$store.dispatch("getRecentTimeBlock");
      }, 1000);
    },
    // EOS파워볼5분 남은시간 계산
    regInterval_calcEOSpower5Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcEOSpower5DiffTime");
      }, 1000);
    },
    // EOS파워볼3분 남은시간 계산
    regInterval_calcEOSpower3Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcEOSpower3DiffTime");
      }, 1000);
    },
    // 코인파워볼5분 남은시간 계산
    regInterval_calccoin5Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcCoin5DiffTime");
      }, 1000);
    },
    // 코인파워볼3분 남은시간 계산
    regInterval_calccoin3Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcCoin3DiffTime");
      }, 1000);
    },
    // 메타파워볼5분 남은시간 계산
    regInterval_calcmeta5Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcMeta5DiffTime");
      }, 1000);
    },
    // 메타파워볼3분 남은시간 계산
    regInterval_calcmeta3Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcMeta3DiffTime");
      }, 1000);
    },
    // 코인사다리5분 남은시간 계산
    regInterval_calccoinladder5Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcCoinladder5DiffTime");
      }, 1000);
    },
    // 코인사다리3분 남은시간 계산
    regInterval_calccoinladder3Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcCoinladder3DiffTime");
      }, 1000);
    },
    // 메타사다리5분 남은시간 계산
    regInterval_calcmetaladder5Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcMetaladder5DiffTime");
      }, 1000);
    },
    // 메타사다리3분 남은시간 계산
    regInterval_calcmetaladder3Diff() {
      return setInterval(() => {
        this.$store.dispatch("calcMetaladder3DiffTime");
      }, 1000);
    },
    // 동행파워볼 남은시간 계산
    regInterval_calcdhpowerballDiff() {
      return setInterval(() => {
        this.$store.dispatch("calcDhpowerballDiffTime");
      }, 1000);
    },
    // 동행사다리 남은시간 계산
    regInterval_calcdhladderDiff() {
      return setInterval(() => {
        this.$store.dispatch("calcDhladderDiffTime");
      }, 1000);
    },
    // PBG파워볼 남은시간 계산
    regInterval_calcpbgpowerballDiff() {
      return setInterval(() => {
        this.$store.dispatch("calcPbgpowerballDiffTime");
      }, 1000);
    },
  },
  watch: {
    loggedIn: {
      handler(bol) {
        if (bol) {
          this.$store.dispatch("ioAuth");
        }
      },
    },
    // 동행파워볼
    dhpowerballdiffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickDhpowerballTimeOver", true);
        } else {
          this.$store.dispatch("setPickDhpowerballTimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.dhpowerballdiffInterval) {
            clearInterval(this.dhpowerballdiffInterval);
          }
          this.$store.dispatch("updateNextdhpowerballInfo");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getDhpowerballRoundList");
            if (this.$store.state.userInfo.userIdx) {
              this.$store.dispatch(
                "updatemyDhpowerballPickInfo",
                this.$store.state.userInfo.userIdx
              );
            }
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyDhpowerballPickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.dhpowerballdiffInterval = this.regInterval_calcdhpowerballDiff();
        }
      },
    },
    // 동행사다리
    dhladderdiffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickDhladderTimeOver", true);
        } else {
          this.$store.dispatch("setPickDhladderTimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.dhladderdiffInterval) {
            clearInterval(this.dhladderdiffInterval);
          }
          this.$store.dispatch("updateNextdhladderInfo");
          this.$store.dispatch("getDhladderRoundList");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getDhladderRoundList");
            if (this.$store.state.userInfo.userIdx) {
              this.$store.dispatch(
                "updatemyDhladderPickInfo",
                this.$store.state.userInfo.userIdx
              );
            }
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyDhladderPickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.dhladderdiffInterval = this.regInterval_calcdhladderDiff();
        }
      },
    },
    // EOS파워볼5분
    EOSpowerball5diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickEOSpower5TimeOver", true);
        } else {
          this.$store.dispatch("setPickEOSpower5TimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.EOSpower5diffInterval) {
            clearInterval(this.EOSpower5diffInterval);
          }
          // 게임결과 바로 안나올 수 있어서 3초후로
          this.$store.dispatch("updateNextEOSpowerball5Info");
          setTimeout(() => {
            this.$store.dispatch("getEOSpowerball5RoundList");
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyEOSpowerball5PickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.EOSpower5diffInterval = this.regInterval_calcEOSpower5Diff();
        }
      },
    },
    // EOS파워볼3분
    EOSpowerball3diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickEOSpower3TimeOver", true);
        } else {
          this.$store.dispatch("setPickEOSpower3TimeOver", false);
        }
        if (diff <= 0 || diff > 180) {
          if (this.EOSpower3diffInterval) {
            clearInterval(this.EOSpower3diffInterval);
          }
          this.$store.dispatch("updateNextEOSpowerball3Info");
          setTimeout(() => {
            this.$store.dispatch("getEOSpowerball3RoundList");
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyEOSpowerball3PickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.EOSpower3diffInterval = this.regInterval_calcEOSpower3Diff();
        }
      },
    },
    // 코인파워볼5분
    coinpowerball5diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickCoin5TimeOver", true);
        } else {
          this.$store.dispatch("setPickCoin5TimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.coin5diffInterval) {
            clearInterval(this.coin5diffInterval);
          }
          this.$store.dispatch("updateNextcoinpowerball5Info");
          setTimeout(() => {
            this.$store.dispatch("getCoinpowerball5RoundList");
            if (this.$store.state.userInfo.userIdx) {
              this.$store.dispatch(
                "updatemyCoinpowerball5PickInfo",
                this.$store.state.userInfo.userIdx
              );
            }
          }, 3000);
          this.coin5diffInterval = this.regInterval_calccoin5Diff();
        }
      },
    },
    // 코인파워볼3분
    coinpowerball3diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickCoin3TimeOver", true);
        } else {
          this.$store.dispatch("setPickCoin3TimeOver", false);
        }

        if (diff <= 0 || diff > 180) {
          if (this.coin3diffInterval) {
            clearInterval(this.coin3diffInterval);
          }
          this.$store.dispatch("updateNextcoinpowerball3Info");
          setTimeout(() => {
            this.$store.dispatch("getCoinpowerball3RoundList");
            if (this.$store.state.userInfo.userIdx) {
              this.$store.dispatch(
                "updatemyCoinpowerball3PickInfo",
                this.$store.state.userInfo.userIdx
              );
            }
          }, 3000);
          this.coin3diffInterval = this.regInterval_calccoin3Diff();
        }
      },
    },
    // 메타파워볼3분
    metapowerball3diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickMeta3TimeOver", true);
        } else {
          this.$store.dispatch("setPickMeta3TimeOver", false);
        }
        if (diff <= 0 || diff > 180) {
          if (this.meta3diffInterval) {
            clearInterval(this.meta3diffInterval);
          }
          this.$store.dispatch("updateNextmetapowerball3Info");
          setTimeout(() => {
            this.$store.dispatch("getMetapowerball3RoundList");
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyMetapowerball3PickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.meta3diffInterval = this.regInterval_calcmeta3Diff();
        }
      },
    },
    // 메타파워볼5분
    metapowerball5diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickMeta5TimeOver", true);
        } else {
          this.$store.dispatch("setPickMeta5TimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.meta5diffInterval) {
            clearInterval(this.meta5diffInterval);
          }
          this.$store.dispatch("updateNextmetapowerball5Info");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getMetapowerball5RoundList");
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyMetapowerball5PickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.meta5diffInterval = this.regInterval_calcmeta5Diff();
        }
      },
    },
    // 코인사다리5분
    coinladder5diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickCoinladder5TimeOver", true);
        } else {
          this.$store.dispatch("setPickCoinladder5TimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.coinladder5diffInterval) {
            clearInterval(this.coinladder5diffInterval);
          }
          this.$store.dispatch("updateNextcoinladder5Info");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getCoinladder5RoundList");
            if (this.$store.state.userInfo.userIdx) {
              this.$store.dispatch(
                "updatemyCoinladder5PickInfo",
                this.$store.state.userInfo.userIdx
              );
            }
          }, 3000);
          this.coinladder5diffInterval = this.regInterval_calccoinladder5Diff();
        }
      },
    },
    // 코인사다리3분
    coinladder3diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickCoinladder3TimeOver", true);
        } else {
          this.$store.dispatch("setPickCoinladder3TimeOver", false);
        }
        if (diff <= 0 || diff > 180) {
          if (this.coinladder3diffInterval) {
            clearInterval(this.coinladder3diffInterval);
          }
          this.$store.dispatch("updateNextcoinladder3Info");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getCoinladder3RoundList");
            if (this.$store.state.userInfo.userIdx) {
              this.$store.dispatch(
                "updatemyCoinladder3PickInfo",
                this.$store.state.userInfo.userIdx
              );
            }
          }, 3000);
          this.coinladder3diffInterval = this.regInterval_calccoinladder3Diff();
        }
      },
    },
    // 메타사다리3분
    metaladder3diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickMetaladder3TimeOver", true);
        } else {
          this.$store.dispatch("setPickMetaladder3TimeOver", false);
        }
        if (diff <= 0 || diff > 180) {
          if (this.metaladder3diffInterval) {
            clearInterval(this.metaladder3diffInterval);
          }
          this.$store.dispatch("updateNextmetaladder3Info");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getMetaladder3RoundList");
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyMetaladder3PickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.metaladder3diffInterval = this.regInterval_calcmetaladder3Diff();
        }
      },
    },
    // 메타사다리5분
    metaladder5diffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickMetaladder5TimeOver", true);
        } else {
          this.$store.dispatch("setPickMetaladder5TimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.metaladder5diffInterval) {
            clearInterval(this.metaladder5diffInterval);
          }
          this.$store.dispatch("updateNextmetaladder5Info");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getMetaladder5RoundList");
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyMetaladder5PickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.metaladder5diffInterval = this.regInterval_calcmetaladder5Diff();
        }
      },
    },
    // PBG파워볼
    pbgpowerballdiffTime: {
      handler(diff) {
        if (diff <= 15) {
          this.$store.dispatch("setPickPbgpowerballTimeOver", true);
        } else {
          this.$store.dispatch("setPickPbgpowerballTimeOver", false);
        }
        if (diff <= 0 || diff > 300) {
          if (this.pbgpowerballdiffInterval) {
            clearInterval(this.pbgpowerballdiffInterval);
          }
          this.$store.dispatch("updateNextpbgpowerballInfo");
          // 게임결과 바로 안나올 수 있어서 3초후로
          setTimeout(() => {
            this.$store.dispatch("getPbgpowerballRoundList");
          }, 3000);
          if (this.$store.state.userInfo.userIdx) {
            this.$store.dispatch(
              "updatemyPbgpowerballPickInfo",
              this.$store.state.userInfo.userIdx
            );
          }
          this.pbgpowerballdiffInterval =
            this.regInterval_calcpbgpowerballDiff();
        }
      },
    },
    // 메인화면 오른쪽 사이드에 채팅방
    // 라우터 변경될때마다 불러오기
    $route: {
      handler() {
        this.$store.dispatch("getBestChatRoomList");
      },
    },
  },
  computed: {
    // 동행 게임 블락 시간 체크
    dhBlock() {
      return this.$store.getters.dhBlock;
    },
    EOSpowerball5diffTime() {
      return this.$store.getters.EOSpowerball5diffTime;
    },
    EOSpowerball3diffTime() {
      return this.$store.getters.EOSpowerball3diffTime;
    },
    coinpowerball5diffTime() {
      return this.$store.getters.coinpowerball5diffTime;
    },
    coinpowerball3diffTime() {
      return this.$store.getters.coinpowerball3diffTime;
    },
    metapowerball5diffTime() {
      return this.$store.getters.metapowerball5diffTime;
    },
    metapowerball3diffTime() {
      return this.$store.getters.metapowerball3diffTime;
    },
    coinladder5diffTime() {
      return this.$store.getters.coinladder5diffTime;
    },
    coinladder3diffTime() {
      return this.$store.getters.coinladder3diffTime;
    },
    metaladder5diffTime() {
      return this.$store.getters.metaladder5diffTime;
    },
    metaladder3diffTime() {
      return this.$store.getters.metaladder3diffTime;
    },
    dhpowerballdiffTime() {
      return this.$store.getters.dhpowerballdiffTime;
    },
    dhladderdiffTime() {
      return this.$store.getters.dhladderdiffTime;
    },
    pbgpowerballdiffTime() {
      return this.$store.getters.pbgpowerballdiffTime;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    // 현재 레이아웃
    layoutName() {
      if (!this.$route.matched.length) {
        return null;
      }
      const route = this.$route;
      const layout = route.meta.layout ? route.meta.layout : "empty";
      return `${layout}-layout`;
    },
  },
  async mounted() {
    // PickRatio 소켓연결
    this.$store.dispatch("MainSocketConnect");
    // 동행 블락시간 계산
    this.calc_dhpowerballBlock();
    this.$store.dispatch("getRecentTimeBlock");
    // 유저 정보 업데이트
    this.refreshUserInfo();

    if (!this.EOSpower5diffInterval) {
      this.EOSpower5diffInterval = this.regInterval_calcEOSpower5Diff();
    }
    if (!this.EOSpower3diffInterval) {
      this.EOSpower3diffInterval = this.regInterval_calcEOSpower3Diff();
    }
    if (!this.coin5diffInterval) {
      this.coin5diffInterval = this.regInterval_calccoin5Diff();
    }
    if (!this.coin3diffInterval) {
      this.coin3diffInterval = this.regInterval_calccoin3Diff();
    }
    if (!this.meta5diffInterval) {
      this.meta5diffInterval = this.regInterval_calcmeta5Diff();
    }
    if (!this.meta3diffInterval) {
      this.meta3diffInterval = this.regInterval_calcmeta3Diff();
    }
    if (!this.coinladder5diffInterval) {
      this.coinladder5diffInterval = this.regInterval_calccoinladder5Diff();
    }
    if (!this.coinladder3diffInterval) {
      this.coinladder3diffInterval = this.regInterval_calccoinladder3Diff();
    }
    if (!this.metaladder5diffInterval) {
      this.metaladder5diffInterval = this.regInterval_calcmetaladder5Diff();
    }
    if (!this.metaladder3diffInterval) {
      this.metaladder3diffInterval = this.regInterval_calcmetaladder3Diff();
    }
    if (!this.dhpowerballdiffInterval) {
      this.dhpowerballdiffInterval = this.regInterval_calcdhpowerballDiff();
    }
    if (!this.dhladderdiffInterval) {
      this.dhladderdiffInterval = this.regInterval_calcdhladderDiff();
    }
    if (!this.pbgpowerballdiffInterval) {
      this.pbgpowerballdiffInterval = this.regInterval_calcpbgpowerballDiff();
    }
  },
};
</script>

<style></style>

export const SOCKET_INFO = process.env.VUE_APP_SOCKET_URI;
export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const AUTH_COOKIE = {
  name: "user_token",
  path: "/",
};
export const SOCK_COOKIE = {
  name: "socket_token",
  path: "/",
};
export const USER_INFO = {
  name: "user_data",
  path: "/",
};
export const USER_ID = {
  name: "user_id",
  path: "/",
};

export const DOCUMENT_TITLE_PREFIX = "BALLPICK";

export const POPUP_INFO = {
  chat: {
    width: 1400,
    height: 800,
  },
  bailList: {
    width: 650,
    height: 520,
  },
  msgPopup: {
    width: 480,
    height: 600,
  },
  gift_user: {
    width: 550,
    height: 520,
  },
};

export const GNB = [
  {
    title: "동행파워볼",
    name: "dhpowerball",
    img: require("@/assets/img/superball_gray.png"),
    isActive: false,
    isActive: false,
    children: [
      {
        text: "동행파워볼",
        name: "dhpowerballLive",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "동행파워볼",
            name: "dhpowerballLive",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "dhpowerballDaily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "dhpowerballRound",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "dhpowerballPattern",
            isActive: false,
          },
        ],
      },
      {
        text: "동행사다리",
        name: "dhladder",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "동행사다리",
            name: "dhladderLive",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "dhladderDaily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "dhladderRound",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "dhladderPattern",
            isActive: false,
          },
        ],
      },
    ],
  },
  {
    title: "EOS파워볼",
    name: "EOSpowerball5",
    img: require("@/assets/img/superball_gray.png"),
    isActive: false,
    children: [
      {
        text: "EOS파워볼 5분",
        name: "EOSpowerball5Live",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "EOS파워볼 5분",
            name: "EOSpowerball5Live",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "EOSpowerball5Daily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "EOSpowerball5Round",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "EOSpowerball5Pattern",
            isActive: false,
          },
        ],
      },
      {
        text: "EOS파워볼 3분",
        name: "EOSpowerball3Live",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "EOS파워볼 3분",
            name: "EOSpowerball3Live",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "EOSpowerball3Daily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "EOSpowerball3Round",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "EOSpowerball3Pattern",
            isActive: false,
          },
        ],
      },
    ],
  },
  {
    title: "코인파워볼",
    name: "coinpowerball5",
    img: require("@/assets/img/superball_gray.png"),
    isActive: false,
    children: [
      {
        text: "코인파워볼 5분",
        name: "coinpowerball5Live",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "코인파워볼 5분",
            name: "coinpowerball5Live",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "coinpowerball5Daily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "coinpowerball5Round",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "coinpowerball5Pattern",
            isActive: false,
          },
        ],
      },
      {
        text: "코인파워볼 3분",
        name: "coinpowerball3Live",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "코인파워볼 3분",
            name: "coinpowerball3Live",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "coinpowerball3Daily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "coinpowerball3Round",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "coinpowerball3Pattern",
            isActive: false,
          },
        ],
      },
      {
        text: "코인사다리 5분",
        name: "coinladderLive5",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "코인사다리 5분",
            name: "coinladderLive5",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "coinladderDaily5",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "coinladderRound5",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "coinladderPattern5",
            isActive: false,
          },
        ],
      },
      {
        text: "코인사다리 3분",
        name: "coinladderLive3",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "코인사다리 3분",
            name: "coinladderLive3",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "coinladderDaily3",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "coinladderRound3",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "coinladderPattern3",
            isActive: false,
          },
        ],
      },
    ],
  },
  {
    title: "메타파워볼",
    name: "metapowerball5",
    img: require("@/assets/img/superball_gray.png"),
    isActive: false,
    children: [
      {
        text: "메타파워볼 5분",
        name: "metapowerball5Live",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "메타파워볼 5분",
            name: "metapowerball5Live",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "metapowerball5Daily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "metapowerball5Round",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "metapowerball5Pattern",
            isActive: false,
          },
        ],
      },
      {
        text: "메타파워볼 3분",
        name: "metapowerball3Live",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "메타파워볼 3분",
            name: "metapowerball3Live",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "metapowerball3Daily",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "metapowerball3Round",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "metapowerball3Pattern",
            isActive: false,
          },
        ],
      },
      {
        text: "메타사다리 5분",
        name: "metaladderLive5",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "메타사다리 5분",
            name: "metaladderLive5",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "metaladderDaily5",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "metaladderRound5",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "metaladderPattern5",
            isActive: false,
          },
        ],
      },
      {
        text: "메타사다리 3분",
        name: "metaladderLive3",
        isActive: false,
        isChildren: false,
        children: [
          {
            text: "메타사다리 3분",
            name: "metaladderLive3",
            isActive: false,
          },
          {
            text: "일별분석",
            name: "metaladderDaily3",
            isActive: false,
          },
          {
            text: "회차분석",
            name: "metaladderRound3",
            isActive: false,
          },
          {
            text: "패턴분석",
            name: "metaladderPattern3",
            isActive: false,
          },
        ],
      },
    ],
  },

  {
    title: "PBG파워볼",
    name: "pbgpowerball",
    img: require("@/assets/img/superball_gray.png"),
    isActive: false,
    children: [
      {
        text: "PBG파워볼",
        name: "pbgpowerballLive",
        isActive: false,
      },
      {
        text: "일별분석",
        name: "pbgpowerballDaily",
        isActive: false,
      },
      {
        text: "회차분석",
        name: "pbgpowerballRound",
        isActive: false,
      },
      {
        text: "패턴분석",
        name: "pbgpowerballPattern",
        isActive: false,
      },
    ],
  },

  {
    title: "커뮤니티",
    name: "board",
    img: require("@/assets/img/community_gray.png"),
    children: [
      {
        text: "자유게시판",
        name: "boardList",
        params: "free",
        query: { sub: "all", page: 1 },
        isActive: false,
      },
      {
        text: "유머게시판",
        name: "boardList",
        params: "enjoy",
        query: { sub: "all", page: 1 },
        isActive: false,
      },
      {
        text: "포토게시판",
        name: "boardList",
        params: "photo",
        query: { sub: "all", page: 1 },
        isActive: false,
      },
      {
        text: "분석게시판",
        name: "boardList",
        params: "analysis",
        query: { sub: "all", page: 1 },
        isActive: false,
      },
    ],
    isActive: false,
  },
  {
    title: "마켓",
    name: "market",
    img: require("@/assets/img/market_gray.png"),
    children: [
      {
        text: "아이템 리스트",
        name: "marketList",
        isActive: false,
      },
    ],
    isActive: false,
  },
  {
    title: "고객센터",
    name: "cscenter",
    img: "@/assets/img/center_gray.png",
    img: require("@/assets/img/center_gray.png"),
    children: [
      {
        text: "공지사항",
        name: "cscenter_noticeList",
        isActive: false,
      },
      {
        text: "자주묻는질문",
        name: "cscenter_faqList",
        isActive: false,
      },
      {
        text: "1:1문의",
        name: "cscenter_qnaList",
        isActive: false,
      },
      {
        text: "영창",
        name: "cscenter_prisonList",
        isActive: false,
      },
      {
        text: "출석체크",
        name: "cscenter_attendanceList",
        isActive: false,
      },
    ],
    isActive: false,
  },
];

export const TYPE_MODEL = {
  board: {
    all: "",
    free: "01",
    enjoy: "02",
    photo: "03",
    analysis: "04",
  },
  notice: {
    all: "00",
    notice: "01",
    event: "02",
    update: "03",
  },
  qna: {
    all: "20",
    charge: "21",
    exchange: "22",
    item: "23",
    error: "24",
    etc: "25",
  },
  faq: {
    all: "10",
    family: "11",
    msg: "12",
    item: "13",
    userInfo: "14",
    etc: "15",
    beginner: "16",
  },
};
export const TYPE_CODE = {
  board: {
    "01": "free",
    "02": "enjoy",
    "03": "photo",
    "04": "analysis",
  },
  report: {
    B001: "낚시",
    B002: "회원 비방 및 욕설",
    B003: "홍보",
    B004: "도배",
    B005: "경쟁 사이트 홍보",
    B006: "모욕 및 비방",
    B007: "개인정보 공유",
    B008: "금전 거래",
    B009: "분란 유발",
    B010: "게시판 규정 미준수",
    B011: "사칭",
    B012: "불법 프로그램 유포",
    B013: "유료 픽 판매",
    B014: "불법 프로그램 불법 사이트 홍보",
    B015: "기타",
  },
  gameType: {
    EOS5: "eospowerball5",
    EOS3: "eospowerball3",
    CB5: "coinpowerball5",
    CB3: "coinpowerball3",
    MB5: "metapowerball5",
    MB3: "metapowerball3",
    CL5: "coinladder5",
    CL3: "coinladder3",
    ML5: "metaladder5",
    ML3: "metaladder3",
    DH: "dhpowerball",
    DL: "dhladder",
    PBG: "pbgpowerball'",
  },
};
export const CONVERT_TYPE_CD = {
  board: {
    "01": "자유",
    "02": "유머",
    "03": "포토",
    "04": "분석",
  },
  qna: {
    21: "입금/충전",
    22: "환전/환불",
    23: "아이템",
    24: "오류",
    25: "기타",
  },
};

export const EXP_INFO = [
  "",
  0,
  1000,
  10000,
  100000,
  1000000,
  10000000,
  100000000,
  200000000,
  300000000,
  400000000,
];

export const ITEM_INFO = [
  "ICD0001",
  "ICD0002",
  "ICD0003",
  "ICD0004",
  "ICD0005",
  "ICD0006",
  "ICD0007",
  "ICD0008",
  "ICD0009",
  "ICD0010",
  "ICD0011",
  "ICD0012",
  "ICD0013",
  "ICD0014",
  "ICD0015",
  "ICD0016",
  "ICD0017",
  "ICD0018",
  "ICD0019",
  "ICD0020",
  "ICD0021",
  "ICD0022",
  "ICD0023",
  "ICD0024",
  "ICD0025",
  "ICD0026",
  "ICD0027",
  "ICD0028",
  "ICD0029",
  "ICD0030",
  "ICD0031",
  "ICD0032",
  "ICD0033",
  "ICD0034",
  "ICD0035",
  "ICD0036",
  "ICD0037",
  "ICD0038",
  "ICD0039",
  "ICD0040",
  "ICD0041",
  "ICD0042",
  "ICD0043",
  "ICD0044",
  "ICD0045",
  "ICD0046",
  "ICD0047",
  "ICD0048",
  "ICD0049",
  "ICD0050",
  "LVB0001",
  "LVB0002",
  "LVB0003",
];

export const SIGNUP_REGEXP = {
  lgnId: /^[a-z0-9]{5,20}$/,
  mail: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  nickName: /^(?=.*[a-z0-9가-힣])[a-z0-9가-힣]{3,8}$/,
  lgnPw: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/,
  familyName: /^[a-zA-Z0-9]{2,5}$/,
};

export const SAYING = [
  "가장 뛰어난 예언자는 과거이다. - 바이런",
  "그래도 지구는 돈다. - 갈릴레오 G. Galile",
  "나는 생각한다. 고로 존재한다. - 데카르트",
  "나는 해야한다. 그러므로 나는 할 수 있다 - 괴테",
  "내 비장의 무기는 아직 손 안에 있다. 그것은 희망이다. - 나폴레옹",
  "내 사전에 불가능은 없다. - 나폴레옹",
  "너 자신을 알라. - 소크라테스(Socrates)",
  "노병(老兵)은 결코 죽지 않고 사라질 뿐이다. - 맥아더(D.Macarthur)",
  "많은 벗을 가진 사람은 한 사람의 진실한 벗을 가질 수 없다. - 아리스토텔레스",
  "말은 행동의 거울이다. - 솔론",
  "민주주의는 정지된 것이 아니라 영원히 계속되는 행진이다. - 루즈벨트",
  "백년을 살 것처럼 일하고 내일 죽을 것처럼 기도하라. - 플랭클린",
  "불행은 진정한 친구가 아닌 자를 가려준다. - 아리스토텔레스",
  "사막이 아름다운 것은 어딘가에 샘을 숨기고 있기 때문이다. - 생텍쥐베리",
  "습관은 제2의 천성으로 제1의 천성을 파괴한다. - 파스칼",
  "신은 모든 것의 원인인데, 모든 것은 신 안에 있다. - 스피노자",
  "아는 것이 힘이다. - 베이컨",
  "악법도 법이다. - 소크라테스",
  "영원히 살 것처럼 꿈을 꾸고 내일 죽을 것처럼 오늘을 살아라 - 제임스딘",
  "오늘을 붙들이라! 되도록 내일에 의지하지 말라! 일년 중 그날 그날이 최선의 날이다. - 에머슨",
  "우리의 삶이 밝을 때도 어두울 때도, 나는 결코 인생을 욕하지 않겠다. - 헤르만 헤세",
  "유감없이 보낸 하루는 즐거운 잠을 가져온다. 잘 보낸 일생은 편안한 죽음을 가져온다. - 다빈치",
  "인간은 사회적 동물이다. - 아리스토텔레스",
  "인간은 생각하는 갈대이다. - 파스칼",
  "인간의 지식은 결코 그 자신의 경험을 넘어 나아갈 수 없다. - 로크",
  "인내는 쓰다. 그러나 그 열매는 달다. - 루소",
  "자연은 결코 우리를 속이지 않는다. 우리를 속이는 것은 언제나 우리 자신이다. - 장 자크 루소",
  "진리는 알 수 없으며 안다고 해도 그것은 진리가 아니다. - 고르기아스",
  "철학의 한계를 정확히 아는 데 철학이 존재한다. - 간트",
  "친구란 두사람의 신체에사는 하나의 영혼이다. - 아리스토텔레스",
  "한 여성을 사랑할 때는 마치 여신을 모시고 사랑하는 것과 같이 하라. - 브하그완",
  "한번의 실패와 영원한 실패를 혼동하지 마라. - F.스콧 핏제랄드",
  "행복을 즐겨야 할 시간은 지금이다. 행복을 즐겨야 할 장소는 여기다. - 로버트인젠솔",
  "행복을 쫒으면 벽으로 보이던 문제가 문으로 변할 것이다. - 조셉캠벨",
];

export default {
  path: "/market",
  name: "market",
  redirect: { name: "marketList" },
  component: () => import("@/views/pc/member/market"),
  meta: {
    layout: "default",
    userOnly: false,
  },
  children: [
    {
      path: "/market/list",
      name: "marketList",
      component: () => import("@/views/pc/member/market/list"),
      meta: {
        layout: "default",
        userOnly: false,
      },
    },
    {
      path: "/market/charge",
      name: "marketCharge",
      component: () => import("@/views/pc/member/market/cashCharge"),
      meta: {
        layout: "default",
        userOnly: false,
      },
    },
  ],
};

<template>
  <article>
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="signUpClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="logo_symbol">
          <img src="@/assets/img/ballpick_logo_1.png" />
        </div>
        <div class="sign_form">
          <fieldset class="input_fs">
            <legend>{{ $t(`text.joinType.${joinType}`) }}</legend>
            <input
              type="text"
              ref="lgnId"
              v-model="model.lgnId"
              placeholder="아이디을 입력해주세요"
              :readonly="joinType !== 'NM' ? true : false"
            />
            <span v-if="this.errorCheck.lgnId" class="error-box_active"
              >5~20자의 영문 소문자, 숫자만 사용 가능합니다.</span
            >
          </fieldset>

          <div class="overlap_check" v-if="joinType === 'NM'">
            <button class="overlap_btn" @click="dupleCheck('lgnId')">
              중복체크
            </button>
          </div>
        </div>
        <div class="sign_form">
          <fieldset class="input_fs">
            <legend>닉네임</legend>
            <input
              type="text"
              ref="nickName"
              minlength="3"
              maxlength="8"
              v-model="model.nickName"
              placeholder="문자,숫자 조합 3자 이상 8자 이하"
            />
            <span v-if="this.errorCheck.nickName" class="error-box_active"
              >3자 이상 8자 이하, 영어 또는 숫자 또는 한글만 가능합니다.</span
            >
          </fieldset>
          <div class="overlap_check">
            <button class="overlap_btn" @click="dupleCheck('nickName')">
              중복체크
            </button>
          </div>
        </div>
        <div class="sign_form" v-if="joinType === 'NM'">
          <fieldset class="input_fs_pw">
            <legend>비밀번호</legend>
            <input
              type="password"
              ref="lgnPw"
              maxlength="20"
              v-model="model.lgnPw"
              placeholder="숫자,문자,특수문자 각 1개 이상 조합, 8자 이상 20자이하"
            />
            <span v-if="this.errorCheck.lgnPw" class="error-box_active"
              >숫자,문자,특수문자 각 1개 이상 조합, 8자 이상 20자 이하만
              가능합니다.</span
            >
          </fieldset>
        </div>
        <div class="sign_form" v-if="joinType === 'NM'">
          <fieldset class="input_fs_pw">
            <legend>비밀번호 확인</legend>
            <input
              type="password"
              ref="lgnPwChk"
              maxlength="20"
              v-model="lgnPwChk"
              placeholder="비밀번호를 다시 입력해주세요"
            />
          </fieldset>
        </div>
        <div class="btn_check">
          <input type="checkbox" id="ckBox" v-model="serviceCheck[1]" />
          <label for="ckBox">
            [필수]
            <button type="button" @click="serviceOpener(1)">이용약관</button>에
            동의합니다.</label
          >
          <div class="serviceBox" v-if="serviceView[1]">
            <dl class="service">
              <dt>
                <span class="tit">이용약관</span>
                <button class="btn_close" @click="serviceOpener(1)">
                  <span>닫기</span>
                </button>
              </dt>
              <dd>
                <div id="s1" class="section">
                  <h2>제 1 조 (목적)</h2>
                  <p>
                    이 약관은 볼픽(이하 회사라 칭한다)가 운영하는 BALLPICK
                    https://ballpick.co.kr (볼픽에서 운영하는 BALLPICK 사이트로
                    연동되는 기타 도메인 포함)에서 제공하는 인터넷 관련
                    서비스(이하 서비스라 칭한다)를 이용함에 있어 회사와 이용자의
                    권리 및 의무, 책임 사항을 규정하는 데에 목적이 있다.
                  </p>
                </div>
                <div id="s2" class="section">
                  <h2>제 2 조 (정의)</h2>
                  <h6>
                    이 약관에서 사용하는 용어의 정의는 제 2 조에서 정하는 것을
                    제외하고는 관계 법령 및 기타 일반적인 상관례에 의한다.
                  </h6>
                  <p>
                    ① '회사'란 볼픽이 BALLPICK을 통해 제공하는 게임 분석 및
                    일체의 정보를 이용자에게 제공하기 위하여 설정한 가상의
                    영업장을 말하며, 아울러 사이버 회사를 운영하는 사업자의
                    의미로도 사용한다.
                  </p>
                  <p>
                    ② '이용자'란 회사의 웹사이트에 접속하여 이 약관에 따라
                    회사가 제공하는 콘텐츠 및 제반 서비스를 이용하는 회원 및
                    비회원을 말한다.
                  </p>
                  <p>
                    ③ '회원'이란 회사와 서비스 이용에 관한 계약을 체결하고 개인
                    정보를 제공하여 아이디를 부여받은 이용자로서, 회사에서
                    제공하는 정보를 지속적으로 제공받는 동시에 회사의 서비스를
                    계속적으로 이용하는 자를 말한다.
                  </p>
                  <p>
                    ④ '비회원'이란 회사에 가입하지 아니하고 회사에서 제공하는
                    정보 및 서비스를 계속적으로 이용하는 자를 말한다.
                  </p>
                  <p>
                    ⑤ '서비스'란 회사가 회원에게 온라인으로 제공하는 콘텐츠 및
                    이에 부수한 제반 서비스를 의한다.
                  </p>
                  <p>
                    ⑥ '이용 계약'이란 이 약관을 포함하여 서비스 이용과 관련하여
                    회사와 회원간에 체결하는 모든 계약을 말한다.
                  </p>
                  <p>
                    ⑦ '비밀번호'란 전자우편 주소 및 기타 개인 정보로 식별되는
                    회원의 본인 여부를 검증하기 위하여 회원이 설정하여 회사에
                    등록한 고유의 문자와 숫자, 특수문자의 조합을 말한다.
                  </p>
                  <p>
                    ⑧ '운영자'란 서비스의 전반적인 관리와 원활한 운영을 위하여
                    회사에서 선정한 사람을 말한다.
                  </p>
                  <p>
                    ⑨ '구매' 및 '결제'라 함은 회사가 정한 결제 수단 중 회원이
                    선택한 결제 수단으로 유료 서비스를 이용하기 위해 회원이
                    대금을 지급하고 서비스를 구입하는 행위를 말한다.
                  </p>
                  <p>
                    ⑩ '환불'이라 함은 회원이 결제 수단을 통해 충전한 캐시를 청약
                    철회를 통하여 지불 처리가 되지 않도록 하는 행위를 말한다.
                  </p>
                  <p>
                    ⑪ '유료 서비스'란 회원이 회사가 제공하는 서비스를 이용 또는
                    구매하기 위해 사용되는 가상의 데이터로서, 회원이 대금을
                    지급하고 구입하는 것을 말한다.
                  </p>
                  <p>
                    ⑫ '게시물'이란 회원이 회사가 제공하는 서비스를 이용함에 있어
                    회원이 게시한 문자, 문서, 그림, 영상, 음성 또는 이들의
                    조합으로 이루어진 모든 정보를 말한다.
                  </p>
                  <p>
                    ⑬ '충전'이라 함은 회원이 회사에 현금 지급 혹은 다른 결제
                    수단을 통해 대금을 지급하고 회사 내에서 사용 가능한 캐시로
                    전환하는 행위를 말한다.
                  </p>
                  <p>
                    ⑭ '캐시'란 회사가 제공하는 서비스를 이용 또는 구매하기 위해
                    사용되는 가상의 결제 수단을 의미하며, 사용된 캐시는 환불되지
                    아니한다.
                  </p>
                  <p>
                    ⑮ '포인트'란 회사가 제공하는 서비스를 이용 또는 구매하기
                    위해 충전 외의 방법으로 회사가 지급한 서비스를 말하며,
                    포인트는 환불되지 아니하며 현금으로 전환되지 아니한다.
                  </p>
                </div>
                <div id="s3" class="section">
                  <h2>제 3 조 (약관 등의 명시와 설명 및 개정)</h2>
                  <p>
                    ① 회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
                    주소(소비자의 불만을 처리할 수 있는 곳의 주소 포함),
                    전화번호, 모사전송번호, 전자우편주소, 사업자등록번호,
                    통신판매업신고번호, 개인정보관리책임자 등을 회원 및 비회원이
                    알 수 있도록 회사의 초기 서비스 화면(전면)에 게시한다. 다만
                    약관의 내용은 회원 및 비회원이 연결 화면을 통하여 볼 수
                    있도록 할 수 있다.
                  </p>
                  <p>
                    ② 회사는 회원 및 비회원이 약관에 동의하기에 앞서 약관에
                    정해져있는 내용 중 청약 철회, 환불 조건 등과 같은 중요한
                    내용을 이용자가 이해할 수 있도록 별도의 연결 화면 또는 팝업
                    화면 등을 제공하여 이용자의 확인을 구해야 한다.
                  </p>
                  <p>
                    ③ 회사는 전자상거래등에서의소비자보호에관한법률,
                    약관의규제에관한법률, 전자거래기본법, 전자서명법,
                    전자통신망이용촉진등에관한법률, 소비자보호법,
                    콘텐츠산업진흥법 등 관련 법을 위배하지 않는 범위에서 이
                    약관을 개정할 수 있다.
                  </p>
                  <p>
                    ④ 회사가 약관을 개정할 경우 적용 일자 및 개정 사유를
                    명시하여 현행 약관과 함께 회사의 초기 화면에 그 적용 일자
                    7일 이전부터 적용일자 전일까지 공지한다. 다만 자세한 약관의
                    내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있다.
                  </p>
                  <p>
                    ⑤ 회원에게 불리하게 약관 내용을 변경하는 경우 최소한 30일
                    이상의 사전 유예 기간을 두고 공지한다. 이 경우 회사는 개정
                    전 내용과 개정 후 내용을 명확하게 비교하여 회원 및 비회원이
                    알기 쉽도록 표시한다.
                  </p>
                  <p>
                    ⑥ 회사가 약관을 개정할 경우에는 개정 약관 공지 후 개정
                    약관의 적용에 대한 회원의 동의 여부를 확인한다. 개정 약관
                    공지시 회원이 동의 또는 거부의 의사 표시를 하지 않으면
                    승낙한 것으로 간주한다는 내용도 함께 공지한 경우에는 회원이
                    약관 시행일까지 거부 의사를 표시하지 않는다면 개정 약관에
                    동의한 것으로 간주할 수 있다.
                  </p>
                  <p>
                    ⑦ 회원은 개정 약관에 대하여 거부 의사를 표시할 수 있으며, 이
                    경우 회사는 회원에게 유료 서비스 제공이 불가능할 수 있고
                    회원의 요청이 있는 경우 비회원으로 즉시 전환 가능하다.
                  </p>
                  <p>
                    ⑧ 이 약관에서 정하지 아니한 사항과 이 약관에 해석에 관해서는
                    관계 법령 또는 상 관례에 따른다.
                  </p>
                </div>
                <div id="s4" class="section">
                  <h2>제 4 조 (서비스의 제공 및 변경)</h2>
                  <p>① 회사는 다음과 같은 업무를 수행한다.</p>
                  <p class="tab10">⑴ 서비스 및 유료 서비스의 제공</p>
                  <p class="tab10">
                    ⑵ 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결
                  </p>
                  <p class="tab10">⑶ 기타 회사가 정하는 업무</p>
                  <p>
                    ② 회사는 서비스 및 유료 서비스의 품절 또는 기술적 사양의
                    변경 등의 경우에는 서비스 및 유료 서비스의 내용을 변경할 수
                    있다. 이 경우에는 변경된 서비스 및 유료 서비스의 내용 및
                    제공 일자를 명시하여 서비스 및 유료 서비스 내용을 게시한
                    곳에 즉시 공지해야 하며, 변경 내용은 연결 화면을 통하여 볼
                    수 있도록 할 수 있다.
                  </p>
                  <p>
                    ③ 회사는 서비스 및 유료 서비스 제공 중 오류가 발생한 경우
                    해당 오류를 인지한 즉시 수정한다.
                  </p>
                  <p>
                    ④ 회사의 과실로 인한 서비스 및 유료 서비스의 오류가 발생한
                    경우 제 5 조 2 항의 기준으로 보상하며, 그 외 회사가 알 수
                    없었던 회원의 특별한 손해에 대해서는 어떠한 보상도 하지
                    않는다.
                  </p>
                  <p>
                    ⑤ 회사는 서비스를 이용하는 회원에 대하여 등급별로 구분하여
                    서비스의 이용에 차등을 둘 수 있다.
                  </p>
                </div>
                <div id="s5" class="section">
                  <h2>제 5 조 (서비스의 중단)</h2>
                  <p>
                    ① 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
                    통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을
                    일시적으로 중단할 수 있다.
                  </p>
                  <p>
                    ② 회사는 제 5 조 1 항의 사유로 유료 서비스의 제공이
                    일시적으로 중단됨에 의하여 회원이 입은 손해에 대해 회원이 제
                    5 조 1 항의 사유로 사용하지 못 한 유료 서비스에 대하여
                    배상한다. 단, 회사가 고의 또는 과실이 없음을 입증하는
                    경우에는 그러지 아니한다.
                  </p>
                  <p>
                    ③ 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로
                    서비스를 제공할 수 없게 된 경우 회사는 제 8 조에 정한
                    방법으로 회원에게 통지하고 당초 회사에서 제시한 조건에 따라
                    소비자에게 보상한다. 다만, 회사가 보상 기준 등을 고지하지
                    아니한 경우에는 이용자들의 마일리지 또는 적립금 등을
                    회사에서 통용되는 통화 가치에 상응하는 현물 또는 현금으로
                    이용자에게 지급한다.
                  </p>
                </div>
                <div id="s6" class="section">
                  <h2>제 6 조 (회원가입)</h2>
                  <p>
                    ① 회원은 회사가 정한 가입 양식에 따라 회원 정보를 기입한 후
                    이 약관에 동의한다는 의사 표시를 함으로써 회원 가입을
                    신청한다.
                  </p>
                  <p>
                    ② 회사는 제 6 조 1 항과 같이 회원으로 가입할 것을 신청한
                    이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록한다.
                  </p>
                  <p class="tab10">
                    ⑴ 가입 신청자가 본 약관 제 7 조 3 항에 의하여 이전에 회원
                    자격을 상실한 적이 있는 경우 다만 제 7 조 3 항에 의한 회원
                    자격 상실 후 3 년이 경과한 자로서 회사의 회원 재가입 승낙을
                    얻은 경우에는 예외로 한다.
                  </p>
                  <p class="tab10">
                    ⑵ 등록 내용에 허위, 기재 누락, 오기가 있는 경우
                  </p>
                  <p class="tab10">
                    ⑶ 회사의 기술상 회원으로 등록하는 것이 회사가 서비스를
                    제공함에 있어 현저히 지장을 초래한다고 판단할 경우
                  </p>
                  <p>
                    ③ 회원 가입 계약의 성립 시기는 회사의 승낙이 회원에게 도달한
                    시점으로 한다.
                  </p>
                  <p>
                    ④ 회원은 제 17 조 1 항의 회원 정보 등록 사항에 변경이 있는
                    경우, 즉시 전자우편 등 기타 방법으로 회사에 변경 사항을
                    알려야 하며, 제 15 조 1 항의 경우 회사는 회원이 입력한
                    회원정보대로 진행하며 회원 정보가 변경되지 않아 발생하는
                    사안에 대하여 책임지지 아니한다.
                  </p>
                </div>
                <div id="s7" class="section">
                  <h2>제 7 조 (회원 탈퇴 및 자격 상실 등)</h2>
                  <p>
                    ① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 이를
                    인지하는 즉시 회원을 탈퇴 처리한다.
                  </p>
                  <p>
                    ② 회원이 회원 스스로의 귀책 사유나 다음 각 호의 사유에
                    해당하는 경우, 회사는 회원 자격을 제한 및 정지시킬 수 있으며
                    이로 인하여 회원이 입은 손해에 대하여 회사는 책임지지
                    아니한다.
                  </p>
                  <p class="tab10">
                    ⑴ 가입 신청시 허위 내용을 등록하거나 기타 회원 정보에 허위
                    내용을 등록하는 경우
                  </p>
                  <p class="tab10">
                    ⑵ 회사의 유료 서비스, 기타 회사가 제공하는 서비스 이용에
                    관하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
                  </p>
                  <p class="tab10">
                    ⑶ 이용자의 회사 서비스 이용을 방해하거나 그 정보를 도용하는
                    등 전자상거래 질서를 위협하는 경우
                  </p>
                  <p class="tab10">
                    ⑷ 회사를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
                    반하는 행위를 하는 경우
                  </p>
                  <p class="tab10">⑸ 제 20 조 1 항 각 호에 해당하는 경우</p>
                  <p>
                    ③ 회사가 회원 자격을 제한, 정지시킨 후 동일한 행위가 2회
                    이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우
                    회사는 회원 자격을 상실시킬 수 있다.
                  </p>
                  <p>
                    ④ 회사가 회원 자격을 상실시키는 경우에는 회원 등록을
                    말소한다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소
                    전에 최소한 30 일 이상의 기간을 정하여 소명할 기회를
                    부여한다.
                  </p>
                  <p>
                    ⑤ 회사가 회원 등록을 말소할 경우에는 회원이 이미 충전한 캐시
                    및 포인트, 보석이 소멸된다. 소멸된 캐시, 포인트, 보석은 복구
                    및 환불이 불가능하다.
                  </p>
                </div>
                <div id="s8" class="section">
                  <h2>제 8 조 (회원에 대한 통지)</h2>
                  <p>
                    ① 회사가 회원에 대한 개별 통지를 하는 경우, 회원이 회사와
                    미리 약정하여 지정한 전자우편 주소로 할 수 있
                  </p>
                  <p>
                    ② 회사는 불특정다수 회원에 대해 통지할 경우, 1주일 이상 회사
                    공지사항 게시판 내에 게시함으로써 개별 통지에 갈음할 수
                    있다. 다만, 회원 본인의 거래와 관련해 중대한 영향을 미치는
                    사항에 대해선 개별 통지를 한다.
                  </p>
                </div>
                <div id="s9" class="section">
                  <h2>제 9 조 (유료 서비스 등의 개시)</h2>
                  <p>
                    ① 회사는 다음 사항을 해당 유료 서비스의 이용 초기 화면 등에
                    회원이 알기 쉽게 표시한다.
                  </p>
                  <p class="tab10">⑴ 유료 서비스의 명칭, 종류 및 내용</p>
                  <p class="tab10">⑵ 유료 서비스의 가격</p>
                  <p class="tab10">⑶ 유료 서비스의 제공 기간</p>
                  <p>
                    ②회사에서 제공하는 유료 서비스는 회원이 구매하는 즉시 회원
                    계정으로 지급되며, 유료 서비스 제공 기간에 따라 사용할 수
                    있다.
                  </p>
                </div>
                <div id="s10" class="section">
                  <h2>제 10 조 (유료 서비스 이용계약의 성립)</h2>
                  <p>
                    ① 회원은 회사가 제공하는 다음 또는 이와 유사한 절차에 의하여
                    이용 신청을 한다. 회사는 계약 체결 전에 다음 각 호의 사항에
                    관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할
                    수 있도록 정보를 제공한다.
                  </p>
                  <p class="tab10">⑴ 유료 서비스의 확인 및 선택</p>
                  <p class="tab10">⑵ 결제 방법의 선택 및 결제 정보의 입력</p>
                  <p class="tab10">
                    ⑶ 유료 서비스의 이용 신청에 관한 확인 또는 회사의 확인에
                    대한 동의
                  </p>
                  <p>
                    ② 회사는 회원의 이용 신청이 다음 각 호에 해당하는 경우에는
                    승낙하지 않거나 승낙을 유보할 수 있다. 다만, 미성년자와
                    계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면
                    미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다.
                  </p>
                  <p class="tab10">
                    ⑴ 실명이 아니거나 타인의 명의를 이용한 경우
                  </p>
                  <p class="tab10">
                    ⑵ 허위의 정보를 기재하거나 회사가 제시하는 내용을 기재하지
                    않은 경우
                  </p>
                  <p class="tab10">
                    ⑶ 미성년자가 청소년보호법에 의해서 이용이 금지되는 유료
                    서비스를 이용하고자 하는 경우
                  </p>
                  <p class="tab10">
                    ⑷ 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상
                    문제가 있는 경우
                  </p>
                  <p>
                    ③ 이용 계약의 성립 시기는 구매 완료를 신청절차 상에서 표시한
                    시점으로 한다.
                  </p>
                  <p>
                    ④ 회원이 유료 서비스를 이용하기 위해서는 이 약관에 동의 후
                    각 서비스에 따른 이용 조건에 따라 이용 요금을 지급하여야
                    한다.
                  </p>
                </div>
                <div id="s11" class="section">
                  <h2>제 11 조 (지급방법)</h2>
                  <h6>
                    회사에서 제공한 유료 서비스에 대한 대금 지급 방법은 다음 각
                    호의 방법 중 가용한 방법으로 할 수 있다.
                  </h6>
                  <p>① 폰 뱅킹, 인터넷 뱅킹, 메일 뱅킹 등의 각종 계좌이체</p>
                  <p>② 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</p>
                  <p>③ 온라인 무통장 입금</p>
                  <p>④ 전자화폐에 의한 결제</p>
                  <p>⑤ 수령시 대금 지급</p>
                  <p>⑥ 마일리지 등 회사가 지급한 포인트에 의한 결제</p>
                  <p>
                    ⑦ 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제
                  </p>
                  <p>⑧ 기타 전자적 지급 방법에 의한 대금 지급 등</p>
                </div>
                <div id="s12" class="section">
                  <h2>제 12 조 (캐시, 포인트, 보석 서비스)</h2>
                  <p>
                    ① 이 약관에 동의한 회원만이 캐시, 포인트, 보석 등의 서비스를
                    이용할 수 있다.
                  </p>
                  <p>
                    ② 회사는 캐시, 포인트, 보석 등의 서비스 이용 방법을 회원이
                    알기 쉽도록 서비스 이용 초기 화면 또는 회사가 지정한 장소에
                    게시하여 회원이 볼 수 있도록 비치한다.
                  </p>
                  <p>
                    ③ 구매 승인은 회원이 선택한 결제 방법으로 신청한 경우 회사가
                    승낙함으로써 성립되며, 회사는 구매 요청시 회원이 입력한
                    내용을 모두 사실로 간주하며, 허위로 내용을 입력한 회원은
                    법적인 보호를 받을 수 없다.
                  </p>
                  <p>
                    ④ 회사는 신용불량자로 등록된 회원이나 대금 지급을 연체하는
                    회원에 대하여 캐시, 포인트, 보석 등의 사용을 제한할 수
                    있으며, 부정한 방법 또는 회사가 금지한 방법을 통해 충전,
                    취득한 캐시, 포인트, 보석 등의 사용과 환불을 제한할 수 있다.
                  </p>
                  <p>
                    ⑤ 캐시, 포인트, 보석 등은 마지막으로 충전 또는 사용한
                    날로부터 5년 이내에 단 한번도 추가 충전 또는 사용하지 않을
                    경우 상법상의 상사소멸시효에 따라 자동 소멸될 수 있다. 다만,
                    이용자가 추가 충전 또는 사용을 하지 않더라도 해당 기간
                    사용할 수 있는 서비스에 최소 1회라도 로그인을 한 경우 로그인
                    시점으로부터 5년간 유효하다(제 5 조 3 항의 경우는 예외로
                    한다).
                  </p>
                  <p>
                    ⑥ 서비스의 중대한 하자에 의하여 구매한 서비스가 손상, 훼손,
                    삭제되었거나 불량했을 경우 회원은 해당 서비스의 복원을
                    요구하거나 서비스를 구매하기 위해 사용한 캐시, 포인트, 보석
                    등의 사용량을 복구할 수 있다.
                  </p>
                  <p>
                    ⑦ 회원은 환불을 신청하는 대신 환불 가능 금액을 기부할 수도
                    있다. 이 기부금은 자선 단체, 종교 단체, 학교 지원 및 회사
                    동호회 지원을 위하여 쓰여진다.
                  </p>
                  <p>
                    ⑧ 회원이 자진 탈퇴 할 경우, 회원이 보유한 캐시, 포인트,
                    보석, 아이템 등은 자동으로 소멸되어 복구 및 환불이
                    불가능하다.
                  </p>
                  <p>
                    ⑨ 회사와 회원 간에 발생한 분쟁은 전자거래기본법 제 28 조 및
                    동 시행령 제 15 조에 의하여 설치된 전자거래분쟁조정위원회의
                    조정에 따를 수 있다.
                  </p>
                </div>
                <div id="s13" class="section">
                  <h2>제 13 조 (수신확인통지, 구매신청 변경 및 취소)</h2>
                  <p>
                    ① 회사는 회원의 구매 신청이 있는 경우 회원에게
                    수신확인통지를 한다.
                  </p>
                  <p>
                    ② 수신 확인 통지를 받은 회원은 의사표시의 불일치 등이 있는
                    경우에는 수신 확인 통지를 받은 후 즉시 구매 신청 변경 및
                    취소를 요청할 수 있고, 회사는 회원의 요청이 있는 경우에는
                    지체 없이 그 요청에 따라 처리해야 한다. 다만, 이미 대금을
                    지불한 경우에는 청약철회 등에 관한 규정에 따른다.
                  </p>
                </div>
                <div id="s14" class="section">
                  <h2>제 14 조 (환불)</h2>
                  <p>
                    회사는 회원이 구매 신청한 유료 서비스 등이 품절 등의 사유로
                    인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를
                    회원에게 통지하고 사전에 유료 서비스 이용 대금을 받은
                    경우에는 대금을 받은 날로부터 3 영업일 이내에 환불하거나
                    환불에 준하는 조치를 취한다.
                  </p>
                </div>
                <div id="s15" class="section">
                  <h2>제 15 조 (청약철회 등)</h2>
                  <p>
                    ① 회사와 캐시 충전에 관한 계약을 체결한 회원은 청약 철회를
                    요청할 수 있으며, 회사는 회원이 충전한 캐시를 환불할 경우
                    환불 금액의 10%에 해당하는 환불 수수료를 공제한 나머지
                    금액을 이용자에게 환불한다. 단, 회사는 1,000 원 이하의
                    캐시는 환불하지 아니한다.
                  </p>
                  <p>
                    ② 회원은 다음 각 호의 어느 하나에 해당하는 경우에는 회사의
                    의사에 반하여 제 15 조 제 1 항에 따른 청약철회 등을 할 수
                    없다.
                  </p>
                  <p class="tab10">
                    ⑴ 회원에게 책임이 있는 사유로 서비스 등이 멸실 되거나 훼손된
                    경우
                  </p>
                  <p class="tab10">
                    ⑵ 충전한 캐시 이외에 회원이 구매한 다른 유료 서비스
                  </p>
                  <p class="tab10">⑶ 시간이 지나 다시 판매하기 곤란할 경우</p>
                  <p class="tab10">
                    ⑷ 그 밖에 거래의 안전을 위하여 법령으로 정하는 경우
                  </p>
                  <p>
                    ③ 회사는 제 15 조 2 항 1 호부터 4 호까지 규정에 따라 청약
                    철회 등이 불가능한 경우에는 그 사실을 회원에게 개별
                    통지하거나, 회원이 쉽게 알 수 있는 곳에 명확하게 적시하거나
                    시험 사용 서비스를 제공하는 등의 방법으로 청약 철회 등의
                    권리 행사가 방해 받지 아니하도록 조치해야 한다. 만약 회사가
                    이러한 조치를 하지 아니한 경우에는 제 15 조 2 항의 청약철회
                    제한 사유에도 불구하고 회원은 청약철회를 할 수 있다.
                  </p>
                  <p>
                    ④ 회원은 제 15 조 1 항 내지 3 항의 규정에도 불구하고 유료
                    서비스의 내용이 표시, 광고 내용과 다르거나 계약내용과 다르게
                    이행된 경우 구매일 또는 유료 서비스 이용일로부터 3월 이내,
                    그 사실을 인지한 날 또는 인지할 수 있었던 날부터 30일 이내에
                    청약철회를 할 수 있다.
                  </p>
                  <p>
                    ⑤ 회원은 구두 또는 서면(전자문서 포함)으로 청약철회를 할 수
                    있으며, 회사는 회원에게 본인 확인을 위한 절차 및 증표를
                    요구할 수 있다.
                  </p>
                  <p>
                    ⑥ 제 7 조 2 항에서 정한 회원 자격 제한 및 정지가 되어 캐시,
                    포인트, 보석 등을 사용하지 못하게 되는 사안에 대하여 회사는
                    책임지지 아니한다.
                  </p>
                </div>
                <div id="s16" class="section">
                  <h2>제 16 조 (청약철회 등의 효과)</h2>
                  <p>
                    ① 회사는 제 15 조 1 항에 의한 청약철회가 된 경우 3 영업일
                    이내에 이미 지급받은 대금을 환불한다. 이 경우 회사가
                    회원에게 대금의 환불을 지연한 때에는 그 지연 기간에 대하여
                    전자상거래등에서의소비자보호에관한법률시행령 제 21 조의 2
                    에서 정하는 지연 이자율을 곱하여 산정한 지연 이자를
                    지급한다.
                  </p>
                  <p>
                    ② 회사는 위 대금을 환불함에 있어 회원이 신용카드 또는
                    전자화폐 등의 결제수단으로 유료 서비스 이용 대금을 지급한
                    때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금
                    대금의 청구를 정지 또는 취소하도록 요청한다.
                  </p>
                  <p>
                    ③ 청약철회의 경우 대금 반환에 필요한 비용은 이용자가
                    부담한다. 회사는 회원에게 청약 철회 등을 이유로 위약금 또는
                    손해배상을 청구하지 않는다. 다만 유료 서비스 등의 내용이
                    표시, 광고 내용과 다르거나 계약내용과 다르게 이행되어 청약
                    철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가
                    부담한다.
                  </p>
                </div>
                <div id="s17" class="section">
                  <h2>제 17 조 (개인정보보호)</h2>
                  <p>
                    ① 회사는 회원의 정보 수집시 구매계약 이행에 필요한 최소한의
                    정보를 수집한다. 다음 각 호를 필수 사항으로 하며 그 외의
                    사항은 선택 사항으로 하되, 선택 사항으로 입력한 정보의
                    유무에 따라 회사가 제공하는 서비스에 차등이 발생할 수 있다.
                  </p>
                  <p class="tab10">⑴ 전자우편 주소</p>
                  <p class="tab10">⑵ 비밀번호</p>
                  <p>
                    ② 회사는 회원이 서비스 이용시 회원의 성명, 주민등록번호,
                    전화번호 등을 요구할 수 있으며, 회원의 개인 식별이 가능한
                    개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를
                    받는다.
                  </p>
                  <p>
                    ③ 제공된 개인정보는 당해 회원의 동의 없이 목적 외의 이용이나
                    제 3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가
                    진다. 다만, 다음의 경우에는 예외로 한다.
                  </p>
                  <p class="tab10">
                    ⑴ 통계 작성, 학술 연구 또는 시장 조사를 위하여 필요한
                    경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
                  </p>
                  <p class="tab10">
                    ⑵ 유료 서비스 등의 이용에 따른 대금 정산을 위하여 필요한
                    경우
                  </p>
                  <p class="tab10">
                    ⑶ 도용방지를 위하여 본인 확인에 필요한 경우
                  </p>
                  <p class="tab10">
                    ⑷ 중대한 약관 위반 행위 또는 법령 위반 행위와 관련하여
                    회원의 통신 내용을 열람할 필요가 있는 경우
                  </p>
                  <p class="tab10">
                    ⑸ 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는
                    경우
                  </p>
                  <p>
                    ④ 회사가 제 17 조 3항에 의해 회원의 동의를 받아야 하는
                    경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호),
                    정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
                    관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등
                    정보통신망이용촉진등에관한법률 제 22 조 2항이 규정한 사항을
                    미리 명시하거나 고지해야 하며 회원은 언제든지 이 동의를
                    철회할 수 있다.
                  </p>
                  <p>
                    ⑤ 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해
                    열람 및 오류 정정을 요구할 수 있으며, 회사는 이에 대해 지체
                    없이 필요한 조치를 취할 의무를 가진다. 회원이 오류의 정정을
                    요구한 경우에 회사는 그 오류를 정정할 때까지 당해 개인정보를
                    이용하지 않는다.
                  </p>
                  <p>
                    ⑥ 회사는 개인정보 보호를 위하여 관리자를 한정하여 그 수를
                    최소화하며 신용카드, 은행계좌 등을 포함한 회원의 개인정보의
                    분실, 도난, 유출, 변조 등으로 인한 회원의 손해에 대하여 모든
                    책임을 진다. 단, 회사가 고의 또는 관리를 게을리하지 않았음을
                    입증하는 경우에는 그러하지 아니한다.
                  </p>
                  <p>
                    ⑦ 회사 또는 그로부터 개인정보를 제공받은 제 3자는 개인정보의
                    수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를
                    지체 없이 파기한다.
                  </p>
                </div>
                <div id="s18" class="section">
                  <h2>제 18 조 (회사의 의무)</h2>
                  <p>
                    ① 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는
                    행위를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고
                    안정적으로 재화, 용역을 제공하는 데 최선을 다하여야 한다.
                  </p>
                  <p>
                    ② 회사는 회원이 안전하게 인터넷 서비스를 이용할 수 있도록
                    회원의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을
                    갖추어야 한다.
                  </p>
                  <p>
                    ③ 회사는 상품이나 용역에 대하여 표시,광고의공정화에관한법률
                    제 3 조 부당한표시,광고행위의금지에 해당하는 행위를 함으로써
                    회원이 손해를 입은 때에는 이를 배상할 책임을 진다.
                  </p>
                  <p>
                    ④ 회사는 회원이 원하지 않는 경우 영리 목적의 광고성
                    전자우편을 발송하지 않는다.
                  </p>
                  <p>
                    ⑤ 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한
                    정보 혹은 불특정다수 회원에 대한 통지를 하는 경우 팝업창,
                    연결화면, 게시판 공지사항, 쪽지, 전자우편 등의 방법으로
                    회원에게 제공할 수 있다.
                  </p>
                  <p>
                    ⑥ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 지속적인
                    서비스 개선을 하며, 설비에 장애가 생기거나 데이터 등이
                    멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이
                    불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이
                    이를 수리 또는 복구하도록 최선의 노력을 다 한다.
                  </p>
                  <p>
                    ⑦ 회사는 이용자가 회사의 조소지에 방문하거나 전자우편 주소
                    또는 기타 회원 관련 정보를 이용하여 자신의 개인정보에 대한
                    열람 또는 정정을 요구하는 경우 본인 여부를 확인하고 지체없이
                    필요한 조치를 취해야 한다.
                  </p>
                  <p>
                    ⑧ 회사는 이용자의 대리인이 방문하여 열람 또는 정정을
                    요구하는 경우에는 대리 관계를 증명할 수 있는 증표를
                    제시하도록 요구할 수 있다.
                  </p>
                </div>

                <div id="s19" class="section">
                  <h2>제 19 조 (회원의 ID 및 비밀번호에 대한 의무)</h2>
                  <p>
                    ① 제 17 조의 경우를 제외한 ID와 비밀번호, 전자우편 주소와
                    전화번호에 관한 관리책임은 회원에게 있다.
                  </p>
                  <p>
                    ② 회원은 자신의 ID와 비밀번호, 전자우편 주소와 전화번호를 제
                    3자에게 이용하게 해서는 안 된다.
                  </p>
                  <p>
                    ③ 회원이 자신의 ID와 비밀번호, 전자우편 주소와 전화번호를
                    도난 당하거나 제 3자가 사용하고 있음을 인지한 경우에는 바로
                    회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야
                    한다.
                  </p>
                </div>
                <div id="s20" class="section">
                  <h2>제 20 조 (이용자의 의무)</h2>
                  <p>
                    ① 회원은 다음 각 호의 행위를 하여서는 안 되며, 각 호에
                    해당하는 행위를 한 회원에게는 회사가 회원 자격을 제한 및
                    정지시킬 수 있다. 또한 아래 행위를 통해 회원에게 불이익이
                    발생하거나 회원간 분쟁이 발생한 경우 회사는 손해에 대하여
                    책임을 지지 아니한다.
                  </p>
                  <p class="tab10">
                    (1) 회원 가입 신청 또는 회원 정보 변경시 허위 내용의 등록
                  </p>
                  <p class="tab10">(2) 타인의 정보 도용</p>
                  <p class="tab10">(3) 회사가 게시한 정보를 변경</p>
                  <p class="tab10">
                    (4) 회사가 정한 정보 이외의 정보 (컴퓨터 프로그램 및 불법
                    프로그램 등) 등을 유포, 송신 또는 홍보, 게시하거나 상용
                    소프트웨어, 크랙 파일 등을 올리는 행위
                  </p>
                  <p class="tab10">
                    (5) 회사 및 기타 제 3자의 저작권 등 지적재산권에 대한 침해
                  </p>
                  <p class="tab10">
                    (6) 회사 및 기타 제 3자의 명예를 손상시키거나 업무를
                    방해하거나 회사를 비방하는 행위
                  </p>
                  <p class="tab10">
                    (7) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                    반하는 정보를 회사에 공개 또는 게시하는 행위
                  </p>
                  <p class="tab10">
                    (8) 다른 이용자에게 게임 진행 상황 및 게임 결과 등 게임에
                    관련한 정보를 허위 유포하는
                  </p>
                  <p class="tab10">
                    (9) 운영자와 특정 회원 혹은 불특정 다수에게 비방 및 욕설
                    등을 하는 행위
                  </p>
                  <p class="tab10">
                    (10) 회사의 승인 없이 타 회사, 방송, 카페, 블로그 등을
                    홍보하는 행위
                  </p>
                  <p class="tab10">
                    (11) 채팅창 혹은 게시판에 반복적인 글을 입력, 게시하는 행위
                  </p>
                  <p class="tab10">
                    (12) 회사 내에서 전화번호, 주소, 메신저, 이메일, 계좌번호
                    등의 개인정보를 공유하는 행위
                  </p>
                  <p class="tab10">
                    (13) 게시판 성격에 맞지 않는 글을 등록하는 행위
                  </p>
                  <p class="tab10">(14) 거짓의 내용을 공유하는 행위</p>
                  <p class="tab10">
                    (15) 회사 내에서 이용자간 분란을 일으키는 행위
                  </p>
                  <p class="tab10">
                    (16) 회사 내에서 경쟁 사이트 등을 홍보하는 행위
                  </p>
                  <p class="tab10">
                    (17) 회원 및 비회원이 이용자 상호간에 금전 거래를 하거나
                    계정, 캐시, 포인트를 양수도하는 행위
                  </p>
                  <p class="tab10">
                    (18) 회사 내에서 특정회원 사칭 및 운영자를 사칭하는 행위
                  </p>
                  <p class="tab10">
                    (19) 회사의 승인 없이 유료 픽 광고, 홍보, 판매 등을 하는
                    행위
                  </p>
                  <p class="tab10">
                    (20) 회사 내에서 불법 사이트 이름 및 주소 언급, 카페 및
                    블로그의 주소 등을 언급하거나 불법사이트 추천 및 홍보, 유도
                    활동을 하는 행위
                  </p>
                  <p class="tab10">
                    (21) 회사 내에서 회원간 비방, 회원을 자극하는 발언, 지역을
                    비하하는 행위
                  </p>
                  <p class="tab10">
                    (22) 정보통신윤리위원회의 등 관련 공공기관의 시정 요구를
                    받는 행위와 기타 관계법령에 위배되는 행위
                  </p>
                  <p>
                    ② 회원이 서비스를 이용함에 있어 행한 불법 행위로 인하여
                    회사가 당해 회원 이외에 제 3 자로부터 손해배상 및 청구,
                    소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 회사의
                    면책을 위하여 노력하여야 하며, 회사가 면책되지 못 한 경우
                    당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야
                    한다.
                  </p>
                  <p>
                    ③ 회원은 이 약관에 규정하는 사항과 서비스 이용 안내 또는
                    주의 사항을 준수하여야 하며 회사가 공지하거나 별도로 게시한
                    사항을 준수해야 한다.
                  </p>
                  <p>
                    ④ 회원은 회사의 서면 혹은 명시적인 사전 동의 없이 서비스를
                    이용하여 영업 활동을 할 수 없으며, 이를 위반하여 발생한
                    손해에 대하여 회사는 책임을 지지 않는 동시에 이와 같은 영업
                    활동으로 회사에 손해를 끼친 경우 손해 배상 의무를 지닌다.
                  </p>
                  <p>
                    ⑤ 회원은 서비스의 이용 약관, 기타 이용 계약상 취득한 지위를
                    타인에게 양도, 증여할 수 없으며 이를 담보
                  </p>
                </div>
                <div id="s21" class="section">
                  <h2>제 21 조 (정보의 수집 등)</h2>
                  <p>
                    ① 회사는 채팅, 쪽지 등 회사 내에서 회원 간에 이루어지는 모든
                    통신 내용을 저장, 보관할 수 있다. 본 정보는 회원 간의
                    분쟁조정, 민원처리 또는 법령이 정한 경우에 한하여 회사,
                    회사의 업무를 위임 받아 처리하는 자 또는 법령으로 권한을
                    부여 받은 자만이 열람하거나 활용할 수 있다.
                  </p>
                  <p>
                    ② 회사는 본 정보를 열람할 경우 열람이 필요한 사유 및 열람
                    범위를 해당 회원에게 사전 고지하기로 한다. 단, 이 약관의
                    중대한 약관 위반행위 또는 법령 위반행위와 관련하여 통신
                    내용을 열람할 경우 사후 고지한다.
                  </p>
                  <p>
                    ③ 회사는 서비스 운영 및 프로그램 안정화 등 서비스 품질
                    개선을 위하여 회원 PC 등 단말기 설정 및 사양 정보, 실행중인
                    프로그램 정보 등을 수집, 활용할 수 있다.
                  </p>
                </div>
                <div id="s22" class="section">
                  <h2>제 22 조 (고객센터의 운영)</h2>
                  <p>
                    ① 회사는 중대한 약관 위반행위 또는 법령 위반행위를 방지하기
                    위하여 고객센터를 운영할 수 있다.
                  </p>
                  <p>
                    ② 고객센터는 회원의 자발적인 신고로 운영되며, 회사는 신고
                    사항을 인지한 후 3시간 이내 조사에 착수하고, 신고된 회원의
                    쪽지, 이메일 등 모든 통신 수단을 열람 할 수 있다.
                  </p>
                  <p>
                    ③ 회사는 회원의 쪽지, 이메일 등의 통신 수단을 열람할 경우 제
                    21 조에 2 항에 따라 열람이 필요한 사유 및 열람 범위를 당해
                    회원에게 사후 고지한다.
                  </p>
                  <p>
                    ④ 신고 처리 기간은 약 7 일의 기간이 소요되며, 조사 결과를
                    신고한 회원에게 통보하거나, 회사가 정하는 방법으로 회사 내에
                    게시 할 수 있다.
                  </p>
                </div>
                <div id="s23" class="section">
                  <h2>제 23 조 (연결회사와 피 연결회사 간의 관계)</h2>
                  <p>
                    ① 상위 회사와 하위 회사가 하이퍼 링크(예: 하이퍼 링크의
                    대상에는 문자, 그림 및 동화상 등이 포함됨) 방식 등으로
                    연결된 경우, 전자를 연결회사(웹 사이트)라고 하고 후자를 피
                    연결회사(웹사이트)라고 한다.
                  </p>
                  <p>
                    ② 연결회사는 피 연결회사가 독자적으로 제공하는 재화 등에
                    의하여 회원과 행하는 거래에 대해서 보증 책임을 지지 않으며,
                    연결회사는 이것을 연결회사의 초기화면 또는 연결되는 시점의
                    팝업 화면으로 명시할 수 있다.
                  </p>
                </div>
                <div id="s24" class="section">
                  <h2>제 24 조 (게시물의 관리)</h2>
                  <p>
                    ① 회사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지
                    없이 삭제하거나 이동 또는 등록 거부 할 수 있다.
                  </p>
                  <p class="tab10">
                    ⑴ 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를
                    손상시키는 내용
                  </p>
                  <p class="tab10">
                    ⑵ 공공질서 및 공서양속에 위반되는 내용을 유포하거나
                    링크시키는 내용
                  </p>
                  <p class="tab10">⑶ 불법복제 또는 해킹을 조장하는 내용</p>
                  <p class="tab10">⑷ 영리를 목적으로 하는 광고</p>
                  <p class="tab10">
                    ⑸ 범죄와 결부된다고 객관적으로 인정되는 내용
                  </p>
                  <p class="tab10">
                    ⑹ 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는
                    내용
                  </p>
                  <p class="tab10">
                    ⑺ 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에
                    부합하지 않는 내용
                  </p>
                  <p class="tab10">
                    ⑻ 기타 관계법령에 위배된다고 판단되는 내용
                  </p>
                </div>
                <div id="s25" class="section">
                  <h2>제 25 조 (저작권의 귀속 및 이용제한)</h2>
                  <p>
                    ① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은
                    회사에 귀속한다.
                  </p>
                  <p>
                    ② 회원은 회사를 이용함으로써 얻은 정보 중 회사에게
                    지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신,
                    출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나
                    제 3자에게 이용하게 하여서는 안 된다.
                  </p>
                  <p>
                    ③ 회사는 약정에 따라 회원에게 귀속된 저작권을 사용하는 경우
                    당해 회원에게 통보하여야 한다.
                  </p>
                  <p>
                    ④ 회원이 회사의 커뮤니티 및 게시판 등에 제 3자의
                    지적재산권을 침해하는 게시물을 등재할 경우 회사는 당해
                    게시물을 삭제할 수 있으며, 이로 인한 당해 회원과 제 3자간의
                    분쟁에 대하여 보상 및 책임을 지지 아니한다.
                  </p>
                </div>
                <div id="s26" class="section">
                  <h2>제 26 조 (서비스 이용 시간)</h2>
                  <p>
                    ① 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한
                    날이나 시간에 서비스를 일시중단할 수 있으며, 예정되어 있는
                    작업으로 인한 서비스 일시중단은 사전에 공지한다.
                  </p>
                  <p>
                    ② 회사는 회사가 통제할 수 없는 사유로 인한 서비스 중단의
                    경우 (시스템 관리자의 고의, 과실 없는 디스크 장애, 시스템
                    다운 등)에 사전 통지가 불가능하며 타인(PC통신회사,
                    기간통신사업자 등)의 고의, 과실로 인한 시스템 중단 등의
                    경우에는 통지하지 않으며 이로 인한 회원의 손해에 대하여 배상
                    책임을 지지 아니한다.
                  </p>
                </div>
                <div id="s27" class="section">
                  <h2>제 27 조 (광고 게재 및 광고주와의 거래)</h2>
                  <p>
                    ① 회사가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의
                    일부는 광고 게재를 통한 수익으로부터 나오므로, 회원은 서비스
                    이용시 노출되는 광고 게재에 대해 동의한 것으로 간주한다.
                  </p>
                  <p>
                    ② 회사는 서비스상에 게재되어 있거나 본 서비스를 통한
                    광고주의 판촉 활동에 회원이 참여하거나 교신 또는 거래를
                    함으로써 발생하는 손실과 손해에 대하여 책임을 지지 않는다.
                  </p>
                </div>
                <div id="s28" class="section">
                  <h2>제 28 조 (청소년보호정책)</h2>
                  <p>
                    ① 회사는 청소년이 건전한 인격체로 성장할 수 있도록 하기
                    위하여 정보통신망이용촉진및정보보호등에관한법률 및
                    청소년보호법에 근거하여 청소년 보호정책을 수립, 시행한다.
                  </p>
                  <p>
                    ② 회사는 청소년 유해정보로 인한 피해 상담 및 고충처리를 위한
                    고객센터를 운영하고 그 피해가 확산되지 않도록 노력
                  </p>
                </div>
                <div id="s29" class="section">
                  <h2>제 29 조 (분쟁해결)</h2>
                  <p>
                    ① 회사는 회원간에 발생한 분쟁에 대하여 회원이 제기하는
                    정당한 의견이나 불만을 반영하여 적절하고 신속하게 조치를
                    취한다. 다만 신속한 처리가 불가능한 경우 회사는 회원에게 그
                    사유와 처리 일정을 통보한다.
                  </p>
                  <p>
                    ② 회사는 회원 간에 발생한 전자상거래 분쟁과 관련하여 회원의
                    피해구제신청이 있는 경우에는 공정거래위원회 또는 시,도지사가
                    의뢰하는 분쟁조정기관의 조정에 따를 수 있다.
                  </p>
                </div>
                <div id="s30" class="section">
                  <h2>제 30 조 (재판권 및 준거법)</h2>
                  <p>
                    ① 회사와 회원 간에 발생한 전자상거래 분쟁에 관한 소송은 제소
                    당시의 회사의 주소지를 관할하는 지방법원의 전속관할로 하며
                    회사와 회원 간에 제기된 전자상거래 소송에는 한국법을
                    적용한다.
                  </p>
                  <p>
                    ② 이 약관에 명시되지 않은 사항은 전기통신사업법 등
                    관계법령과 상관례에 따른다.
                  </p>
                </div>
                <div id="s31" class="section">
                  <h2>제 31 조 (면책조항)</h2>
                  <p>
                    ① 회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 및
                    기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
                    경우에는 서비스 제공에 대한 책임이 면제된다.
                  </p>
                  <p>
                    ② 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등
                    부득이한 사유로 발생한 손해에 대한 책임이 면제된다.
                  </p>
                  <p>
                    ③ 회사는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는
                    회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가
                    발생한 경우 책임을 지지 아니한다.
                  </p>
                  <p>
                    ④ 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지
                    못하거나 상실한 것에 대하여 책임을 지지 아니하며, 서비스를
                    이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지
                    아니한다.
                  </p>
                  <p>
                    ⑤ 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의
                    신뢰도, 정확성 등 내용에 대하여 책임을 지지 아니하며, 회원
                    상호간 및 회원과 제 3자 상호 간에 서비스를 매개로 발생한
                    분쟁에 대해 개입할 의무가 없고, 이로 인한 손해를 배상할
                    책임을 지지 아니한다.
                  </p>
                  <p>
                    ⑥ 회사는 회원의 게시물을 등록 전에 사전심사 하거나
                    상시적으로 게시물의 내용을 확인 또는 검토하여야 할 의무가
                    없으며, 그 결과에 대한 책임을 지지 아니한다.
                  </p>
                  <p>
                    ⑦ 회사는 회사의 사이트에 연결되거나 제휴한 업체(타
                    온라인사이트)에 포함되어 있는 내용의 유효성, 적합성, 법적
                    합리성, 저작권 준수 여부 등에 책임을 지지 않으며, 이로 인한
                    어떠한 손해에 대하여도 책임을 지지 아니한다.
                  </p>
                </div>

                <div id="s32" class="section">
                  <h2>&lt;부칙&gt;</h2>
                  <p>(시행일) 본 약관은 2023년 07월 25일부터 적용된다.</p>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div class="btn_check">
          <input type="checkbox" id="info_ckBox" v-model="serviceCheck[2]" />
          <label for="info_ckBox"
            >[필수] 서비스 이용을 위한
            <button type="button" @click="serviceOpener(2)">
              개인정보 수집 및 이용에 동의</button
            >합니다.
          </label>
          <div class="serviceBox" v-if="serviceView[2]">
            <dl class="service">
              <dt>
                <span class="tit">개인정보 수집 및 이용에 동의</span>
                <button class="btn_close" @click="serviceOpener(2)">
                  <span>닫기</span>
                </button>
              </dt>
              <dd>
                <h2>
                  볼픽(이하 'BALLPICK' 또는 '회사')는
                  정보통신망이용촉진및정보보호등에관한법률, 개인정보보호법,
                  통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가
                  준수하여야 할 관련 법령상의 개인정보 규정을 준수하며, 관련
                  법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에
                  최선을 다하고 있습니다.
                </h2>
                <div id="s1" class="section">
                  <h2>제 1 조 (개인정보 수집 목적 및 근거)</h2>
                  <p>
                    ① 회사는 법령에 따른 개인정보 보유, 이용 기간 또는 정보
                    주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유, 이용
                    기간 내에 개인정보를 처리, 보유한다.
                  </p>
                  <p>
                    ② 회사는 회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인
                    식별 및인증, 회원 자격 유지 및 관리, 제한적 본인확인제
                    시행에 따른 본인 확인, 서비스 부정 이용 방지, 각종 고지 및
                    통지, 고충 처리, 분쟁 조정을 위한 기록 보존 등을 목적으로
                    개인정보를 처리한다.
                  </p>
                  <p>
                    ③ 회사는 회원의 신원 확인, 회원이 회사에 요구하는 사항 확인,
                    사실 조사를 위한 연락 및 통지, 처리 결과 통보 등을 목적으로
                    개인정보를 처리한다.
                  </p>
                  <p>
                    ④ 회사는 회원에게 서비스 제공, 유료 서비스 구매 및 요금
                    결제, 본인 인증, 요금 추심, 청구서 발송, 콘텐츠 제공, 맞춤
                    서비스 제공 등을 목적으로 개인정보를 처리한다.
                  </p>
                  <p>
                    ⑤ 신규 서비스 개발, 이벤트 및 광고성 정보 제공 및 참여 기회
                    제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재,
                    서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스
                    이용에 대한 통계 등을 목적으로 개인정보를 처리한다.
                  </p>
                  <p>
                    ⑥ 회원은 사이트 이용자 정보 식별을 위해 개인정보를 수집,
                    보유한다.
                  </p>
                </div>
                <div id="s2" class="section">
                  <h2>제 2 조 (개인정보 수집 항목)</h2>
                  <h6>
                    이 약관에서 사용하는 용어의 정의는 제 2 조에서 정하는 것을
                    제외하고는 관계 법령 및 기타 일반적인 상관례에 의한다.
                  </h6>
                  <p>
                    ① 회사는 이용 약관에 의거하여 회원 가입시 회원에 관한 아래
                    각 호에 해당하는 항목을 수집한다.
                  </p>
                  <p class="tab10">⑴ 전자우편 주소</p>
                  <p class="tab10">⑵ 비밀번호</p>
                  <p>
                    ② 회사는 회원의 결제 기록, 접속 IP 정보, 쿠키, 서비스 이용
                    기록, 접속 로그 항목, 불량 이용 기록, PC 사양 정보를 수집할
                    수 있다
                  </p>
                  <p>
                    ③ 회사는 회원이 모바일 서비스 이용시 단말기 정보(단말기
                    모델, 이동통신사 정보, OS 정보, 기기 고유 번호)를 수집할 수
                    있다.
                  </p>
                  <p>
                    ④ 회사는 유료 서비스 결제시 생년월일, 휴대전화번호, 성명
                    등을 추가 수집할 수 있으며, 제 2 조 2 항 및 3 항에서 정하는
                    개인정보의 제공을 회원이 동의하지 않을 경우 일부 서비스에
                    차등이 발생할 수 있다.
                  </p>
                  <p>
                    ⑤ 회사는 유료 서비스 결제시 아래 각 호의 결제 관련 정보를
                    수집할 수 있다.
                  </p>
                  <p class="tab10">
                    ⑴ 휴대폰 결제시 : 이동통신사, 휴대전화번호, 결제승인번호,
                    주민번호 앞 7자리
                  </p>
                  <p class="tab10">
                    ⑵ 신용카드 결제시 : 카드사명, 카드번호(일부) 등
                  </p>
                  <p class="tab10">⑶ 계좌이체 결제시 : 은행명, 계좌번호</p>
                  <p class="tab10">
                    ⑷ 상품권류 결제시 : 상품권 아이디 및 상품권 번호 등
                  </p>
                </div>
                <div id="s3" class="section">
                  <h2>제 3 조 (개인정보 수집 방법)</h2>
                  <p>
                    ① 회사는 홈페이지, 서면양식, 전화, 팩스, 전자우편, 게시판,
                    이벤트 응모, 서비스 이용 과정에서 발생하는 생성 정보 수집
                    툴을 통해 개인정보를 수집한다.
                  </p>
                  <p>
                    ② 회사는 제휴 회사로부터의 제공에 따라 개인정보를 수집할
                    때는 회원의 동의 하에 개인정보를 수집할 수 있다.
                  </p>
                </div>
                <div id="s4" class="section">
                  <h2>제 4 조 (개인정보 보유 기간)</h2>
                  <p>
                    ① 회사는 회원 가입시 개인정보 수집, 이용에 관한 동의일로부터
                    3년까지 개인정보를 보유, 이용한다. 개인정보는 개인정보의
                    수집 및 이용 목적이 달성되면 지체 없이 파기한다.
                  </p>
                  <p>
                    ② 회원이 약관에 의거 회원을 탈퇴하거나, 회원이 약관 제 7
                    조에 의거하여 회원 자격 정지, 상실되었을 경우 법령에 따른
                    개인정보 보유, 이용 기간 또는 정보 주체로부터 개인정보를
                    수집시에 동의받은 개인정보 보유, 이용 기간 내에서 개인정보를
                    처리, 보유한다.
                  </p>
                  <p class="tab10">
                    ⑴ 소비자의불만또는분쟁처리에관한기록 : 3년
                  </p>
                  <p class="tab10">⑵ 대금결제및재화등의공급에관한기록 : 5년</p>
                  <p class="tab10">⑶ 계약또는청약철회등에관한기록 : 5년</p>
                </div>
                <div id="s5" class="section">
                  <h2>제 5 조 (개인정보의 공유 및 제공)</h2>
                  <p class="tab10">
                    ① 회사는 이용자들의 개인정보를 제 1조에서 고지한 범위 내에서
                    이용하며, 회원의 사전 동의 없이는 동 범위를 초과하여
                    이용하거나 원칙적으로 회원의 개인정보를 외부에 공개하지
                    않는다. 다만, 아래의 경우에는 예외로 한다.
                  </p>
                  <p class="tab10">⑴ 회원이 사전에 공개에 동의한 경우</p>
                  <p class="tab10">
                    ⑵ 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진
                    절차와 방법에 따라 수사기관의 요구가 있는 경우
                  </p>
                </div>
                <div id="s6" class="section">
                  <h2>제 6 조 (개인정보의 취급 위탁)</h2>
                  <p>
                    ① 회사는 회원의 개인정보를 원칙적으로 외부 업체에 위탁하지
                    않으며, 회사가 이용자의 개인정보를 외부 업체에 위탁하는 경우
                    반드시 회원에게 통지하고 이용자의 동의를 받도록 한다.
                  </p>
                  <p>
                    ② 회사는 이용자의 다양한 서비스 제공 등의 사업적 이유로
                    이용자의 개인정보를 제휴사에 제공하거나 공유할 수 있다.
                    개인정보를 제공할 경우 제공 이전에 제휴사명, 제휴 목적, 제공
                    또는 공유되는 개인정보의 제공범위, 정보 이용 및 보관기간
                    등에 대해 사전에 동의를 구하고 회원이 동의하지 않는 경우
                    제휴사에 개인정보를 제공 또는 공유하지 않는다.
                  </p>
                  <p>
                    ③ 회사가 제 3 자에게 이용자의 개인정보를 취급할 수 있도록
                    업무를 위탁하는 경우 개인정보 취급 위탁을 받는 자, 개인정보
                    취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를
                    받아야 한다. 다만, 서비스 제공에 관한 계약 이행을 위해
                    필요하고 이용자의 편의 증진과 관련한 경우에는
                    정보통신망이용촉진및정보보호등에관한법률에서 정하고 있는
                    방법으로 개인정보 취급 방침을 통해 알림으로써 고지 절차와
                    동의 절차를 거치지 않아도 된다.
                  </p>
                </div>
                <div id="s7" class="section">
                  <h2>제 7 조 (개인정보 파기 절차 및 방법)</h2>
                  <p>
                    ① 이용자가 회원 가입 등을 위해 입력한 정보는 목적이 달성된
                    후 별도의 DB로 옮겨져 (종이의 경우 별도의 서류함) 내부 방침
                    및 기타 관련 법령에 의한 정보보호 사유에 따라(제 4 조 참조)
                    일정 기간 저장된 후 파기된다.
                  </p>
                  <p>
                    ② 개인 정보는 법률에 의한 경우가 아니고서는 보유되는 이외의
                    다른 목적으로 이용되지 않는다.
                  </p>
                  <p>
                    ③ 파기시 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
                    통하여 파기하고, 전자적 파일 형태로 저장된 개인정보는 기록을
                    재생할 수 없는 기술적 방법을 사용하여 삭제한다.
                  </p>
                </div>
                <div id="s8" class="section">
                  <h2>제 8 조 (이용자 및 법정대리인의 권리와 그 행사 방법)</h2>
                  <p>
                    ① 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은
                    당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수
                    있으며, 회사의 개인 정보의 처리에 동의하지 않는 경우 동의를
                    거부하거나 가입 계약 해지(회원 탈퇴)를 요청할 수 있다. 다만,
                    그런 경우 서비스의 일부 또는 전부 이용이 어려울 수 있다.
                  </p>
                  <p>
                    ② 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을
                    위해서는 개인정보 변경(또는 회원정보 수정 등)을, 가입 계약
                    해지를 위해서는 회원 탈퇴를 클릭하여 본인 확인 절차를 거친
                    후 직접 열람, 정정 또는 탈퇴가 가능하다.
                  </p>
                  <p>
                    ③ 이용자 본인이 회사에게 서면, 전화 또는 이메일로 연락하여
                    가입 계약 해지를 요구할 경우 회사는 지체 없이 조치한다.
                  </p>
                  <p>
                    ④ 이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정을
                    완료하기 전까지 당해 회원의 개인정보를 이용 또는 제공하지
                    않는다.
                  </p>
                </div>
                <div id="s9" class="section">
                  <h2>
                    제 9 조 (개인정보 자동 수집 장치의 설치/운영 및 거부에 관한
                    사항)
                  </h2>
                  <p>
                    ① 회사는 이용자에게 특화된 맞춤서비스를 제공하기 위해
                    이용자의 정보를 저장하고 수시로 불러오는 쿠키(cookie)를
                    사용할 수 있다. 쿠키는 웹사이트를 운영하는 데에 이용되는
                    서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며,
                    이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 한다.
                  </p>
                  <p>
                    ② 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 이용자는
                    웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나,
                    쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
                    저장을 거부할 수 있다.
                  </p>
                  <p>
                    ③ 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스는
                    이용에 어려움이 있을 수 있으며, 이로 인해 회원에게 발생하는
                    손해에 대하여 회사는 책임지지 아니한다.
                  </p>
                </div>

                <div id="s10" class="section">
                  <h2>&lt;부칙&gt;</h2>
                  <p>(시행일) 본 약관은 2023년 07월 25일부터 적용된다.</p>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div class="signUp" v-if="joinType === 'NM'">
          <button class="signUp_btn" type="button" @click="join">
            회원 가입하기
          </button>
        </div>
        <div class="btn_login_wrap sns_btns_wrap" v-if="joinType !== 'NM'">
          <button id="login_google" v-if="joinType === 'G'" @click="socialJoin">
            <img src="@/assets/img/sns/logo_google.png" />
            <span>구글 계정으로 회원가입</span>
          </button>
          <button id="login_naver" v-if="joinType === 'N'" @click="socialJoin">
            <img src="@/assets/img/sns/logo_naver.png" />
            <span>네이버 계정으로 회원가입</span>
          </button>
          <button id="login_kakao" v-if="joinType === 'K'" @click="socialJoin">
            <img src="@/assets/img/sns/logo_kakao.png" />
            <span>카카오 계정으로 회원가입</span>
          </button>
        </div>
        <div class="contour_line" v-if="joinType === 'NM'">
          <span>or</span>
        </div>
        <!-- 소셜 회원가입 -->
        <div class="user_info_item" v-if="joinType === 'NM'">
          <div class="btn_login_wrap sns_btns_wrap">
            <a
              id="login_google"
              :href="`http://${this.$store.state.domainURL}/api/auth/GOOGLE`"
            >
              <img src="@/assets/img/sns/logo_google.png" />
              <span>구글 계정으로 회원가입</span>
            </a>
            <a
              id="login_naver"
              :href="`http://${this.$store.state.domainURL}/api/auth/NAVER`"
            >
              <img src="@/assets/img/sns/logo_naver.png" />
              <span>네이버 계정으로 회원가입</span>
            </a>
            <a
              id="login_kakao"
              :href="`http://${this.$store.state.domainURL}/api/auth/KAKAO`"
            >
              <img src="@/assets/img/sns/logo_kakao.png" />
              <span>카카오 계정으로 회원가입</span>
            </a>
            <!-- <a
              id="login_google"
              href="http://ec2-3-39-190-11.ap-northeast-2.compute.amazonaws.com:9999/auth/GOOGLE"
            >
              <img src="@/assets/img/sns/logo_google.png" />
              <span>구글 계정으로 회원가입</span>
            </a>
            <a
              id="login_naver"
              href="http://ec2-3-39-190-11.ap-northeast-2.compute.amazonaws.com:9999/auth/NAVER"
            >
              <img src="@/assets/img/sns/logo_naver.png" />
              <span>네이버 계정으로 회원가입</span>
            </a>
            <a
              id="login_kakao"
              href="http:/ec2-3-39-190-11.ap-northeast-2.compute.amazonaws.com:9999/auth/KAKAO"
            >
              <img src="@/assets/img/sns/logo_kakao.png" />
              <span>카카오 계정으로 회원가입</span>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import {
  apiSignUp,
  apiSocialSignUp,
  apiDuplicateId,
  apiDuplicateNick,
} from "@/api/user";
import { getUserData } from "@/libs/auth-helper";
import { SIGNUP_REGEXP } from "@/libs/constants";
import { check } from "prettier";

export default {
  name: "signUp",
  emits: ["modalClose"],

  data() {
    return {
      serviceCheck: {
        1: false,
        2: false,
      },
      serviceView: {
        1: false,
        2: false,
      },
      model: {
        lgnId: "",
        lgnPw: "",
        mail: "",
        nickName: "",
      },
      socialModel: {},
      lgnPwChk: "",
      dupleChecked: {
        lgnId: false,
        nickName: false,
      },
      errorCheck: {
        lgnId: false,
        nickName: false,
        lgnPw: false,
      },
    };
  },
  watch: {
    serviceCheck: {
      handler(obj) {
        for (const key in obj) {
          if (obj[key]) {
            this.serviceView[key] = !obj[key];
          }
        }
      },
      deep: true,
    },
    "model.lgnId": {
      handler(value, oldValue) {
        this.dupleChecked.lgnId = false;
      },
    },
    "model.nickName": {
      handler(value, oldValue) {
        this.dupleChecked.nickName = false;
      },
    },
    joinType: {
      handler(value) {
        if (value !== "NM") {
          if (this.$route.query.lgnId) {
            (this.model.lgnId = this.$route.query.lgnId),
              (this.model.socialType = this.$route.query.socialType);
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    joinType() {
      const socialType = this.$route.query.socialType;
      if (!socialType) {
        return "NM";
      } else {
        return socialType;
      }
    },
  },
  methods: {
    serviceOpener(flag) {
      this.serviceView[flag] = !this.serviceView[flag];
    },
    userRegExp(type) {
      const regExp = SIGNUP_REGEXP[type];
      const target = this.model[type];
      return regExp.test(target);
    },
    // 중복체크
    async dupleCheck(type) {
      let res = "";
      const model = {};
      model[type] = this.model[type];
      if (!model[type]) {
        alert(this.$t(`text.${type}`) + "를 입력해주세요.");
        return;
      }
      const regExpTest = this.userRegExp(type);
      if (!regExpTest) {
        // alert(this.$t(`text.${type}`) + "를 다시 입력해주세요.");
        this.errorCheck[type] = true;
        this.$refs[type].focus();
        return;
      } else {
        this.errorCheck[type] = false;
      }
      switch (type) {
        // 아이디 중복체크
        case "lgnId": {
          res = await apiDuplicateId(model);
          break;
        }
        // 닉네임 중복체크
        case "nickName": {
          res = await apiDuplicateNick(model);
          break;
        }
      }
      if (res) {
        alert("사용 가능한 " + this.$t(`text.${type}`) + "입니다.");
        this.dupleChecked[type] = true;
      }
    },
    signUpClose() {
      this.$router.replace({ name: "main" });
      this.$emit("modalClose", false);
    },
    // 소셜 회원가입
    async socialJoin() {
      if (!this.model.nickName) {
        alert("닉네임을 입력해주세요.");
        this.$refs.nickName.focus();
        return;
      }
      if (!this.dupleChecked.nickName) {
        alert("닉네임 중복체크를 해주세요.");
        return;
      }
      for (const key in this.serviceCheck) {
        if (!this.serviceCheck[key]) {
          alert("필수 동의사항을 전부 체크해주세요.");
          return;
        }
      }
      try {
        const req = {
          lgnId: this.model.lgnId,
          socialType: this.model.socialType,
          nickName: this.model.nickName,
          mail: this.model.lgnId,
        };
        const res = await apiSocialSignUp(req);
        if (res) {
          alert("회원가입완료");
          this.$store.dispatch("settingSetSignUpCount", 1);
          this.$router.replace({ name: "main" });
          this.signUpClose();
        }
      } catch (err) {
        alert("서버와의 연결이 끊겼습니다. 새로고침 후 다시 시도해주세요.");
        // alert(err);
      }
    },
    // 회원가입
    async join() {
      const req = this.model;
      if (!this.model.lgnId) {
        alert("아이디를 입력해주세요.");
        this.$refs.lgnId.focus();
        return;
      }
      if (!this.dupleChecked.lgnId) {
        alert("아이디 중복체크를 해주세요.");
        return;
      }

      if (!this.model.nickName) {
        alert("닉네임을 입력해주세요.");
        this.$refs.nickName.focus();
        return;
      }

      if (!this.dupleChecked.nickName) {
        alert("닉네임 중복체크를 해주세요.");
        return;
      }

      if (!this.model.lgnPw) {
        alert("비밀번호를 입력해주세요!");
        this.$refs.lgnPw.focus();
        return;
      }
      if (this.model.lgnPw) {
        const regExpTest = this.userRegExp("lgnPw");
        if (!regExpTest) {
          this.errorCheck.lgnPw = true;
          this.$refs.lgnPw.focus();
          return;
        } else {
          this.errorCheck.lgnPw = false;
        }
      }

      if (this.model.lgnPw !== this.lgnPwChk) {
        alert("비밀번호가 다릅니다 다시 확인해주세요.");
        this.$refs.lgnPwChk.focus();
        return;
      }

      for (const key in this.serviceCheck) {
        if (!this.serviceCheck[key]) {
          alert("필수 동의사항을 전부 체크해주세요.");
          return;
        }
      }
      try {
        const res = await apiSignUp(req);
        if (res) {
          alert("회원가입완료");
          this.$store.dispatch("settingSetSignUpCount", 1);
          this.signUpClose();
        }
      } catch (err) {
        alert("서버와의 연결이 끊겼습니다. 새로고침 후 다시 시도해주세요.");
      }
    },
    valildationCheck() {},
  },
  created() {
    if (this.loggedIn) {
      this.signUpClose();
    }
  },
};
</script>

<style scoped>
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.signUp_wrap .signUp_contents {
  width: 566px;
  /* min-height: 1115px; */
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;

  position: relative;
  padding-bottom: 40px;
  margin: 40px 0;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 60px;
}

.logo_symbol img {
  width: 200px;
  /* height: 68px; */
}

.sign_form {
  padding: 0 50px;
  margin-top: 30px;
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
  position: relative;
}
/* .sign_form input:focus */
.input_fs {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input {
  font-size: 1.3em;
  width: 100%;
  padding: 0 17px;
  box-sizing: border-box;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.input_fs_pw {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs_pw > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs_pw > input {
  padding: 0 20px;
  font-size: 1.3em;
  width: 100%;
  box-sizing: border-box;
}

.input_fs_pw > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.overlap_check {
  display: flex;
  margin-top: 10px;
}

.warning_word {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 20px;
}

.red_line {
  width: 64px;
  height: 64px;
  font-size: 46px;
  /* background: #e74c3c; */
  border-radius: 100%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border: 3px solid #e74c3c;
}

.warning_text {
  display: inline-grid;
}

.warning_text span {
  color: #a3a1a1;
  font-size: 0.95em;
}

.warning_text span:last-child {
  color: #0c0c0c;
  font-size: 1.4em;
  font-weight: 700;
}

.adult_certification {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.adult_certification_btn {
  height: 56px;
  width: 470px;

  /* border: 1px solid #e0e0eb; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1em;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
  font-weight: 700;
  background-color: #e74646;
  box-shadow: 0px 0px 10px #bdbdbd;
}

.btn_check {
  position: relative;
  margin: 35px;
}

.btn_check input[type="checkbox"]::-ms-check {
  border: solid 1px #fff;
}

.btn_check input[type="checkbox"] {
  -webkit-appearance: none;
}

.btn_check input[type="checkbox"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 55px;
  font-weight: 500;
}

.btn_check input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 29px;
  margin-right: 10px;
  position: absolute;
  top: -4px;
  left: 17px;
  bottom: 1px;
  background-color: #ccc;
  border-radius: 7px;
}
.btn_check input[type="checkbox"]:checked + label:before {
  content: "\2713";
  text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
  font-size: 25px;
  font-weight: 900;
  color: #fff;
  background: #e62645;
  text-align: center;
  line-height: 33px;
  border-radius: 7px;
}
.btn_check button {
  text-decoration-line: underline;
  color: #ffce32;
  cursor: pointer;
}
.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

.serviceBox .service {
  border: 1px solid gray;
  margin-top: 10px;
}
.serviceBox .service dt {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: 500;
  padding: 10px;
  border-bottom: 1px solid gray;
}
.serviceBox .service dd {
  height: 150px;
  overflow-y: scroll;
  font-size: 0.8em;
  margin: 10px;
}
.contour_line {
  font-size: 0.85em;
  font-weight: 500;
  color: #d5d5d5;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}
.contour_line:before,
.contour_line:after {
  content: "";
  display: block;
  flex: 1 1 auto;
  height: 1px;
  background: #d5d5d5;
}
.sns_btns_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 470px;
  margin: 0 auto;
}
.sns_btns_wrap img {
  display: inline-block;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.sns_btns_wrap a,
.sns_btns_wrap button {
  position: relative;
  height: 55px;
  display: flex;

  overflow: hidden;
  flex: 1 0 auto;
  background-size: cover;
  align-items: center;
  box-shadow: 1px 1px 12px -5px #808080b3;
  text-align: center;
  cursor: pointer;
}
.sns_btns_wrap a > span,
.sns_btns_wrap button > span {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  font-weight: 700;
}
#login_google {
  background: #fff;
}

#login_naver {
  background: #03c75a;
  font-weight: 500;
  color: #fff;
}
#login_kakao {
  background: #fee500;
  font-weight: 500;
  color: #000;
}
.error-box_active {
  position: relative;
  top: 18px;
  font-size: 0.75rem;
  color: red;
}
</style>

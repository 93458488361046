<template>
  <header>
    <div>
      <ul class="rank_wrap fx fa-xs">
        <ul class="gnb_rank"></ul>
        <li class="gnb_logo">
          <router-link to="/"
            ><img src="@/assets/img/ballpick_logo_1.png"
          /></router-link>
        </li>
        <ul class="gnb_rank"></ul>
      </ul>
      <div id="gnb_box">
        <ul id="gnb" class="fx">
          <li
            :class="{ active: item.isActive }"
            v-for="item in menu"
            :key="item.title"
            class="menu_item"
            @mouseover="item.isActive = true"
            @mouseleave="totalActiveChk(item)"
          >
            <router-link :to="{ name: item.name }" class="navi_title">{{
              item.title
            }}</router-link>
            <ul class="gnb_sub_mono" v-if="item.isActive">
              <li
                v-for="child in item.children"
                :key="child"
                @mouseover="child.isChildren = true"
                @mouseleave="setisChildren(child)"
              >
                <router-link
                  v-if="child.params"
                  class="gnb_child"
                  :to="{
                    name: child.name,
                    params: { type: child.params },
                    query: child.query,
                  }"
                  :class="{ active: child.isActive }"
                  @mouseover="child.isActive = true"
                  @mouseleave="child.isActive = false"
                  >{{ child.text }}</router-link
                >
                <router-link
                  v-else
                  class="gnb_child"
                  :to="{ name: child.name }"
                  :class="{ active: child.isActive }"
                  @mouseover="child.isActive = true"
                  @mouseleave="totalActiveChk(child)"
                  >{{ child.text }}
                </router-link>

                <ul
                  v-if="child.children"
                  class="game_sub"
                  :style="{ display: child.isChildren ? 'block' : 'none' }"
                  @mouseover="child.isActive = true"
                  @mouseleave="child.isActive = false"
                >
                  <li
                    v-for="childItem in child.children"
                    :key="childItem"
                    :class="{ active: childItem.isActive }"
                    @mouseover="childItem.isActive = true"
                    @mouseleave="totalActiveChk(childItem)"
                  >
                    <router-link
                      v-if="childItem.params"
                      class="gnb_child"
                      :to="{
                        name: childItem.name,
                        params: { type: childItem.params },
                        query: childItem.query,
                      }"
                      :class="{ active: child.isActive }"
                      @mouseover="childItem.isActive = true"
                      @mouseleave="childItem.isActive = false"
                      >{{ childItem.text }}</router-link
                    >
                    <router-link
                      v-else
                      class="gnb_child"
                      :to="{ name: childItem.name }"
                      :class="{ active: childItem.isActive }"
                      @mouseover="childItem.isActive = true"
                      @mouseleave="childItem.isActive = false"
                      >{{ childItem.text }}</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div id="total_bg" v-if="totalActive"></div>
    </div>
  </header>
  <div id="alarm_box">
    <iframe
      :src="`${this.muteFile}`"
      allow="autoplay"
      id="audio"
      style="display: none"
    ></iframe>
  </div>
  <pick-advert :pickData="pickData" @modalOpen="modalOpen" />
</template>

<script>
import { GNB } from "@/libs/constants";
import pickAdvert from "@/components/Modal/pickAdvert.vue";
export default {
  name: "Header",
  emits: ["modalOpen", "modalClose"],
  components: { pickAdvert },
  data() {
    return {
      menu: [],
      totalActive: false,
      chatAlarmActive: false,
      pickAlarmActive: false,
    };
  },
  methods: {
    // 1:1 채팅 알람 사운드 설정
    chatAlarmOn() {
      this.chatAlarmActive = true;
      setTimeout(() => {
        this.chatAlarmActive = false;
      }, 2000);
      // 알람 허용 일때만 알람 울림
      if (this.soundActive) {
        document.querySelector("#player")?.remove();
        document
          .querySelector("#alarm_box")
          ?.insertAdjacentHTML(
            "beforeend",
            "<audio id='player' autoplay></audio>"
          );
        document
          .querySelector("#player")
          ?.insertAdjacentHTML(
            "beforeend",
            `<source src = "${this.audioFile}" type = "audio/mp3">`
          );
      }
    },
    // 픽 알람 사운드 설정
    pickAlarmOn() {
      this.pickAlarmActive = true;
      setTimeout(() => {
        this.pickAlarmActive = false;
      }, 1000);
      // 알람 허용 일때만 알람 울림
      if (this.soundActive) {
        document.querySelector("#pickplayer")?.remove();
        document
          .querySelector("#alarm_box")
          ?.insertAdjacentHTML(
            "beforeend",
            "<audio id='pickplayer' autoplay></audio>"
          );
        document
          .querySelector("#pickplayer")
          ?.insertAdjacentHTML(
            "beforeend",
            `<source src = "${this.pickAudioFile}" type = "audio/mp3">`
          );
      }
    },
    // 1:1 채팅 알람 오픈
    openModal() {
      this.modalOpen("talkAlarm");
      setTimeout(() => this.$emit("modalClose"), 5000);
    },
    totalActiveChk(item) {
      // console.log(this.totalActive)
      // console.log(item)
      if (this.totalActive) {
        item.isActive = this.totalActive;
      } else {
        item.isActive = false;
      }
    },
    setisChildren(item) {
      item.isChildren = false;
    },
  },
  computed: {
    route() {
      return this.$route;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    roomData() {
      return this.$store.state.talkRoomData;
    },
    pickData() {
      return this.$store.getters.pickAlarmData;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    soundActive() {
      return this.$store.getters.soundActive;
    },
    muteFile() {
      const audio = require("../../../src/assets/audio/mute.mp3");
      return audio;
    },
    audioFile() {
      const audio = require("../../../src/assets/audio/alarm2.mp3");
      return audio;
    },
    pickAudioFile() {
      const audio = require("../../../src/assets/audio/alarm1.mp3");
      return audio;
    },
  },
  watch: {
    totalActive(value) {
      const menu = this.menu;
      for (const child of menu) {
        // console.log(child);
        child.isActive = value;
      }
    },
    $route() {
      this.totalActive = false;
    },
    roomData: {
      handler() {
        this.openModal();
        this.chatAlarmOn();
      },
    },
    "pickData.length": {
      handler(newleng, oldleng) {
        if (newleng !== 0 && newleng > oldleng) {
          this.pickAlarmOn();
        }
      },
    },
  },
  created() {
    this.menu = GNB;
  },
};
</script>

<style scoped>
header {
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
}
header > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#gnb_box {
  width: 100%;
  background-color: #e62645;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#gnb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1400px;
  height: 50px;
}
#gnb li[class*="gnb_"] > ul {
  display: flex;
  align-items: center;
}
#gnb a {
  color: #fff;
  font-weight: 500;
}
.gnb_logo a {
  height: fit-content;
  display: flex;
}
.gnb_logo img {
  height: 120px;
  width: 100%;
  display: inline-block;
  transform: scale(0.9);
}
.gnb_logo img:hover {
  animation: 0.4s logo ease-in-out;
  animation-fill-mode: forwards;
}
.gnb_rank {
  display: flex;
  gap: 25px;
  width: 360px;
  height: inherit;
  align-items: flex-end;
  margin-bottom: 30px;
  justify-content: flex-end;
}
@keyframes logo {
  0% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(0.9);
  }
}
.gnb_menu {
  display: flex;
  min-width: 1100px;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.gnb_menu .menu_wrap {
  /* gap: 20px; */
  height: 100%;
}
.rank_wrap {
  min-width: 1200px;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  gap: 45px;
}

.rank_wrap button {
  color: #777;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
}
#btn-hamberg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;
  cursor: pointer;
}
.menu_item {
  height: 100%;
  position: relative;
  width: 10%;
  box-sizing: border-box;
}
.menu_item.active {
  background-color: #b3b3b377;
}
.menu_item .navi_title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* padding: 0 35px; */
}

.gnb_sub_mono,
.game_sub {
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 10px 0;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  box-shadow: 5px 5px 10px #b3b3b377;
}
.game_sub {
  display: none;
  top: -10px;
  left: 150%;
}

.gnb_sub_mono > li {
  position: relative;
}
.menu_wrap.all .gnb_sub_mono {
  top: 81px;
  width: unset;
}

.gnb_child {
  display: block;
  width: 106px;
  margin: 0 auto;
  text-align: center;
  padding: 3px 0;
  font-size: 14px;
  color: #454545 !important;
}

.gnb_child.active {
  color: #e62645 !important;
  transition: 0.25s ease;
  background-color: #b3b3b377;
  border-radius: 7px;
}
#total_bg {
  background: #fff;
  width: 100%;
  height: 190px;
  top: 130px;
  position: absolute;
  z-index: 2;
}
</style>

<template>
  <article id="user_notice_wrap">
    <ul class="notice_title_wrap">
      <li :class="{ active: tabType === 'C' }">
        <button
          type="button"
          class="fx dir_col center_all"
          @click="tabChange('C')"
        >
          <span class="fw_bold">공통</span>
          <alarm-counter v-if="alarm.C" :count="alarm.C"></alarm-counter>
        </button>
      </li>
      <li :class="{ active: tabType === 'S' }">
        <button
          type="button"
          class="fx dir_col center_all"
          @click="tabChange('S')"
        >
          <span class="fw_bold">구독자</span>
          <alarm-counter v-if="alarm.S" :count="alarm.S"></alarm-counter>
        </button>
      </li>
      <li :class="{ active: tabType === 'F' }">
        <button
          type="button"
          class="fx dir_col center_all"
          @click="tabChange('F')"
        >
          <span class="fw_bold">가족방</span>
          <alarm-counter v-if="alarm.F" :count="alarm.F"></alarm-counter>
        </button>
      </li>
    </ul>
    <div class="user_notice">
      <div class="notice_title">
        <span>{{ $t(`nav.alarm.${tabType}`) }}</span>
        <!-- 가족방 있는 사람 -->
        <template
          v-if="
            tabType !== 'family' ||
            (tabType === 'family' && this.loginUser.familyNm)
          "
        >
          <button type="button" @click="alarmDeleteAll">전체삭제</button>
        </template>
        <!-- 가족방 없는 사람 -->
        <template v-else>
          <div class="btn_family_wrapper">
            <button type="button" @click="modalOpen('familyCreate')">
              가족방 생성
            </button>
            <button type="button">가족방 랭킹</button>
          </div>
        </template>
      </div>
      <div class="notice_contents custom_scroll">
        <template v-if="list.length !== 0">
          <template v-for="item in list" :key="item">
            <div class="news">
              <!-- 공통 알림창 -->
              <img
                v-if="item.kindCd == 'C'"
                :src="require(`@/assets/img/alarm/${item.tgtCd}.png`)"
              />
              <!-- 구독자 알림창 -->
              <img
                v-else-if="item.kindCd == 'S'"
                :src="require(`@/assets/img/alarm/S001.png`)"
              />
              <img
                v-else-if="item.kindCd == 'F'"
                :src="require(`@/assets/img/alarm/C005.png`)"
              />
              <span v-if="!item.link">{{ item.msg }}</span>
              <router-link v-else :to="item.link">{{ item.msg }}</router-link>
            </div>
          </template>
        </template>
        <!-- 가족방 알림창  -->
        <template v-else>
          <template v-if="tabType === 'F' && !this.loginUser.familyNm">
            <div class="news notice_empty">등록된 가족방이 없습니다.</div>
          </template>
          <template v-else>
            <div class="notice_empty news">
              새로운 {{ $t(`nav.alarm.${tabType}`) }}소식이 없습니다.
            </div>
          </template>
        </template>
      </div>
    </div>
  </article>
</template>

<script>
import { apiAlarmList, apiAlarmDeleteAll } from "@/api/user";
import alarmCounter from "@/components/alarmCounter";
export default {
  name: "NotiWindow",
  components: {
    alarmCounter,
  },
  data() {
    return {
      tabType: "C",
      list: [],
      alarmLink: {
        // 쪽지알림
        Z001: { name: "" },
        // 픽적중알림
        C001: {
          name: "user_pick_game",
          params: { type: "dhpowerball" },
          query: { userIdx: "" },
        },
        // 보석수신알림
        C002: { name: "user_myPage_jewel", query: { userIdx: "" } },
        //  채팅방 개설
        C003: { name: "chat_rank_list" },
        // 게시물 댓글 등록 알림
        C004: {
          name: "boardList",
          params: { type: "free" },
          query: { page: "1", boardIdx: "" },
        },
        // 가족방 신청 결과 알림
        C005: { name: "family_main", query: { familyIdx: "" } },
        // 아이템 선물 알림
        C006: {
          name: "user_myPage_inventory_holdings",
          query: { userIdx: "" },
        },
        // 캐시 선물 알림
        C007: { name: "user_myPage_cash", query: { userIdx: "" } },
        // 보석 선물 알림
        C008: { name: "user_myPage_jewel", query: { userIdx: "" } },
        // 포인트 선물 알림
        C009: { name: "user_myPage_point", query: { userIdx: "" } },
        // 경험치 선물 알림
        C010: { name: "user_myPage_exp", query: { userIdx: "" } },
        // 캐시 충전 알림
        C011: { name: "user_myPage_cash", query: { userIdx: "" } },
        // 구독중인 회원 로그인 알림
        S001: { name: "user_main", query: { userIdx: "" } },
        // 나를 구독 추가한 회원 알림
        S002: { name: "user_main", query: { userIdx: "" } },
        // 가족방 가입 신청
        F001: { name: "family_manage_regist", query: { familyIdx: "" } },
        // 가족방 게시물 등록
        F002: {
          name: "family_board_list",
          query: { familyIdx: "", familyBoardIdx: "" },
        },
        // 가족방 게시물 댓글 등록
        F003: {
          name: "family_board_list",
          query: { familyIdx: "", familyBoardIdx: "" },
        },
        // 가족원 로그인 알림
        F004: { name: "user_main", query: { userIdx: "" } },
        // 가족방 포인트 기부
        F005: { name: "family_manage_point", query: { familyIdx: "" } },
        // 포인트 적립 알림
        P001: { name: "user_myPage_point", query: { userIdx: "" } },
      },
    };
  },
  methods: {
    // 알람 전체 삭제
    async alarmDeleteAll() {
      const model = {
        kindCd: this.tabType,
      };
      const res = await apiAlarmDeleteAll(model);
      // console.log(res)
      if (res) {
        this.$store.dispatch("getNewAlarm");
        await this.getAlarmList();
        // alert('알림 전체 삭제 완료');
      }
    },
    tabChange(type) {
      this.tabType = type;
    },
    // 알람 리스트 불러오기
    async getAlarmList(type, page) {
      // if (!this.alarm[type]) {
      //   return;
      // }
      const model = {
        page: 1,
        rowCnt: 1000,
        kindCd: type,
      };
      if (page) {
        model.page = page;
      }
      const res = await apiAlarmList(model);
      if (res) {
        const _list = this.clone(res.list);
        for (const item of _list) {
          if (this.alarmLink[item.tgtCd].name) {
            const _alarmLink = this.clone(this.alarmLink[item.tgtCd]);
            item.link = _alarmLink;
            if (item.tgtValMap) {
              if (_alarmLink.params) {
                if (item.tgtCd == "C004") {
                  _alarmLink.query.boardIdx = item.tgtValMap.boardIdx;
                  if (item.tgtValMap.typeCd) {
                    _alarmLink.params.type = this.getTypeCode(
                      "board",
                      item.tgtValMap.typeCd
                    );
                  }
                } else if (item.tgtCd == "C001") {
                  item.link.query = Object.assign(_alarmLink.query, {
                    userIdx: item.userIdx,
                  });
                  item.link.params.type = this.getTypeCode(
                    "gameType",
                    item.tgtValMap.gameType
                  );
                } else {
                  item.link.params = Object.assign(
                    _alarmLink.params,
                    item.tgtValMap
                  );
                }
              } else {
                if (item.tgtCd == "F002" || item.tgtCd == "F003") {
                  _alarmLink.query.familyIdx = this.userInfo.familyIdx;
                } else {
                  item.link.query = Object.assign(
                    _alarmLink.query,
                    item.tgtValMap
                  );
                }
              }
            } else {
              if (_alarmLink.params) {
                item.link.params = Object.assign(_alarmLink.params, {
                  userIdx: item.userIdx,
                });
              } else {
                if (item.tgtCd == "F001" || item.tgtCd == "F005") {
                  item.link.query = Object.assign(_alarmLink.query, {
                    familyIdx: this.userInfo.familyIdx,
                  });
                } else {
                  if (item.link.query) {
                    item.link.query = Object.assign(_alarmLink.query, {
                      userIdx: item.userIdx,
                    });
                  }
                }
              }
            }
          }
        }
        this.list = _list;
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    alarm() {
      return this.$store.getters.alarm;
    },
  },
  watch: {
    tabType: {
      async handler(type) {
        // console.log(type)
        await this.getAlarmList(type, 1);
        // 알람 카운트 함수 실행
        this.$store.dispatch("getNewAlarm");
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
#user_notice_wrap {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #ddd;
}
.notice_title_wrap {
  display: flex;
  width: 100%;
}

.notice_title_wrap > li {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 45px;
  border-bottom: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
}
.notice_title_wrap > li:last-child {
  border-right: 0;
}
.notice_title_wrap > li.active {
  /* background: #e74646;
  border-color: #e74646; */
  border-bottom: 3px solid #ffce32 !important;
}
.notice_title_wrap > li button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.user_notice {
  height: 300px;
}
.notice_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #dadada;
}
.notice_title span {
  font-size: 1em;
  font-weight: 700;
  padding-left: 20px;
}
.notice_title button {
  border: 1px solid rgb(206, 206, 206);
  padding: 5px;
  font-size: 0.8em;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
  color: rgb(49, 49, 49);
}
.notice_title button:hover {
  background: #e6264c;
  color: #fff;
  border-color: #e6264c;
}
.notice_contents div.news {
  border-bottom: 1px solid #dadada;
  padding: 10px;
  color: #a9a7a7;
  font-size: 0.9em;
  align-items: center;
  display: flex;
}
.notice_contents div.news a {
  color: #a9a7a7;
}
.notice_contents div.news a:hover {
  color: #000;
}
.notice_contents img {
  width: 18px;
  height: 18px;
  padding-right: 10px;
  opacity: 0.7;
}
.notice_empty {
  justify-content: center;
}

.notice_contents {
  overflow-y: scroll;
  height: calc(100% - 50px);
}
.alarm_count {
  top: 50%;
  right: 8px;
}
</style>

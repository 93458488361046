import httpClient from "@/libs/http-client";

// 팝업 & 배너
export function apiAdvert() {
  const url = "/advert/list";
  return httpClient.get(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 한줄메세지
export function apiArowList() {
  const url = "/customer/arow/list";
  return httpClient.get(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

export default {
  path: "/policy",
  name: "policy",
  redirect: { name: "policy_service" },
  component: () => import("@/views/mobile/policy"),
  meta: {
    layout: "mobile",
    userOnly: false,
  },
  children: [
    {
      path: "/policy/service",
      name: "policy_service",
      component: () => import("@/views/mobile/policy/service"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/policy/privacy",
      name: "policy_privacy",
      component: () => import("@/views/mobile/policy/privacy"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/policy/refusalmail",
      name: "policy_refusalmail",
      component: () => import("@/views/mobile/policy/refusalMail"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/policy/youthpolicy",
      name: "policy_youthpolicy",
      component: () => import("@/views/mobile/policy/youthpolicy"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/policy/point",
      name: "policy_point",
      component: () => import("@/views/mobile/policy/point"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/policy/prisonpolicy",
      name: "policy_prisonpolicy",
      component: () => import("@/views/mobile/policy/prisonpolicy"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
  ],
};

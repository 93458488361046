<template>
  <article>
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="signInClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="logo_symbol">
          <img src="@/assets/img/ballpick_logo_1.png" />
        </div>
        <div class="sign_form">
          <fieldset class="input_fs">
            <legend>아이디</legend>
            <input
              type="text"
              placeholder="아이디 입력"
              v-model="signInModel.lgnId"
              @keyup.enter="signIn"
            />
          </fieldset>
        </div>
        <div class="sign_form">
          <fieldset class="input_fs_pw">
            <legend>비밀번호</legend>
            <input
              type="password"
              placeholder="비밀번호 입력"
              v-model="signInModel.lgnPw"
              @keyup.enter="signIn"
            />
          </fieldset>
        </div>
        <div class="adult_certification m_bottom_30">
          <button class="adult_certification_btn" @click="signIn">
            로그인
          </button>
        </div>
        <div class="contour_line">
          <span>or</span>
        </div>
        <div class="btn_login_wrap sns_btns_wrap">
          <button id="login_google" @click="alertSocial"></button>
          <button id="login_naver" @click="alertSocial"></button>
          <button id="login_kakao" @click="alertSocial"></button>

          <!-- <a
            id="login_google"
            :href="`http://${this.$store.state.domainURL}/api/auth/GOOGLE`"
          ></a>
          <a
            id="login_naver"
            :href="`http://${this.$store.state.domainURL}/api/auth/NAVER`"
          ></a>
          <a
            id="login_kakao"
            :href="`http:///${this.$store.state.domainURL}/api/auth/KAKAO`"
          ></a> -->
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { apiSignIn, apiSignOut } from "@/api/user";
import { setAuthData, getUserData, removeAuthData } from "@/libs/auth-helper";

export default {
  name: "signUp",
  emits: ["modalClose"],
  data() {
    return {
      model: {
        lgnId: "",
        lgnPw: "",
        mail: "",
        nickName: "",
      },
      signInModel: {
        lgnId: "",
        lgnPw: "",
      },
      lgnPwChk: "",
    };
  },
  methods: {
    // 임시 소셜로그인 alert
    alertSocial() {
      alert("준비중입니다.");
    },
    signInClose() {
      this.$emit("modalClose", false);
    },
    saveId() {
      this._saveId = true;
    },
    async signIn() {
      for (const key in this.signInModel) {
        if (!this.signInModel[key]) {
          return alert("빈칸을 입력해주세요");
        }
      }
      const req = this.signInModel;
      try {
        const res = await apiSignIn(req);
        if (!res) return;
        setAuthData(res.data);
        const cookieUserData = getUserData();
        // console.log(cookieUserData);
        this.$store.dispatch("setUserInfo", cookieUserData);
        this.loginUser = this.$store.state.userInfo;

        this.signInClose();

        // this.$router.go();
      } catch (err) {
        // console.error(err);
      }
    },
    async goSignOut() {
      removeAuthData();
      this.$store.dispatch("setUserInfo", null);
      this.$router.go();
    },
  },
  computed: {},
  beforeRouteEnter(to, from, next) {
    if (Object.keys(to.query).length !== 0 && !!to.query.socialType) {
      next((vm) => vm.modalOpen("signUp"));
    } else {
      next();
    }
  },
};
</script>

<style scoped>
/*
input {
  border: 1px solid black;
} */
article {
  width: 90%;
  min-height: 80%;
  max-height: 80%;
  overflow-y: scroll;
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signUp_wrap .signUp_contents {
  width: 100%;
  /* height: 1115px; */
  background: #ffffff;
  box-shadow: 0px 0px 10px #ebe7e7;

  position: relative;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 50px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  padding: 30px 30px 0px 30px;
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
}

.input_fs {
  width: 100%;
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input {
  font-size: 1em;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-weight: 700;
}

.input_fs_pw {
  width: 100%;
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
}

.input_fs_pw > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs_pw > input {
  font-size: 1em;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}

.input_fs_pw > input::placeholder {
  color: rgb(197, 197, 197);
  font-weight: 700;
}

.overlap_check {
  display: flex;
  margin-top: 10px;
}

.warning_word {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 20px;
}

.red_line {
  width: 64px;
  height: 64px;
  font-size: 46px;
  /* background: #e74c3c; */
  border-radius: 100%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border: 3px solid #e74c3c;
}

.warning_text {
  display: inline-grid;
}

.warning_text span {
  color: #a3a1a1;
  font-size: 0.95em;
}

.warning_text span:last-child {
  color: #0c0c0c;
  font-size: 1.4em;
  font-weight: 700;
}

.adult_certification {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
}

.adult_certification_btn {
  height: 56px;
  width: 84%;
  /* border: 1px solid #e0e0eb; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.9em;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
  font-weight: 700;
  background-color: #e62645;
  box-shadow: 0px 0px 10px #bdbdbd;
}
.adult_certification_btn.signup {
  background-color: #7c7c7c;
}

.btn_check {
  position: relative;
  margin: 35px;
}

.btn_check input[type="checkbox"]::-ms-check {
  border: solid 1px #fff;
}

.btn_check input[type="checkbox"] {
  -webkit-appearance: none;
}

.btn_check input[type="checkbox"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 76px;
  margin-right: 0px;
  font-size: 21px;
  color: #6a68a1;
  font-weight: 500;
}

.btn_check input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 36px;
  height: 35px;
  margin-right: 10px;
  position: absolute;
  top: -4px;
  left: 17px;
  bottom: 1px;
  background-color: #ccc;
  border-radius: 7px;
}
.btn_check input[type="checkbox"]:checked + label:before {
  content: "\2713";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 38px;
  font-weight: 800;
  color: #fff;
  background: #e62645;
  text-align: center;
  line-height: 43px;
  border-radius: 7px;
}
.btn_check a {
  text-decoration-line: underline;
  color: #ffce32;
}
.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

.m_bottom_30 {
  margin-bottom: 30px;
}

.contour_line {
  font-size: 0.85em;
  font-weight: 500;
  color: #d5d5d5;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}
.contour_line:before,
.contour_line:after {
  content: "";
  display: block;
  flex: 1 1 auto;
  height: 1px;
  background: #d5d5d5;
}
.sns_btns_wrap {
  justify-content: space-around;
  font-size: 13px;
  margin: 20px 0 30px;
}
.sns_btns_wrap img {
  display: inline-block;
}
.sns_btns_wrap {
  display: flex;
}
.sns_btns_wrap a,
.sns_btns_wrap button {
  width: 80px;
  height: 40px;
  background-size: cover;
  box-shadow: 3px 4px 12px -5px #80808094;
}

#login_google {
  background-image: url(../../assets/img/sns/btn_google.png);
}
#login_naver {
  background-image: url(../../assets/img/sns/btn_naver.png);
}
#login_kakao {
  background-image: url(../../assets/img/sns/btn_kakao.png);
}
</style>

<template>
  <div class="main_item_wrap board_fix_item">
    <div>
      <div class="tab_content_wrap">
        <ul class="tab_wrap pick" id="main_fixitem_ul">
          <span
            class="test_slide"
            :style="{
              width: btnWidth == 0 ? '20%' : +btnWidth + 'px',
              left: btnLeft + 1 + 'px',
            }"
          ></span>
          <li :class="{ active: gameType.indexOf('dh') > -1 }">
            <button
              type="button"
              class="btn_tab"
              @click="[gameTabChange('dhpowerball', 0), setSubType('')]"
            >
              동행파워볼
            </button>
          </li>
          <li :class="{ active: gameType.indexOf('eos') > -1 }">
            <button
              type="button"
              class="btn_tab"
              @click="[gameTabChange('eospowerball', 1), setSubType('5')]"
            >
              EOS파워볼
            </button>
          </li>
          <li :class="{ active: gameType.indexOf('coin') > -1 }">
            <button
              type="button"
              class="btn_tab"
              @click="[gameTabChange('coinpowerball', 2), setSubType('5')]"
            >
              코인파워볼
            </button>
          </li>
          <li :class="{ active: gameType.indexOf('meta') > -1 }">
            <button
              type="button"
              class="btn_tab"
              @click="[gameTabChange('metapowerball', 3), setSubType('5')]"
            >
              메타파워볼
            </button>
          </li>

          <li :class="{ active: gameType.indexOf('pbg') > -1 }">
            <button
              type="button"
              class="btn_tab"
              @click="[gameTabChange('pbgpowerball', 4), setSubType('')]"
            >
              PBG파워볼
            </button>
          </li>
        </ul>
        <ul class="sub_type">
          <template v-if="isSubType">
            <li
              v-for="type in subGameType[
                gameType.indexOf('coin') > -1
                  ? 'coinpowerball'
                  : gameType.indexOf('meta') > -1
                  ? 'metapowerball'
                  : gameType.indexOf('dh') > -1
                  ? 'dhpowerball'
                  : gameType
              ]"
              :key="type"
            >
              <button
                :class="{
                  active: `${type.name}${type.type}` == `${gameType}${subType}`,
                }"
                @click="
                  [gameTabChange(type.name, type.slide), setSubType(type.type)]
                "
              >
                {{ type.title }}
              </button>
            </li>
          </template>
        </ul>
        <article class="fx border_box">
          <div class="table_wrap rank">
            <table>
              <template v-if="roomList.length !== 0">
                <tr
                  class="active"
                  v-for="(item, idx) in roomList"
                  :key="item.roomIdx"
                  @click="chatRoomEnter(item)"
                >
                  <template v-if="Object.keys(item).length !== 0">
                    <td class="br_1 spetific fx vt_c">
                      <img
                        v-if="idx < 3"
                        :src="require(`@/assets/img/trophy_${idx + 1}.png`)"
                      />
                      <span v-else class="best_rank">{{ idx + 1 }}</span>
                      <lvImg :level="item.userLevelCd" />
                    </td>
                    <td>
                      <span>{{ item.userNm }}</span>
                      <!-- <span
                        >{{ item.totalRecord }}전 {{ item.mainTotWin }}승
                        {{ item.mainTotLose }}패</span
                      > -->
                      <span>
                        [ 연승 {{ item.mainConWin }} / 연패
                        {{ item.mainConLose }} ]</span
                      >
                    </td>
                  </template>
                  <template v-else>
                    <td colspan="3"></td>
                  </template>
                </tr>
              </template>
              <template v-else>
                <tr style="height: 200px">
                  <td class="txt_p_c" colspan="3">개설된 채팅방이 없습니다.</td>
                </tr>
              </template>
            </table>
          </div>
          <div class="contour"></div>

          <div class="table_wrap pick" v-if="gameType === 'eospowerball'">
            <template v-if="subType === '5'">
              <div class="item_container">
                <table class="result_table">
                  <template v-if="EOSpowerball5LiveList.length !== 0">
                    <tr v-for="item in EOSpowerball5LiveList" :key="item.round">
                      <td>
                        <span>
                          {{ $t(`games.${gameType}${subType}`) }}
                          {{ item.round }}회차</span
                        >
                      </td>
                      <td>
                        <div class="fx ball_container">
                          <div
                            class="ball small"
                            :class="item.pwOe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.pwUnover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwUnover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.oe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.unover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="
                              item.size === 1
                                ? 'green'
                                : item.size === 2
                                ? 'blue'
                                : 'red'
                            "
                          >
                            <span>{{
                              item.size === 1
                                ? "소"
                                : item.size === 2
                                ? "중"
                                : "대"
                            }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="table_wrap pick">
                <div class="item_container">
                  <table class="result_table">
                    <template v-if="EOSpowerball3LiveList.length !== 0">
                      <tr
                        v-for="item in EOSpowerball3LiveList"
                        :key="item.round"
                      >
                        <td>
                          <span>
                            {{ $t(`games.${gameType}${subType}`) }}
                            {{ item.round }}회차</span
                          >
                        </td>
                        <td>
                          <div class="fx ball_container">
                            <div
                              class="ball small"
                              :class="item.pwOe === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.pwUnover === 1 ? 'blue' : 'red'"
                            >
                              <span>{{
                                item.pwUnover === 1 ? "언" : "오"
                              }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.oe === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.unover === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="
                                item.size === 1
                                  ? 'green'
                                  : item.size === 2
                                  ? 'blue'
                                  : 'red'
                              "
                            >
                              <span>{{
                                item.size === 1
                                  ? "소"
                                  : item.size === 2
                                  ? "중"
                                  : "대"
                              }}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </template>
          </div>

          <div class="table_wrap pick" v-else-if="gameType === 'coinpowerball'">
            <template v-if="subType === '5'">
              <div class="item_container">
                <table class="result_table">
                  <template v-if="coinpowerball5LiveList.length !== 0">
                    <tr
                      v-for="item in coinpowerball5LiveList"
                      :key="item.round"
                    >
                      <td>
                        <span>
                          {{ $t(`games.${gameType}${subType}`) }}
                          {{ item.round }}회차</span
                        >
                      </td>
                      <td>
                        <div class="fx ball_container">
                          <div
                            class="ball small"
                            :class="item.pwOe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.pwUnover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwUnover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.oe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.unover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="
                              item.size === 1
                                ? 'green'
                                : item.size === 2
                                ? 'blue'
                                : 'red'
                            "
                          >
                            <span>{{
                              item.size === 1
                                ? "소"
                                : item.size === 2
                                ? "중"
                                : "대"
                            }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="table_wrap pick">
                <div class="item_container">
                  <table class="result_table">
                    <template v-if="coinpowerball3LiveList.length !== 0">
                      <tr
                        v-for="item in coinpowerball3LiveList"
                        :key="item.round"
                      >
                        <td>
                          <span>
                            {{ $t(`games.${gameType}${subType}`) }}
                            {{ item.round }}회차</span
                          >
                        </td>
                        <td>
                          <div class="fx ball_container">
                            <div
                              class="ball small"
                              :class="item.pwOe === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.pwUnover === 1 ? 'blue' : 'red'"
                            >
                              <span>{{
                                item.pwUnover === 1 ? "언" : "오"
                              }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.oe === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.unover === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="
                                item.size === 1
                                  ? 'green'
                                  : item.size === 2
                                  ? 'blue'
                                  : 'red'
                              "
                            >
                              <span>{{
                                item.size === 1
                                  ? "소"
                                  : item.size === 2
                                  ? "중"
                                  : "대"
                              }}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </template>
          </div>
          <div class="table_wrap pick" v-else-if="gameType === 'coinladder'">
            <template v-if="subType === '5'">
              <div class="item_container">
                <table class="result_table">
                  <template v-if="coinladder5LiveList.length !== 0">
                    <tr v-for="item in coinladder5LiveList" :key="item.round">
                      <td>
                        <span>
                          {{ $t(`games.${gameType}${subType}`) }}
                          {{ item.round }}회차</span
                        >
                      </td>
                      <td>
                        <div class="fx ball_container">
                          <div
                            class="ball small"
                            :class="item.lr === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.lr === 1 ? "좌" : "우" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.line === 3 ? 'blue' : 'red'"
                          >
                            <span>{{ item.line === 3 ? "3" : "4" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.oe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="table_wrap pick">
                <div class="item_container">
                  <table class="result_table">
                    <template v-if="coinladder3LiveList.length !== 0">
                      <tr v-for="item in coinladder3LiveList" :key="item.round">
                        <td>
                          <span>
                            {{ $t(`games.${gameType}${subType}`) }}
                            {{ item.round }}회차</span
                          >
                        </td>
                        <td>
                          <div class="fx ball_container">
                            <div
                              class="ball small"
                              :class="item.lr === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.lr === 1 ? "좌" : "우" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.line === 3 ? 'blue' : 'red'"
                            >
                              <span>{{ item.line === 3 ? "3" : "4" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.oe === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </template>
          </div>

          <div class="table_wrap pick" v-else-if="gameType === 'metapowerball'">
            <template v-if="subType === '5'">
              <div class="item_container">
                <table class="result_table">
                  <template v-if="metapowerball5LiveList.length !== 0">
                    <tr
                      v-for="item in metapowerball5LiveList"
                      :key="item.round"
                    >
                      <td>
                        <span>
                          {{ $t(`games.${gameType}${subType}`) }}
                          {{ item.round }}회차</span
                        >
                      </td>
                      <td>
                        <div class="fx ball_container">
                          <div
                            class="ball small"
                            :class="item.pwOe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.pwUnover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwUnover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.oe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.unover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="
                              item.size === 1
                                ? 'green'
                                : item.size === 2
                                ? 'blue'
                                : 'red'
                            "
                          >
                            <span>{{
                              item.size === 1
                                ? "소"
                                : item.size === 2
                                ? "중"
                                : "대"
                            }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="item_container">
                <table class="result_table">
                  <template v-if="metapowerball3LiveList.length !== 0">
                    <tr
                      v-for="item in metapowerball3LiveList"
                      :key="item.round"
                    >
                      <td>
                        <span>
                          {{ $t(`games.${gameType}${subType}`) }}
                          {{ item.round }}회차</span
                        >
                      </td>
                      <td>
                        <div class="fx ball_container">
                          <div
                            class="ball small"
                            :class="item.pwOe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.pwUnover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.pwUnover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.oe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.unover === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="
                              item.size === 1
                                ? 'green'
                                : item.size === 2
                                ? 'blue'
                                : 'red'
                            "
                          >
                            <span>{{
                              item.size === 1
                                ? "소"
                                : item.size === 2
                                ? "중"
                                : "대"
                            }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </template>
          </div>

          <div class="table_wrap pick" v-else-if="gameType === 'metaladder'">
            <template v-if="subType === '5'">
              <div class="item_container">
                <table class="result_table">
                  <template v-if="metaladder5LiveList.length !== 0">
                    <tr v-for="item in metaladder5LiveList" :key="item.round">
                      <td>
                        <span>
                          {{ $t(`games.${gameType}${subType}`) }}
                          {{ item.round }}회차</span
                        >
                      </td>
                      <td>
                        <div class="fx ball_container">
                          <div
                            class="ball small"
                            :class="item.lr === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.lr === 1 ? "좌" : "우" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.line === 3 ? 'blue' : 'red'"
                          >
                            <span>{{ item.line === 3 ? "3" : "4" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.oe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="table_wrap pick">
                <div class="item_container">
                  <table class="result_table">
                    <template v-if="metaladder3LiveList.length !== 0">
                      <tr v-for="item in metaladder3LiveList" :key="item.round">
                        <td>
                          <span>
                            {{ $t(`games.${gameType}${subType}`) }}
                            {{ item.round }}회차</span
                          >
                        </td>
                        <td>
                          <div class="fx ball_container">
                            <div
                              class="ball small"
                              :class="item.lr === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.lr === 1 ? "좌" : "우" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.line === 3 ? 'blue' : 'red'"
                            >
                              <span>{{ item.line === 3 ? "3" : "4" }}</span>
                            </div>
                            <div
                              class="ball small"
                              :class="item.oe === 1 ? 'blue' : 'red'"
                            >
                              <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </template>
          </div>

          <div class="table_wrap pick" v-if="gameType === 'dhpowerball'">
            <div class="item_container">
              <table class="result_table">
                <template v-if="dhpowerballLiveList.length !== 0">
                  <tr v-for="item in dhpowerballLiveList" :key="item.round">
                    <td>
                      <span>
                        {{ $t(`games.${gameType}${subType}`) }}
                        {{ item.round }}회차</span
                      >
                    </td>
                    <td>
                      <div class="fx ball_container">
                        <div
                          class="ball small"
                          :class="item.pwOe === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="item.pwUnover === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.pwUnover === 1 ? "언" : "오" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="item.oe === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="item.unover === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="
                            item.size === 1
                              ? 'green'
                              : item.size === 2
                              ? 'blue'
                              : 'red'
                          "
                        >
                          <span>{{
                            item.size === 1
                              ? "소"
                              : item.size === 2
                              ? "중"
                              : "대"
                          }}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
          <div class="table_wrap pick" v-else-if="gameType === 'dhladder'">
            <div class="table_wrap pick">
              <div class="item_container">
                <table class="result_table">
                  <template v-if="dhladderLiveList.length !== 0">
                    <tr v-for="item in dhladderLiveList" :key="item.round">
                      <td>
                        <span>
                          {{ $t(`games.${gameType}${subType}`) }}
                          {{ item.round }}회차</span
                        >
                      </td>
                      <td>
                        <div class="fx ball_container">
                          <div
                            class="ball small"
                            :class="item.lr === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.lr === 1 ? "좌" : "우" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.line === 3 ? 'blue' : 'red'"
                          >
                            <span>{{ item.line === 3 ? "3" : "4" }}</span>
                          </div>
                          <div
                            class="ball small"
                            :class="item.oe === 1 ? 'blue' : 'red'"
                          >
                            <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
          <div class="table_wrap pick" v-if="gameType === 'pbgpowerball'">
            <div class="item_container">
              <table class="result_table">
                <template v-if="pbgpowerballLiveList.length !== 0">
                  <tr v-for="item in pbgpowerballLiveList" :key="item.round">
                    <td>
                      <span>
                        {{ $t(`games.${gameType}${subType}`) }}
                        {{ item.round }}회차</span
                      >
                    </td>
                    <td>
                      <div class="fx ball_container">
                        <div
                          class="ball small"
                          :class="item.pwOe === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.pwOe === 1 ? "홀" : "짝" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="item.pwUnover === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.pwUnover === 1 ? "언" : "오" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="item.oe === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.oe === 1 ? "홀" : "짝" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="item.unover === 1 ? 'blue' : 'red'"
                        >
                          <span>{{ item.unover === 1 ? "언" : "오" }}</span>
                        </div>
                        <div
                          class="ball small"
                          :class="
                            item.size === 1
                              ? 'green'
                              : item.size === 2
                              ? 'blue'
                              : 'red'
                          "
                        >
                          <span>{{
                            item.size === 1
                              ? "소"
                              : item.size === 2
                              ? "중"
                              : "대"
                          }}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div>
      <ul class="tab_wrap pick" id="main_fix_board_type">
        <span
          class="test_slide"
          :style="{
            width: boardTypeWidth == 0 ? '25%' : +boardTypeWidth + 'px',
            left: boardTypeLeft + 1 + 'px',
          }"
        ></span>
        <li :class="{ active: boardType === 'free' }">
          <button type="button" class="btn_tab" @click="tabChange('free', 0)">
            자유
          </button>
        </li>
        <li :class="{ active: boardType === 'enjoy' }">
          <button type="button" class="btn_tab" @click="tabChange('enjoy', 1)">
            유머
          </button>
        </li>
        <li :class="{ active: boardType === 'photo' }">
          <button type="button" class="btn_tab" @click="tabChange('photo', 2)">
            포토
          </button>
        </li>
        <li :class="{ active: boardType === 'analysis' }">
          <button
            type="button"
            class="btn_tab"
            @click="tabChange('analysis', 3)"
          >
            분석
          </button>
        </li>
      </ul>
      <div class="tab_content_wrap">
        <div class="table_wrap board border_box">
          <table>
            <template v-if="list.length > 0">
              <template v-for="item in list" :key="item.boardIdx">
                <tr v-if="item.blind != 0">
                  <td>
                    <router-link
                      :to="{
                        name: 'boardList',
                        params: { type: getTypeCode('board', item.typeCd) },
                        query: { boardIdx: item.boardIdx },
                      }"
                    >
                      <span>{{ item.title }}</span>
                      <span class="fc_red"> [{{ item.replyCnt }}]</span>
                    </router-link>
                  </td>
                </tr>
                <tr v-else>
                  <td class="fc_disabled">
                    운영 정책 위반으로 블라인드 처리되었습니다.
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr style="height: 200px">
                <td class="txt_p_c just_center" colspan="2">
                  게시글이 없습니다.
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiRoomList, apiRoomImmigration } from "@/api/chat";
import { apiBoardList } from "@/api/board";

export default {
  name: "FixedItem",
  emits: ["modalOpen"],
  data() {
    return {
      btnWidth: 0,
      btnLeft: 0,
      boardTypeWidth: 0,
      boardTypeLeft: 0,
      boardType: "free",
      gameType: "dhpowerball",
      reqModel: {
        page: 1,
        rowCnt: 5,
        typeCd: "01",
      },
      isSubType: true,
      subType: "",
      subGameType: {
        dhpowerball: [
          {
            title: "동행파워볼",
            name: "dhpowerball",
            type: "",
            slide: "0",
          },
          {
            title: "동행사다리",
            name: "dhladder",
            type: "",
            slide: "0",
          },
        ],
        eospowerball: [
          {
            title: "EOS파워볼 5분",
            name: "eospowerball",
            type: "5",
            slide: "1",
          },
          {
            title: "EOS파워볼 3분",
            name: "eospowerball",
            type: "3",
            slide: "1",
          },
        ],
        coinpowerball: [
          {
            title: "코인파워볼 5분",
            name: "coinpowerball",
            type: "5",
            slide: "2",
          },
          {
            title: "코인파워볼 3분",
            name: "coinpowerball",
            slide: "2",
            type: "3",
          },
          {
            title: "코인사다리 5분",
            name: "coinladder",
            type: "5",
            slide: "2",
          },
          {
            title: "코인사다리 3분",
            name: "coinladder",
            type: "3",
            slide: "2",
          },
        ],
        metapowerball: [
          {
            title: "메타코인볼 5분",
            name: "metapowerball",
            type: "5",
            slide: "3",
          },
          {
            title: "메타코인볼 3분",
            name: "metapowerball",
            slide: "3",

            type: "3",
          },
          {
            title: "메타사다리 5분",
            name: "metaladder",
            slide: "3",
            type: "5",
          },
          {
            title: "메타사다리 3분",
            name: "metaladder",
            slide: "3",
            type: "3",
          },
        ],
      },
      dupleList: {
        lgnId: false,
        mail: false,
        nickName: false,
      },
      list: [],
      roomList: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    myRoom() {
      return this.$store.getters.myRoom;
    },
    liveList() {
      let list = this.clone(this.$store.getters.liveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    EOSpowerball5LiveList() {
      let list = this.clone(this.$store.getters.EOSpowerball5LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }

      return list;
    },
    EOSpowerball3LiveList() {
      let list = this.clone(this.$store.getters.EOSpowerball3LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }

      return list;
    },
    coinpowerball5LiveList() {
      let list = this.clone(this.$store.getters.coinpowerball5LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    coinpowerball3LiveList() {
      let list = this.clone(this.$store.getters.coinpowerball3LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    metapowerball5LiveList() {
      let list = this.clone(this.$store.getters.metapowerball5LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    metapowerball3LiveList() {
      let list = this.clone(this.$store.getters.metapowerball3LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    coinladder5LiveList() {
      let list = this.clone(this.$store.getters.coinladder5LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    coinladder3LiveList() {
      let list = this.clone(this.$store.getters.coinladder3LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    metaladder5LiveList() {
      let list = this.clone(this.$store.getters.metaladder5LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    metaladder3LiveList() {
      let list = this.clone(this.$store.getters.metaladder3LiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    dhpowerballLiveList() {
      let list = this.clone(this.$store.getters.dhpowerballLiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    dhladderLiveList() {
      let list = this.clone(this.$store.getters.dhladderLiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
    pbgpowerballLiveList() {
      let list = this.clone(this.$store.getters.pbgpowerballLiveList);
      const length = list.length;
      if (length > 5) {
        list = list.slice(0, 5);
      } else {
        const diff = 5 - length;
        for (let i = 0; i < diff; i++) {
          list.push({});
        }
      }
      return list;
    },
  },
  methods: {
    // 탭 이동
    gameTabChange(tab, li) {
      this.gameType = tab;
      const selector = document.querySelector("#main_fixitem_ul  button");
      this.btnWidth = selector.offsetWidth;
      this.btnLeft = this.btnWidth * li;
      this.isSubType = true;
    },

    setSubType(subType) {
      this.subType = subType;
    },
    // 채팅방 입장
    async chatRoomEnter(item) {
      if (!this.loggedIn) {
        return alert("로그인 후 이용해주세요");
      }
      let check;
      // item.isSecretRoom 1 비밀방
      // item.isSecretRoom 2 공개방
      // 본인 방이면 확인 없이 바로 입장
      if (this.myRoom.roomIdx == item.roomIdx) {
        return window.open(
          `/chat/room/${item.roomIdx}?gameType=${item.gameType}`,
          "chat",
          "width=1400,height=800,location=no"
        );
      }
      // 비밀방일때, 패스워드 모달 오픈
      if (item.isSecretRoom == 1) {
        this.modalOpen("roomPassword");
        this.$store.dispatch("settingsetCheckRoomInfo", item);
        return;
      }

      // 일반 방일때 권한 확인
      await this.$store.dispatch("roomImmigration", item).then((res) => {
        check = res;
      });
      if (check) {
        window.open(
          `/chat/room/${item.roomIdx}?gameType=${item.gameType}`,
          "chat",
          "width=1400,height=800,location=no"
        );
      }
    },
    // 게시판 목록 불러오기
    async getBoardList() {
      const req = this.reqModel;
      const res = await apiBoardList(req);
      const list = res.list;
      if (list) {
        for (const item of list) {
          item.boardType = this.getTypeCode("board", item.typeCd);
        }
        this.list = list;
      }
    },
    async tabChange(type, li) {
      this.boardType = type;
      this.reqModel.typeCd = this.getType("board", type);
      await this.getBoardList();
      const selector = document.querySelector("#main_fix_board_type  button");
      this.boardTypeWidth = selector.offsetWidth;
      this.boardTypeLeft = this.boardTypeWidth * li;
    },
    async getRoomList() {
      let type = `${this.gameType}${this.subType}`;
      let room =
        type == "eospowerball5"
          ? "EOS5"
          : type == "eospowerball3"
          ? "EOS3"
          : type == "coinpowerball5"
          ? "CB5"
          : type == "coinpowerball3"
          ? "CB3"
          : type == "metapowerball5"
          ? "MB5"
          : type == "metapowerball3"
          ? "MB3"
          : type == "coinladder5"
          ? "CL5"
          : type == "coinladder3"
          ? "CL3"
          : type == "metaladder5"
          ? "ML5"
          : type == "metaladder3"
          ? "ML3"
          : type == "dhpowerball"
          ? "DH"
          : type == "dhladder"
          ? "DL"
          : type == "pbgpowerball"
          ? "PBG"
          : "";
      const model = {
        page: 1,
        rowCnt: 10,
        gameType: room,
      };
      const res = await apiRoomList(model);
      if (res) {
        this.roomList = res.list;
        for (const item of this.roomList) {
          const totalRecord = item.mainTotLose + item.mainTotWin;
          item.totalRecord = totalRecord;
          if (totalRecord <= 0) {
            item.winRate = 0;
          } else {
            item.winRate = (item.mainTotWin / totalRecord).toFixed(2) * 100;
          }
          //conWin=1, conLose=0
          item.wlStatusType = !item.mainConLose ? 1 : 0;
          item.wlStatus = !item.mainConLose
            ? item.mainConWin
            : item.mainConLose;
        }
        const length = this.roomList.length;
        if (length > 5) {
          this.roomList = this.roomList.slice(0, 5);
        }
      }
    },
    // 룸 권한 체크
    async roomImmigration(roomIdx, roomsPw) {
      const req = {
        roomIdx: roomIdx,
        roomsPw: roomsPw ? roomsPw : "",
      };
      const res = await apiRoomImmigration(req).catch(() => {
        alert("존재하지 않는 방입니다.");
        this.getRoomList();
      });
      if (res) {
        return true;
      } else {
        return false;
      }
    },
    // 방 입장하기
    async roomEnter(item, type) {
      const roomCd = item.roomCd;
      const roomIdx = item.roomIdx;
      const roomsPw = this.roomsPw;
      const secret = item.isSecretRoom;
      const gameType = item.gameType;
      let trigger = true;
      if (secret === 1) {
        this.passCheck = true;
        this.enterRoomIdx = roomIdx;
        this.enterRoomCd = roomCd;
        return;
      } else {
        trigger = await this.roomImmigration(roomIdx, roomsPw);
      }
      if (trigger) {
        this.$router.push({
          name: "chatRoom",
          params: { roomIdx: roomIdx, roomCd: roomCd },
          query: { gameType: gameType },
        });
      } else {
        alert("방 입장을 거부당했습니다.");
      }
    },
  },
  watch: {
    gameType: {
      async handler() {
        await this.getRoomList();
      },
      immediate: true,
    },
    subType: {
      async handler(type) {
        await this.getRoomList();
      },
    },
    boardType(type) {
      this.reqModel.boardType = type;
    },
  },
  async created() {
    await this.getBoardList();
    this.tabChange("free", 0);
    // this.gameTabChange("dhpowerball", 0);
  },
};
</script>

<style scoped>
.tab_wrap.board > li {
  flex: 0 0 25%;
}

.board_fix_item {
  display: flex;
  gap: 20px;
  min-height: 305px;
  box-sizing: border-box;
}
.board_fix_item > div {
  width: 70%;
}
.board_fix_item > div:last-child {
  width: 50%;
  max-width: 430px;
}

.tab_content_wrap {
  display: flex;
  flex-direction: column;
  /* background: #fff; */

  /* box-shadow: 0px 0px 10px #ebe7e7; */
  justify-content: space-between;
}

.table_wrap {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: 0.8rem;
  min-height: 205px;
  font-weight: 500;
}
.table_wrap tr {
  height: 40px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.table_wrap.board {
  width: 100%;
  padding: 15px;
  min-height: 230px;
}

.table_wrap.board table {
  width: 100%;
  table-layout: fixed;
  height: fit-content;
}
.table_wrap.rank {
  flex: 1 0 auto;
  width: 50%;
  font-size: 0.8rem;
}
.table_wrap.rank tr.active {
  user-select: none;
}
.table_wrap.rank tr.active:hover {
  color: gray;
  cursor: pointer;
}
.table_wrap.pick {
  width: 50%;
  height: fit-content;
}
.table_wrap.board td {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.table_wrap.board td a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table_wrap.board td a > span:first-child {
  padding-right: 5px;
}
.table_wrap.board td:first-child {
  width: 100%;
}

.table_wrap td {
  padding: 5px 0;
  letter-spacing: -1px;
}

.table_wrap td:last-child {
  padding-right: 0;
}

.contour {
  background: #eaeaf5;
  width: 2px;
  margin: 15px 0;
}

.br_1.spetific img {
  width: 40px;
  vertical-align: middle;
}

.br_1.spetific img:nth-child(1) {
  width: 25px;
}

.result_table td {
  padding: 9px 0;
  padding-right: 4px;
  white-space: nowrap;
}

a:hover {
  color: #000;
}
.ball.small {
  width: 22px !important;
  height: 21px !important;
  line-height: 17px !important;
}
.best_rank {
  font-size: 1.5em;
  font-weight: 900;
  display: inline-block;
  color: gray;
  text-align: center;
  width: 25px;
}

.border_box {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

/* 볼 순서대로 올라오는 효과 */
.ball_container > div {
  position: relative;
  animation: fadeInUp 1s;
}
.ball_container > div:nth-child(2) {
  animation-delay: 0.1s;
}
.ball_container > div:nth-child(3) {
  animation-delay: 0.2s;
}
.ball_container > div:nth-child(4) {
  animation-delay: 0.3s;
}
.ball_container > div:nth-child(5) {
  animation-delay: 0.4s;
}

/* 페이드인업 효과 */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    taransform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.test_slide {
  position: absolute;
  width: 0;
  height: 3px;
  background: #e6264c;
  transition: left 0.3s ease;
}
.sub_type {
  display: flex;
  background-color: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 10px 10px 0 10px;
  font-size: 0.7rem;
  min-height: 15px;
}
.sub_type li {
  padding: 0 10px;
}
.sub_type button {
  color: #afb0c0;
}
.sub_type button:hover,
.sub_type button.active {
  color: #e6264c;
  cursor: pointer;
  font-weight: 700;
}
</style>

<template>
  <section id="side_right">
    <!-- before login -->
    <article id="user_info_wrap" class="unlogged" v-if="!loggedIn">
      <div class="user_info_item">
        <input
          type="text"
          placeholder="아이디 입력"
          v-model="signInModel.lgnId"
          @keyup.enter="signIn"
        />
        <input
          type="password"
          placeholder="비밀번호 입력"
          v-model="signInModel.lgnPw"
          @keyup.enter="signIn"
        />
      </div>
      <div class="user_info_item no_margin">
        <div class="btn_login_wrap">
          <button
            type="button"
            class="btn_login signUp"
            @click="modalOpen('signUp')"
          >
            회원가입
          </button>
          <button type="button" class="btn_login signIn" @click="signIn">
            로그인
          </button>
        </div>
      </div>
      <!-- <div class="contour_line">
        <span>or</span>
      </div> -->
      <div class="user_info_item">
        <!-- 소셜 로그인 -->
        <div class="btn_login_wrap sns_btns_wrap">
          <button id="login_google" @click="alertSocial"></button>
          <button id="login_naver" @click="alertSocial"></button>
          <button id="login_kakao" @click="alertSocial"></button>
          <!-- <a
            id="login_google"
            :href="`http://${this.$store.state.domainURL}/api/auth/GOOGLE`"
          ></a>
          <a
            id="login_naver"
            :href="`http:///${this.$store.state.domainURL}/api/auth/NAVER`"
          ></a>
          <a
            id="login_kakao"
            :href="`http:///${this.$store.state.domainURL}/api/auth/KAKAO`"
          ></a> -->
        </div>
      </div>
    </article>
    <!-- after login -->
    <article id="user_info_wrap" class="loggedIn" v-if="loggedIn">
      <div class="profile">
        <profileImg :img="userInfo.profileImgSrc" />
        <div class="profile_text_wrap">
          <div class="level_icon">
            <lvImg :level="userInfo.levelCd" />
          </div>
          <div>
            <p class="idBox">
              <router-link
                :to="{ name: 'user', query: { userIdx: userInfo.userIdx } }"
                >{{ userInfo.nickName }}</router-link
              >
              <span class="tooltip">마이홈</span>
            </p>
            <p class="nickBox" v-if="userInfo.familyNm">
              <router-link
                :to="{
                  name: 'family',
                  query: { familyIdx: userInfo.familyIdx },
                }"
                >[{{ userInfo.familyNm }}]</router-link
              >
              <span class="tooltip">가족방홈</span>
            </p>
          </div>
        </div>
      </div>
      <div class="user_info_box">
        <div class="user_info_item">
          <ul class="ui_list_section_line bottomBox">
            <li>
              <button
                class="fx center_all dir_col btn_bottom_item"
                @click="openMsg"
              >
                <img src="@/assets/img/icon-talk.png" />
                <span>쪽지</span>
                <alarm-counter
                  class="nav_message"
                  v-if="alarm.Z"
                  :count="alarm.Z"
                ></alarm-counter>
              </button>
            </li>
            <li class="line"></li>

            <li>
              <button
                class="fx center_all dir_col btn_bottom_item"
                @click="notiToggle"
              >
                <img src="@/assets/img/alarm-icon.png" />
                <span>알림</span>
                <alarm-counter
                  v-if="alarm.TOTAL"
                  :count="alarm.TOTAL"
                ></alarm-counter>
              </button>
            </li>

            <li>
              <button
                class="fx center_all dir_col btn_bottom_item"
                @click="talkToggle"
              >
                <img src="@/assets/img/icon-talk2.png" />
                <span>채팅방</span>
              </button>
            </li>

            <li class="line"></li>

            <li>
              <button
                class="fx center_all dir_col btn_bottom_item"
                @click="goSignOut"
              >
                <img src="@/assets/img/icon-logout.png" />
                <span>로그아웃</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="user_info_item">
          <ul class="ui_list_section_line cashBox">
            <li>
              <div
                class="fx center_all cash_item gap_3 blue ml_30 btn_link cash_tooltip"
                @click="
                  pushPage('user_myPage_cash', {
                    query: { userIdx: userInfo.userIdx },
                  })
                "
              >
                <span class="cash_icon">C</span>
                <span class="cash_value">{{ addCommas(userInfo.cash) }}</span>
                <span class="tooltip"> 캐시 </span>
              </div>
            </li>
            <li>
              <div
                class="fx center_all cash_item gap_3 red ml_30 btn_link point_tooltip"
                @click="
                  pushPage('user_myPage_point', {
                    query: { userIdx: userInfo.userIdx },
                  })
                "
              >
                <span class="cash_icon">P</span>
                <span class="cash_value">{{ addCommas(userInfo.point) }}</span>
                <span class="tooltip">포인트</span>
              </div>
            </li>
            <li>
              <div
                class="fx center_all cash_item gap_3 green ml_30 btn_link exp_tooltip"
                @click="
                  pushPage('user_myPage_exp', {
                    query: { userIdx: userInfo.userIdx },
                  })
                "
              >
                <span class="cash_icon">E</span>
                <span class="cash_value">{{ addCommas(userInfo.exp) }}</span>
                <span class="tooltip">경험치</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </article>
    <!-- ad2 -->
    <article id="ads_2" v-if="B002.idx">
      <img
        :src="`${this.$store.state.adminURL}/api/advert/cmsimg/${B002.imgSrc}`"
      />
    </article>

    <!-- notice alarm -->
    <noti-window
      v-if="noticeActive"
      @modalOpen="modalOpen"
      @modalClose="modalClose"
    />

    <!-- 회원간 개인 채팅방 -->
    <talk-window
      v-if="talkActive"
      :B002="B002"
      @modalOpen="modalOpen"
      @modalClose="modalClose"
    />

    <!-- chat -->
    <article id="user_chat_wrap">
      <ul class="chat_title_wrap">
        <li :class="{ active: navType === 'chat_live' }">
          <button
            type="button"
            class="fx dir_col center_all"
            @click="navType = 'chat_live'"
          >
            <span class="fw_bold"
              >실시간 채팅
              <span class="fa-xs">
                <i class="fas fa-user-circle"></i>
                {{ addCommas(chatCnt) }}</span
              >
            </span>
          </button>
        </li>
        <template v-if="userInfo.userIdx">
          <li :class="{ disable: navType === 'gamePicker' }">
            <button
              type="button"
              class="fx dir_col center_all"
              @click="navType = 'gamePicker'"
            >
              <span class="fw_bold">게임픽 등록</span>
            </button>
          </li>
        </template>
      </ul>
      <chat-room
        v-if="navType.indexOf('chat_') === 0"
        :loggedIn="loggedIn"
        :noticeActive="noticeActive"
        :talkActive="talkActive"
        :userInfo="userInfo"
        :B002="B002"
        @setChatCnt="setChatCnt"
      />
      <game-pick v-if="navType === 'gamePicker'" />
    </article>
    <Modal
      :modalName="modalName"
      :message="message"
      @modalClose="modalClose"
      v-if="modalActive"
    />
  </section>
</template>

<script>
import { apiSignIn } from "@/api/user";
import { setAuthData, getUserData, removeAuthData } from "@/libs/auth-helper";

import ChatRoom from "./chat_nav";
import GamePick from "./gamePick";
import NotiWindow from "./notiWindow";
import TalkWindow from "./TalkWindow.vue";

import alarmCounter from "@/components/alarmCounter";

export default {
  name: "Nav",
  components: {
    GamePick,
    NotiWindow,
    ChatRoom,
    alarmCounter,
    TalkWindow,
  },
  props: ["B002"],
  data() {
    return {
      modalActive: false,
      modalName: "errorModal",
      noticeActive: false,
      talkActive: false,
      signInModel: {
        lgnId: "",
        lgnPw: "",
      },
      userModel: {},
      _saveId: false,
      navType: "chat_live",
      message: "",
      chatCnt: 0,
      decode: "",
    };
  },
  methods: {
    // 임시 소셜로그인 alert
    alertSocial() {
      alert("준비중입니다.");
    },
    // 채팅방 참여자 수
    setChatCnt(cnt) {
      this.chatCnt = cnt;
    },
    modalClose() {
      this.modalActive = false;
    },
    pushFamily() {
      // console.log(this.userInfo)
      if (!this.userInfo.familyNm) {
        const trigger = confirm(
          "등록된 가족방이 없습니다. 가족방을 생성하시겠습니까?"
        );
        if (trigger) {
          this.modalOpen("familyCreate");
        } else {
          return;
        }
      } else {
        this.$router.push({
          name: "family_main",
          query: { familyIdx: this.userInfo.familyIdx },
        });
      }
    },
    notiToggle() {
      this.noticeActive = !this.noticeActive;
      this.talkActive = false;
    },
    talkToggle() {
      this.talkActive = !this.talkActive;
      this.noticeActive = false;
    },
    openMsg() {
      const routerLink = this.$router.resolve({
        name: "msgList",
        params: { type: "receiveMsg" },
      });
      window.open(
        routerLink.href,
        "msgPopup",
        "width=480px,height=600px,top=0,left=0,scrollbars=no"
      );
    },

    saveId() {
      this._saveId = true;
    },
    // 로그인
    async signIn() {
      for (const key in this.signInModel) {
        if (!this.signInModel[key]) {
          return alert("빈칸을 입력해주세요");
        }
      }
      const req = this.signInModel;
      try {
        const res = await apiSignIn(req);
        if (!res) return;
        setAuthData(res.data);
        const cookieUserData = getUserData();
        await this.$store.dispatch("setUserInfo", cookieUserData);
        this.loginUser = this.$store.state.userInfo;
        this.$store.dispatch("getNewAlarm");
        this.$store.dispatch("chatOwn");
        this.refreshUserInfo();
      } catch (err) {
        console.error(err);
        if (err == "MZE131") {
          this.message = "블랙리스트 회원입니다!";
          this.modalActive = true;
        }
      }
    },
    // 로그아웃
    async goSignOut() {
      removeAuthData();
      this.$router.push({ name: "main" });
      this.$store.dispatch("setUserInfo", null);
      this.$router.go(0);
    },
  },
  computed: {
    alarm() {
      return this.$store.getters.alarm;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>

<style scoped>
#user_info_wrap {
  width: 100%;
  /* border-radius: 10px; */
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border: 1px solid #ddd;
}

/* cmc user_info unlodded */
#user_info_wrap.unlogged {
  background-color: #fff;
  border: 1px solid #eee;
  color: #fff;
  font-size: 1.083em;
  padding: 20px;
}

.user_info_box {
  display: flex;
}

.user_info_box .user_info_item {
  width: 50%;
}
.user_info_item {
  margin-bottom: 20px;
  font-size: 1rem;
}

.user_info_item:last-child {
  margin-bottom: 0;
}

#user_info_wrap input[type="text"],
#user_info_wrap input[type="password"] {
  padding: 11px 12px;
  border: 2px solid #eee;
  color: #696464;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: #f8f8f8;
}

#user_info_wrap input[type="password"] {
  margin-bottom: 0;
}

.btn_login_wrap {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}

.btn_login_wrap button {
  cursor: pointer;
}

.btn_login {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  height: 36px;
}

.btn_login.signUp {
  background: #919191;
}
.btn_login.signUp:hover {
  background: #7c7c7c;
}
.btn_login.signIn {
  background-color: #e6264c;
}
.btn_login.signIn:hover {
  background-color: #e62645;
}

/* cmc user_info loggedIn */
#user_info_wrap.loggedIn {
  position: relative;
  background: #fff;
  padding: 20px;
}

#user_info_wrap.loggedIn .upperBack {
  position: absolute !important;
  height: 85px;
  width: 100%;
  background: #006e26;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

ul[class*="ui_list"] {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}

ul[class*="ui_list"].bottomBox {
  flex-wrap: wrap;
  flex-direction: row;
}

ul[class*="ui_list"] > li {
  display: flex;
  align-items: center;
}
ul[class*="ui_list"].cashBox > li:not(.line) {
  flex: 1 1 calc((100% - 4px) / 3);
  margin-top: 7px;
}
ul[class*="ui_list"].bottomBox > li:not(.line) {
  flex: 1 1 calc((100% - 6px) / 4);
  justify-content: center;
  margin: 0 5px;
}
ul[class*="ui_list"] > li.line {
  display: inline-block;
  width: 2px;
  height: 22px;
  background: #f5f5fa;
}

ul[class*="ui_list"] > li:last-child:after {
  content: "";
  display: none;
}

.cashBox * {
  font-size: 0.8rem;
  font-weight: 700;
}

.cashBox .cash_icon {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #fff;
}

.cash_item.blue .cash_value {
  color: #1abcff;
}

.cash_item.blue .cash_icon {
  background: #1abcff;
}

.cash_item.red .cash_value {
  color: #fd434f;
}

.cash_item.red .cash_icon {
  background: #fd434f;
}

.cash_item.green .cash_value {
  color: #00d975;
}

.cash_item.green .cash_icon {
  background: #00d975;
}
.btn_user_link {
  position: relative;
}
.btn_user_link:hover span {
  opacity: unset;
}

.btn_my_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.btn_bottom_item {
  font-size: 0.875em;
  font-weight: 500;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

ul[class*="ui_list"].bottomBox > li:after {
  height: 40px;
  margin: 0 14px;
}

/* cmc chat */
#user_chat_wrap {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
}

.chat_title_wrap {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
}

.chat_title_wrap > li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 45px;
}

.chat_title_wrap > li.active {
  background-color: #e6264c;
}

.chat_title_wrap > li.disable {
  background-color: #e6264c;
}

.chat_title_wrap > li.active span,
.chat_title_wrap > li.disable span {
  color: #fff;
}

.chat_title_wrap > li button {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#side_right {
  width: 330px;
  min-width: 330px;
  position: relative;
}

#side_right > article {
  margin-bottom: 20px;
}

.no_margin {
  margin-bottom: 0;
}
.contour_line {
  font-size: 0.85em;
  font-weight: 500;
  color: #7c7c7c;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}
.contour_line:before,
.contour_line:after {
  content: "";
  display: block;
  flex: 1 1 auto;
  height: 1px;
  background: #7c7c7c;
}
.sns_btns_wrap {
  justify-content: space-around;
  font-size: 13px;
  border-top: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
}
.sns_btns_wrap img {
  display: inline-block;
}

.sns_btns_wrap a,
.sns_btns_wrap button {
  width: 80px;
  height: 40px;
  background-size: cover;
  border: 1px solid #e6e6e694;
  border-radius: 7px;
  /* box-shadow: 3px 4px 12px -5px #80808094; */
}

#login_google {
  background-image: url(../../../assets/img/sns/btn_google.png);
}
#login_naver {
  background-image: url(../../../assets/img/sns/btn_naver.png);
}
#login_kakao {
  background-image: url(../../../assets/img/sns/btn_kakao.png);
}

#ads_2 {
  height: 250px;
  width: inherit;
  box-shadow: none !important;
}

#ads_2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile {
  display: flex;
  margin-bottom: 20px;
  gap: 5px;
  justify-content: center;
}

.profile .profile_text_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.profile .profile_text_wrap > div {
  display: flex;
  flex-direction: column;
}
.profile .idBox {
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  width: 120px;
}
.profile .nickBox {
  font-size: 14px;
  white-space: nowrap;
}
.tooltip {
  left: 60%;
}
.idBox .tooltip {
  top: 15px;
  left: 55%;
}
.nickBox .tooltip {
  top: 35px;
  left: 55%;
}

.cash_tooltip .tooltip {
  top: 110px;
}
.point_tooltip .tooltip {
  top: 140px;
}
.exp_tooltip .tooltip {
  top: 170px;
}
.idBox:hover .tooltip {
  top: 25px;
  opacity: 1;
}
.nickBox:hover .tooltip {
  top: 45px;
  opacity: 1;
}
.cash_tooltip:hover .tooltip {
  top: 120px;
  opacity: 1;
}
.point_tooltip:hover .tooltip {
  top: 150px;
  opacity: 1;
}
.exp_tooltip:hover .tooltip {
  top: 180px;
  opacity: 1;
}
</style>

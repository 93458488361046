<template>
  <article class="modalWrap_ad">
    <template v-for="(item, index) in pickData" :key="item.idx">
      <!-- conWin : 0 gameType : "pinball" nickName : "훌랄라34" roomCode :
        "1da9402b9439438c86b018f14b809cf1" roomIdx : 318 round : 129 userIdx : 27 -->
      <button class="modal_close" @click="modalClose(index)">
        <i class="fas fa-times"></i>
      </button>

      <div class="signUp_wrap" v-if="item.roomCode" @click="roomEnter(item)">
        <div class="signUp_contents bgc_1">
          <span
            >{{ $t(`games.${item.gameType}`) }}
            방채팅픽
            {{ item.conWin }} 연승</span
          >
          <span>{{ item.nickName }}님 {{ item.round }}회차 방채팅픽 등록</span>
        </div>
      </div>
      <div class="signUp_wrap" v-else @click="openUserhome(item.userIdx)">
        <div class="signUp_contents bgc_2">
          <span
            >{{ $t(`games.${item.gameType}`) }}
            공개픽
            {{ item.conWin }} 연승</span
          >
          <span>{{ item.nickName }}님 {{ item.round }}회차 공개픽 등록</span>
        </div>
      </div>
    </template>
  </article>
</template>
<script>
export default {
  props: ["pickData"],

  data() {
    return {};
  },
  methods: {
    // 알림 모달 닫기
    modalClose(index) {
      this.$store.commit("setPickDataSplice", index);
    },
    // 유저 홈 오픈
    openUserhome(useridx) {
      this.$router.push({
        name: "user_main",
        query: { userIdx: useridx },
      });
    },
    // 채팅방 입장
    async roomEnter(item) {
      if (!this.loggedIn) {
        alert("로그인 후 이용해주세요!");
        return;
      }
      if (this.myRoom.roomIdx == item.roomIdx) {
        return this.$router.push({
          name: "chatRoom",
          query: { gameType: item.gameType },
          params: { roomIdx: item.roomIdx },
        });
      }
      if (item.isSecretRoom == 1) {
        this.modalOpen("roomPassword");
        this.$store.dispatch("settingsetCheckRoomInfo", item);
        return;
      }
      let check;
      // 일반 방일때 권한 확인
      // console.log(item);
      await this.$store.dispatch("roomImmigration", item).then((res) => {
        check = res;
      });
      // console.log(check, "check");
      if (check) {
        this.$router.push({
          name: "chatRoom",
          query: { gameType: item.gameType },
          params: { roomIdx: item.roomIdx },
        });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    myRoom() {
      return this.$store.getters.myRoom;
    },
  },
};
</script>

<style scoped>
.modalWrap_ad {
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 10;
  margin: 20px;
  height: 100vh;
  margin-bottom: 100px;
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.signUp_wrap:hover {
  cursor: pointer;
}
.signUp_wrap .signUp_contents {
  align-items: center;
  justify-content: center;
  height: 70px;
  color: #fff;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  border-radius: 6px;
  font-weight: 600;
  margin-bottom: 10px;
  width: 300px;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.bgc_1 {
  background-color: #ffb100;
}

.bgc_2 {
  background-color: #3da0f4;
}
.modal_close {
  position: absolute;
  right: 0;
  z-index: 1;
  color: #fff;
  font-size: 0.7rem;
  padding: 10px;
  cursor: pointer;
}
</style>

import axios from "axios";
import router from "@/router/pc";
import store from "@/store";
import { IS_PRODUCTION } from "@/libs/constants";
import { apiErrorHandler } from "@/libs/errorHandler";
import { setAuthToken, getAuthToken, removeAuthData } from "@/libs/auth-helper";

let baseUrl = "https://";

if (IS_PRODUCTION) {
  baseUrl += process.env.VUE_APP_WEB_URI;
} else {
  baseUrl += process.env.VUE_APP_WEB_URI;
}

// 받아온 url의 첫번째 문자열을 확인
// '/'일 경우 true, 아닐경우 해당 url에 baseurl이 있는지 체크
function isApiUrl(url) {
  if (url.charAt(0) === "/") {
    return true;
  }
  return url.indexOf(baseUrl) === 0;
}

const httpClient = axios.create({
  baseURL: baseUrl,
});

// 요청 인터셉터 추가
httpClient.interceptors.request.use(
  // 요청 보내기 전에 수행 할 일
  function (config) {
    // console.log(config)
    if (isApiUrl(config.url)) {
      const headerConfig = {
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
      };

      const token = getAuthToken();
      if (token && token.indexOf("TKE00") === -1) {
        headerConfig.VICTOKEN = token;
      }
      config.headers = Object.assign(config.headers, headerConfig);
      config.url = "/api" + config.url;
    }
    return config;
  },
  // 오류 요청 보내전에 수행 할 일
  function (error) {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
httpClient.interceptors.response.use(
  // 응답 데이터를 가공
  function (response) {
    // if (isApiUrl(response.config.url) && response.data.msg !== "SUCCESS") {
    if (response.data.res !== "OK" && response.data.msg !== "SUCCESS") {
      const message = response.data || "RESPONSE_FAILED";
      const error = new Error();
      error.url = response.config.url;
      error.code = message.res;
      error.message = message.msg;

      if (error.code === "RQT001") {
        removeAuthData();
        store.dispatch("setUserInfo", null);
        router.push({ name: "main" });
      }
      if (error.code === "MZE166a") {
        router.go(-1);
      }
      // return apiErrorHandler(error);
      return Promise.reject(apiErrorHandler(error));
    } else {
      const token = response.headers.victoken;
      if (token) {
        setAuthToken(token);
      }
      return response;
    }
  }
);

export default httpClient;

<template>
  <div class="signUp_wrap">
    <div class="signUp_contents">
      <div class="close">
        <button class="close_btn" @click="modalClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="sign_form">
        <fieldset class="input_fs_pw">
          <legend>채팅방 비밀번호</legend>
          <input
            type="password"
            maxlength="20"
            v-model="model.roomPw"
            placeholder="채팅방 비밀번호"
          />
        </fieldset>
      </div>
      <div class="signUp">
        <button class="signUp_btn" type="button" @click="roomEnter">
          입장하기
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "roomPassword",
  emits: ["modalClose", "setSecretCheck", "secretRoomInfo"],
  props: ["SecretCheck"],
  data() {
    return {
      model: {
        managerIdx: 0,
        roomIdx: 0,
        roomPw: "",
      },
    };
  },
  methods: {
    modalClose() {
      this.$emit("modalClose", false);
      this.$store.dispatch("settingsetCheckRoomInfo", {});
      this.model.roomPw = "";
      this.$emit("setSecretCheck", false);
    },
    // 채팅방 입장
    async roomEnter() {
      const roomInfo = this.$store.state.checkRoomInfo;
      this.model.roomIdx = roomInfo.roomIdx || roomInfo.celebRoomIdx;
      this.model.managerIdx = roomInfo.userIdx;
      let check;
      this.$store
        .dispatch("roomImmigration", this.model)
        .then((res) => {
          check = res;
          if (check) {
            window.open(
              `/chat/room/${
                roomInfo.roomIdx || roomInfo.celebRoomIdx
              }?gameType=${roomInfo.gameType || roomInfo.roomType}`,
              "chat",
              "width=1400,height=800,location=no"
            );
          }
        })
        .catch(() => {
          this.modalClose();
        })
        .finally(() => {
          this.modalClose();
        });
    },
  },
};
</script>

<style scoped>
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9em;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;
  position: relative;
  padding: 30px 48px;
  box-sizing: border-box;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 60px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.input_fs {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input {
  /* margin: .4rem; */
  /* border: none; */
  /* padding: 20px; */
  font-size: 1.4em;
  margin-left: 17px;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.overlap_check {
  display: flex;
  margin-top: 10px;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.signUp_btn {
  height: 56px;
  width: 470px;

  /* border: 1px solid #e0e0eb; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1em;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
  font-weight: 700;
  background-color: #ffce32;
  box-shadow: 0px 0px 10px #cecece;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

.input_fs_pw {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs_pw > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs_pw > input {
  padding: 0 20px;
  font-size: 1.3em;
  width: 100%;
  box-sizing: border-box;
}

.input_fs_pw > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}
.error-box_active {
  position: relative;
  top: 18px;
  font-size: 0.75rem;
  color: red;
}
</style>

import httpClient from "@/libs/http-client";
import axios from "axios";

// 다음 게임 정보
export function apiNextInfo(gametype) {
  const url = `/${gametype}/pick/nextgame`;
  return httpClient.get(url).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// ! 게임픽 등록 공통
export function apiGamesPick(name, data) {
  const url = `/${name}/pick`;
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//경험치 리필
export function apiExpRefill(data) {
  const url = "/exp/refill";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// * 중계 > 통계데이터
export function apiGameLiveInfo(method, name, data) {
  const url = `/${name}/static/main` + method;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// * 일별분석 > 하루씩 > 라운드연속그래프
export function apiGameDailyInfo(method, name, data) {
  const url = `/${name}/static/daily` + method;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//  * 일별분석 > 기간 > 검색기간 내 최다/최소 출현 통계데이터

export function apiGameSeasonInfo(method, name, data) {
  const url = `/${name}/static/season` + method;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// * 패턴분석 > 최근회차 데이터
export function apiGamePatternInfo(name, data) {
  const url = `/${name}/static/pattern`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// * 회차분석 > 단일 회차 > 통계데이터
export function apiGameSingleRound(method, name, data) {
  const url = `/${name}/static/round/single` + method;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// *  회차분석 > 복수회차 > 회차별그루핑데이터
export function apiGamelMultiRound(name, data) {
  const url = `/${name}/static/round/many/group/data`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// * 패턴 분석 > 최근회차분석
export function apiGameRecentRound(method, name, data) {
  const url = `/${name}/static/round/recent` + method;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 픽스터랭킹
export function apiGamePickster(data, gameType) {
  const url = `/${gameType}/pickster`;
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

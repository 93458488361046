import httpClient from "@/libs/http-client";

//가족방 생성
export function apiFamilyCreate(data) {
  const url = "/family";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 가족방 해체
export function apiFamilyExit(data) {
  const url = "/family/setting/exit";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 가족포인트 기부
export function apiFamilyDonation(data) {
  const url = "/family/point/donation";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 가족방 가입신청
export function apiFamilyJoin(data) {
  const url = "/family/join";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 가족방 탈퇴
export function apiFamilyBye(data) {
  const url = "/family/bye";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 가족방 선택 멤버 추방하기
export function apiFamilyJoinStatus(data) {
  const url = "/family/user/status";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 가족방 추방 멤버 가입 허가
export function apiFamilyAllow(data) {
  const url = "/family/user/allow";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 등급구매
export function apiFamilyLevelUp(data) {
  const url = "/family/level/up";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 상세
export function apiFamilyDetail(data) {
  const url = "/family/info";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 리스트
export function apiFamilyList(data) {
  const url = "/family/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 멤버리스트
export function apiFamilyMemberList(data) {
  const url = "/family/user/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 게시판
export function apiFamilyBoardList(data) {
  const url = "/family/board/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 게시판 글 추천
export function apiFamilyBoardLike(data) {
  const url = "/family/board/like";
  return httpClient.put(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 게시판 작성
export function apiFamilyBoardWrite(data) {
  const url = "/family/board/item";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 게시판 상세
export function apiFamilyBoardDetail(data) {
  const url = "/family/board/item";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 게시판 수정
export function apiFamilyBoardUpdate(data) {
  const url = "/family/board/item";
  return httpClient.put(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 게시판 글 삭제
export function apiFamilyBoardDelete(data) {
  const url = "/family/board/item";
  return httpClient.delete(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 게시판 댓글 작성
export function apiFamilyReplyWrite(data) {
  const url = "/family/board/reply";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 가족방 게시글 댓글 수정
export function apiReplyUpdate(data) {
  const url = "/family/board/reply";
  return httpClient.put(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 게시글 댓글 삭제
export function apiReplyDelete(data) {
  const url = "/family/board/reply";
  return httpClient.delete(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 가족방 정보 변경
export function apiSetFamilyInfo(type, data) {
  const url = `/family/setting/${type}`;
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 프로필 업로드
export function apiSetFamilyImgSrc(data) {
  const url = "/family/setting/image";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 배너 이미지 업로드
export function apiSetFamilyBannerSrc(data) {
  const url = "/family/setting/banner";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 축하메세지 업로드
export function apiSetcongratMsg(data) {
  const url = "/family/setting/congrat";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 설명 업로드
export function apiSetFamilyDesc(data) {
  const url = "/family/setting/description";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 이름 업로드
export function apiSetFamilyName(data) {
  const url = "/family/setting/name";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 공지 업로드
export function apiSetNotice(data) {
  const url = "/family/setting/notice";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//프로필 이미지 업로더
export function apiFamilyProfileUpload(data) {
  const url = "/family/logo/image/upload";
  const headers = { "Content-Type": "multipart/form-data" };
  return httpClient.post(url, data, headers).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//배너 이미지 업로더
export function apiFamilyBannerUpload(data) {
  const url = "/family/banner/image/upload";
  const headers = { "Content-Type": "multipart/form-data" };
  return httpClient.post(url, data, headers).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 활동소식
export function apiFamilyNewsList(data) {
  const url = "/family/news";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//가족방 레벨별 정보
export function apiFamilyBenefitInfo(data) {
  const url = "/family/common/rule";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 포인트 로그
export function apiFamilyPointLog(data) {
  const url = "/family/point/log";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//가족방 레벨제한 변경
export function apiSetFamilyLevelLimit(data) {
  const url = "/family/setting/limit";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

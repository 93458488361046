<template>
  <!-- 팝업내용 들어갈 자리 -->
  <template v-for="(item, index) in popup" :key="index">
    <div v-if="item.active" class="modal_popup" :class="`position${index + 1}`">
      <div class="modal_popup_img">
        <img
          :src="`${this.$store.state.adminURL}/api/advert/cmsimg/${item.imgSrc}`"
        />
      </div>
      <div class="modal_popup_foot">
        <button type="button" @click="closeWeek(item, item.idx)">
          <span>일주일동안 보지 않기</span>
        </button>

        <button type="button" @click="close(item)"><span>닫기 X</span></button>
      </div>
    </div>
  </template>
</template>

<script>
import { getPopupDate, setPopupDate } from "@/libs/auth-helper";

export default {
  props: ["popup"],
  computed: {
    popupdata() {
      return this.popup;
    },
    check() {
      return this.checkpopup();
    },
  },
  methods: {
    // 일주일동안 보지 않기
    closeWeek(item, index) {
      setPopupDate(index);
      if (getPopupDate(index) === "Y") {
        item.active = 0;
      }
    },
    // 닫기
    close(item) {
      item.active = 0;
    },
  },
};
</script>

<style scoped>
.modal_popup {
  width: 500px;
  height: 640px;
  margin-right: 20px;
  position: absolute;
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.2);
}
.modal_popup_img {
  width: 500px;
  height: 600px;
}
.modal_popup_img img {
  width: 100%;
  height: 100%;
}

.modal_popup_foot {
  width: 500px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0;
  background: #000;
  text-align: left;
  color: #fff;
  text-align: left;
  position: relative;
}
.modal_popup_foot button {
  padding: 12px;
  border: 0;
  color: #fff;
  font-size: 0.8rem;
  cursor: pointer;
}
.position2 {
  right: 350px;
}
</style>

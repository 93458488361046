import game from "./game";
import cscenter from "./cscenter";
import user from "./user";
import msg from "./msg";
import chat from "./chat";
import board from "./board";
import market from "./market";
import family from "./family";
import policy from "./policy";
import talkroom from "./talkroom";
import talk from "./talk";

const member = [
  game,
  cscenter,
  user,
  board,
  market,
  family,
  msg,
  chat,
  policy,
  talk,
  talkroom,
];
const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/views/mobile"),
    meta: {
      layout: "mobile",
      userOnly: false,
    },
  },
  {
    path: "/signUp",
    name: "signUp",
    component: () => import("@/views/mobile/signUp"),
    meta: {
      layout: "mobile",
      userOnly: false,
    },
  },
  {
    path: "/member",
    name: "member",
    component: () => import("@/views/mobile/member"),
    redirect: { name: "main" },
    meta: {
      layout: "mobile",
      userOnly: false,
    },
    children: member,
  },
  {
    path: "/family_rank",
    name: "family_rank",
    component: () => import("@/views/mobile/member/rank/familyRank"),
    meta: {
      layout: "mobile",
      userOnly: false,
    },
  },
  {
    path: "/pickster_rank",
    name: "pickster_rank",
    component: () => import("@/views/mobile/member/rank/picksterRank/index"),
    redirect: {
      name: "pickster_rank_list",
    },
    meta: {
      layout: "mobile",
      userOnly: false,
    },
    children: [
      {
        path: "/pickster_rank/list",
        name: "pickster_rank_list",
        component: () => import("@/views/mobile/member/rank/picksterRank/list"),
        props: true,
        meta: {
          layout: "mobile",
          userOnly: false,
        },
      },
    ],
  },
  {
    path: "/subscription",
    name: "subscription_user",
    component: () => import("@/components/Modal_mobile/subscribe"),
    meta: {
      layout: "mobile",
      userOnly: true,
    },
  },
  {
    path: "/giftitem",
    name: "gift_user",
    component: () => import("@/components/Modal_mobile/giftItem"),
    meta: {
      layout: "mobile",
      userOnly: true,
    },
  },
  {
    path: "/bail/list",
    name: "bail_list",
    component: () => import("@/views/mobile/bail/index.vue"),
    meta: {
      layout: "mobile",
      userOnly: false,
    },
  },
  {
    path: "/social/:type",
    name: "social_auth",
    component: () => import("@/views/SocialAuth.vue"),
    meta: {
      userOnly: false,
    },
  },
];

export default routes;

export default {
  path: "/talk",
  name: "talk",
  redirect: { name: "talk_friend" },
  component: () => import("@/views/mobile/talk"),
  meta: {
    layout: "mobile",
    userOnly: false,
  },
  children: [
    {
      path: "/talk/friend",
      name: "talk_friend",
      component: () => import("@/views/mobile/talk/friend"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
    {
      path: "/talk/list",
      name: "talk_list",
      component: () => import("@/views/mobile/talk/list"),
      meta: {
        layout: "mobile",
        userOnly: false,
      },
    },
  ],
};

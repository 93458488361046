<template>
  <article class="modalWrap custom_scroll">
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="modalClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="logo_symbol">
          <img src="@/assets/img/ballpick_logo_1.png" />
        </div>
        <div class="sign_form">
          <fieldset class="input_fs">
            <legend>회원 검색</legend>
            <input
              type="text"
              v-model="searchModel.searchUserNm"
              placeholder="닉네임을 입력해주세요."
            />
          </fieldset>
          <div class="overlap_check">
            <button class="overlap_btn" @click="searchUser">검색</button>
          </div>
        </div>
        <div class="signUp">
          <button class="signUp_btn" type="button" @click="giftItem">
            선물하기
          </button>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { apiSearchUser } from "@/api/user";
import { apiItemGift } from "@/api/market";

export default {
  name: "giftItem",
  props: ["giftItems"],
  emits: ["closeModal", "resetPayType"],
  data() {
    return {
      model: {
        nickName: "",
      },
      searchModel: {
        searchUserNm: "",
        page: 1,
        rowCnt: 1,
      },
      userCheck: false,
      target: {
        tgtUserIdx: 0,
      },
    };
  },
  methods: {
    modalClose() {
      this.$emit("closeModal");
    },
    // 아이템 선물
    async giftItem() {
      const req = {
        tgtUserIdx: this.target.tgtUserIdx,
        itemCd: this.giftItems.cd,
        buyCnt: this.giftItems.buyCnt,
      };
      if (!this.userCheck) {
        alert("선물할 회원의 닉네임을 확인해주세요.");
        return;
      }
      try {
        const res = await apiItemGift(req);
        if (res) {
          alert("선물을 완료했습니다.");
          this.$emit("resetPayType");
          this.modalClose();
        } else {
          this.$emit("resetPayType");
          this.modalClose();
        }
      } catch (err) {
        alert(err);
      }
    },
    async searchUser() {
      const req = this.searchModel;
      const res = await apiSearchUser(req);
      if (!req.searchUserNm) {
        alert("선물할 회원을 입력해주세요.");
        return;
      }
      if (res) {
        try {
          if (res.list.length !== 0) {
            const user = res.list[0];
            if (user.nickName !== req.searchUserNm) {
              // console.log("new Error");
              throw new Error("No User");
            } else {
              this.target.tgtUserIdx = user.userIdx;
              this.userCheck = true;
              alert("유저확인완료.");
            }
          } else {
            throw new Error("No User");
          }
        } catch (e) {
          // console.log(e);
          alert("존재하지 않는 유저입니다. 다시 확인해주세요.");
        }
      }
    },
  },
  watch: {
    searchModel: {
      handler(model) {
        if (model.searchUserNm == "") {
          this.target.celebIdx = "";
          this.userCheck = false;
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.sendUser) {
      this.searchModel.searchUserNm = this.sendUser;
      await this.searchUser();
    }
  },
  computed: {
    sendUser() {
      const user = this.$route.query.nickname;
      return user;
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   const user = getUserData();
  //   if (!!user) {
  //     next({ name: "main" });
  //   } else {
  //     next();
  //   }
  // },
};
</script>

<style scoped>
.modalWrap {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 9999;
  background: #28282838;
  overflow-y: scroll;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100vh;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.signUp_wrap .signUp_contents {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px #ebe7e7;

  position: relative;
  padding: 15px;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.input_fs {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input {
  font-size: 1em;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.overlap_check {
  display: flex;
  margin-top: 10px;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.signUp_btn {
  width: 100%;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}
</style>

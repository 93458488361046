import httpClient from "@/libs/http-client";
//게시판 목록
export function apiBoardList(data) {
  const url = "/board/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//게시판 글 추천
export function apiBoardLike(data) {
  const url = "/board/like";
  return httpClient.put(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//게시판 작성
export function apiBoardWrite(data) {
  const url = "/board/item";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//게시판 상세
export function apiBoardDetail(data) {
  const url = "/board/item";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//게시판 수정
export function apiBoardUpdate(data) {
  const url = "/board/item";
  return httpClient.put(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 게시판 본인 게시글
export function apiBoardListMy(data) {
  const url = "/board/private/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
// 게시판 본인 댓글
export function apiBoardListMyReply(data) {
  const url = "/board/private/reply/list";
  return httpClient.get(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//게시판 글 삭제
export function apiBoardDelete(data) {
  const url = "/board/item";
  return httpClient.delete(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//게시판 댓글작성
export function apiReplyWrite(data) {
  const url = "/board/reply";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

//게시판 수정
export function apiReplyUpdate(data) {
  const url = "/board/reply";
  return httpClient.put(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}
//게시판 댓글 삭제
export function apiReplyDelete(data) {
  const url = "/board/reply";
  return httpClient.delete(url, { params: data }).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

// 게시글 신고
export function apiBoardReport(data) {
  const url = "/board/report";
  return httpClient.post(url, data).then((response) => {
    if (response) {
      return response.data;
    } else {
      return response;
    }
  });
}

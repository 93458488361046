<template>
  <article>
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="modalClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="logo_symbol">
          <img src="@/assets/img/ballpick_logo_1.png" />
        </div>
        <section class="signUp_body">
          <div class="sign_form">
            <fieldset class="input_fs">
              <legend>선물 받을 사람</legend>
              <span class="fx vt_c">
                <lvImg :level="roomInfo.userLevelCd" />
                <span>{{ roomInfo.userNm }}</span>
              </span>
            </fieldset>
          </div>
          <div class="sign_form">
            <fieldset class="input_fs">
              <legend>보유보석</legend>
              <span>{{ addCommas(cloneJewel - donationJewel) }}</span>
            </fieldset>
          </div>
          <div class="sign_form">
            <fieldset class="input_fs">
              <legend>선물받은보석</legend>
              <span>{{ addCommas(this.userInfo.jewelReceive) }}</span>
            </fieldset>
          </div>
          <div class="sign_form">
            <fieldset class="input_fs">
              <legend>선물할 보석</legend>
              <input
                type="text"
                maxlength="10"
                ref="donatedJewel"
                :value="addCommas(donationJewel)"
                @input="donatedJewel"
                @change="donatedJewel"
              />
            </fieldset>
          </div>

          <div class="signUp">
            <button class="signUp_btn" type="button" @click="jewelDonate">
              선물하기
            </button>
          </div>
        </section>
      </div>
    </div>
  </article>
</template>
<script>
import { getUserData } from "@/libs/auth-helper";
import { onlyNumber } from "@/libs/utils";
import lodash from "lodash";
import { apiJewelDonation } from "@/api/user";
export default {
  name: "familyDonation",
  emits: ["modalClose"],
  data() {
    return {
      model: {
        donationPoint: 0,
      },
      donationJewel: 0,
    };
  },
  methods: {
    donatedJewel(e) {
      e.target.value = onlyNumber(e);
      this.donationJewel = this.deleteCommas(e.target.value);
    },
    // 채팅방 보석 선물
    async jewelDonate() {
      const _confirm = confirm("보석을 선물하시겠습니까?");
      if (!_confirm) {
        return;
      } else {
        const model = {
          toUserIdx: this.roomInfo.userIdx,
          jewelCnt: this.donationJewel,
        };
        if (model.jewelCnt < 1 || !model.jewelCnt) {
          alert("선물할 보석을 입력해주세요");
          return;
        }
        const res = await apiJewelDonation(model);
        if (res) {
          await this.refreshUserInfo();
          this.donationJewel = 0;
          alert("선물 완료");
          this.modalClose();
        }
      }
    },

    modalClose(succ) {
      this.$emit("modalClose", succ);
    },
  },
  computed: {
    clonePoint() {
      return lodash.cloneDeep(this.$store.getters.userInfo.jewel);
    },
    roomInfo() {
      return this.$store.getters.roomInfo;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    donationJewel: {
      handler(value) {
        if (value > this.cloneJewel) {
          this.donationJewel = this.cloneJewel;
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    const user = getUserData();
    if (!!user) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
article {
  width: 90%;
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signUp_wrap .signUp_contents {
  width: 100%;
  /* height: 1115px; */
  background: #ffffff;
  box-shadow: 0px 0px 10px #ebe7e7;

  position: relative;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
}

.logo_symbol img {
  width: 130px;
  /* height: 68px; */
}
.signUp_body {
  padding: 0 5vw;
  padding-bottom: 20px;
}
.sign_form {
  padding: 20px 0 0;
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
}

.input_fs {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  /* height: 56px; */
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input,
.input_fs > span {
  font-size: 1em;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
  font-weight: 700;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.signUp_btn {
  height: 48px;
  width: 90%;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}
.lv_img {
  height: 20px;
}
</style>

export default {
  path: "/user",
  name: "user",
  redirect: { name: "user_main" },
  component: () => import("@/views/pc/member/user"),
  beforeEnter(to, from, next) {
    const require = to.query.userIdx;
    if (!require) {
      alert("존재하지 않는 회원입니다.");
      return next(from.fullPath);
    } else {
      return next();
    }
  },
  meta: {
    userOnly: true,
  },
  children: [
    {
      path: "/user/userMain",
      name: "user_main",
      component: () => import("@/views/pc/member/user/userMain"),
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
    {
      path: "/user/userPick",
      name: "user_pick",
      redirect: { name: "user_pick_game" },

      component: () => import("@/views/pc/member/user/userPick/index.vue"),
      meta: {
        layout: "default",
        userOnly: true,
      },
      children: [
        {
          path: "/user/userPick/:type",
          name: "user_pick_game",
          component: () =>
            import("@/views/pc/member/user/userPick/gameType.vue"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
      ],
    },
    {
      path: "/user/community",
      name: "user_community",
      component: () => import("@/views/pc/member/user/community"),
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
    {
      path: "/user/subscribe",
      name: "user_subscribe",
      component: () => import("@/views/pc/member/user/subscribe"),
      meta: {
        layout: "default",
        userOnly: true,
      },
    },
    {
      path: "/user/myPage",
      name: "user_myPage",
      component: () => import("@/views/pc/member/user/myPage"),
      redirect: { name: "user_myPage_basic" },
      meta: {
        layout: "default",
        userOnly: true,
      },
      children: [
        {
          path: "/user/myPage/basic",
          name: "user_myPage_basic",
          component: () => import("@/views/pc/member/user/myPage/basic"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/login",
          name: "user_myPage_login",
          component: () => import("@/views/pc/member/user/myPage/loginLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/nickname",
          name: "user_myPage_nickname",
          component: () => import("@/views/pc/member/user/myPage/nicknameLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/inventory",
          name: "user_myPage_inventory",
          redirect: { name: "user_myPage_inventory_holdings" },
          component: () => import("@/views/pc/member/user/myPage/inventory"),
          meta: {
            layout: "default",
            userOnly: true,
          },
          children: [
            {
              path: "/user/myPage/inventory/holdings",
              name: "user_myPage_inventory_holdings",
              component: () =>
                import("@/views/pc/member/user/myPage/inventory/holdings"),
              meta: {
                layout: "default",
                userOnly: true,
              },
            },
            {
              path: "/user/myPage/inventory/log",
              name: "user_myPage_inventory_log",
              component: () =>
                import("@/views/pc/member/user/myPage/inventory/log"),
              meta: {
                layout: "default",
                userOnly: true,
              },
            },
          ],
        },
        {
          path: "/user/myPage/cash",
          name: "user_myPage_cash",
          component: () => import("@/views/pc/member/user/myPage/cashLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/point",
          name: "user_myPage_point",
          component: () => import("@/views/pc/member/user/myPage/pointLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/exp",
          name: "user_myPage_exp",
          component: () => import("@/views/pc/member/user/myPage/expLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/jewel",
          name: "user_myPage_jewel",
          component: () => import("@/views/pc/member/user/myPage/jewelLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/bank",
          name: "user_myPage_bank",
          component: () => import("@/views/pc/member/user/myPage/bankLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/exchange",
          name: "user_myPage_exchange",
          component: () => import("@/views/pc/member/user/myPage/exchangeLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
        {
          path: "/user/myPage/crime",
          name: "user_myPage_crime",
          component: () => import("@/views/pc/member/user/myPage/crimeLog"),
          meta: {
            layout: "default",
            userOnly: true,
          },
        },
      ],
    },
  ],
};

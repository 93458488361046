<template>
  <div class="alarm_count" id="alarm_subscribe">{{ count }}</div>
</template>

<script>
export default {
  name: "Alarm_counter",
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.alarm_count {
  display: inline-block;
  font-weight: 900;
  background: #fd434f;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  transform: translate(0, -50%);
  top: 0%;
  right: -8px;
}
</style>

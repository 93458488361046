<template>
  <img
    class="lv_img"
    :src="
      require(`@/assets/img/badge/${this.level ? Number(this.level) : 0}.png`)
    "
  />
</template>

<script>
export default {
  name: "level_image",
  props: ["level"],
  data() {
    return {};
  },
};
</script>

<style scoped></style>

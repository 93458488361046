<template>
  <article class="modalWrap custom_scroll" v-if="modalName">
    <component :is="this.modalName" @modalClose="modalClose"></component>
  </article>
</template>

<script>
import SignUp from "./signUp";
import SignIn from "./signIn";
import FamilyCreate from "./familyCreate";
import RoomCreate from "./chatRoomCreate";
import Subscribe from "./subscribe";
import FamilyDonation from "./FamilyDonation";
import giftItem from "./giftItem";
import roomPassword from "./roomPassword.vue";
import talkAlarm from "./talkAlarm.vue";
import giftJewel from "./giftJewel.vue";
import giftJewelRoom from "./giftJewelRoom.vue";
import password from "./password.vue";
export default {
  name: "ModalContainer",
  emits: ["modalClose"],
  data() {
    return {
      modalComponent: "",
    };
  },
  props: ["isActive", "modalName"],
  components: {
    signUp: SignUp,
    familyCreate: FamilyCreate,
    roomCreate: RoomCreate,
    subscribe: Subscribe,
    familyDonation: FamilyDonation,
    signIn: SignIn,
    giftItem,
    roomPassword,
    talkAlarm,
    giftJewel,
    giftJewelRoom,
    password,
  },
  methods: {
    modalClose(value) {
      this.$emit("modalClose", value);
    },
  },
};
</script>

<style scoped>
.modalWrap {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 9999;
  background: #28282838;
  overflow-y: scroll;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100vh;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
</style>

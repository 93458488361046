export default {
  path: "/popup",
  name: "popup",
  redirect: { name: "msgList" },
  component: () => import("@/views/pc/popup"),
  meta: {
    layout: "popup",
    userOnly: true,
  },
  children: [
    {
      path: "/user/msg",
      name: "userMessage",
      redirect: { name: "msgList" },
      component: () => import("@/views/pc/popup/msg"),
      meta: {
        userOnly: true,
      },
      children: [
        {
          path: "/user/msg/list/:type",
          name: "msgList",
          component: () => import("@/views/pc/popup/msg/list"),
          meta: {
            userOnly: true,
          },
        },
        {
          path: "/user/msg/write/:type",
          name: "msgWrite",
          component: () => import("@/views/pc/popup/msg/write"),
          meta: {
            userOnly: true,
          },
        },
        {
          path: "/user/msg/detail/:type/:idx",
          name: "msgDetail",
          component: () => import("@/views/pc/popup/msg/detail"),
          meta: {
            userOnly: true,
          },
          props: true,
        },
      ],
    },
  ],
};

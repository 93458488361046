<template>
  <nav :class="{ active: navActive }" @click="navClose">
    <div class="nav_wrap">
      <!-- 알람 소리 셋팅 -->
      <div class="alarm_wrap">
        <button @click="this.$store.commit('alarmSet')">
          <i v-if="soundActive" class="fa-volume-up fas"></i>
          <i v-else class="fa-volume-mute fas"></i>
        </button>
      </div>
      <div class="nav_header" v-if="loggedIn">
        <profileImg
          style="width: 40px; height: 40px"
          :img="userInfo.profileImgSrc"
        />
        <div class="nav_profile">
          <div>
            <router-link
              :to="{ name: 'user', query: { userIdx: userInfo.userIdx } }"
              >{{ userInfo.nickName }}</router-link
            >
          </div>
          <div v-if="userInfo.familyNm">
            <router-link
              :to="{ name: 'family', query: { familyIdx: userInfo.familyIdx } }"
              >[{{ userInfo.familyNm }}]</router-link
            >
          </div>
        </div>
        <div class="nav_logOut">
          <button @click="goSignOut">로그아웃</button>
        </div>
      </div>
      <div class="nav_header" v-else>
        <div class="btn_wrap fx col_sbw">
          <button class="btn_login signUp" @click="modalOpen('signUp')">
            회원가입
          </button>
          <button class="btn_login signIn" @click="modalOpen('signIn')">
            로그인
          </button>
        </div>
      </div>

      <!-- ad2 -->
      <article id="ads_2" v-if="B002.idx">
        <img
          :src="`${this.$store.state.adminURL}/api/advert/cmsimg/${B002.imgSrc}`"
        />
      </article>
      <ul class="nav_menu_wrap">
        <li>
          <router-link class="nav_list flex_row main" :to="{ name: 'main' }">
            <img src="@/assets/img/monitor_gray.png" />
            <p>메인</p>
          </router-link>
          <div class="nav_sub_list"></div>
        </li>
        <template v-for="item in gnb" :key="item.name">
          <li>
            <div class="nav_list flex_row" @click="gnbOpen($event, item)">
              <img :src="item.img" />
              <p>{{ item.title }}</p>
            </div>
            <!-- 트랜지션처리 -->
            <div class="nav_sub_list" :class="{ active: item.isActive }">
              <template v-if="item.children">
                <button v-for="child in item.children" :key="child.name">
                  <router-link
                    v-if="child.params"
                    :to="{
                      name: child.name,
                      params: { type: child.params },
                      query: child.query,
                    }"
                    >{{ child.text }}</router-link
                  >
                  <router-link v-else :to="{ name: child.name }">{{
                    child.text
                  }}</router-link>
                </button>
              </template>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import { GNB } from "@/libs/constants";
import { removeAuthData } from "@/libs/auth-helper";
export default {
  name: "Nav",
  props: ["navActive", "loggedIn", "B002"],
  emits: ["navToggle", "modalOpen"],
  data() {
    return {
      isActive: false,
      gnb: this.clone(GNB),
    };
  },
  methods: {
    navClose(event) {
      this.$emit("navToggle", false);
    },
    async goSignOut() {
      removeAuthData();
      this.$router.push({ name: "main" });
      this.$store.dispatch("setUserInfo", null);
      this.$router.go(0);
    },
    // 사이드 메뉴
    gnbOpen(event, item) {
      // console.log(event);
      event.stopPropagation();
      item.isActive = !item.isActive;
    },
  },
  computed: {
    currentPage() {
      return this.$store.getters.currentPage;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    soundActive() {
      return this.$store.getters.soundActive;
    },
  },
  watch: {
    currentPage() {
      this.$emit("navToggle", false);
    },
  },
  created() {},
};
</script>

<style scoped>
nav {
  top: 0;
  left: 0; /* transform: translateX(-100%); */
  position: fixed;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  box-sizing: border-box;
  padding: var(--header) 0 var(--footer);
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
}
nav.active {
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
}
.nav_wrap {
  background: #fff;
  box-shadow: 0px 0px 10px #ebe7e7;
  width: 100vw;
  height: 100vh;
  /* padding-bottom: 20px; */
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.nav_wrap::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.nav_header {
  display: flex;
  position: relative;
  width: 100%;
}
.nav_header .nav_profile {
  align-content: center;
  display: grid;
}

.nav_header .nav_logOut {
  position: absolute;
  right: 13px;
  top: 24px;
}
.nav_header .nav_profile div:nth-child(1) {
  font-weight: 500;
  font-size: 0.8em;
  color: #4a4882;
}
.nav_header .nav_profile div:nth-child(2) {
  font-weight: 500;
  font-size: 0.6em;
  color: rgb(146, 146, 146);
}
.nav_header .nav_logOut button {
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #e6264c;
  color: #fff;
  font-size: 0.5em;
}
.nav_menu_wrap {
  color: rgb(202, 202, 202);
}
.nav_menu_wrap .nav_list > img {
  width: 20px;
  margin-right: 15px;
}
.nav_menu_wrap .nav_list {
  cursor: pointer;
  padding: 0 30px;
  height: 46px;
  position: relative;
  font-size: 1em;
  color: #b3b3b3;
  font-weight: 700;
}
.nav_menu_wrap .nav_list:not(.main)::after {
  content: "";
  width: 30px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 16px;
  background: url(../../assets/img/page_arrow_down.png) no-repeat center center;
}

.flex_row {
  display: flex;
  align-items: center;
}
.nav_menu_wrap .nav_sub_list {
  padding: 0 0 0 30px;
  margin-left: 10px;
  font-size: 1em;
  display: none;
}
.nav_menu_wrap .nav_sub_list.active {
  display: block;
}
.nav_menu_wrap .nav_sub_list button {
  display: block;
  padding: 5px 10px 6px 24px;
}
.nav_menu_wrap .nav_sub_list button a {
  font-size: 0.8em;
  color: #a5a4a4;
  font-weight: 700;
}
.btn_login {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  height: 36px;
}

.btn_login.signUp {
  background: #919191;
}

.btn_login.signIn {
  background-color: #e6264c;
  /* background: linear-gradient(to right top, #e6264c, #ffd676); */
}
.btn_wrap {
  width: 100%;
  padding: 0.6em;
  gap: 0.6em;
}
.profile_img {
  margin: 10px;
}
#ads_2 {
  width: 100%;
  box-shadow: none !important;
}

#ads_2 > img {
  width: 100%;
}
.alarm_wrap {
  padding: 10px;
  text-align: end;
}
.alarm_wrap > button {
  width: 30px;
  height: 30px;
  background-color: #404040;
  border-radius: 100%;
  color: #fff;
  font-size: 0.8rem;
}
</style>

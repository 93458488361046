<template>
  <header>
    <ul class="header_wrap">
      <li class="hamberg_btn">
        <button type="button" @click="navToggle" id="btn-hamberg">
          <i class="fas fa-bars"></i>
        </button>
      </li>
      <li class="gnb_logo">
        <router-link to="/"
          ><img src="@/assets/img/ballpick_logo_4.png"
        /></router-link>
      </li>
      <li class="side_nav">
        <button v-if="!loggedIn" type="button" @click="openModal('signIn')">
          <i class="fas fa-user"></i>
        </button>
        <button
          v-else
          type="button"
          @click="
            pushPage('user', {
              query: { userIdx: this.$store.getters.userInfo.userIdx },
            })
          "
        >
          <i class="fas fa-user-cog"></i>
        </button>
      </li>
    </ul>
  </header>
  <div id="alarm_box">
    <iframe
      :src="`${this.muteFile}`"
      allow="autoplay"
      id="audio"
      style="display: none"
    ></iframe>
  </div>
  <pick-advert :pickData="pickData" @modalOpen="modalOpen" />
</template>

<script>
import pickAdvert from "@/components/Modal_mobile/pickAdvert.vue";
export default {
  name: "Header",
  components: {
    pickAdvert,
  },
  emits: ["navToggle", "modalOpen"],
  data() {
    return {
      chatAlarmActive: false,
      pickAlarmActive: false,
    };
  },
  props: {
    navActive: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    // 1:1 채팅 알람 사운드 설정
    chatAlarmOn() {
      this.chatAlarmActive = true;
      setTimeout(() => {
        this.chatAlarmActive = false;
      }, 2000);
      // 알람 허용 일때만 알람 울림
      if (this.soundActive) {
        document.querySelector("#player")?.remove();
        document
          .querySelector("#alarm_box")
          ?.insertAdjacentHTML(
            "beforeend",
            "<audio id='player' autoplay></audio>"
          );
        document
          .querySelector("#player")
          ?.insertAdjacentHTML(
            "beforeend",
            `<source src = "${this.audioFile}" type = "audio/mp3">`
          );
      }
    },
    // 픽 알람 사운드 설정
    pickAlarmOn() {
      this.pickAlarmActive = true;
      setTimeout(() => {
        this.pickAlarmActive = false;
      }, 1000);
      // 알람 허용 일때만 알람 울림
      if (this.soundActive) {
        document.querySelector("#pickplayer")?.remove();
        document
          .querySelector("#alarm_box")
          ?.insertAdjacentHTML(
            "beforeend",
            "<audio id='pickplayer' autoplay></audio>"
          );
        document
          .querySelector("#pickplayer")
          ?.insertAdjacentHTML(
            "beforeend",
            `<source src = "${this.pickAudioFile}" type = "audio/mp3">`
          );
      }
    },
    // 로그인 모달
    openModal() {
      this.$emit("modalOpen", "signIn");
    },
    navToggle() {
      this.$emit("navToggle", !this.navActive);
    },
    // 1:1 채팅 알람 오픈
    alarmModalOpen() {
      this.$emit("modalOpen", "talkAlarm");
      setTimeout(() => this.$emit("modalClose"), 5000);
    },
  },
  watch: {
    roomData: {
      handler() {
        this.chatAlarmOn();
        this.alarmModalOpen();
      },
    },
    "pickData.length": {
      handler(newleng, oldleng) {
        if (newleng !== 0 && newleng > oldleng) {
          this.pickAlarmOn();
        }
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    roomData() {
      return this.$store.state.talkRoomData;
    },
    pickData() {
      return this.$store.state.pickAlarmData;
    },
    soundActive() {
      return this.$store.getters.soundActive;
    },
    muteFile() {
      const audio = require("../../../src/assets/audio/mute.mp3");
      return audio;
    },
    audioFile() {
      const audio = require("../../../src/assets/audio/alarm2.mp3");
      return audio;
    },
    pickAudioFile() {
      const audio = require("../../../src/assets/audio/alarm1.mp3");
      return audio;
    },
  },
};
</script>

<style scoped>
header {
  height: var(--header);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background-color: #423e31;
  display: flex;
  justify-content: center;
  z-index: 9999;
}
.header_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header_wrap .side_nav img {
  width: 25px;
}
.header_wrap .hamberg_btn {
  display: flex;
  width: 15%;
  justify-content: center;
}

.gnb_logo {
  min-width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gnb_logo img {
  /* margin-right: 120px; */
  width: 100px;
  display: inline-block;
}
.header_wrap .side_nav {
  width: 15%;
  display: flex;
  justify-content: center;
}
.header_wrap button {
  color: #fff;
}
</style>

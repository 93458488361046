<template>
  <article class="modalWrap custom_scroll">
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="modalClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="logo_symbol">
          <img src="@/assets/img/ballpick_logo_1.png" />
        </div>
        <div class="sign_form">
          <fieldset class="input_fs">
            <legend>회원 검색</legend>
            <input
              type="text"
              v-model="searchModel.searchUserNm"
              placeholder="닉네임을 입력해주세요."
            />
          </fieldset>
          <div class="overlap_check">
            <button class="overlap_btn" @click="searchUser">검색</button>
          </div>
        </div>
        <div class="signUp">
          <button class="signUp_btn" type="button" @click="subscribeAdd">
            구독하기
          </button>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { apiSubscribeAdd, apiSearchUser } from "@/api/user";

export default {
  name: "SubscribeAdd",
  data() {
    return {
      model: {
        nickName: "",
      },
      searchModel: {
        searchUserNm: "",
        page: 1,
        rowCnt: 1,
      },
      userCheck: false,
      target: {
        celebIdx: 0,
      },
    };
  },
  methods: {
    modalClose() {
      this.$emit("modalClose", false);
    },
    // 선물하기
    async subscribeAdd() {
      const req = this.target;
      if (!this.userCheck) {
        alert("구독할 회원의 닉네임을 확인해주세요.");
        return;
      }
      try {
        const res = await apiSubscribeAdd(req);
        if (res) {
          alert("회원 구독 성공");
          await this.refreshUserInfo();
          this.modalClose();
          this.emitter.emit("Getceleb");
        }
      } catch (err) {
        alert(err);
      }
    },
    // 회원 검색
    async searchUser() {
      const req = this.searchModel;
      const res = await apiSearchUser(req);
      if (!req.searchUserNm) {
        alert("구독할 회원을 입력해주세요.");
        return;
      }
      if (res) {
        try {
          if (res.list.length !== 0) {
            const user = res.list[0];
            if (user.nickName !== req.searchUserNm) {
              // console.log("new Error");
              // throw new Error("No User");
            } else {
              this.target.celebIdx = user.userIdx;
              this.userCheck = true;
              alert("유저확인완료.");
            }
          } else {
            throw new Error("No User");
          }
        } catch (e) {
          // console.log(e);
          alert("존재하지 않는 유저입니다. 다시 확인해주세요.");
        }
      }
    },
  },
  watch: {
    searchModel: {
      handler(model) {
        if (model.searchUserNm == "") {
          this.target.celebIdx = "";
          this.userCheck = false;
        }
      },
      deep: true,
    },
  },
  computed: {},
  beforeRouteEnter(to, from, next) {
    const user = getUserData();
    if (!!user) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.modalWrap {
  position: fixed;
  z-index: 9999;
  background: #28282838;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9em;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;

  position: relative;
  padding: 30px 48px;
  box-sizing: border-box;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 60px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.input_fs {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input {
  /* margin: .4rem; */
  /* border: none; */
  /* padding: 20px; */
  font-size: 1.4em;
  margin-left: 17px;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.overlap_check {
  display: flex;
  margin-top: 10px;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}
</style>

oninput
<template>
  <article>
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <div class="close">
          <button class="close_btn" @click="modalClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="logo_symbol">
          <img src="@/assets/img/ballpick_logo_1.png" />
        </div>
        <div class="sign_form">
          <fieldset class="input_fs">
            <legend>보유포인트</legend>
            <span>{{ addCommas(clonePoint - model.donationPoint) }}</span>
          </fieldset>
        </div>
        <div class="sign_form">
          <fieldset class="input_fs">
            <legend>기부할 가족점수</legend>
            <input
              type="text"
              :value="addCommas(model.donationPoint)"
              @input="convertPoint"
            />
          </fieldset>
        </div>
        <div class="warning_word">
          <div class="warning_text">
            <span>1:1 비율로 포인트를 가족방점수로 기부가 가능합니다.</span>
            <span>한번 전환한 가족포인트는 되될릴 수 없습니다.</span>
            <span></span>
          </div>
        </div>
        <div class="signUp">
          <button class="signUp_btn" type="button" @click="donate">
            기부하기
          </button>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { apiFamilyDonation } from "@/api/family";
import { getUserData } from "@/libs/auth-helper";
import { onlyNumber } from "@/libs/utils";
import lodash from "lodash";

export default {
  name: "familyDonation",
  emits: ["modalClose"],
  data() {
    return {
      model: {
        donationPoint: 0,
      },
    };
  },
  methods: {
    // 가족방 포인트 기부
    async donate() {
      const req = this.model;
      if (req.donationPoint < 1) {
        alert("기부할 포인트를 입력해주세요.");
        return;
      }
      req.familyIdx = this.familyIdx;
      const res = await apiFamilyDonation(req);
      if (res) {
        this.refreshUserInfo();
        alert("기부완료");
        this.modalClose(true);
      }
    },
    convertPoint(e) {
      e.target.value = onlyNumber(e);
      this.model.donationPoint = this.deleteCommas(e.target.value);
    },
    modalClose(succ) {
      this.$emit("modalClose", succ);
    },
  },
  computed: {
    familyIdx() {
      return this.$route.query.familyIdx;
    },
    clonePoint() {
      return lodash.cloneDeep(this.$store.getters.userInfo.point);
    },
  },
  watch: {
    "model.donationPoint": {
      handler(value) {
        if (value > this.clonePoint) {
          this.model.donationPoint = this.clonePoint;
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    const user = getUserData();
    if (!!user) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  backdrop-filter: blur(3px);
}

.signUp_wrap .signUp_contents {
  width: 566px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;

  position: relative;
  padding-bottom: 40px;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 80px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  padding: 30px 30px 0px 30px;
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
}

.input_fs {
  padding: 10px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 340px;
  /* height: 45px; */
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input,
.input_fs > span {
  font-size: 1.4em;
  margin-left: 17px;
  font-weight: 700;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.warning_word {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 20px;
}

.warning_text {
  display: inline-grid;
}

.warning_text span {
  color: #a3a1a1;
  font-size: 0.95em;
}

.warning_text span:last-child {
  color: #0c0c0c;
  font-size: 1.4em;
  font-weight: 700;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.signUp_btn {
  width: 348px;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}
</style>

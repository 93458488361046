<template>
  <div class="signUp_wrap">
    <div class="signUp_contents">
      <div class="close">
        <button class="close_btn" @click="modalClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="logo_symbol">
        <img src="@/assets/img/icon_caution.png" />
      </div>
      <div class="text_box">
        <h1>경고</h1>
        <p>{{ this.message }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["message"],
  emits: ["modalClose"],
  data() {
    return {};
  },
  methods: {
    modalClose() {
      this.$emit("modalClose", false);
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9em;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;
  position: relative;
  padding: 30px 48px;
  box-sizing: border-box;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}
.text_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  /* width: 100%; */
}
.text_box h1 {
  font-size: 2.3rem;
  font-weight: 700;
  /* margin-left: 10px; */
}

.text_box p {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 400;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}
</style>

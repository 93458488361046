<template>
  <div class="signUp_wrap">
    <div class="signUp_contents">
      <div class="close">
        <button class="close_btn" @click="modalClose">
          <i class="fas fa-times"></i></button>
      </div>
      <div class="logo_symbol">
        <img src="@/assets/img/ballpick_logo_1.png" />
      </div>
      <div class="sign_form">
        <fieldset class="input_fs">
          <legend>채팅방명</legend>
          <input type="text" maxlength="15" v-model="model.roomsTit" placeholder="15글자 이내의 방제목을 입력해주세요." />
        </fieldset>
      </div>
      <div class="sign_form">
        <fieldset class="input_fs">
          <legend>구매타입</legend>
          <select v-model="model.openMoney">
            <option value="CS">캐시</option>
            <option value="PN">포인트</option>
          </select>
        </fieldset>
      </div>
      <div class="sign_form">
        <fieldset class="input_fs">
          <legend>게임선택</legend>
          <select v-model="model.gameType">
            <option value="DH">동행파워볼</option>
            <option value="DL">동행사다리</option>
            <option value="EOS5">EOS파워볼 5분</option>
            <option value="EOS3">EOS파워볼 3분</option>
            <option value="CB5">코인파워볼 5분</option>
            <option value="CB3">코인파워볼 3분</option>
            <option value="MB5">메타파워볼 5분</option>
            <option value="MB3">메타파워볼 3분</option>
            <option value="CL5">코인사다리 5분</option>
            <option value="CL3">코인사다리 3분</option>
            <option value="ML5">메타사다리 5분</option>
            <option value="ML3">메타사다리 3분</option>
            <option value="PBG">PBG파워볼</option>
          </select>
        </fieldset>
      </div>
      <div class="sign_form">
        <fieldset class="input_fs">
          <legend>공개여부</legend>
          <select v-model="secret">
            <option :value="false">공개방</option>
            <option :value="true">비공개방</option>
          </select>
        </fieldset>
      </div>
      <div class="sign_form" v-if="secret">
        <fieldset class="input_fs">
          <legend>비밀번호</legend>
          <input type="password" maxlength="10" v-model="model.roomsPw" placeholder="비밀번호를 입력해주세요." />
        </fieldset>
      </div>
      <div class="sign_form" v-if="model.openMoney == 'CS'">
        <fieldset class="input_fs">
          <legend>방 타입</legend>
          <select v-model="this.model.chatItemIdx">
            <option value="">개설할 방을 선택해주세요</option>
            <template v-if="items.length!==0">
              <option v-for="item in items" :key="item" :value="item.idx">{{item.desc}} - {{ item.chatTime }}시간 :: {{ item.cash }}캐시</option>
            </template>
            <option v-else>
              <option>방 개설 아이템이 없습니다.</option>
            </option>
          </select>
        </fieldset>
      </div>
      <div class="sign_form" v-if="model.openMoney =='PN'">
        <fieldset class="input_fs">
          <legend>방 타입</legend>
          <select v-model="this.model.chatItemIdx">
            <option value="">개설할 방을 선택해주세요</option>
            <template v-if="items.length!==0">
              <option v-for="item in items" :key="item" :value="item.idx">{{item.desc}} - {{ item.chatTime }}시간 :: {{ item.point }}포인트</option>
            </template>
            <option v-else>
              <option>방 개설 아이템이 없습니다.</option>
            </option>
          </select>
        </fieldset>
      </div>
    
      <div class="signUp">
        <button class="signUp_btn" :class="{disable:!createActive}" @click="create">채팅방 개설하기</button>
      </div>
    </div>
  </div>
</template>
<script>
import { apiChatItemList, apiRoomOpen } from "@/api/chat";
import { getUserData } from "@/libs/auth-helper";

export default {
  name: "chatRoomCreate",
  data() {
    return {
      model: {
        roomsTit: "",
        chatItemIdx:"",
        gameType: "DH",
        openMoney:"CS",
        roomsPw:"",
      },
      cnt:0,
      createActive:false,
      items:[],
      secret:false
    };
  },
  emits:['modalClose'],
  methods: {
    modalClose() {
      this.$emit("modalClose", false);
      
  },
    // 채팅방 개설하기
    async create() {
      if(!this.createActive) return;
      const req = this.clone(this.model);
      for (const key in req) {
        if (!req[key]) {
          delete req[key];
        }
      }
      for(const key in req){
        if(!req[key] && key!=='roomsPw'){
          alert(this.$t(`alert.${key}`));
          return
        }
      }
      if(this.secret){
        if(!this.model.roomsPw){
          alert(this.$t(`alert.secretRoom`));
          return
        }
      }
      try {
        const res = await apiRoomOpen(req);
        if (res) {
          alert('방 생성 완료');
          this.modalClose();
          this.emitter.emit('roomCreateResult')
        }
      } catch (err) {
        console.error(err);
        this.$router.push({ name: "chat_rank" });
      }
    },
    // 채팅 개설권 리스트 
    async getItemList(){
      const res = await apiChatItemList();
      if(res.list){
        this.items = res.list;
      }
    }
  },
  watch:{
    model:{
      handler(model){
        this.cnt = 0
        for(const key in model){
          if(!model[key]){
          } else {
            if(key =='chatItemIdx'&& typeof(model[key]) !== 'number'){
                return
            }
            this.cnt++
          }
        }
        if(this.secret){
          if(this.cnt >4){
            this.createActive = true
          }else{
            this.createActive = false
          }
        }else{
          if(this.cnt >3){
            this.createActive = true
          }else{
            this.createActive = false
          }
        }
        
      },
      deep:true,
    },
    secret:{
      handler(bool){
        if(bool){
          if(this.cnt >4){
            this.createActive = true
          }else{
            this.createActive = false
          }
        }else{
          this.model.roomsPw = ""
          if(this.cnt >3){
            this.createActive = true
          }else{
            this.createActive = false
          }
        }
      }
    },
  },
  computed: {},
  async created(){
    await this.getItemList();
  },
  beforeRouteEnter(to, from, next) {
    const user = getUserData();
    if (!!user) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9em;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;
   
  position: relative;
  padding:30px 48px 48px;
  box-sizing: border-box;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 40px;
}

.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}

.sign_form {
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top:30px;
  width:100%;
}

.input_fs {
  padding: 3px;
  border: 1px solid rgb(205 195 195);
  border-radius: 8px;
  width: 100%;
  height: 56px;
}

.input_fs > legend {
  color: rgb(177, 177, 177);
  padding: 0px 6px;
  font-size: 1em;
  font-weight: 700;
  margin-left: 10px;
}

.input_fs > input,
.input_fs > select {
  font-size: 1.2em;
  padding: 5px 17px;
  font-weight: 700;
  width:100%;
  box-sizing: border-box;
}

.input_fs > input::placeholder {
  color: rgb(197, 197, 197);
  font-size: 0.8em;
  font-weight: 700;
}

.signUp {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

</style>

<template>
  <div class="modalWrap">
    <div class="signUp_wrap">
      <div class="signUp_contents">
        <span>1:1대화를 신청하셨습니다.</span>
        <profileImg :img="roomData.toProfile" />
        <span>{{ roomData.fromNickName }}님</span>
        <div class="btn_wrap">
          <button type="buttton" @click="openTalk(this.roomData)">수락</button>
          <button type="buttton" @click="modalClose">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["modalClose"],
  data() {
    return {};
  },
  methods: {
    // 알림 모달 닫기
    modalClose() {
      this.$emit("modalClose", false);
    },
    // 해당 1:1 대화방으로 이동
    async openTalk(data) {
      const model = {
        toUserIdx: data.toUserIdx,
      };
      this.$emit("modalClose", false);

      // 방 존재 => 바로 방으로 연결
      const routerLink = this.$router.resolve({
        name: "personal_talk_room",
        query: {
          userIdx: data.fromUserIdx,
          roomcode: data.roomsCode,
          roomidx: data.roomIdx,
        },
      });
      window.open(
        routerLink.href,
        `${data.roomsCode}`,
        "width=540px,height=750px,top=0,left=0,scrollbars=yes,resizable=yes"
      );
    },
  },
  watch: {},
  computed: {
    roomData() {
      return this.$store.state.talkRoomData;
    },
  },
};
</script>

<style scoped>
.modalWrap {
  position: fixed;
  z-index: 9999;
  background: #28282838;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
}
.signUp_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.signUp_wrap .signUp_contents {
  width: 566px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 10px #464646;
  position: relative;
  padding: 50px 30px;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.signUp_contents .logo_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.signUp_contents > span {
  font-size: 1.2rem;
  font-weight: 800;
}
.logo_symbol img {
  width: 174px;
  /* height: 68px; */
}
.text_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  /* width: 100%; */
}
.text_box h1 {
  font-size: 2.3rem;
  font-weight: 700;
  /* margin-left: 10px; */
}

.text_box p {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 400;
}
.close {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 1%;
  right: 2%;
}

.btn_wrap > button {
  padding: 10px 15px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  margin: 0 10px;
  cursor: pointer;
}
.btn_wrap > button:hover {
  font-weight: 800;
}
.btn_wrap > button:nth-child(1) {
  background-color: #ffce32;
}
.btn_wrap > button:nth-child(2) {
  background-color: #ddd;
}
</style>
